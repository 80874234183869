import React from 'react';

import { Checkbox,ButtonWithOptions, Button, Color } from '@swivl/great-grey-components';
import './DataPointsView.scss';
import { DataSet, User,HoursMinutesSeconds, DataSetCreationMethod,Capitalize,NumberWithCommas, CreateLogger, DataSetRow, ColmunTypesForRows, DataSetColumnType, CategoryData, DataPoint, DataPointStatus } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';
import { Config } from '../../../Models/Models/Config/Config';
import Service from '../../../Services/Service';
import { ActionsConsumer, ActionType, Actions, Action } from '../../../Actions/Actions';
import { DataTable } from '../../../Components/DataTable/DataTable';
import CreateDataSetFromDataPointsModal from './CreateDataSetFromDataPointsModal/CreateDataSetFromDataPointsModal';


const log = CreateLogger("DataPointsDetailsView")

const moment = require('moment');
var pluralize = require('pluralize')

interface DataPointsDetailsViewProps {

}
interface DataPointsDetailsViewState {
  selectedRows:string[]
  isDeleting:boolean; 
  isCreatingJob:boolean;
}

export  class DataPointsDetailsView extends ActionsConsumer<DataPointsDetailsViewProps,DataPointsDetailsViewState> {
  constructor(props:any) {
    super(props)
    this.state = {selectedRows:[], isCreatingJob:false, isDeleting:false };
    // this.fetchData  = this.fetchData.bind(this);
    this.setSelectedRows       = this.setSelectedRows.bind(this)


    this.onProgress = this.onProgress.bind(this);

    this.checkboxClicked   = this.checkboxClicked.bind(this)

    this.removeRows        = this.removeRows.bind(this)

    this.removeClicked     = this.removeClicked.bind(this)
    this.createJobClicked  = this.createJobClicked.bind(this)
    this.renderHeader      = this.renderHeader.bind(this)
  }
  componentDidMount() { 
    Actions.Controller.subscribeTo(this, [
      ActionType.DataPointsLoaded
      ] )
      
   }
   handleAction(action:Action) {
    console.log("HANDLE ACTION", action)   
    this.forceUpdate();
  
  }

  setSelectedRows(selectedRows:string[]) {
    this.setState({selectedRows})
  }
 
  onProgress(progress:number) {
    console.log("progress", progress);
    const element = document.getElementById("dataSetProgress");
    if (element) {
     element.setAttribute("value", String(progress));
    }
  }
  onError(error:Error) {
    console.warn(error);
  }

  checkboxClicked(name:string, checked:boolean) {

    // if (name === "SelectAllCheckbox") {
    //   if (checked) {
    //     const rowData:Array<DataSetRow> = this.props.dataSet.rows;
    //     this.setState({selectedRows:rowData.map(row=>row.id)});
    //   } else {
    //     this.setState({selectedRows:[]});
    //   }
    //   return;
    // }

    // let selectedRows = this.state.selectedRows;

    // if (checked) { 
    //   selectedRows.push(name)
    //  } else {
    //    for (let index = 0; index < selectedRows.length; index++) {
    //      if (selectedRows[index] === name) {
    //       selectedRows.splice(index, 1);
    //       break;
    //      }
         
    //    }
    //  }
    //  this.setState({selectedRows:selectedRows});
    //  log("selectedRows", selectedRows, name, checked)
  }
  removeClicked() {
    const dataPointIds = this.dataPointIdsForRowIts()
    if (!dataPointIds.length) {   return;  }
    Model.DataPoint.ignoreDataPoints(dataPointIds)
    this.setState({selectedRows:[]})
    // if (window.confirm("Are you sure you want to delete this data set? The action cannot be undone.")) {
    //   Model.DataSet.delete(this.props.dataSet);
    //   Model.Navigation.setPath("/dataset")
    // }
  }
  
  createJobClicked() {

  }
  createNewJob = () => {

  }
  
  removeRows() {

  }
  renderHeader() {
   

let    buttonOptions = []
const array = Model.DataPoint.unnasigned;
const count = (array) ? array.length : 0;
    return <div className="detailsHeader">
            <div className="actionButton">
              <ButtonWithOptions 
                isLoading={this.state.isCreatingJob}
                onClick={this.createDataSetClicked}
                buttonOptions={buttonOptions} 
                isDisabled={(this.state.selectedRows.length == 0)}
              
              >Assign to Data Set</ButtonWithOptions>
            </div>
            <h2>Unassigned Data Points</h2>
            <br/>
            
         
            <br/>
            {NumberWithCommas(count)} Data Points

            {this.renderDeleteRowsButton()}

          </div>;
  }

  dataPointIdsForRowIts = ():string [] => {
    if (!this.state.selectedRows.length) { 
      return []; 
    }
    const selectedRows = this.state.selectedRows
    const array = Model.DataPoint.array;
    var dataPointIds = []
    for (let i = 0; i < array.length; i++) {
      if (selectedRows.includes(array[i].row.id)) {
        dataPointIds.push(array[i].id)
      }
      
    }
    return dataPointIds
  }

  createDataSetClicked = () => {
    const dataPointIds = this.dataPointIdsForRowIts()
    if (!dataPointIds.length) {   return;  }
    // const selectedRows = this.state.selectedRows
    // const array = Model.DataPoint.array;
    // var dataPointIds = []
    // for (let i = 0; i < array.length; i++) {
    //   if (selectedRows.includes(array[i].row.id)) {
    //     dataPointIds.push(array[i].id)
    //   }
      
    // }
    Actions.Controller.trigger({type:ActionType.ShowModal, modalContent:<CreateDataSetFromDataPointsModal dataPointIds={dataPointIds} unasigneSelected={this.unasigneSelected}/>})

  }
  unasigneSelected = () => { this.setState({selectedRows:[]})}


  renderDeleteRowsButton() {    
    if (this.state.selectedRows.length > 0 || this.state.isDeleting)   {
        return <div className="deleteButton">
          <Button isLoading={this.state.isDeleting} onClick={this.removeClicked} >
            Ignore {pluralize("Row",this.state.selectedRows.length, true )}
            </Button>
          </div> 
    }
       
    return null;
  }

  


  render() {
    let content ;


    const array = Model.DataPoint.unnasigned;
    console.log("Unnassigned datapoints",array);
    
    if (array) {
      const rows = array.map(i =>  i.row);

      content =     <DataTable 
                      rows={rows} 
                      selectedRows={this.state.selectedRows} 
                      setSelectedRows={this.setSelectedRows}
                      />

    } else {
      content  =  <div className="ProgressBlock">
                    <progress id="dataSetProgress" className="progress is-primary" value="0" max="100">50%</progress>
                  </div>;
    }
    return <div className="DataPointsDetailsView">
    {this.renderHeader()}
    {content}
    </div>;
  }

}
