import React from 'react';
import { Select,Option, Button, Color, Pill } from '@swivl/great-grey-components';
import {  Model, } from '../../../../../Models/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CreateLogger} from '@swivl/great-grey-lib';

import "./MLModelSelectionView.scss"
import { ActionsConsumer, Actions, ActionType, Action } from '../../../../../Actions/Actions';
import {Job,MLModel, JobStatus } from '@swivl/great-grey-lib';

const log = CreateLogger("job:MLModelSelectionView");

interface MLModelSelectionViewModelItemProps {
  id:string; 
  name:string; 
  deleteMLModel(modelId:string);
}
class MLModelSelectionViewModelItem extends React.Component<MLModelSelectionViewModelItemProps> {
  render() {

    if (!Model.MLModel.state.items) {
      return <FontAwesomeIcon icon={faSpinner} spin={true} />
    }
    const model = Model.MLModel.state.items[this.props.id];
    if (!model) {
      return <Pill name={this.props.id} deleteClicked={this.props.deleteMLModel}>MISSING MODEL: {this.props.name}</Pill>
    }
    return <Pill name={this.props.id} deleteClicked={this.props.deleteMLModel}>{model.name}</Pill>

  }
}



interface MLModelSelectionViewProps {
  job:Job,
  setMLLoadingStatus(isLoadingMLData:boolean)
}
interface MLModelSelectionViewState {
  showModelSelectionView:boolean;
  selectedMLModelToAdd?:MLModel; 
}

export  class MLModelSelectionView extends ActionsConsumer<MLModelSelectionViewProps,MLModelSelectionViewState> {
  constructor(props:any) {
    super(props)
    this.state = {showModelSelectionView:false};
    this.addMLModelClicked = this.addMLModelClicked.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveClicked = this.saveClicked.bind(this)
    this.cancelClicked = this.cancelClicked.bind(this);
    this.deleteMLModel = this.deleteMLModel.bind(this)
    this.checkForLoadedData = this.checkForLoadedData.bind(this)
    Actions.Controller.subscribeTo(this, [ActionType.MLModelsLoaded, ActionType.MLModelDataFetched])
  }
  componentDidMount(){
    if (Model.MLModel.state.items)  {
      this.checkForLoadedData() 
    } else {
      Model.MLModel.load() 
    }
  }

  handleAction(action:Action) {
    if (action.type === ActionType.MLModelsLoaded)  {
      this.checkForLoadedData() 
    }
    this.forceUpdate()
  }

  addMLModelClicked() {this.setState({showModelSelectionView:true}); }
  onChange(selectedOption:Option) {
    const model =  Model.MLModel.state.items[selectedOption.value];
    this.setState({selectedMLModelToAdd:model});
  }
  saveClicked() {
    let job = {...this.props.job}; 
    job.models[this.state.selectedMLModelToAdd.id] = this.state.selectedMLModelToAdd.name; 
    Model.Job.addModelToJob(job, this.state.selectedMLModelToAdd);

    this.checkForLoadedData();
    this.setState({selectedMLModelToAdd:null,showModelSelectionView:false});
  }
  cancelClicked() { this.setState({showModelSelectionView:false});  }
  deleteMLModel(modelId:string) {
    let job = this.props.job;     
    delete job.models[modelId];
    Model.Job.update(job, true);
  }


  checkForLoadedData() {
    log("checkForLoadedData")
    const models = this.props.job.models;
    
    for (const key in models) {
      if (models.hasOwnProperty(key)) {
        // const model = Model.MLModel.byId(key)
        // if (!model) { log.warn("Missing model", models[key]); continue; }
        // if (!model.isLoadingData && !model.data) {
        //   model.fetchData(
        //       (progress)=>{ log("Fetching", progress)},
        //       (error)=>{ log("Error Fetching Data", error)});
        // }
      }
    }
  }




  renderAddMLModel() {
    if (this.props.job.status !==  JobStatus.Unstarted) { return null; }
    if (!Model.MLModel.state.items) { return null; }
    if (!this.state.showModelSelectionView) {
      return <Button isDashed={true} isRound={true} onClick={this.addMLModelClicked}>+ Add ML Model</Button>
    }
    const mlModels = Model.MLModel.array;
    let content; 
    if (mlModels) {
      const options:Array<Option> = mlModels.map((item:MLModel) => { return { value: item.id, label:item.name }})
      const value = (this.state.selectedMLModelToAdd) ? { value: this.state.selectedMLModelToAdd.id, label:this.state.selectedMLModelToAdd.name } : null;

      return <div className="AddMLModel">
              <h4>Select ML Model To Add</h4>
        <div className="selectRow">
          <div className="selectML">
        <Select 
                options={options}
                value={value}
                isSearchable={true}
                placeholder="Select ML Model..."
                onChange={this.onChange}
              />
              </div>
        <Button 
          color={Color.Primary}
          onClick={this.saveClicked}
          isDisabled={!this.state.selectedMLModelToAdd}>Save</Button>
          <Button onClick={this.cancelClicked}>Cancel</Button>
        </div>
      </div>
              
    } else {
      return <div className="AddMLModel">
                  <h4>Loading ML Models</h4>
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
              </div>
    }
    
  }

  renderModels() {


    let modelsAssigned = [];
    const models = this.props.job.models;
    for (const key in models) {
      if (models.hasOwnProperty(key)) {
        if (this.props.job.status ===  JobStatus.Unstarted) {
        modelsAssigned.push(<MLModelSelectionViewModelItem 
                              name={models[key]} 
                              id={key} 
                              key={key} 
                              deleteMLModel={this.deleteMLModel} />)
        } else {
          modelsAssigned.push(<Pill hideDelete>{models[key]}</Pill>)
        }
      }
    }
    if (modelsAssigned.length > 0) {
      return <div className="assignedModels">{modelsAssigned}</div>
    }
    return null;
  }

  
  render() {
    
    return <div className="MLModelSelectionView clearfix">
      <div className="container">
        <h4>MACHINE LEARNING MODEL</h4>
        {this.renderModels()}
        {this.renderAddMLModel()}
      </div>
  
    </div>;
  }

}
