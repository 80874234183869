import React from 'react';
import "./LoadingScreen.scss";


export default class LoadingScreen extends React.Component {
  constructor(props:any) {
    super(props)
  }


  render() {
      return <div className="LoadingScreen">
        <div className="loadingContent">
          <div className="hoverWave"><img src="/img/greatgrey.png" /></div>
          <div className="loadingLogo"><img src="/img/swivl_logo_dark.png" /></div>
        <div className="loadingBar">LOADING</div>
        </div>
      </div> 
       
  }

}
