import React from 'react';
import { MembershipRole, Organisation, OrganisationMembership, Project, ProjectMembership } from '@swivl/great-grey-lib';
import "./OrgMemberView.scss"
import { Model } from '../../../../Models/Model';
import { Option, Select } from '@swivl/great-grey-components';

// import * as Model from '../../Models/Model';
interface State {
 
    
}
interface Props {
    membership:OrganisationMembership
    org:Organisation
 }
 const options = [
  { value: MembershipRole.Admin, label: 'ADMIN' },
  { value: MembershipRole.Member, label: 'MEMBER' },
];
export default class OrgMemberView extends React.Component<Props,State> {
 
  constructor(props:Props) {
    super(props)
    this.removeMember = this.removeMember.bind(this)
    this.onRoleChange = this.onRoleChange.bind(this)
    
  }
  removeMember() {
    Model.OrgMembership.removeUserFromOrg(this.props.membership, this.props.org)
  }
   onRoleChange(selectedOption:Option){
    // item.role = selectedOption.value as MembershipRole;
    // itemUpdated(item) 
    var membership = this.props.membership 
    membership.role = selectedOption.value as MembershipRole;

    Model.OrgMembership.update(membership)
  }
    render() {
      const user = this.props.membership.user 
        
  return <div className="OrgMemberView">
          <div className="squareAvatar"></div>
            <span className="name">{user.firstName} {user.lastName}</span>
            <span className="email">{user.email}</span>
            <span className="location">{user.location}</span>
            <div className="role"><Select options={options} value={this.props.membership.role} onChange={this.onRoleChange} /></div>
            <a className="remove" onClick={this.removeMember}>Remove</a>

  </div>;
}

}
