import { MLModel, GGDictionary,MLModelTypeForData, MLModelData, CreateLogger} from '@swivl/great-grey-lib';
import { ActionType, Actions, ItemsLoadedAction } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import { Model } from '../Model';
import Service from '../../Services/Service';
const log = CreateLogger("MLModel")


export interface MLModelModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<MLModel>
  dataFiles:GGDictionary<MLModelData>
}> {}

export class MLModelModel extends BaseModel<MLModel,MLModelModelState>  {
  static get Model():MLModelModel {   if (MLModelModel._Model) { return MLModelModel._Model; }  else {   MLModelModel._Model = new MLModelModel(); return MLModelModel._Model ; } }

  
  private static _Model:MLModelModel;
  // private constructor() { super();  this.state = {...this.state, dataFiles:{}} }

  state:MLModelModelState = {
    isLoading:false,
    items:undefined,
    dataFiles:{}
  }

  ENTITY_NAME = "mlmodel";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.MLModelsLoaded,
    ItemUpdated : ActionType.MLModelUpdated,
    ItemDeleted : ActionType.MLModelDeleted,
    ItemCreated : ActionType.MLModelCreated
  } 

  

  createAndAddNewMLModel(name:string,  data:MLModelData):Promise<MLModel> {
    console.log("ML Model",Model.Session.state);

    const mLModel:any = {
      name: name,
      type:MLModelTypeForData(data),
      data: data,
      createdById:Model.Session.state.user.id,
      createdBy:Model.Session.state.user.id,
      organisationId:Model.Session.state.currentMembership.organisation.id,
      projectId:Model.Session.state.currentProjectMembership.projectId

    }

    log("ML MODEL WE ARE CREATING", mLModel)
    return this.create(mLModel).then((createdMLModel) => {
    Model.Navigation.setPath("/model/"+createdMLModel.id)
      return Promise.resolve(createdMLModel)
    }).catch((e) => {
      Service.Toast.error(e, "Error creating job.");
    })
  }

}
