import React, { createRef } from 'react';
import { CreateLogger, ShortId, TimelineCategory, TimelineSegment } from '@swivl/great-grey-lib';
import "./SelectionView.scss"
import { Pos } from '../TimelineView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'




const log = CreateLogger("AudioSplitExersiseView:SelectionView");

const MIN_WIDTH = 20; 
const SELECTION_PLAY_ID = "SelectionPlayId"
interface SelectionViewRowProps {
    left:number;
    width:number;
    doneSelecting:boolean;
    trackIndex:number;
    setShow(show:boolean);
    addSegmentToCategory(trackIndex:number, segment:TimelineSegment);

}

class SelectionViewRow extends React.Component<SelectionViewRowProps> {
    constructor(props:any) {
        super(props) 
        this.addClicked = this.addClicked.bind(this);
    }
    addClicked() {
        const segment = {   id:ShortId(), 
                            startTime:this.props.left * 0.05, 
                            endTime:(this.props.left + this.props.width) * 0.05}
        this.props.addSegmentToCategory(this.props.trackIndex, segment);
        this.props.setShow(false);
    }
    render() {
        if (!this.props.doneSelecting) { return <div className="rowSelection" />; }
        return <div className="rowSelection doneSelecting">
                    <div className="selectionButton" onClick={this.addClicked}>
                        <div className="selectionIcon">
                            <span/><span className="cross" />
                            </div>
                    </div>
                </div>;
    }
}



interface SelectionViewProps {
    isPlaying:boolean;
    playingId?:string;
    pause();
    playAtTime(time:number, duration?:number, playingId?:string);
    categories:TimelineCategory[]

    addSegmentToCategory(trackIndex:number, segment:TimelineSegment);
}

interface SelectionViewState {

    show:boolean;
    doneSelecting:boolean  


    startX:number;
    endX:number; 
}

interface LeftAndWidth {
    left:number 
    width:number
}

export class SelectionView extends React.Component<SelectionViewProps,SelectionViewState> {
    constructor(props:any) {
        super(props) 
        this.state =  {startX:0, endX:0, show:false, doneSelecting:false}
        this.leftAndWidth           = this.leftAndWidth.bind(this)
        this.setStartX              = this.setStartX.bind(this)
        this.setEndX                = this.setEndX.bind(this)
        this.setShow                = this.setShow.bind(this)
        this.doneSelecting          = this.doneSelecting.bind(this);
        this.playButtonSelected     = this.playButtonSelected.bind(this);
    }

    leftAndWidth(startXParam?:number,  endXParam?:number):LeftAndWidth {
        const startX = (typeof startXParam !== 'undefined') ? startXParam : this.state.startX; 
        const endX = (typeof endXParam !== 'undefined') ? endXParam : this.state.endX; 
        const left = (startX > endX) ? endX : startX; 
        const width = (startX > endX) ? startX-endX : endX-startX; 
        return {left:left, width:width}
    }

    setStartX(x:number) { this.setState({startX:Math.max(0,x), show:false, doneSelecting:false}); }
    setEndX(endX:number) {
        const {width} = this.leftAndWidth(this.state.startX, endX);
        if (width >=   1) {
            this.setState({endX:endX, show:true}); 
        } else {
            this.setState({endX:endX, show:false}); 
        }
        }
    setShow(show:boolean) { this.setState({show:show, doneSelecting:false}); }
    doneSelecting(endX:number) {

        const {startX, show} = this.state;
        if(!show) { return; }
        const { width} = this.leftAndWidth(startX, endX);

        if (width >=  MIN_WIDTH) {
            this.setState({endX:endX, doneSelecting:true})
        }  else {
            this.setState({show:false, doneSelecting:false})
        }
    }



    

    playButtonSelected() {
        const { left, width} = this.leftAndWidth();
        this.props.playAtTime(left * 0.05, width * 0.05, SELECTION_PLAY_ID)
    }

  renderMainSelection() {
    if (!this.state.doneSelecting) { return <div className="mainSelection" />; }
    const button = (this.props.playingId === SELECTION_PLAY_ID) ?
    <div className="playButton" onClick={this.props.pause}><div className="playButtonIcon"><FontAwesomeIcon icon={faPause} /></div></div>
    : <div className="playButton" onClick={this.playButtonSelected}><div className="playButtonIcon playIcon"><FontAwesomeIcon icon={faPlay} /></div></div>



    return <div className="mainSelection doneSelecting">
        {button}
            </div>;
  }


  render() {    
      
    //   const {isSelecting,isMouseUp, showSelection, mouseDown, mouseUp, cursor} = this.props; 
    // if ((!isSelecting || !showSelection)|| !mouseDown || !mouseUp || !cursor) { return null; }
    //     const startX = mouseDown.x;
    //     const endX = (isMouseUp) ? mouseUp.x : cursor.x; 
      
        
        const {startX, endX,   show,doneSelecting} = this.state;
        if(!show) { return null; }
        const left = (startX > endX) ? endX : startX; 
        const width = (startX > endX) ? startX-endX : endX-startX; 
        // const width = thhis 
        const style = {left:left + "px", width:width+"px"}
        // log("this.props.cursor.x",this.props.cursor.x)
        // log("left w",startX, endX,   show,left,width)
        const rows = this.props.categories.map((item,index) => {
            return <SelectionViewRow 
                        key={"SelectionViewRow_" + index}
                        trackIndex={index} 
                        left={left}
                        width={width}
                        doneSelecting={doneSelecting}
                        setShow={this.setShow}
                        addSegmentToCategory={this.props.addSegmentToCategory}
            />

        })
        const className = (doneSelecting)  ? "SelectionView doneSelecting" : "SelectionView";
        return <div style={style} className={className}>
                    {this.renderMainSelection()}
                    <div className="selectionRows">{rows}</div>
                </div>

    
  }
}