import React from 'react';

import { Pill, Button ,OnOffItem} from '@swivl/great-grey-components';

import {Job, CreateLogger, Intent, TaskType, TaskData_Intent,MakeDictonaryArrayUnique } from '@swivl/great-grey-lib';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../Actions/Actions';
import { Model } from '../../../Models/Model';


import "./IntentTaskJobStartedView.scss";

const log = CreateLogger("job:JobTaskIntent");

interface IntentTaskJobStartedViewProps { job:Job }
interface IntentTaskJobStartedViewState {  }






export  class IntentTaskJobStartedView extends React.Component<IntentTaskJobStartedViewProps,IntentTaskJobStartedViewState> {
  constructor(props:IntentTaskJobStartedViewProps) {
    super(props)
  }

  render() {
    const job = this.props.job;
    const intentTask:TaskData_Intent = job.tasks[TaskType.Intent] as TaskData_Intent;
    const intents = intentTask.intents;
    let intentViews = intents.map((item,index) => {
      return <Pill 
                name={item.name} 
                key={item.name} 
                hideDelete={true}
                dark
                >
              {item.name}
              </Pill>
    })
    
    return <div className="IntentTaskJobStartedView">
        {intentViews}
    </div>
  }

}
