import React from "react";
import { ActionConsumerInterface } from "../Controller/ActionsController";
import { ShortId } from "@swivl/great-grey-lib";
import { Action, Actions } from "../../Actions/Actions";

export class ActionsConsumerClass<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> implements ActionConsumerInterface{
    subscriptionId = ShortId();
    handleAction(action:Action) { this.forceUpdate(); }
    componentWillUnmount() {
        Actions.Controller.unsubscribe(this)
    }
    render(): React.ReactNode {
      throw new Error("Model Consumer Can Not Directly Insantiate");
      return null;
    }
  }