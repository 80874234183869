import { GGDictionary, HasId, CreateLogger,ErrorPromise, Job, DataFileType, SigningRequestResponse } from '@swivl/great-grey-lib';
import { Config } from '../Models/Models/Config/Config';
import { Model } from '../Models/Model';
import fetch from 'cross-fetch'
import { type } from 'os';
const log = CreateLogger("Service:main")

export default class API {
  static get Service():API {   if (API._Service) { return API._Service; }  else {   API._Service = new API(); return API._Service ; } }
  private static _Service:API;
  private constructor() { }
  headers() { 
    let headers:any = {  
      "Content-Type": "application/json" ,
      "Authorization":  "Bearer " + Model.Session.state.token
    }
    if  (Model.Session.state.currentMembership) {
      log("Model.Session.state.currentMembership",Model.Session.state.currentMembership)
      headers.CurrentMembershipId = Model.Session.state.currentMembership.id
     }
      if  (Model.Session.state.currentProjectMembership) { 
        headers.CurrentProjectMembershipId = Model.Session.state.currentProjectMembership.id 
      }


    return headers; 
  }
  queryHeaders() { 
    let queryString = "token=" + Model.Session.state.token; 

    if  (Model.Session.state.currentMembership) {
      queryString += "&currentmembershipid=" + Model.Session.state.currentMembership.id 
    }
    if  (Model.Session.state.currentProjectMembership) { 
      queryString += "&currentprojectmembershipid=" + Model.Session.state.currentProjectMembership.id 
    }
    return queryString; 
  }


  async getItemsAndIds<T>(objectType:string):Promise<GGDictionary<T>> {

    log("getItemsAndIds", objectType)
    console.log("Howdy1")

    // return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}`, {
    //     method: "GET",
    //     headers: this.headers()
    //   })
    //   .then(authMiddleware)
    //   .then(function(response) {
    //     console.log("Howdy2")

    //     if (!response.ok) {   return parseErrorResponse(response);   }
    //     return response.json() ;


      return this.getItems<T>(objectType)
      .then( (response:Array<T&HasId>) => {
        console.log("Howdy3")

          const dict:GGDictionary<T> = {};
          for (let index = 0; index < response.length; index++) {
              dict[response[index].id] =  response[index];
          }
          return Promise.resolve(dict)
      })
      
    }

    async getItems<T>(objectType:string):Promise<Array<T&HasId>> {
      log("getItems", objectType)
  
      return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}`, {
          method: "GET",
          headers: this.headers()
        })
        .then(authMiddleware)
        .then(function(response) {
          console.log("Howdy2")
  
          if (!response.ok) {   return parseErrorResponse(response);   }
          return response.json() ;
        })
        
        

    }


  async getItem<T>(objectType:string, objectId:string):Promise<T> {

    log("getItem", objectType)

    return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}/${objectId}`, {
        method: "GET",
        headers: this.headers()
      }).then(authMiddleware)
      .then(function(response) {
        if (!response.ok) {    return parseErrorResponse(response);   }
        return response.json() ;
      })
      
    }
  
  async updateItem<T>(objectType:string, item:T&HasId):Promise<T> {
    log("item", item, typeof item)
    return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}/${item.id}`, {
            method: "PUT",
            body: JSON.stringify(item),
            headers: this.headers()
          }).then(authMiddleware)
          .then(function(response) {
            if (!response.ok) {    return parseErrorResponse(response);   }
            return response.json() ;
          }).then(function(response) {
            return Promise.resolve(item);
          })
    }
  

    async finishItem<T>(objectType:string, item:T&HasId):Promise<T> {
      log("item", item, typeof item)
      return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}/${item.id}/finish`, {
              method: "PUT",
              body: JSON.stringify(item),
              headers: this.headers()
            }).then(authMiddleware)
            .then(function(response) {
              if (!response.ok) {  return parseErrorResponse(response); }
              return response.json() ;
            }).then(function(response) {
              return Promise.resolve(response);
            }).catch((e) => { return Promise.reject(e) })
      }
    

  async createItem<T>(objectType:string, item:T):Promise<T&HasId> {
    delete (item as any).id; // Make sure we don't send something with an id.     
    delete (item as any).createdAt;
    delete (item as any).updatedAt;
    
    return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}`, {
      method: "POST",
      body: JSON.stringify(item),
      headers: this.headers()
    }).then(authMiddleware)
    .then(function(response) {
      if (!response.ok) {   return parseErrorResponse(response);  }
      return response.json() ;
    })
    }
  
    async post<T>(path:string, params:any):Promise<T&HasId> {
      
      return fetch(`${Config.serverURL}/api/v1/${path.toLowerCase()}`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: this.headers()
      }).then(authMiddleware)
      .then(function(response) {
        if (!response.ok) {   return parseErrorResponse(response);  }
        return response.json() ;
      })
      }
    

  async deleteItem<T>(objectType:string, item:T&HasId):Promise<boolean> {
    return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}/${item.id}`, {
      method: "DELETE",
      body: JSON.stringify(item),
      headers: this.headers()
    }).then(authMiddleware)
    .then(function(response) {
      if (!response.ok) {   return parseErrorResponse(response);  }
      return Promise.resolve(true);
    })
    }
  
    async startItem<T>(objectType:string, item:HasId):Promise<T> {
      log("START ITEM", item);
      return fetch(`${Config.serverURL}/api/v1/${objectType.toLowerCase()}/${item.id}/start`, {
        method: "POST",
        headers: this.headers()
      }).then(authMiddleware)
      .then(function(response) {
        if (!response.ok) {  return parseErrorResponse(response);  }
        return response.json()
      })

    }
    async getSignedRequest(fileName:string,type:DataFileType,isUpdate:boolean):Promise<SigningRequestResponse> {
      log("getSignedRequest", fileName)  
      return fetch(`https://${Config.serverAddress}/data/sign-s3?type=${type}&isUpdate=${isUpdate}&fileName=${fileName}`, {method: "GET",
      headers: this.headers()
      }).then(authMiddleware)
      .then((response) => response.json())
  
    
  }

    async login(email:string, password:string): Promise<any> {
      return fetch(`${Config.serverURL}/api/v1/session/authenticate`, {
              method: "POST",
              body: JSON.stringify({email:email, password:password}),
              headers: {"Content-Type": "application/json"} ,
      }).then(async (response) => {
        if (!response.ok) {  return parseErrorResponse(response) } // ErrorPromise(response.statusText) }
        return response.json(); 
      })
    }
    async signUp(email:string, password:string, firstName:string, lastName:string): Promise<any> {
      return fetch(`${Config.serverURL}/api/v1/user/register`, {
              method: "POST",
              body: JSON.stringify({email:email, password:password, firstName:firstName, lastName:lastName}),
              headers: {"Content-Type": "application/json"} ,
      }).then(async (response) => {
        if (!response.ok) { 
          return parseErrorResponse(response) } 
        return response.json(); 
      })
    }

    async refreshSessionUser(): Promise<any> {
      console.log("wut");
      return fetch(`${Config.serverURL}/api/v1/session/user`, {
              method: "GET",
              headers: this.headers(),
            }).then(authMiddleware)
            .then((response) => response.json() )
    }

    async logout():Promise<any> {
      log("FETCHING DELETE");
      return fetch(`${Config.serverURL}/api/v1/session`, {
        method: "DELETE",
        headers: this.headers()
      }).then((response:any) => {
        if (!response.ok) {  return parseErrorResponse(response);  }
        return response; 
      })
    }


    
/*ENDOFCLASS*/  }

function parseErrorResponse(response:Response):Promise<any> {
  return response.json().then((parsedResponse) => {
    console.log("Parsed Response", parsedResponse);
    if (parsedResponse && parsedResponse.message) {
      console.log("Parsed Response", parsedResponse);

      return clrErrorPromise(parsedResponse.message);
    } 
    return ErrorPromise(response.statusText);
  })
}

function clrErrorPromise(messageOrError: string | Error): Promise<Error> {
  if (typeof messageOrError === 'string') {
    console.log("it's a string")
    return Promise.reject(new Error(messageOrError))
  }
  return Promise.reject(messageOrError)
}

function authMiddleware(response:Response):Promise<Response> {
  console.log("AUTH MIDLEWARE",response)
  if (!response.ok) {
    console.log("response", response);
    return response.json().then((re) =>{
      console.log("Re", re);
      if (re.logout) {
        Model.Session.logOut();
        console.log("mk1")
        return Promise.reject("Session Expired"); 
      }

      return Promise.reject(re); 

    })
  }
  console.log("mk3")

  return Promise.resolve(response); 
}

