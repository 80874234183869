import React from 'react';
import {Model} from '../../Models/Model';
import {Input, InputType, Button} from "@swivl/great-grey-components"
import { faLock, faEnvelope, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons'

import "./LoginScreen.scss"
import { ActionsConsumer } from '../../Actions/Actions';


interface LoginScreenProps {
  /** set when you want to log out, such as naving to it. */
  logout?:boolean;
}
interface LoginScreenState {
  
  email:string,
  password:string
  confirmPassword:string,
  firstName:string,
  lastName:string,
  isLoading:boolean,
  errorMessage?:string,
  tab:string
}

export default class LoginScreen extends ActionsConsumer<LoginScreenProps,LoginScreenState>  {
  constructor(props:LoginScreenProps) {
    super(props)
    this.state = {
      email:"", 
      password:"", 
      confirmPassword:"",
      firstName:"",
      lastName:"",
      isLoading:false, 
      tab:"login"
    }
      
    this.onChange              = this.onChange.bind(this);
    this.loginTabClicked       = this.loginTabClicked.bind(this)
    this.signupTabClicked      = this.signupTabClicked.bind(this)
    this.loginClicked          = this.loginClicked.bind(this);
    this.signupClicked         = this.signupClicked.bind(this);
  }

  componentDidMount() {
    if (this.props.logout) { Model.Session.logOut(); }
  }
  onChange(event:React.ChangeEvent<HTMLInputElement>) {
    let state = this.state;
    state[event.target.name] = event.target.value
    this.setState(state);
    // if (event.target.name === "email")    { this.setState({email:event.target.value }); }
    // if (event.target.name === "password") { this.setState({password:event.target.value}); }
  }
  loginTabClicked() {   this.setState({tab:"login"}) }
  signupTabClicked() {  this.setState({tab:"signup"}) }
  loginClicked(e:any) {
    e.preventDefault()
    console.log("Login Clicked");
    this.setState({isLoading:true, errorMessage:null});
    Model.Session.login(this.state.email, this.state.password).then((success) => {
      console.log("Supposidly logged in");
    }).catch((error) =>  {
      console.warn("Error Loading", error);
      const msg = (error  && error.message) ? error.message : "Error logging in.";
      this.setState({isLoading:false, errorMessage:msg});
    })
  }

  signupClicked(e:any) {
    e.preventDefault()

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({errorMessage:"Passwords Don't Match"});
      return;
    }
    this.setState({isLoading:true, errorMessage:null});
    Model.Session.signUp(this.state.email, this.state.password, this.state.firstName, this.state.lastName).then((success) => {
      console.log("Supposidly logged in");
    }).catch((error) =>  {
      console.warn("Error Loading", error,  typeof error);
      const msg = (error  && error.message) ? error.message : "Error logging in.";
      this.setState({isLoading:false, errorMessage:msg});
    })
  }

  renderLogin() {
    return <form onSubmit={this.loginClicked}>
          <div className="panel-block">
            <Input name="email" value={this.state.email} icon={faEnvelope} onChange={this.onChange} isReadOnly={this.state.isLoading}/>
          </div>

          <div className="panel-block">
            <Input name="password" value={this.state.password} icon={faLock} type={InputType.Password} onChange={this.onChange} isReadOnly={this.state.isLoading}/>
          </div>

          <div className="panel-block">
            <Button
            type={"submit"}
              isLoading={this.state.isLoading}
              onClick={this.loginClicked}
              isFullWidth={true}>Login</Button>
          </div>
        </form>
  }
  renderSignup() {
    return <form onSubmit={this.signupClicked}>
    <div className="panel-block">
      <Input name="email" value={this.state.email} icon={faEnvelope} onChange={this.onChange} isReadOnly={this.state.isLoading}/>
    </div>

    <div className="panel-block">
      <Input name="password" value={this.state.password} icon={faLock} type={InputType.Password} onChange={this.onChange} isReadOnly={this.state.isLoading}/>
    </div>
    <div className="panel-block">
      <Input name="confirmPassword" placeholder="Confirm password..."  type={InputType.Password} value={this.state.confirmPassword} icon={faLock} onChange={this.onChange} isReadOnly={this.state.isLoading}/>
    </div>

    <div className="panel-block">
      <Input name="firstName" placeholder="First name..." icon={faUser} onChange={this.onChange} isReadOnly={this.state.isLoading}/>
    </div>

    <div className="panel-block">
      <Input name="lastName" placeholder="Last name..." icon={faUserTie} onChange={this.onChange} isReadOnly={this.state.isLoading}/>
    </div>

    <div className="panel-block">
      <Button
        type="submit"
        isLoading={this.state.isLoading}
        onClick={this.signupClicked}
        isFullWidth={true}>Signup</Button>
    </div>
  </form>
  }
  render() {
    const errorMessage =  (this.state.errorMessage) ? <div className="panel-block"><p className="help is-warning">{this.state.errorMessage}</p></div>:null;
    return <div className="LoginScreen"><div className="loginColumns columns is-vcentered is-fullheight">
    <div className="column is-one-third"></div>
    <div className="column is-one-third loginColumn">




      <nav className="panel  is-centered">
        <p className="panel-heading">
          Great Grey
        </p>

        <p className="panel-tabs">
          <a  onClick={this.loginTabClicked} className={(this.state.tab === "login") ? "is-active" : ""}>Login</a>
          <a onClick={this.signupTabClicked} className={(this.state.tab === "login") ? "" : "is-active"}>Sign Up</a>
        </p>
        {(this.state.tab === "login") ? this.renderLogin() : this.renderSignup()}
        {errorMessage}

      </nav>

    </div>
    <div className="column is-one-third"></div>
  </div>
  </div>;
  }

}
