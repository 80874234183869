import React, {  } from 'react';
import { CreateLogger,CreateRowChange_AudioCategorize,CreateRowChange_AudioSplit, Intent, DataSetRow, TaskType, TimelineCategory, TimelineSegment } from '@swivl/great-grey-lib';
import "./AudioPlayer.scss"
import { loadAudio } from '../AudioHelper';
import { WaveformView } from '../WaveformView/WaveformView';
import { TimelineControlsView } from '../TimelineControls/TimelineControlsView';
import { PlayheadView } from './PlayheadView/PlayheadView';
import { WaveformWrapper } from './WaveformWrapper/WaveformWrapper';
// import { TimelineView } from './components/TimelineView/TimelineView';
// import { CategoryTitles } from './components/CategoryTitles/CategoryTitles';
// import { AddSegmentToCategory, AdjustEndTime, AdjustStartTime, DeleteSegment, AdjustPosition } from './TrackHelper';
// import "./Colors/TimelineColors.scss"
// import { TimelineControlsView } from './components/TimelineControls/TimelineControlsView';

const log = CreateLogger("AudioPlayer:Main");





interface AudioPlayerProps {
    audioURL:string 
    startTime?:number 
    endTime?:number 
}

interface AudioPlayerState {
    playingId?:string
    isPlaying:boolean;
    arrayBuffer?:ArrayBuffer;
    audioBuffer?:AudioBuffer; 
    dataPoints?:number[];
    duration?:number;
}

export class AudioPlayer extends React.Component<AudioPlayerProps, AudioPlayerState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  playbackNode?:AudioBufferSourceNode; // If this is active it means it is playing!
  playbackStartTime?:number; 
  playbackOffset?:number; 
  pauseTime:number = 0;

  audioContext:AudioContext; 

  constructor(props:any) {
    super(props) 

    log("MY PROPS", props)
    

    this.state = { isPlaying:false }
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

    this.playClicked      = this.playClicked.bind(this);
    this.pauseClicked     =  this.pauseClicked.bind(this);
    this.backClicked      = this.backClicked.bind(this)
    this.forwardClicked   = this.forwardClicked.bind(this)
    this.setPlayhead      = this.setPlayhead.bind(this);
    this.playAtTime       = this.playAtTime.bind(this);


    
  }

 
  
  componentWillUnmount() {
    if (this.playbackNode) {
      this.playbackNode.stop();
    }
  }
  playClicked() {
    this.playAtTime(0)
  }
  pauseClicked() {
    const startTime  = this.playbackStartTime;
    const contextTime = this.audioContext.currentTime; 
    this.pauseTime = (contextTime - startTime) + this.playbackOffset;
    this.playbackNode.stop()
  }
  backClicked() {
     if (this.pauseTime > 10) {this.pauseTime -= 10; } else  { this.pauseTime = 0; }
     if (this.playbackNode) {
        const startTime  = this.playbackStartTime;
        const contextTime = this.audioContext.currentTime; 
        const split = (contextTime - startTime) + this.playbackOffset; 
        if ((split  - 10 ) > 0) { 
          this.playAtTime(split - 10); 
        } else {
          this.playAtTime(0);
        }

     }
  }

  forwardClicked() {
    // if  (!this.state.duration) { return; } 

    //   if ((this.pauseTime  + 10 ) < this.state.duration ) { this.pauseTime += 10; } else  { this.pauseTime = this.state.duration; }
    
        
    //     if(this.playbackNode) {
    //       const startTime  = this.playbackStartTime;
    //       const contextTime = this.audioContext.currentTime; 
    //       const split = (contextTime - startTime) + this.playbackOffset;  
    //       if ((split  + 10 ) < this.state.duration) { 
    //         this.playAtTime(split + 10); 
    //       } else {
    //         this.playbackNode.stop()
    //         this.pauseTime = this.state.duration;
    //       } 
    //     }

  }

  setPlayhead(time:number) {
    // log("set playhead", time);
    // if (!this.playbackNode) {
    //   this.pauseTime = time; 
    // } else {
    //   this.playAtTime(time)
    // }
  } 
  

/**
 * 
 * @param time 
 * @param duration 
 * @param playingId - Used to determin what component has triggered the playback.
 */
  playAtTime(time:number, duration?:number, playingId?:string) {
    //   time = time + this.props.startTime;

    log("Play at time", time, duration)
    this.setState({isPlaying:true, playingId:playingId})
    if (this.playbackNode) { this.playbackNode.stop();  }
    if (this.state.audioBuffer) {

      this.playbackNode   =  this.audioContext.createBufferSource();
      if (!time && this.pauseTime) {  time = this.pauseTime; }

      this.playbackOffset = time;
      this.playbackStartTime = this.audioContext.currentTime;
      // set the buffer in the AudioBufferSourceNode
      this.playbackNode.buffer = this.state.audioBuffer;
      // connect the AudioBufferSourceNode to the
      // destination so we can hear the sound
      this.playbackNode.connect(this.audioContext.destination);
      // start the source playing
      if (duration) {
        this.playbackNode.start(0,time,duration);
      } else {
        this.playbackNode.start(0,time);
      }
      var self = this;
      this.playbackNode.onended =  function() {
        if (this ===  self.playbackNode) {
          log("II'm me");
          self.playbackNode = null;
          self.setState({isPlaying:false, playingId:null})

        }
      }
    }
  }
  
  componentDidMount() {

    loadAudio(this.props.audioURL, this.props.startTime, this.props.endTime).then((output) => {
        log("Loadded Audio",output)
      this.setState({  arrayBuffer:output.arrayBuffer, 
                       audioBuffer:output.audioBuffer,
                       dataPoints:output.dataPoints,
                       duration:output.audioBuffer.duration
                      })

                      
    })

  }
  
  

  

  render() {    
    return <div className="AudioPlayer">
          <WaveformWrapper delegate={this} dataPoints={this.state.dataPoints} />
      
        <div className="timelineControls">
            <TimelineControlsView         
                isPlaying={this.state.isPlaying}
                playClicked={this.playClicked}
                pauseClicked = {this.pauseClicked}
                backClicked = {this.backClicked}
                forwardClicked = {this.forwardClicked}
            />

        </div>
    </div>
    

    // const duration = (this.state.duration) ? this.state.duration :( (window.screen.width -  320) * 0.05 );
    // return <div className="AudioCategorizeAndSplitExercisesView">
    //         <div className="leftColumn">
    //           <TimelineControlsView  
    //               isPlaying={this.state.isPlaying}
    //               playClicked={this.playClicked}
    //               pauseClicked = {this.pauseClicked}
    //               backClicked = {this.backClicked}
    //               forwardClicked = {this.forwardClicked}
    //           />
             
             
    //           <CategoryTitles categories={this.state.categories} />

    //         </div>
    //         <div className="rightColumn">
    //           <TimelineView 
    //             categories={this.state.categories}
    //             playAtTime={this.playAtTime}
    //             pause={this.pauseClicked}
    //             delegate={this} 
    //             isPlaying={this.state.isPlaying}
    //             playingId={this.state.playingId}
    //             duration={duration} 
    //             dataPoints={this.state.dataPoints} />
    //         </div>
    // </div>;
  }
}
