
import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App/App';
import * as serviceWorker from './serviceWorker';
import './index.scss'
import { Config } from './Models/Models/Config/Config';

import "@swivl/great-grey-components/build/index.css"; // This must be loaded first to overwrite bulma.

if (process.env.NODE_ENV === 'production')  {
    Config.serverAddress = 'great-grey-server.herokuapp.com';
    Config.serverURL = 'https://' + Config.serverAddress;
    Config.serverName = 'Great-Grey-Server-Local';
    Config.fileServerURL = 'https://great-grey-local.s3.amazonaws.com';
} else {
    Config.serverAddress = 'greatgreyserver.ngrok.io';
    Config.serverURL = 'https://' + Config.serverAddress;
    Config.serverName = 'Great-Grey-Server-Local';
    Config.fileServerURL = 'https://great-grey-local.s3.amazonaws.com';
}



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
