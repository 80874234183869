import React from 'react';
import { CreateRowChange_Entity, Entity, EntityDefinition, MLModel, OrganisationMembership, Project, RowChange, RowChangeType, RowChange_Entity, User } from '@swivl/great-grey-lib';
import "./EntityReviewCustomModal.scss"
import { Actions, ActionsConsumer, ActionType } from '../../../Actions/Actions';
import TextWithEntities from '../../../Components/TextWithEntities/TextWithEntities';
import { GGTokenizer } from '../../../Components/TextWithEntities/Tokenizer';
import { Button } from '@swivl/great-grey-components';



// import * as Model from '../../Models/Model';

interface Props {
    text:string 
    change?:RowChange
    possibileEntities:EntityDefinition[]
    updateChange(change:RowChange_Entity)
}
export default class EntityReviewCustomModal extends ActionsConsumer<Props> {
 
  constructor(props:Props) {
    super(props)
    this.updateEntities = this.updateEntities.bind(this)

  }
  updateEntities(entities:Entity[]) {
    let change = this.props.change; 
    if (!change || change.type !== RowChangeType.Entity) {
        change = CreateRowChange_Entity(entities)
        console.log("Change", change);
        
        this.props.updateChange(change) 
    } else {
        change.entities = entities; 
        this.props.updateChange(change) 

        //NEED TO MAKE SURE THIS IS SOTRTED!
    }
    }
  doneClicked() {
      Actions.Controller.trigger({type:ActionType.HideModal})
  }

    render() {
    
  return <div className="EntityReviewCustomModal">

      <h1>Custom </h1>

        <TextWithEntities  
            text={this.props.text} 
            entities={(this.props.change && this.props.change.type === RowChangeType.Entity) ? this.props.change.entities : []}
             possibileEntities={this.props.possibileEntities} 
            updateEntities={this.updateEntities}  /> 
        <div className="buttons">
            <Button onClick={this.doneClicked} >Done</Button>

        </div>
  </div>
}

}
