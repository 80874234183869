import React from 'react';
import { Stage, Layer } from 'react-konva';

import { ActionsConsumer,Action, Actions, ActionType } from '../../../Actions/Actions';

import Rectangle from './Rectangle/Rectangle';
import RectTransformer from './Rectangle/RectTransformer';
import AnnotationImage from './AnnotationImage/AnnotationImage';

import {ShortId} from "@swivl/great-grey-lib"
import './AnnotationTool.scss';

interface Props {
  imageURL:string 
  imageWidth:number, 
  imageHeight:number 
}

interface State {
  rectangles: any[],
  rectCount: number,
  selectedShapeName: string,
  mouseDown: boolean,
  mouseDraw: boolean,
  newRectX: number,
  newRectY: number,
}
class AnnotationTool extends ActionsConsumer<Props, State> {
  state = {
    rectangles: [],
    rectCount: 0,
    selectedShapeName: '',
    mouseDown: false,
    mouseDraw: false,
    newRectX: 0,
    newRectY: 0,
  };
  img:any; 
  stage:any; 



  componentDidMount() {
    this.img.moveToBottom();


    Actions.Controller.subscribeTo(this,
      [ 
        ActionType.KeyboardKeyDown
      ]);
  }
  handleAction(action:Action) { 
      console.log("action", action)
      if (action.type === ActionType.KeyboardKeyDown && this.state.selectedShapeName) {
        console.log("should delete!", this.state.selectedShapeName);
        if (action.event.keyCode == 8) {
          this.deleteRect(this.state.selectedShapeName);
        }
      }

   }

   deleteRect = (shapeName:string) => {
      let rectangles = this.state.rectangles 
      for (let i = 0; i < rectangles.length; i++) {
        const rect = rectangles[i]; 
        console.log("rect:", rect);
        if (rect.name == shapeName) {
          rectangles.splice(i, 1)
          this.setState({rectangles:rectangles})
          return; 
        }
      }
   }

  handleStageMouseDown = (event) => {
    const { rectangles } = this.state;
    // clicked on stage - clear selection or ready to generate new rectangle
    if (event.target.className === 'Image') {
      const stage = event.target.getStage();
      const mousePos = stage.getPointerPosition();
      this.setState({
        mouseDown: true,
        newRectX: mousePos.x,
        newRectY: mousePos.y,
        selectedShapeName: '',
      });
      return;
    }
    // clicked on transformer - do nothing
    const clickedOnTransformer = event.target.getParent().className === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    // find clicked rect by its name
    const name = event.target.name();
    const rect = rectangles.find(r => r.name === name);
    if (rect) {
      this.setState({
        selectedShapeName: name,
        rectangles,
      });
    } else {
      this.setState({
        selectedShapeName: '',
      });
    }
  };

  handleRectChange = (index, newProps) => {
    const { rectangles } = this.state;

    console.log("Handle rect changes", newProps);
    

    rectangles[index] = {
      ...rectangles[index],
      ...newProps,
    };

    this.setState({ rectangles });
  };

  handleNewRectChange = (event) => {
    const {
      rectangles, rectCount, newRectX, newRectY,
    } = this.state;
    const stage = event.target.getStage();
    const mousePos = stage.getPointerPosition();
    if (!rectangles[rectCount]) {
      const id = ShortId()
      rectangles.push({
        x: newRectX,
        y: newRectY,
        width: mousePos.x - newRectX,
        height: mousePos - newRectY,
        name: `rect_${id}`,
        stroke: 'rgba(246,120,2,0.5)',
        selectedStroke: 'rgba(246,120,2,1)',
        fill: 'rgba(246,120,2,0.3)',
        key: id
      });
      return this.setState({ rectangles, mouseDraw: true });
    }
    rectangles[rectCount].width = mousePos.x - newRectX;
    rectangles[rectCount].height = mousePos.y - newRectY;
    return this.setState({ rectangles });
  };

  handleStageMouseUp = () => {
    const { rectCount, mouseDraw } = this.state;
    if (mouseDraw) {
      this.setState({ rectCount: rectCount + 1, mouseDraw: false });
    }
    this.setState({ mouseDown: false });
  };

  render() {
    const {
      state: { rectangles, selectedShapeName, mouseDown },
      handleStageMouseDown,
      handleNewRectChange,
      handleRectChange,
      handleStageMouseUp,
    } = this;
    return (
      <div className="AnnotationTool" id="annotationTool">
        <Stage
          ref={(node) => {
            this.stage = node;
          }}
          container="annotationTool"
          width={this.props.imageWidth}
          height={this.props.imageHeight}
          onMouseDown={handleStageMouseDown}
          onTouchStart={handleStageMouseDown}
          onMouseMove={mouseDown && handleNewRectChange}
          onTouchMove={mouseDown && handleNewRectChange}
          onMouseUp={mouseDown && handleStageMouseUp}
          onTouchEnd={mouseDown && handleStageMouseUp}
        >
          <Layer>
            {rectangles.map((rect, i) => (
              <Rectangle
                sclassName="rect"
                key={rect.key}
                {...rect}
                selectedRectName={selectedShapeName}
                onTransform={(newProps) => {
                  handleRectChange(i, newProps);
                }}
              />
            ))}
            {/* <RectTransformer selectedShapeName={selectedShapeName} /> */}
          </Layer>
          <Layer
            ref={(node) => {
              this.img = node;
            }}
          >
            <AnnotationImage {...this.props} />
          </Layer>
        </Stage>
      </div>
    );
  }
}

export default AnnotationTool;
