import { Model } from '../Model';

import { DataPoint,DataFileType, GGDictionary, CreateLogger, DataPointStatus} from '@swivl/great-grey-lib';
import { Actions, ActionType } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import Service from '../../Services/Service';
const log = CreateLogger("DataPointModel")


export interface DataPointModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<DataPoint>
}> {}

export class DataPointModel extends BaseModel<DataPoint,DataPointModelState>  {
  static get Model():DataPointModel {   if (DataPointModel._Model) { return DataPointModel._Model; }  else {   DataPointModel._Model = new DataPointModel(); return DataPointModel._Model ; } }

  private static _Model:DataPointModel;
  private constructor() { super();   }
  ENTITY_NAME = "datapoint";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.DataPointsLoaded,
    ItemFetched : ActionType.DataPointFetched,
    ItemUpdated : ActionType.DataPointUpdated,
    ItemDeleted : ActionType.DataPointDeleted,
    ItemCreated : ActionType.DataPointCreated
  } 
  get unnasigned():DataPoint[]|undefined {  
    
    return (this.state.items) ? Object.values(this.state.items).filter((i) => { 
      console.log("wooga wooga", i)
      return (i.status === DataPointStatus.Unassigned) ? true : false}) : undefined; }

  createDataSetFromDataPoints(dataPointIds:string[]) {
   return Service.API.post("datapoint/create_dataset", {dataPointIds:dataPointIds}).then((dataSet) => {
      let state = this.state; 
      for (let i = 0; i < dataPointIds.length; i++) {
        if (state.items[dataPointIds[i]]) {
          state.items[dataPointIds[i]].status = DataPointStatus.Assigned 
        }
      }
      Actions.Controller.trigger({type:ActionType.DataPointsLoaded});
      Model.DataSet.fetchById(dataSet.id)
      return Promise.resolve(dataSet)

    }).catch((e) => {
      Service.Toast.error(e)
    })
  }
  ignoreDataPoints(dataPointIds:string[]) {
    let state = this.state; 
    for (let i = 0; i < dataPointIds.length; i++) {
      if (state.items[dataPointIds[i]]) {
        state.items[dataPointIds[i]].status = DataPointStatus.Ignored 
      }
    }
    Actions.Controller.trigger({type:ActionType.DataPointsLoaded});
    
    return Service.API.post("datapoint/ignore", {dataPointIds:dataPointIds}).then((_) => {

        return Promise.resolve(true)
      }).catch((e) => {
        Service.Toast.error(e)
      })
  }


}