import React from 'react';
import { Select,Option, Pill } from '@swivl/great-grey-components';
import { Model } from '../../../../../Models/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CreateLogger } from '@swivl/great-grey-lib';
import "./DataSetSelectionView.scss"
import { ActionsConsumer, Actions, ActionType, Action } from '../../../../../Actions/Actions';
import { Job,DataSet, JobStatus } from '@swivl/great-grey-lib';

const log = CreateLogger("job:data_pool_selection");

interface DataSetSelectionViewProps {
  job:Job,
}
interface DataSetSelectionViewState {

}

export  class DataSetSelectionView extends React.Component<DataSetSelectionViewProps,DataSetSelectionViewState> {
  constructor(props:any) {
    super(props)
    this.state = {};

    this.onChange = this.onChange.bind(this)

  }

  componentDidMount(){Model.DataSet.load() }


  
  onChange(selectedOption:Option) {
    let job = this.props.job; 
    job.dataSet = Model.DataSet.state.items[selectedOption.value];
    job.rowCount = job.dataSet.rowCount;
    Model.Job.update(job, true);
  }

  render() {

    
    const dataSets = Model.DataSet.array 
    log("dataSets",dataSets)
    let content;

    if (this.props.job.status !== JobStatus.Unstarted) {
      content = <Pill hideDelete>{this.props.job.dataSet?.name}</Pill>
    } else if (dataSets) {
    


      const options:Array<Option> = dataSets.map((item:DataSet) => { return { value: item.id, label:item.name }})
      const value = (this.props.job.dataSet) ? { value: this.props.job.dataSet.id, label:this.props.job.dataSet.name } : null;

      content = <Select
            options={options}
            value={value}
            isSearchable={true}
            placeholder="Select data set..."
            onChange={this.onChange}
          />
    } else {
      content= <FontAwesomeIcon icon={faSpinner} spin={true} />
    }

    return <div className="DataSetSelectionView">
      <div className="columns">
        <div className="column is-one-third">
          <h4>DATA SET</h4>
          {content}
        </div>
        <div className="column"></div>
      </div>
    </div>;
  }

}
