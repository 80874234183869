import React from 'react';
import { Model } from '../../Models/Model';
import "./DashboardScreen.scss";

interface Props {
  setNavBar(left:any|undefined, center:any|undefined, right:any|undefined);
}

export default class DashboardScreen extends React.Component<Props> {

  
  componentDidMount() {
    this.props.setNavBar(null,<h3>Dashboard</h3>,null)
  }
 
  

  render() {
    return <div className="DashboardScreen">
      {/* 👋 Welcome, {Model.Session.state.user.firstName} */}
    </div>;
  }

}
