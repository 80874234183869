import React from 'react';
import {Model} from '../../Models/Model';
import {Input, InputType, Button, Color} from "@swivl/great-grey-components"
import { faLock, faEnvelope, faUser, faUserTie, faSpinner } from '@fortawesome/free-solid-svg-icons'

import "./InviteScreen.scss"
import { ActionsConsumer, ActionType } from '../../Actions/Actions';
import { Invite, User } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Service from '../../Services/Service';
import { NavBar } from '../Layout/NavBar/NavBar';
import { ActionsController } from '../../Actions/Controller/ActionsController';
import { Session } from 'inspector';


interface Props {
  // setNavBar(left:any|undefined, center:any|undefined, right:any|undefined);

  /** set when you want to log out, such as naving to it. */
}
interface State {
  invite?:Invite
  noInvite:boolean,
  isDifferentUser:boolean, 
  email:string,
  password:string
  confirmPassword:string,
  firstName:string,
  lastName:string,
  isLoading:boolean,
  errorMessage?:string,
  tab:string
}

export default class InviteScreen extends ActionsConsumer<Props,State>  {
  constructor(props:Props) {
    super(props)
    this.state = {
      noInvite:false, 
      isDifferentUser:false, 
      email:"", 
      password:"", 
      confirmPassword:"",
      firstName:"",
      lastName:"",
      isLoading:false, 
      tab:"login"
    }
      
    this.loadInvite = this.loadInvite.bind(this)
    this.createUser = this.createUser.bind(this)
    this.onChange = this.onChange.bind(this)
    this.acceptInvite = this.acceptInvite.bind(this)
  }
  componentDidMount() { this.loadInvite() }

  loadInvite() {
    console.log("Load invite");
    
    Service.API.getItem<Invite>("invite", Model.Navigation.second()).then((invite) => {
      console.log("Loaded invite", invite);

      if (Model.Session.state.user && 
          invite.inviteeUserId && 
          Model.Session.state.user.id !== invite.inviteeUserId 
          ) {
            this.setState({isDifferentUser:true})
            return; 
      }

      this.setState({invite:invite})
    }).catch((e) => Service.Toast.error(e))
  }
  createUser(e:React.FormEvent) {
    e.preventDefault()
    if (!this.state.password || !this.state.firstName || !this.state.lastName) {
      Service.Toast.error("Please fill out all fields.")
      return;
    }

    this.setState({isLoading:true})
    Service.API.post(`invite/${this.state.invite.id}/accept_and_create_account`, {
      password:this.state.password, 
      firstName:this.state.firstName,
      lastName:this.state.lastName,
    }).then((response:any) => {
      this.setState({isLoading:false})
      Service.Toast.success("Account Created. Please Log In.")
      Model.Navigation.setPath("/")
      ActionsController.Controller.trigger({type:ActionType.UserLoaded, user:response as User})
    }).catch((e:Error) => {
      this.setState({isLoading:false})

      Service.Toast.error(e)
    })
  }


  acceptInvite(e:React.FormEvent) {
    e.preventDefault()


    this.setState({isLoading:true})
    Service.API.post(`invite/${this.state.invite.id}/accept`, {}).then((response:any) => {
      this.setState({isLoading:false})
      Service.Toast.success("Joined!")
      Model.Navigation.setPath("/")
      Model.Session.refreshUser()
      // ActionsController.Controller.trigger({type:ActionType.UserLoaded, user:response as User})
    }).catch((e:Error) => {
      this.setState({isLoading:false})

      Service.Toast.error(e)
    })
  }



  onChange(e:React.ChangeEvent<HTMLInputElement>) {
    let state = this.state; 
    state[e.target.name] = e.target.value; 
    this.setState(state)
  }

  renderEmailInvite(invite:Invite) {
    const title = invite.project ? <h1>You've been invited to join the <b>{invite.project.name}</b> Project on swivl.</h1> : <h1>You've been invited to join  <b>{invite.organistation.name}</b> on swivl.</h1>

    return <div className="leftColumn">
    <div className="roundAvatar huge" />

    <div className="inputColumn">
      <form onSubmit={this.createUser}>
      {title}
      <label>Email</label>
      <h3>{invite.inviteeEmail}</h3>
      <label>First Name</label>
      <Input placeholder="First name..." value={this.state.firstName}  name="firstName" onChange={this.onChange} />
      <label>Last Name</label>
      <Input placeholder="Last name..." value={this.state.lastName} name="lastName" onChange={this.onChange} />
      <label>Password</label>
      <Input type={InputType.Password} placeholder="Enter a password..."  value={this.state.password} name="password" onChange={this.onChange}/>
      
      <Button type={"submit"} onClick={this.createUser}  color={Color.Primary} isLoading={this.state.isLoading}>Sign Up</Button>
      <div className="legal">
      By selecting Sign Up, you agree to swivl’s <a href="https://tryswivl.com/tos/" target="_blank">Terms of Service</a> and acknowledge our <a href="https://tryswivl.com/privacy-policy/" target="_blank">Privacy Policy and Cookie Policy</a>.
      </div>
      </form>

      </div>
    </div>
  }
  renderLoading() {
    return <div className="leftColumn">
        <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  }
  renderDifferentUser() {
    return <div className="leftColumn">
      This invite was not sent to you.
    </div>
  }
  renderUser(invite:Invite) {
    if (!Model.Session.state.user)   {
        return <div className="leftColumn">
        You need to <a href="/">login</a> before you can accept this invite.
      </div>
    } 
    if (Model.Session.state.user.id !== invite.inviteeUserId)   {
      return <div className="leftColumn">
      Wronge user. Please log in to the account this invite was sent to.
    </div>   
     }

     const title = invite.project ? <h1>You've been invited to join the <b>{invite.project.name}</b> Project on swivl.</h1> : <h1>You've been invited to join  <b>{invite.organistation.name}</b> on swivl.</h1>

     return <div className="leftColumn">
     <div className="roundAvatar huge" />
 
     <div className="inputColumn">
       <form onSubmit={this.acceptInvite}>
       {title}
   
       <Button type={"submit"} onClick={this.acceptInvite}  color={Color.Primary} isLoading={this.state.isLoading}>Join</Button>
       <div className="legal">
       By selecting Sign Up, you agree to swivl’s <a href="https://tryswivl.com/tos/" target="_blank">Terms of Service</a> and acknowledge our <a href="https://tryswivl.com/privacy-policy/" target="_blank">Privacy Policy and Cookie Policy</a>.
       </div>
       </form>
 
       </div>
     </div>
  }




  render() {
    const errorMessage =  (this.state.errorMessage) ? <div className="panel-block"><p className="help is-warning">{this.state.errorMessage}</p></div>:null;
    var content; 
    const {isDifferentUser, invite, noInvite} = this.state; 
    
    if (isDifferentUser) {
      content = this.renderDifferentUser()
    } else if (!invite) {
      content = this.renderLoading()
    } else if (invite.inviteeUserId) {
      content = this.renderUser(invite)
    } else if (invite) {
      content = this.renderEmailInvite(invite)
    }

    return <div className="LayoutView">
            <NavBar center={
            <div style={{height:"70px", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <img src="/img/swivl_logo.png" />
            </div>} />
            
       
 <div className="InviteScreen">
      {content}
      <div className="rightColumn">
        <img src="/img/graphics/yogakyle.png" width={246} height={264} title="Yoga Kyle" />

      </div>
  </div>            </div>
;
  }

}
