import React from 'react';
import './EntityReviewItem.scss';
import { CreateLogger, ReviewRow, RowChangeType, ChangeStatus, RowChange, Entity, CreateRowChange_Entity, Job, TaskData_Entity, CreateRowChange_Excluded, TaskType, RowChange_Entity } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button,ButtonDropdown, Option, Color } from '@swivl/great-grey-components';
import { ReviewItemViewProps } from '../../TaskViews';
import _ from "lodash"
import StaticTextwithEntities from '../../../Components/TextWithEntities/StaticTextWithEntities';
import { Actions, ActionType } from '../../../Actions/Actions';
import EntityReviewCustomModal from './EntityReviewCustomModal';
function entitiesKeyForArray(entities:Entity[]): string {
    let output = "entity_key"
    entities.sort(function(a:Entity, b:Entity) {
        return a.startChar - b.startChar;
    });
    output += entities.map(i => `_${i.name}_${i.startChar}_${i.endChar}`)
    return output; 
}

const log = CreateLogger("job:EntityReviewItem")

function OptionDot() { return <div className="optionDot"><div className="dotContent"><span/></div></div>}

interface EntityReviewItemOptionProps {
  text:any 
  count:number 
  change:RowChange_Entity,
  changeSelected(option:RowChange_Entity)
  selected:boolean 
}
export class EntityReviewItemOption extends React.Component<EntityReviewItemOptionProps> {
  constructor(props:any) {
    super(props)
    this.onClick = this.onClick.bind(this)
    } 
    onClick() { this.props.changeSelected(this.props.change) }
    render() {
      const className = (this.props.selected) ? "EntityReviewItemOption selected" : "EntityReviewItemOption"

        const labelText = (this.props.count == 1) ? "1 Labeler" : this.props.count + " Labelers"
     return  <div className={className} onClick={this.onClick}>
         <OptionDot/>
         <div className="content">
         <label>{labelText}</label>

         <div className="text">{this.props.text}</div>
         </div>
         </div>
    }
}

function SkippedOption(props:{count:number,   selectedChange?:RowChange,  skipClicked:()=>void}) {
    const className = (props.selectedChange && props.selectedChange.type == RowChangeType.Excluded) ? "EntityReviewItemOption selected" : "EntityReviewItemOption"
    return <div className={className} onClick={props.skipClicked}>
         <OptionDot/>
    <div className="content">
    <label>{props.count} Skipped</label>

   <div className="text">Exclude</div>
    </div>
    </div>;
}

function CustomOption(props:{ text:any, change?:RowChange_Entity, selected:boolean, customClicked:(wasCustom:boolean)=>void}) {
  const className = (props.selected) ? "EntityReviewItemOption selected" : "EntityReviewItemOption"
  function customClicked() {
    props.customClicked(props.selected)
  }
  return <div className={className} onClick={customClicked}>
       <OptionDot/>
  <div className="content">
  <label>Custom</label>

 <div className="text">{props.text}</div>
  </div>
  </div>;
}



// interface EntityReviewItemProps {  
//   row:ReviewRow 
//   job:Job
//   // entityOptions:Option[]
//   updatedRow(row:ReviewRow)
// }
interface EntityReviewItemState {
  entityOptions:Option[]
}

export class EntityReviewItem extends React.Component<ReviewItemViewProps,EntityReviewItemState> {
    constructor(props:any) {
        super(props)

        const entityTaskData:TaskData_Entity = this.props.job.tasks[TaskType.Entity] as TaskData_Entity; 
        let entityOptions:Option[] = [] //  entityTaskData.entitys.map((item) =>  { return { value: item.name, label: item.name, data:item } }   )
        this.state = {entityOptions:entityOptions}

        // this.customOptionSelected = this.customOptionSelected.bind(this)
        // this.entitySelected = this.entitySelected.bind(this)
        // this.excludeSelected = this.excludeSelected.bind(this)


        this.changeSelected     = this.changeSelected.bind(this)
        this.skipClicked        = this.skipClicked.bind(this)
        this.customClicked      = this.customClicked.bind(this)
    }

    // customOptionSelected(option:Option) {
    //   let item = this.props.row;
    //   item.changeStatus = ChangeStatus.ResolvedEscalation;
    //   item.change = CreateRowChange_Entity(option.data);
    //   this.props.updatedRow(item);
    // }
    // entitySelected(entity:Entity) {
    //   log("entity selected", entity);
    // //   let item = this.props.row;
    // //   item.changeStatus = ChangeStatus.ResolvedEscalation;
    // //   item.change = CreateRowChange_Entity(entity);
    // //   this.props.updatedRow(item);
    // }
    // excludeSelected() {
    //   let item = this.props.row;
    //   item.changeStatus = ChangeStatus.ResolvedEscalation;
    //   item.change = CreateRowChange_Excluded()
    //   this.props.updatedRow(item);
    // }
    changeSelected(change:RowChange_Entity) {
         log("entity selected", change);
      let item = this.props.row;
      item.changeStatus = ChangeStatus.ResolvedEscalation;
      item.change = change
      this.props.updatedRow(item);
    }
    skipClicked() {
      let item = this.props.row;
      item.changeStatus = ChangeStatus.ResolvedEscalation;
      item.change = CreateRowChange_Excluded()
      this.props.updatedRow(item);
    }
    customClicked(wasCustom:boolean) {
      const {   originalRow, change } = this.props.row;
      const possibileEntities = (this.props.job.tasks[TaskType.Entity] as TaskData_Entity).entities;
      const changeToUse = (wasCustom) ? change : CreateRowChange_Entity([]);

      Actions.Controller.trigger({type:ActionType.ShowModal, modalContent:
      <EntityReviewCustomModal
        text={originalRow.text}
        change={changeToUse} 
        possibileEntities={possibileEntities}
        updateChange={this.changeSelected}
      />})

    }
  render() {
    const {  type,  originalRow, work, confidence, changeStatus, change } = this.props.row;
    let uniqueKeys = {"skipped":{count:0, key:"skipped", change:null}}
    for (let i = 0; i < work.length; i++) {
        const element = work[i]  
        if (element.change.type == RowChangeType.Skipped) {
          uniqueKeys.skipped.count++;
        }
        if (element.change.type === RowChangeType.Entity) {
            const key = entitiesKeyForArray(element.change.entities)  
            if (!uniqueKeys[key]) { uniqueKeys[key] = {count:0, key:key, change:element.change}  } 
            uniqueKeys[key].count++; 
        }
    }
    console.log("uniqueKeys",uniqueKeys)

    const possibileEntities = (this.props.job.tasks[TaskType.Entity] as TaskData_Entity).entities;

    const selectedKey = (change && change.type === RowChangeType.Entity) ? entitiesKeyForArray(change.entities) : null; 
    let isCustom = (selectedKey) ? true : false; 
    let options = []
    _.forOwn(uniqueKeys, (item) => {
    
        if (item.change?.type == RowChangeType.Entity) {
          if (selectedKey && selectedKey == item.key) { isCustom = false; }
        options.push(<EntityReviewItemOption 
                        key={item.key} 
                        count={item.count} 
                        text={<StaticTextwithEntities text={originalRow.text} entities={item.change.entities} possibileEntities={possibileEntities}/>} 
                        change={item.change} 
                        selected={(selectedKey && selectedKey == item.key)}
                        changeSelected={this.changeSelected} />)
        }
    })

    console.log("Custom ", (change && change.type === RowChangeType.Entity),change);
    
    options.push(<CustomOption 
                  key={"custom"} 
                  selected={isCustom}
                  customClicked={this.customClicked} 
                  text={<StaticTextwithEntities 
                          key={"key_" + selectedKey}
                          text={originalRow.text} 
                          entities={(isCustom && change && change.type === RowChangeType.Entity) ? change.entities : []}
                          possibileEntities={possibileEntities}/>} 
                  change={(change && change.type === RowChangeType.Entity) ? change : null}/>)

    options.push(<SkippedOption key={uniqueKeys["skipped"].key} count={uniqueKeys["skipped"].count}  skipClicked={this.skipClicked} selectedChange={change} />)

    console.log("Unique Keys",uniqueKeys);
    

    const completeIcon = (changeStatus === ChangeStatus.ResolvedEscalation) ? <div className="completeIcon"><FontAwesomeIcon icon={faCheck} /></div> : null;



    return <div className="EntityReviewItem">
              {completeIcon}
              {options}
            </div>

    
  }

}
