import React from 'react';
import { Option, MultiSelectList } from '@swivl/great-grey-components';
import { CreateLogger,AssignmentWorkRow, Intent, DataSetRow, TaskData_TextCategorize, CreateRowChange_TextCategorize, RowChange_TextCategorize } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../Screens/Assignment/Exercises/Card/ExerciseCard';
import "./TextCategorizeExerciseView.scss"
// import TextCategorizeExerciseViewButton from './Button/TextCategorizeExerciseViewButton';
// import { MultiSelectList } from './MultiSelectList/MultiSelectList';
import { ExerciseNav } from '../../../Screens/Assignment/Exercises/components/ExerciseNav/ExerciseNav';

const log = CreateLogger("IntentExercise:Main");


export class TextCategorizeExerciseNav extends ExerciseNav {
  renderRight() {  return <div className="skipAndDone">   <div className="ExerciseNavButton" onClick={this.doneClicked}>NEXT</div></div>  }
}


export class TextCategorizeExerciseView extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  constructor(props:any) {
    super(props) 
    let row:AssignmentWorkRow = props.work;

    const data = (row.change && (row.change as RowChange_TextCategorize).categories) ?(row.change as RowChange_TextCategorize).categories.map(item => item.id) : []

    this.state = {isShowing:false, data:data}
    // this.state = {isShowing:false, data:{}}
    // let intents = (this.props.assignment.task as TaskData_Intent).intents;
    // let row:AssignmentWorkRow = props.work;
    this.categorySelected = this.categorySelected.bind(this)

  }
  doneClicked() {

    
    let row:AssignmentWorkRow = this.props.work;
    let task = (this.props.assignment.task as TaskData_TextCategorize);

    const data = this.state.data || []
    const cats = task.categories.filter(item => data.includes(item.id))

    row.change = CreateRowChange_TextCategorize(cats)

    this.props.save(row)
    
  }
  handleKeyDown(event:KeyboardEvent)  {
    log("Key Down");
    if (!this.state.isShowing) {
      log("I'm not supposed to be showing");
    }
    if  (event.key === "d" || event.key === "D") {
      this.doneClicked()
      
    }
  }
  

  categorySelected(option:Option) {
    if (!option || !option.value) { return; }
    let data:string[] = this.state.data || []
    let index = data.indexOf(option.value)

    if ( index>=0) {
      data.splice(index,1)
    } else {
      data.push(option.value)
    }

    this.setState({data:data})
  }


  

  

  renderContent() {
    let task = (this.props.assignment.task as TaskData_TextCategorize);
    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 


    let {data} = this.state; 

    let options:Option[] = []

    for (let i = 0; i < task.categories.length; i++) {
      const cat = task.categories[i];
      
      // if (data.showingSpecific) {
      //   if (data.showingSpecific.id === cat.id) {
      //   specificHeading = <h3><FontAwesomeIcon  icon={faArrowLeft} onClick={this.clearSelected} />{cat.specificTitle}</h3>
      //   // buttons = cat.specific.map((item) => <TextCategorizeExerciseViewButton key={item.id} category={item} categorySelected={this.categorySelected} /> )
      // }
      // } else {
        options.push( {label: cat.name, value: cat.id} )
      // }
    }


    
    return <div className="TextCategorizeExerciseView">
      <div className="textRow">"{originalRow.text}"</div>
      <div className="selectionWrapper">
              <label>Options</label>
              <MultiSelectList options={options} selectedOptionValues={data} optionSelected={this.categorySelected} />

            </div>
            
     {this.renderKey(true, "Select", "Select / Deselect", "D", "Next / Done")}
    </div>   
  }
}
