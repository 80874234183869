import React from 'react';
import ReactPaginate from 'react-paginate';

import './JobReviewScreen.scss';
import { Job,CreateLogger, JobReview, DataSetRow, AssignmentWorkRow, ChangeStatus, ReviewRow, TaskType, TaskData_Intent } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';
import { ActionsConsumer, ActionType, Actions } from '../../../Actions/Actions';
import { JobReviewItem } from './components/JobReviewItem';
import { reviewRows } from './TestData'
import { NavTextButton,Option, Switch } from '@swivl/great-grey-components';
import { NavBar } from '../../Layout/NavBar/NavBar';
import LoadingScreen from '../../Loading/LoadingScreen';
import Service from '../../../Services/Service';
import { TaskViews } from '../../../TaskViews/TaskViews';
const log = CreateLogger("job:JobReviewScreen")

const NUM_PER_PAGE = 50; 

interface JobReviewScreenProps {

}

export type ByTaskType = { [type: string]: ReviewRow[] }

interface JobReviewScreenState {
  isSaving:boolean;
  jobReview?:JobReview;
  labeled?:ByTaskType;
  escalated?:ByTaskType; 
  filterReviewed:boolean; 
  currentPage:number;
}

export class JobReviewScreen extends ActionsConsumer<JobReviewScreenProps,JobReviewScreenState> {
    get job():Job|undefined {  return ( Model.Navigation.second() && Model.Job.state.items && Model.Job.state.items[Model.Navigation.second()]) ?  Model.Job.state.items[Model.Navigation.second()] : null }

    constructor(props:any) {
    super(props)
    this.state = {currentPage:0, isSaving:false,filterReviewed:false};
    this.saveAndExitClicked = this.saveAndExitClicked.bind(this);
    this.escalationUpdated  = this.escalationUpdated.bind(this)
    this.filterToggled = this.filterToggled.bind(this);
    this.handlePageClick  = this.handlePageClick.bind(this);
    Actions.Controller.subscribeTo(this, [
      ActionType.NavigationPathUpdated,
      ActionType.JobsLoaded, 
      ActionType.JobFetched

    ])
    Model.Job.load();
    this.load()
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.filterReviewed !== this.state.filterReviewed) { 
  //     // this.renderNavBar();
  //   }
  // }

  load() {
    Model.JobReview.loadJobReview(Model.Navigation.second()).then((jobReview) => {
      log("Got Job Review", jobReview);
      const rows = jobReview.rows;
      var labeled:ByTaskType = {}
      var escalated:ByTaskType = {}
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        if (item.changeStatus === ChangeStatus.ResolvedEscalation || item.changeStatus === ChangeStatus.UnresolvedEscalation) {
          if(!escalated[item.type]) { escalated[item.type] = [] };
          escalated[item.type].push(item);
        } else {
          if(!labeled[item.type]) { labeled[item.type] = [] };
          labeled[item.type].push(item);
        }
        
      }
      Model.Job.fetchById(jobReview.jobId)
      this.setState({jobReview:jobReview,labeled:labeled, escalated:escalated})
    }).catch(Service.Toast.error);

  }

  escalationUpdated(row:ReviewRow) {
    let escalated = this.state.escalated;
    let rows = escalated[row.type]
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].originalRow.id ===  row.originalRow.id) {
        rows[i] = row;
        break;
      }
    }
    escalated[row.type] = rows; 
    this.setState({escalated:escalated})
  }
  
  async saveAndExitClicked(){
    this.setState({isSaving:true});
    var {jobReview, escalated, labeled} = this.state;
    let rows =  [];
    let unresolvedEscalation = 0;
    for (const key in escalated) { if (escalated.hasOwnProperty(key)) { rows = rows.concat(escalated[key]) }}
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].changeStatus === ChangeStatus.UnresolvedEscalation) {  unresolvedEscalation++; }      
    }
    for (const key in labeled) { if (labeled.hasOwnProperty(key))     { rows = rows.concat(labeled[key]) }}
    log("unresolvedEscalation",  unresolvedEscalation);
    jobReview.rows = rows; 
    
    try {
      
   
    await Model.JobReview.saveJobReview(jobReview)
    
    // .then((_:boolean) => {
      let job = Model.Job.state.items[jobReview.jobId]
      if  (job) {
        job.unresolvEdescalations = unresolvedEscalation;
        await Model.Job.update(job,  true)
      }
     
      Model.Navigation.setPath("/job/" + jobReview.jobId); 
    } catch (e) {
      
          Service.Toast.error(e);
      this.setState({isSaving:false});

    }


  }

  filterToggled(switchName:string,  on:boolean) {
    this.setState({filterReviewed:on, currentPage:0})
  }
  handlePageClick(data) { log("DATA",  data) 
  if (typeof data.selected !== 'undefined') { this.setState({currentPage:data.selected})}
  }
  
  renderNavBar() {

    return <NavBar 
            left={<NavTextButton onClick={this.saveAndExitClicked} text={"Save & Exit"} isLoading={this.state.isSaving} />}
            center={<h3>Review</h3>}
            />
            // right={<div>Filter Reviewed <Switch on={this.state.filterReviewed} name="isFiltering" onChange={this.filterToggled} /></div>}

  }

  renderEscalations() {
    const {escalated} = this.state;
    if (!escalated) { return null; }
    const job  =  Model.Job.state.items[this.state.jobReview.jobId];
    if (!job || !job.tasks) { return null; }
    var items = [];

    // const intentTaskData:TaskData_Intent = job.tasks[TaskType.Intent] as TaskData_Intent; 
    // let intentOptions:Option[] = intentTaskData.intents.map((item) =>  { return { value: item.name, label: item.name, data:item } }   )



    var totalNumberOfPossibileRows = 0; 
    for (const type in escalated) {
      if (escalated.hasOwnProperty(type)) {
        for (let i = 0; i < escalated[type].length; i++) {
          const item = escalated[type][i];
          if (!this.state.filterReviewed || item.changeStatus !== ChangeStatus.ResolvedEscalation) {
            totalNumberOfPossibileRows++;
          }
        }

        items.push(<h1 key={type}>{type} Task</h1>)

        let startingNumber = this.state.currentPage * NUM_PER_PAGE;
        if (startingNumber > escalated[type].length) {
          startingNumber = escalated[type].length; 
        }
        let index = 0; 
        for (let i = startingNumber; i < escalated[type].length; i++) {
          if (index >= NUM_PER_PAGE) { break; }
          index++; 
          const item = escalated[type][i];
          if (!this.state.filterReviewed || item.changeStatus !== ChangeStatus.ResolvedEscalation) {
            items.push(TaskViews.ReviewItem( job, item,this.escalationUpdated))
          }

          // items.push(<JobReviewItem intentOptions={intentOptions} updatedRow={this.escalationUpdated} key={"type_"+item.originalRow.id} item={item} />);
        }
        


      }

      

      items.push(      <ReactPaginate
        key={"pagination"}
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(totalNumberOfPossibileRows / NUM_PER_PAGE)}
        forcePage={this.state.currentPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />)
    }
    

    return items;
  }


  renderItems() {
    if (Model.Navigation.third() === "escalations") {
      log("Render Escalations");
      return this.renderEscalations();
    }
    // let items =  []
    // for (const key in reviewRows) {
    //   if (reviewRows.hasOwnProperty(key)) {
    //     const element = reviewRows[key];
    //   }
    // }
    // return items; 
  }
  renderLoading() { return <div className="loadingReview"><span>🦉</span></div> }
  renderAccessDenied() { return <div className="notAllowed"><h1>You don't seem to have access to this job.</h1></div> }
  render() {
    const {jobReview} = this.state;

    log("JOB", this.job, jobReview)
  
    let content = (!jobReview || !Model.Job.state.items) ? this.renderLoading() : this.renderItems();
    if (jobReview && Model.Job.state.items &&  !Model.Job.state.items[jobReview.jobId]) {
      content = this.renderAccessDenied();
    } 

    return <div className="JobReviewScreen">
      {this.renderNavBar()}
      <div className="screen">
        {content}
      </div>
    </div>;
  }

}
