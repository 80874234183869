import React from 'react';


import {  Model } from '../../../../Models/Model';
import { Job,CreateLogger, JobStatus,TaskData,TaskType,EmptyTaskData } from '@swivl/great-grey-lib';
import "./JobTask.scss";

import {TaskViews}  from '../../../../TaskViews/TaskViews'
import { Switch,  SaveInput, Input } from '@swivl/great-grey-components';
const log = CreateLogger("job:JobTask");

interface JobTaskProps {
    job:Job;
    taskType:TaskType
}
interface JobTaskState {
  savedTaskData?:TaskData  // This is used to save a backup of the task.
}

export  class JobTask extends React.Component<JobTaskProps,JobTaskState> {
  constructor(props:any) {
    super(props)
    this.state = {};
    this.toggleActive      = this.toggleActive.bind(this)
    this.taskTitleOnChange = this.taskTitleOnChange.bind(this);
  }

  toggleActive(name, on) { 
    let job = this.props.job; 
    if (on) {
      if (this.state.savedTaskData) {
        job.tasks[this.props.taskType] = this.state.savedTaskData;
      } else {
        job.tasks[this.props.taskType] = EmptyTaskData(this.props.taskType)
      }
    } else {
      log("Deleting Task Type");
      this.setState({savedTaskData:job.tasks[this.props.taskType] }) // Save it before switching it off
      delete job.tasks[this.props.taskType]; 
    }
    Model.Job.update(job,true);
  }


  taskTitleOnChange(event:React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;
    if (text.length < 1) { return; }
    let job = this.props.job; 
    job.tasks[this.props.taskType].name = text;
    Model.Job.update(job,false);
  }



  render() {
    const active = (this.props.job.tasks[this.props.taskType])?true:false
    if (!active && this.props.job.status !== JobStatus.Unstarted) { return null; }
    const switchView = (this.props.job.status === JobStatus.Unstarted) ?      <Switch 
    name="taskOn"
    on={active} 
    onChange={this.toggleActive}
    /> : <div className="status">{this.props.job.tasks[this.props.taskType].status}</div>

  

    const taskView = (this.props.job.status === JobStatus.Unstarted) ? 
    TaskViews.Creation(this.props.taskType, this.props.job) : 
    TaskViews.JobStarted(this.props.taskType, this.props.job)

    let title;    
    
    if (this.props.job.status  === JobStatus.Unstarted && active) {
      title = <Input  onChange={this.taskTitleOnChange} value={this.props.job.tasks[this.props.taskType].name} name="taskName" placeholder="Task name..." /> 
      } else if (active) {
        title =  this.props.job.tasks[this.props.taskType].name;
      } else {
        title = <h3>{this.props.taskType}</h3> 
      }
      console.log("Title",title);
      
    
    return <div className="JobTask">
              <div className="header">
                {switchView}
                <div className="title">{title}</div>
              </div>
              {taskView}
            </div>;
  }

}
