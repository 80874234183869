import React, { createRef } from 'react';
import { SelectionList,Option } from '@swivl/great-grey-components';
import { CreateLogger,Assignment,WorkRowStatus,CreateRowChange_Intent, TaskData_Intent, AssignmentWorkRow, Intent, DataSetRow } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../Screens/Assignment/Exercises/Card/ExerciseCard';
import { AudioCategorizeAndSplitExercisesView } from './AudioCategorizeAndSplitExercisesView';

const log = CreateLogger("IntentExercise:Main");




export class AudioCateforizeAndSplitExerciseCard extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  mainRef = createRef<AudioCategorizeAndSplitExercisesView>();
  
  constructor(props:any) {
    super(props) 

    // let intents = (this.props.assignment.task as TaskData_Intent).intents;
    // let row:AssignmentWorkRow = props.work;
    this.optionSelected = this.optionSelected.bind(this)

  }

  doneClicked() {
    if (this.mainRef && this.mainRef.current) {
      this.mainRef.current.doneClicked();
    }
  }

  handleKeyDown(event:KeyboardEvent)  {
    log("Key Down");
    if (!this.state.isShowing) {
      log("I'm not supposed to be showing");
    }
    if  (event.keyCode === 13) {
      let row:AssignmentWorkRow = this.props.work;
      if (this.hasUpdated && this.selectedIntent) {
        row.change = CreateRowChange_Intent(this.selectedIntent)
      } else {
        const intents = (this.props.assignment.task as TaskData_Intent).intents;
        row.change = CreateRowChange_Intent(intents[0])
      } 
      log("Change", row.change)
      this.props.save(row);
    }
  }
  
  optionSelected(selectedOption:Option) {
    log("Option Selected Selected Option", selectedOption.data)
    this.hasUpdated = true; 
    this.selectedIntent = selectedOption.data;

    // let row:AssignmentWorkRow = this.props.work;
    // row.change = CreateRowChange_Intent(selectedOption.data) ;
    // this.props.update(row);
  }
  renderKey() { return null;  }

  renderContent() {
    console.log("Wut up", this.props.work, this.props.assignment);
    
    return <div className="AudioCateforizeAndSplitExerciseCard">
            <AudioCategorizeAndSplitExercisesView ref={this.mainRef} {...this.props} />
     
    </div>   
  }
}
