import React from 'react';
import { SelectionList,Option } from '@swivl/great-grey-components';
import { CreateLogger,Assignment,WorkRowStatus,CreateRowChange_Entity, TaskData_Entity, AssignmentWorkRow, Entity, DataSetRow, RowChange_Entity, CreateRowChange_Unchanged, AreEntityArraysEqual } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../Screens/Assignment/Exercises/Card/ExerciseCard';

import "./EntityExerciseView.scss"
import TextWithEntities from '../../../Components/TextWithEntities/TextWithEntities';
const log = CreateLogger("EntityExercise:Main");

// Tokenizer.addRegex( /\s+/gi, 'space', 's' );


export class EntityExerciseView extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedEntity?:Entity;
  constructor(props:any) {
    super(props) 
    let row:AssignmentWorkRow = props.work;

    const data = (row.change && (row.change as any).entities) ?(row.change as any).entities : []

    this.state = {isShowing:false, data:data}

    // let entitys = (this.props.assignment.task as TaskData_Entity).entitys;
    console.log("ROW", row);
    this.updateEntities = this.updateEntities.bind(this)

  }
 
  
  updateEntities(entities:Entity[]) {
      // let data = this.state.data;
      // data.push(entity);
      this.setState(entities as any) 
      //  let row:AssignmentWorkRow = this.props.work;
      //  row.tempData.entities = (row.tempData.entities ) ? (row.tempData.entities ) : []; 
      //  row.tempData.entities.push(entity)
      //  this.props.update(row);
  }
  doneClicked() {
    console.log("Done Clicked");
    let row:AssignmentWorkRow = this.props.work;

    
    if (this.state.data) {
      let entities = this.state.data as Entity[]
      let originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
      if (originalRow.entities) {
       if (AreEntityArraysEqual(entities, originalRow.entities)) {
        row.change = CreateRowChange_Unchanged()
       } else {
        row.change = CreateRowChange_Entity(this.state.data)
       }
      } else {
        row.change = CreateRowChange_Entity(this.state.data)
      }
    } else {
      row.change = CreateRowChange_Unchanged()
    }
    this.props.save(row);
  }

  renderContent() {
  
    let possibileEntities = (this.props.assignment.task as TaskData_Entity).entities;
    // const options = entitys.map((item) => {return { value: item.name, label: item.name, data:item }})
    // log("the assignment", this.props.assignment, this.props.work.originalRowId)
    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
    let row:AssignmentWorkRow = this.props.work;

    
    
    // const entities:Entity[] = [{
    //   id:"Price",
    //   name:"Price",
    //   startChar:0,
    //   endChar:5
    // }]

    return <div className="EntityExerciseView">
              <TextWithEntities updateEntities={this.updateEntities} text={originalRow.text} entities={this.state.data} possibileEntities={possibileEntities} />
           
           
   
     
    </div>   
  }
}
