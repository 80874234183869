import React from 'react';
import { CreateLogger,TimelineCategory } from '@swivl/great-grey-lib';

import "./TimelineTracks.scss"
const log = CreateLogger("TimelineSplitRow:Main");

interface TimelineTracksProps { 
  duration?:number 
  categories:TimelineCategory[]

}

export class TimelineTracks extends React.Component<TimelineTracksProps> {

  render() {    
    const style = {width:this.props.duration * 20 + "px"} 
    const tracks = this.props.categories.map((item,index) => <div key={"track_" + index} style={style} className="TimelineTracksTrack"></div> )   
    return <div style={style} className="TimelineTracks">
      {tracks}
    </div>   
  }
}
