import React from 'react';
import { MultiSelectList, Option } from '@swivl/great-grey-components';
import { Category, ChangeStatus, CreateLogger,CreateRowChange_TextCategorize,ReviewRow, RowChangeType } from '@swivl/great-grey-lib';

import "./TextCategorizeResolution.scss"
// import TextCategorizeResolutionViewButton from './Button/TextCategorizeResolutionViewButton';
// import { MultiSelectList } from './MultiSelectList/MultiSelectList';
import { ExerciseNav } from '../../../Screens/Assignment/Exercises/components/ExerciseNav/ExerciseNav';
import { ReviewItemViewProps } from '../../TaskViews';
import { ReviewTaskBase, ReviewTaskBaseProps } from '../../Base/ReviewTaskBase';

const log = CreateLogger("IntentResolution:Main");

// interface Props {
//     reviewRow:ReviewRow 

// }
interface State {
  selectedOptionValues:string[]
}

// export class TextCategorizeResolutionNav extends ExerciseNav {
//   renderRight() {  return <div className="skipAndDone">   <div className="ResolutionNavButton" onClick={this.doneClicked}>NEXT</div></div>  }

// }
export class TextCategorizeResolutionView<ReviewTaskBaseProps, State> extends ReviewTaskBase { 
  hasUpdated = false; 
  options:Option[]
  allCategories:Category[]
  alreadySelecteCategoryIds:string[]

  constructor(props:any) {
    super(props) 
    // let row:AssignmentWorkRow = props.work;

    // const data = (row.change && (row.change as RowChange_TextCategorize).categories) ?(row.change as RowChange_TextCategorize).categories.map(item => item.id) : []

    // this.state = {isShowing:false, data:data}
    // this.state = {isShowing:false, data:{}}
    // let intents = (this.props.assignment.task as TaskData_Intent).intents;
    // let row:AssignmentWorkRow = props.work;
    this.categorySelected = this.categorySelected.bind(this)
    this.allCategories = []
    this.alreadySelecteCategoryIds =[]

    let selectedOptionValues:string[] = []

    const {row} = props; 
    var categoriesAndCount = {}
    for (let i = 0; i < row.work.length; i++) {
      const element = row.work[i];
      if (element.change.type === RowChangeType.TextCategorize) {
        for (let c = 0; c < element.change.categories.length; c++) {
          const cat = element.change.categories[c];
          if (!categoriesAndCount[cat.id]) { categoriesAndCount[cat.id] = { count:0, category:cat}}
          categoriesAndCount[cat.id].count += 1;           
        }

      }
      
    }
    this.options = [] 
    for (const key in categoriesAndCount) {
      if (Object.prototype.hasOwnProperty.call(categoriesAndCount, key)) {
        const element = categoriesAndCount[key];
        this.allCategories.push(element.category)
        if (element.count < row.work.length) {
          this.options.push( {label: element.category.name, value:element.category.id} )
        } else {
          this.alreadySelecteCategoryIds.push(element.category.id)
        }
      }
    }



    
    this.state = {selectedOptionValues:selectedOptionValues}
  }


  getUpdatedReviewRow():ReviewRow|undefined {
    let row = this.props.row;
    // const allCats = 

    
    const cats = this.allCategories.filter(item => (this.state as any).selectedOptionValues.includes(item.id) || this.alreadySelecteCategoryIds.includes(item.id))

    row.changeStatus = ChangeStatus.ResolvedEscalation;

    row.change = CreateRowChange_TextCategorize(cats)

    return row; 
}



  handleKeyDown(event:KeyboardEvent)  {
    log("Key Down");
    // if (!this.state.isShowing) {
    //   log("I'm not supposed to be showing");
    // }
    if  (event.key === "d" || event.key === "D") {
      this.props.next()
      
    }
  }
  

  categorySelected(option:Option) {
    if (!option || !option.value) { return; }
    let data:string[] = (this.state as any ).selectedOptionValues || []
    let index = data.indexOf(option.value)

    if ( index>=0) {
      data.splice(index,1)
    } else {
      data.push(option.value)
    }

    this.setState({selectedOptionValues:data})
  }


  

  

  render() {

    const {row} = this.props; 
    // let task = (this.props.assignment.task as TaskData_TextCategorize);
    // const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 


    // let {data} = this.state; 


    // for (let i = 0; i < task.categories.length; i++) {
    //   const cat = task.categories[i];
      
    //   // if (data.showingSpecific) {
    //   //   if (data.showingSpecific.id === cat.id) {
    //   //   specificHeading = <h3><FontAwesomeIcon  icon={faArrowLeft} onClick={this.clearSelected} />{cat.specificTitle}</h3>
    //   //   // buttons = cat.specific.map((item) => <TextCategorizeResolutionViewButton key={item.id} category={item} categorySelected={this.categorySelected} /> )
    //   // }
    //   // } else {
    //     options.push( {label: cat.name, value: cat.id} )
    //   // }
    // }

    



    

    
    return <div className="TextCategorizeResolutionView">
      <div className="textRow">"{row.originalRow.text}"</div>
      <div className="selectionWrapper">
              <label>Options</label>
              <MultiSelectList options={this.options} selectedOptionValues={(this.state as any ).selectedOptionValues} optionSelected={this.categorySelected} />

            </div>
            
     {this.renderKey(true, "Select", "Select / Deselect", "D", "Next / Done")}
    </div>   
  }
}
