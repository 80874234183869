import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReviewRow, Job, TaskType } from "@swivl/great-grey-lib";
import React from "react";
import { Action, Actions, ActionsConsumer, ActionType } from "../../Actions/Actions";

export interface ReviewTaskBaseProps{
    row:ReviewRow 
    job:Job
    // intentOptions:Option[]
    next()
}

export class ReviewTaskBase extends ActionsConsumer<ReviewTaskBaseProps> {
    constructor(props:ReviewTaskBaseProps) {
        super(props)
        Actions.Controller.subscribeTo(this, [ActionType.KeyboardKeyDown])

    }
    handleAction(action:Action) {
        if (action.type === ActionType.KeyboardKeyDown) {
                setTimeout(()=> {
              this.handleKeyDown(action.event)
            },50);
        }

        
    
      }
      handleKeyDown(event:KeyboardEvent) {
      }
    
    getUpdatedReviewRow():ReviewRow|undefined {
        return this.props.row
    }
    
    renderUpdownKey(title?:string) {
      return  <div className="ExerciseKeyItem">
                <div className="icon">
                  <FontAwesomeIcon icon={faSort} />
                </div>
                <div className="text">{(title)?title:"Move selection"}</div>
                </div>;
    }
    
    renderEnterKey(title?:string) {
      return  <div className="ExerciseKeyItem">
                <div className="icon">
                  <div className="med">⏎</div>
                  <small>Enter</small>
                </div>
                <div className="text">{(title)?title:"Accept / Next"}</div>
                </div>;
    }
    renderAnyKey(key?:string ,title?:string) {
      if (!key ) { return; }
      return  <div className="ExerciseKeyItem">
                <div className="icon">
                  <div className="key">{key}</div>
                </div>
                <div className="text">{(title)?title:" Next"}</div>
                </div>;
    }
  
    renderKey(show?:boolean ,upDownText?:string, enterText?:string, anyKey?:string, anyKeyText?:string) {
      return <div className="ExerciseKey">
              {this.renderUpdownKey(upDownText)}
              {this.renderEnterKey(enterText)}
              {this.renderAnyKey(anyKey,anyKeyText)}
            </div>
    }
}