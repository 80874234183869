import React from 'react';
import { Model } from '../../Models/Model';
import { SortAndSearch, ListItem, ListItemContentTextAndFooter, Button, NavTextButton, LoadingItem} from '@swivl/great-grey-components';
// import { UploadDataPoints } from './Create/UploadDataPoints';
import { Modal } from '../Layout/Modal/Modal';
import { ShortId, SubTask,  NumberWithCommas, CreateLogger, GGDictionary, TaskType, HoursMinutesSeconds } from '@swivl/great-grey-lib';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SubTaskDetailsView,nameForTaskType } from './SubTaskDetailsView/SubTaskDetailsView';
import { ActionType, ActionsConsumer, Action, Actions } from '../../Actions/Actions';
import { BaseScreenWithColumns, BaseScreenWithColumnsState, SortType } from '../Base/BaseScreenWithColumns';
import "./SubTasksScreen.scss"

var pluralize = require('pluralize')
const log = CreateLogger("SubTasksScreen")
const moment = require('moment');

interface SubTasksScreenProps {
  setNavBar(left:any|undefined, center:any|undefined, right:any|undefined);

}
interface SubTasksScreenState extends BaseScreenWithColumnsState {
  isShowingModal:boolean;
  mLModels?:Array<SubTask>;
  selectedSubTask?:SubTask;
}

export  class SubTasksScreen extends BaseScreenWithColumns<SubTasksScreenProps, SubTask> {
  modalKey:string;
  model = "SubTask";
  path = "task";

  sortOptions = [
    {value:SortType.Newest, label:SortType.Newest},    
    {value:SortType.Oldest, label:SortType.Oldest},
    {value:SortType.Type,   label:SortType.Type},
    {value:SortType.AtoZ,   label:SortType.AtoZ},
    {value:SortType.ZtoA,   label:SortType.ZtoA}
 ];
  constructor(props:SubTasksScreenProps) {
    super(props)
    this.state = { ...this.state, isShowingModal:false }
    
    Actions.Controller.subscribeTo(this, [
      ActionType.CurrentMembershipUpdated,

       ActionType.AssignmentsLoaded,
       ActionType.AssignmentCreated,
       ActionType.AssignmentUpdated,
       ActionType.SubTasksLoaded,
       ActionType.SubTaskUpdated,
       ActionType.SubTaskDeleted] )

       Model.SubTask.load();
      //  if (!Model.Assignment.state.items) {
        Model.Assignment.load();
      //  } else {
        //  console.log("NOT LOADING ASSIGNMENTS")
      //  }
      this.gotoInProgressAssignment.bind(this);
  }

  componentDidMount() {
    this.props.setNavBar(null,<h3>Tasks</h3>,null   )
  }
  
  gotoInProgressAssignment() {
    let inProgressAssignments = Model.Assignment.inProgressAssignments()
    if (inProgressAssignments.length > 0) { 
      Model.Navigation.setPath("/assignment/" + inProgressAssignments[0].id );
    }
  }

  gotoDemo = () => {
    Model.Navigation.setPath("/image");
  }
  
  _renderList() {
    if (!Model.Assignment.state.items) {
      return <LoadingItem /> 
    }
    console.log("Model.User.state.user.id", Model.Session.state?.user?.id);
    var demoItem;
    if (  Model.Session.state.user.id && 
      (  Model.Session.state.user.id === '9c0c18f2-aee0-458b-ad23-d4903dfb0fdc' ||
         Model.Session.state.user.id === '768434b1-175e-420b-a90d-d1392f1971ef' ||
         Model.Session.state.user.id === '8ab216b7-f90f-49a6-8d6f-81333590715c'
        )) {
           demoItem =     <div className="ListItem" onClick={this.gotoDemo}>
          <div className="selectionChit"/>
          <div className="content">
          <ListItemContentTextAndFooter
                        text={"Image Identification"}
                        footerText={"Aug 19, 2020"}
                        footerRightText={  "In Progress" }
                        />
          </div>
          </div>
        }


    let inProgressAssignments = Model.Assignment.inProgressAssignments()
    const inProgressCell =  (inProgressAssignments.length > 0) ? 
    <div className="inProgressCell" onClick={this.gotoInProgressAssignment}>You have  {pluralize(" assignments",inProgressAssignments.length, true )} in progress. </div> :null;
    let output  = <div>{inProgressCell}
    {demoItem}
    {super._renderList()}

    </div>;
    return output;
  }
  
  renderItem(item:SubTask) {
    log("Item", item)
    let right = ""
    if (item.type  === TaskType.AudioCategorize ||
      item.type  === TaskType.AudioSplit ||
      item.type  === TaskType.Transcribe
      ) {
        var totalDuration = 0  
        item.dataOrder.forEach(index => { totalDuration += item.data[index].duration }); 
        right =   item.dataOrder.length + " exercises, " + HoursMinutesSeconds(totalDuration) + " total";
      }  else {
        right = item.dataOrder.length + " exercises";
      }
    return <ListItemContentTextAndFooter
                  text={item.task.name}
                  footerText={moment(item.createdAt).format("MMM D, YYYY")}
                  footerRightText={  right }
                  />
  }

  // handleAction(action:Action) {
  //   if (action.type === ActionType.CurrentMembershipUpdated) {
  //     Model.SubTask.load();
  //     Model.Assignment.load();    
  //     return;
  //   }   

    
  //   this.forceUpdate(); }

  renderDetails(item:any) { return <SubTaskDetailsView subTask={item} />}
}
