import * as React from 'react';


import { CreateLogger, MembershipRole, Organisation, OrganisationMembership, ProjectMembership } from '@swivl/great-grey-lib';
import { Model } from '../../../../../Models/Model';
import { ActionsConsumer, Actions, ActionType } from '../../../../../Actions/Actions';
import {  Input, Size } from '@swivl/great-grey-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import './NavProjectSelector.scss';

const log = CreateLogger('NavMenu');

interface NavProjectItemProps {
    projectMembershipSelected:(projectMembership:ProjectMembership, orgMembership:OrganisationMembership)=>void,
    projectMembership:ProjectMembership
    orgMembership:OrganisationMembership
}

function NavProjectItem({projectMembershipSelected,projectMembership,orgMembership}:NavProjectItemProps) {
    function onClick() { projectMembershipSelected(projectMembership,orgMembership) }
    return (
        <div className="NavProjectItem" onClick={onClick}>
            <div className="projectAvatar squareAvatar" />
            <div className="projectName">{projectMembership?.project?.name}</div>
        </div>
    );
}

interface OrgGroupProps {
    searchQuery:string 
    projectMembershipSelected:(projectMembership:ProjectMembership, orgMembership:OrganisationMembership)=>void,
    membership:OrganisationMembership
    orgMembershipSelected(orgMembership:OrganisationMembership)
}

function OrgGroup({projectMembershipSelected,membership, searchQuery, orgMembershipSelected}:OrgGroupProps) {
    var hasItem = false; 

    const projects = (!membership.projectMemberships || membership.projectMemberships.length === 0) ? <div className="noProjects">No Active Projects</div> : 
    membership.projectMemberships.map((item:ProjectMembership) =>
    {
        if (searchQuery.length) {
            if (!item.project.name.toLowerCase().includes(searchQuery.toLowerCase())) { return null; }
        }
        if(item.project?.archived) { return null; }
        hasItem = true; 
        return <NavProjectItem projectMembership={item} orgMembership={membership} projectMembershipSelected={projectMembershipSelected} />
    
    })
    if (searchQuery.length && !hasItem) { return null }
    const  orgTitleClicked = () => { orgMembershipSelected(membership) }
    console.log("membership.projectMemberships",membership)
    return (
        <div className="OrgGroup">
            <h3 onClick={orgTitleClicked}>{membership.organisation.name}</h3>
            {projects}
        </div>
    );
}


interface State {
    showList:boolean;
    searchQuery:string;
}

interface Props {
    isShowing:boolean; 
    
}




export default class NavProjectSelector extends ActionsConsumer<Props,State> {
    constructor(props:Props) {
      super(props)

      this.state = {showList:false,searchQuery:""}
      Actions.Controller.subscribeTo(this, [
        ActionType.CurrentMembershipUpdated,
        ActionType.UserLoggedIn
       ] )
       this.onSearchChange = this.onSearchChange.bind(this)
       this.toggleList = this.toggleList.bind(this)
       this.orgMembershipSelected = this.orgMembershipSelected.bind(this)
       this.projectMembershipSelected = this.projectMembershipSelected.bind(this)
    }
    onSearchChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({searchQuery:event.target.value});
      }
    
    toggleList() { this.setState({showList:!this.state.showList})}

    orgMembershipSelected(orgMembership:OrganisationMembership) {
        this.toggleList()
        Model.Session.setCurrentMembership(orgMembership,null)
    }
    projectMembershipSelected(projectMembership:ProjectMembership, orgMembership:OrganisationMembership) { 
        this.toggleList()
        Model.Session.setCurrentMembership(orgMembership,projectMembership)
    }
    renderList() {

        const user = Model.Session.state.user;
        const orgs = user.membership.map((item) => <OrgGroup searchQuery={this.state.searchQuery} membership={item} orgMembershipSelected={this.orgMembershipSelected} projectMembershipSelected={this.projectMembershipSelected} />)
        const className = (this.state.showList) ? "projectList show" : "projectList"

        return <div className={className}>
            <div className="searchArea">
                <Input 
                    isRound 
                    isSearch={true} 
                    size={Size.Small} 
                    value={this.state.searchQuery}
                    onChange={this.onSearchChange}
                    />

            </div>
            <div className="projectArea">
            {orgs}
            </div>
        </div>
    }

    renderHeader(currentMembership?:OrganisationMembership, currentProjectMembership?:ProjectMembership ) {
        const icon = (this.state.showList) ? faChevronUp : faChevronDown
        let title;
        if (currentProjectMembership && currentProjectMembership.project?.name) {
            title = currentProjectMembership.project?.name
        } else if (currentMembership && currentMembership.organisation?.name) {
            title = currentMembership.organisation?.name
        } 
        
        return <div className="SelectedProjectHeader" onClick={this.toggleList}>
            <div className="projectAvatar squareAvatar" />

            <div className="selectedProjectTitle">{title}</div>
            <FontAwesomeIcon icon={icon} />
        </div>
    }

    render() {

      const {isShowing} = this.props; 
      const user = Model.Session.state.user;
      const currentMembership = Model.Session.state.currentMembership
      const currentProjectMembership = Model.Session.state.currentProjectMembership
      
      if (!currentMembership && !currentProjectMembership) { return null; }

      if (!user || !user.membership) { return null } // Don't draw if not showing. Waste of processing power. 
       

      
      return <div className={"NavProjectSelector"}>
                {this.renderHeader(currentMembership,currentProjectMembership )}
                {this.renderList()}
      </div>
    } 
}
