import React from 'react';
import "./TwoColumnLayoutView.scss"


import {Option } from '@swivl/great-grey-components';
interface TwoColumnLayoutViewNavItemProps { 
    itemClicked(item:Option)
    item:Option 
    selectedItemValue?:string 
 }

class TwoColumnLayoutViewNavItem extends React.Component<TwoColumnLayoutViewNavItemProps> {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick() { this.props.itemClicked(this.props.item)}
    render() {
        const {item, selectedItemValue } = this.props 
        const className = (selectedItemValue && item.value === selectedItemValue) ? "TwoColumnLayoutViewNavItem selected" : "TwoColumnLayoutViewNavItem" 
        return  <div 
                    onClick={this.onClick}
                    className={className}>
                        {item.label}
                    </div>;
    }
}

// import * as Model from '../../Models/Model';
interface Props {
    header?:React.ReactNode
    items:Option[]
    itemSelected(item:Option)

    selectedItemValue?:string  
}
export default class TwoColumnLayoutView extends React.Component<Props> {
  constructor(props:any) {
    super(props)

  
  }
  
  componentDidMount() {

  }




  render() {
    const {header, items, selectedItemValue, itemSelected } = this.props 
    const headerView = (header) ? <div className="columnHeader">{this.props.header}</div> : null
    const itemsViews = items.map((item:Option) =>  <TwoColumnLayoutViewNavItem key={item.value} item={item} itemClicked={itemSelected} selectedItemValue={selectedItemValue} /> )
    
    // <div className={(selectedItemValue && item.value === selectedItemValue) ? "navOption selected" : "navOption"}>{item.label}</div>)
    return <div className="TwoColumnLayoutView">
            <div className="leftColumn">
                {headerView}
                <div className="navOptions">{itemsViews}</div>
            </div>
            <div className="twoColumnLayoutViewContent">{this.props.children}</div> 
    </div>;
  }

}
