import { Model } from '../Model';

import { DataSet,DataFileType, GGDictionary, CreateLogger} from '@swivl/great-grey-lib';
import { ActionType } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import Service from '../../Services/Service';
const log = CreateLogger("DataSetModel")


export interface DataSetModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<DataSet>
}> {}

export class DataSetModel extends BaseModel<DataSet,DataSetModelState>  {
  static get Model():DataSetModel {   if (DataSetModel._Model) { return DataSetModel._Model; }  else {   DataSetModel._Model = new DataSetModel(); return DataSetModel._Model ; } }

  private static _Model:DataSetModel;
  private constructor() { super();   }
  ENTITY_NAME = "dataset";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.DataSetsLoaded,
    ItemFetched : ActionType.DataSetFetched,
    ItemUpdated : ActionType.DataSetUpdated,
    ItemDeleted : ActionType.DataSetDeleted,
    ItemCreated : ActionType.DataSetCreated
  } 

  createAndAddNewDataSet(dataSet:any):Promise<DataSet> {

    
    return this.create(dataSet);
  }

  // fetchDataSetData(dataSet:DataSet, progressCallback:(percentage:number)=>void):Promise<DataSetData> {
  //   return Service.File.fetchFile<DataSetData>(dataSet.dataFileName, progressCallback)
  // }

  // updateDataSetData(dataSet:DataSet, newData:DataSetData, progressCallback:(percentage:number)=>void):Promise<any> {
  //   log("Updating Data Set")
  //   dataSet.rowCount = newData.rows.length;
  //   return Service.File.uploadFile(dataSet.dataFileName, DataFileType.DataSet, true, newData, progressCallback )
  //   .then((uploadResponse) => {
  //     log("uploadResponse",uploadResponse);
  //     this.update(dataSet,true);
  //   }).catch(Service.Toast.error);
    

  // }


}