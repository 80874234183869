import React from 'react';
import LoginScreen from '../Screens/Login/LoginScreen';


import {Model} from '../Models/Model';
import { LayoutView } from '../Screens/Layout/LayoutView';
import { Action, ActionType, ActionsConsumer, Actions } from '../Actions/Actions';
import { CreateLogger,MembershipRole,User } from '@swivl/great-grey-lib';
import LoadinigScreen from '../Screens/Loading/LoadingScreen';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';

import 'react-toastify/dist/ReactToastify.css';
import { ActionsController } from '../Actions/Controller/ActionsController';
import InviteScreen from '../Screens/Invite/InviteScreen';


const log = CreateLogger("app:main");




interface State {
  showModal:boolean
  modalContent?:React.ReactNode
}

const modalStyle = {
  overlay: {
    zIndex                : 999999

  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    borderRadius          : '8px',
    transform             : 'translate(-50%, -50%)',
    overflow              :'visible'
  }
};

export class App extends ActionsConsumer<any, State> {
  static serverAddress:string;
  static serverName:string;


  
  constructor(props:any) {
    super(props);
    this.state = {showModal:false}
    log("props", props);
    Model.initModels();
    Actions.Controller.subscribeTo(this, 
      [ActionType.UserLoaded , 
        ActionType.UserLoggedIn ,
         ActionType.UserLoggedOut,
        ActionType.ShowModal,
        ActionType.HideModal,
        ])
    // Model.Session.loadCurrentUserAndNotify()
  }

  componentDidMount() {
    log("componentDidMount")
    // setTimeout(() => {
    //   log("Set timeout",this);
    //   this.setState({isLoading:false})
    // }, 3000)
  }

  handleAction(action:Action) {
      log("APP ACTION",action.type);
      if (action.type === ActionType.UserLoaded ||
          action.type === ActionType.UserLoggedIn || 
          action.type === ActionType.UserLoggedOut 
          ) {
        this.forceUpdate();
      }

      if (action.type === ActionType.ShowModal) {
        this.setState({modalContent:action.modalContent}, ()=>{this.setState({showModal:true})})
      }
      if (action.type === ActionType.HideModal) {
        this.setState({showModal:false}, ()=>{this.setState({modalContent:undefined})})
      }


      
     log("App Model Updated");
     }

     closeModal() {
      Actions.Controller.trigger({type:ActionType.HideModal})
    }
  renderContent() {
    log("Render Login",Model.Session.state.isLoggedIn ,Model.Session.state.user )
    if (!Model.Session.state.isUserLoaded) {
      return <LoadinigScreen/>
    }
    if (Model.Navigation.first() == "invite") {
      return <InviteScreen />
    }
    console.log("Has user?", Model.Session.state.user )
    if (!Model.Session.state.user) {
      return <LoginScreen />
    }

    // if (!Model.Session.state.currentMembership || 
    //   Model.Session.state.currentMembership.role  !== MembershipRole.Admin
    //   ) { 
    //     return <div className="maintenanceMode">
    //       <h1>Maintenance Mode</h1>
    //       <h2>Please check back later.</h2>
    //     </div>
    //   }

    
    return <LayoutView />


  }
  render() {
    return <div className="app">
            <ToastContainer />
            {this.renderContent()}

            <Modal
          isOpen={(this.state.modalContent)}
          style={modalStyle}
          onRequestClose={this.closeModal}
        >{this.state.modalContent}
        </Modal>

            </div>
  }

}