import React from 'react';
import "./CategoryTitles.scss"

import { TimelineCategory } from '@swivl/great-grey-lib';

interface CategoryTitlesProps { categories:TimelineCategory[] }
export class CategoryTitles extends React.Component<CategoryTitlesProps> {
  render() {    
    const items = this.props.categories.map((item) => <h1 key={item.id}>{item.name}</h1>)
    return <div className="CategoryTitles">
      {items}
    </div>   
  }
}
