import * as React from 'react';


import { CreateLogger, MembershipRole, ProjectPermissionType } from '@swivl/great-grey-lib';
import { Model } from '../../../../Models/Model';
import { ActionsConsumer, Actions, ActionType } from '../../../../Actions/Actions';
import { Fade } from '@swivl/great-grey-components';

import './NavTray.scss';
import NavProjectSelector from './NavProjectSelector/NavProjectSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const log = CreateLogger('NavMenu');

interface Props {
  isShowing:boolean;
  toggleMenu():void 
}

export default class NavTray extends ActionsConsumer<Props> {
    constructor(props:Props) {
      super(props)
      this.dashboardClicked     = this.dashboardClicked.bind(this)
      this.tasksClicked         = this.tasksClicked.bind(this)
      this.jobsClicked          = this.jobsClicked.bind(this)
      this.dataSetsClicked     = this.dataSetsClicked.bind(this)
      this.modelsClicked        = this.modelsClicked.bind(this)
      this.settingsClicked      = this.settingsClicked.bind(this)

      Actions.Controller.subscribeTo(this, [
        ActionType.CurrentMembershipUpdated,
        ActionType.UserLoggedIn
       ] )
    }
    dashboardClicked() {
      this.props.toggleMenu()
    }
    tasksClicked() {
      this.props.toggleMenu()
    }
    jobsClicked() {
      this.props.toggleMenu()
    }
    dataSetsClicked() {
      this.props.toggleMenu()
    }
    modelsClicked() {
      this.props.toggleMenu()
    }
    settingsClicked() {
      this.props.toggleMenu()
    }

    
    renderMenuItems() {

      var dashboard, tasks, jobs, dataSets, models;

      // var isAdmin = false; 
      // var isMember = false; 

      const path = Model.Navigation.first()
      


      // if (Model.Session.state.currentMembership) {
      //   if (Model.Session.state.currentMembership.role && Model.Session.state.currentMembership.role === MembershipRole.Admin) {
      //     isAdmin = true; 
      //     isMember = true; 
      //   } else {
      //     isMember = true;
      //   }
      // }
      if (Model.Session.canAccess(ProjectPermissionType.Dashboard))  {

        dashboard = <a className={(!path || path == "" || path == "dashboard") ? "mainNavButton selected" : "mainNavButton"}  href="/"  onClick={this.dashboardClicked}>
        Dashboard
        <span className="icon"><img src='/img/nav/icon-dashboard@2x.png' alt="Dashboard" /></span>

      </a>
      }
      if (Model.Session.canAccess(ProjectPermissionType.Task))  {

        tasks = <a className={(path === "task") ? "mainNavButton selected" : "mainNavButton"} href="/task" onClick={this.tasksClicked}>
            Tasks
            <span className="icon"><img src='/img/nav/icon-tasks@2x.png' alt="Tasks" /></span>
          </a>;
      }
      if (Model.Session.canAccess(ProjectPermissionType.Job)) {

        jobs = <a className={(path == "job") ? "mainNavButton selected" : "mainNavButton"} href="/job" onClick={this.jobsClicked}>
              Jobs
              <span className="icon"><img src='/img/nav/icon-jobs@2x.png' alt="Jobs" /></span>

            </a>;
          }

      if (Model.Session.canAccess(ProjectPermissionType.DataSet)) {

        dataSets = <a className={(path == "dataset") ? "mainNavButton selected" : "mainNavButton"} href="/dataset" onClick={this.dataSetsClicked}>
                    Data Sets
                    <span className="icon"><img src='/img/nav/icon-data@2x.png' alt="Data Sets" /></span>

                  </a>
            }


      if (Model.Session.canAccess(ProjectPermissionType.Model)) {

        models = <a className={(path == "model") ? "mainNavButton selected" : "mainNavButton"}  href="/model" onClick={this.modelsClicked}>
                    Models
                    <span className="icon"><img src='/img/nav/icon-models@2x.png' alt="Models"/></span>
                  </a>
            }


      return <div className={"mainMenu"}>
              {dashboard}
              {tasks}
              {jobs}
              {dataSets}
              {models}
        </div>

    }
    
 
    

    renderScreen(showing:boolean) {
      return <Fade show={showing}><div className="navMenuScreen" onClick={this.props.toggleMenu} >sup</div></Fade>
    }

    render() {
      const {isShowing} = this.props; 
      const className = (isShowing) ? "NavTray show" : "NavTray"
        
      return <div className={className}>
        <NavProjectSelector isShowing={isShowing} />
        {this.renderMenuItems()}
        <a className="settingsButton" href="/settings" onClick={this.settingsClicked}>
          <FontAwesomeIcon icon={faCog} />

        </a>
      </div>
    } 
}
