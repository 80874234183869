



import {ActionsController} from './Controller/ActionsController'
import {ActionsConsumerClass} from './Consumer/ActionsConsumer'
import { DataSet, Job, MLModel,User, Assignment, GGDictionary, SubTask, OrganisationMembership, ProjectMembership, Project, Instructions, DataPoint } from '@swivl/great-grey-lib';
// Loaded: ActionType.ModelLoaded,
// ItemUpdated : ActionType.ItemUpdated,
// ItemDeleted: ActionType.ItemDeleted,
// ItemCreated: ActionType.ItemCreated

export enum ActionType {

    // Generic - For Base Model
    ItemsLoaded             = "ItemsLoaded",
    ItemFetched             = "ItemFetched",
    ItemUpdated             = "ItemUpdate",
    ItemDeleted             = "ItemDeleted",
    ItemCreated             = "ItemCreated",

    //Data Set
    DataSetsLoaded             = "DataSetsLoaded",
    DataSetFetched             = "DataSetFetched",
    DataSetUpdated             = "DataSetUpdate",
    DataSetDeleted             = "DataSetDeleted",
    DataSetCreated             = "DataSetCreated",

    //Data Set
    DataPointsLoaded             = "DataPointsLoaded",
    DataPointFetched             = "DataPointFetched",
    DataPointUpdated             = "DataPointUpdate",
    DataPointDeleted             = "DataPointDeleted",
    DataPointCreated             = "DataPointCreated",
        
    //Jobs
    JobsLoaded             = "JobsLoaded",
    JobUpdated             = "JobUpdate",
    JobDeleted             = "JobDeleted",
    JobCreated             = "JobCreated",
    JobFetched             = "JobFetched",

    MLModelsLoaded             = "MLModelsLoaded",
    MLModelUpdated             = "MLModelUpdate",
    MLModelDeleted             = "MLModelDeleted",
    MLModelCreated             = "MLModelCreated",
    MLModelDataFetched         = "MLModelDataFetched",

    InstructionsLoaded             = "InstructionsLoaded",
    InstructionsUpdated             = "InstructionsUpdate",
    InstructionsDeleted             = "InstructionsDeleted",
    InstructionsCreated             = "InstructionsCreated",
    InstructionsDataFetched         = "InstructionsDataFetched",
    
    SubTasksLoaded             = "SubTasksLoaded",
    SubTaskUpdated             = "SubTaskUpdate",
    SubTaskDeleted             = "SubTaskDeleted",
    SubTaskCreated             = "SubTaskCreated",


    AssignmentsLoaded             = "AssignmentsLoaded",
    AssignmentUpdated             = "AssignmentUpdate",
    AssignmentDeleted             = "AssignmentDeleted",
    AssignmentCreated             = "AssignmentCreated",

    OrgMembershipsLoaded             = "OrgMembershipsLoaded",
    OrgMembershipFetched             = "OrgMembershipFetched",
    OrgMembershipUpdated             = "OrgMembershipUpdate",
    OrgMembershipDeleted             = "OrgMembershipDeleted",
    OrgMembershipCreated             = "OrgMembershipCreated",

    ProjectUpdated                   = "ProjectUpdated",


    ProjectMembershipsLoaded             = "ProjectMembershipsLoaded",
    ProjectMembershipFetched             = "ProjectMembershipFetched",
    ProjectMembershipUpdated             = "ProjectMembershipUpdate",
    ProjectMembershipDeleted             = "ProjectMembershipDeleted",
    ProjectMembershipCreated             = "ProjectMembershipCreated",


    KeyboardKeyDown                      = "KeyboardKeyDown",
    ShowModal                            = "ShowModal",
    HideModal                            = "HideModal",

    
    NavigationPathUpdated   = "NavigationPathUpdated",
    UserLoaded              = "UserLoaded",
    UserLoggedIn            = "UserLoggedIn",
    UserLoggedOut           = "UserLoggedOut",
    CurrentMembershipUpdated = "CurrentMembershipUpdated",


    ExerciseDoneClicked     = "ExerciseDoneClicked" 

}

/*

BLANK: 


export interface Action  { type:ActionType.; }

*/

    // ItemsLoaded             = "ItemsLoaded",
    // ItemUpdated             = "ItemUpdate",
    // ItemDeleted             = "ItemDeleted",
    // ItemCreated             = "ItemCreated",

//  GENERICS
export interface ItemsLoadedAction           { type:ActionType.ItemsLoaded }
export interface ItemFetchedAction           { type:ActionType.ItemFetched, item:any }
export interface ItemUpdatedAction           { type:ActionType.ItemUpdated, item:any }
export interface ItemDeletedAction           { type:ActionType.ItemDeleted; item:any }
export interface ItemCreatedAction           { type:ActionType.ItemCreated; item:any }
//Data Set
export interface DataSetsLoadedAction           { type:ActionType.DataSetsLoaded }
export interface DataSetFetched                 { type:ActionType.DataSetFetched, item:DataSet }
export interface DataSetUpdatedAction           { type:ActionType.DataSetUpdated, item:DataSet }
export interface DataSetDeletedAction           { type:ActionType.DataSetDeleted; item:DataSet }
export interface DataSetCreatedAction           { type:ActionType.DataSetCreated; item:DataSet }
//Data Point
export interface DataPointsLoadedAction           { type:ActionType.DataPointsLoaded }
export interface DataPointFetched                 { type:ActionType.DataPointFetched, item:DataPoint }
export interface DataPointUpdatedAction           { type:ActionType.DataPointUpdated, item:DataPoint }
export interface DataPointDeletedAction           { type:ActionType.DataPointDeleted; item:DataPoint }
export interface DataPointCreatedAction           { type:ActionType.DataPointCreated; item:DataPoint }
//Jobs
export interface JobsLoadedAction               { type:ActionType.JobsLoaded; }
export interface JobUpdatedAction               { type:ActionType.JobUpdated; item:Job }
export interface JobDeletedAction               { type:ActionType.JobDeleted; item:string }
export interface JobCreatedAction               { type:ActionType.JobCreated; item:Job }
export interface JobFetchedAction               { type:ActionType.JobFetched; item:Job }

//MLModels
export interface MLModelsLoadedAction               { type:ActionType.MLModelsLoaded; }
export interface MLModelUpdatedAction               { type:ActionType.MLModelUpdated; item:MLModel }
export interface MLModelDeletedAction               { type:ActionType.MLModelDeleted; item:string }
export interface MLModelCreatedAction               { type:ActionType.MLModelCreated; item:MLModel }
export interface MLModelDataFetchedAction           { type:ActionType.MLModelDataFetched; item:MLModel }

export interface InstructionsLoadedAction               { type:ActionType.InstructionsLoaded; }
export interface InstructionsUpdatedAction               { type:ActionType.InstructionsUpdated; item:Instructions }
export interface InstructionsDeletedAction               { type:ActionType.InstructionsDeleted; item:string }
export interface InstructionsCreatedAction               { type:ActionType.InstructionsCreated; item:Instructions }
export interface InstructionsDataFetchedAction           { type:ActionType.InstructionsDataFetched; item:Instructions }


export interface SubTasksLoadedAction               { type:ActionType.SubTasksLoaded; }
export interface SubTaskUpdatedAction               { type:ActionType.SubTaskUpdated; item:SubTask }
export interface SubTaskDeletedAction               { type:ActionType.SubTaskDeleted; item:string }
export interface SubTaskCreatedAction               { type:ActionType.SubTaskCreated; item:SubTask }

export interface AssignmentsLoadedAction               { type:ActionType.AssignmentsLoaded; }
export interface AssignmentUpdatedAction               { type:ActionType.AssignmentUpdated; item:Assignment }
export interface AssignmentDeletedAction               { type:ActionType.AssignmentDeleted; item:string }
export interface AssignmentCreatedAction               { type:ActionType.AssignmentCreated; item:Assignment }

export interface OrgMembershipsLoadedAction           { type:ActionType.OrgMembershipsLoaded }
export interface OrgMembershipFetched                 { type:ActionType.OrgMembershipFetched, item:OrganisationMembership }
export interface OrgMembershipUpdatedAction           { type:ActionType.OrgMembershipUpdated, item:OrganisationMembership }
export interface OrgMembershipDeletedAction           { type:ActionType.OrgMembershipDeleted; item:OrganisationMembership }
export interface OrgMembershipCreatedAction           { type:ActionType.OrgMembershipCreated; item:OrganisationMembership }


export interface ProjectUpdatedAction                 { type:ActionType.ProjectUpdated; item:Project }



export interface ProjectMembershipsLoadedAction       { type:ActionType.ProjectMembershipsLoaded }
export interface ProjectMembershipFetched             { type:ActionType.ProjectMembershipFetched, item:ProjectMembership }
export interface ProjectMembershipUpdatedAction       { type:ActionType.ProjectMembershipUpdated, item:ProjectMembership }
export interface ProjectMembershipDeletedAction       { type:ActionType.ProjectMembershipDeleted; item:ProjectMembership }
export interface ProjectMembershipCreatedAction       { type:ActionType.ProjectMembershipCreated; item:ProjectMembership }


export interface KeyboardKeyDownAction          { type:ActionType.KeyboardKeyDown, event:KeyboardEvent }

export interface ShowModalAction              { type:ActionType.ShowModal, modalContent:React.ReactNode|undefined }
export interface HideModalAction              { type:ActionType.HideModal }

export interface NavigationPathUpdatedAction    { type:ActionType.NavigationPathUpdated; path:Array<string>}
export interface UserLoadedAction               { type:ActionType.UserLoaded; user:User }
export interface UserLoggedInAction             { type:ActionType.UserLoggedIn; user:User }
export interface UserLoggedOutAction            { type:ActionType.UserLoggedOut; }
export interface CurrentMembershipUpdatedAction { type:ActionType.CurrentMembershipUpdated; }



export interface SubTasksLoadedAction           { type:ActionType.SubTasksLoaded; }


export interface ExerciseDoneClickedAction      { type:ActionType.ExerciseDoneClicked, originalRowId:string }



export type Action = 
    ItemsLoadedAction   |
    ItemFetchedAction   |
    ItemCreatedAction   |
    ItemUpdatedAction   |
    ItemDeletedAction   |

    DataSetsLoadedAction           |
    DataSetFetched                 |
    DataSetCreatedAction           |
    DataSetUpdatedAction           |      
    DataSetDeletedAction           |     
    
    DataPointsLoadedAction           |
    DataPointFetched                 |
    DataPointCreatedAction           |
    DataPointUpdatedAction           |      
    DataPointDeletedAction           |     

    JobsLoadedAction                |
    JobCreatedAction                |
    JobUpdatedAction                |
    JobDeletedAction                |
    JobFetchedAction                |

    MLModelCreatedAction                |
    MLModelDeletedAction                |
    MLModelUpdatedAction                |
    MLModelsLoadedAction                |
    MLModelDataFetchedAction             |

    InstructionsCreatedAction                |
    InstructionsDeletedAction                |
    InstructionsUpdatedAction                |
    InstructionsLoadedAction                |
    InstructionsDataFetchedAction             |

    SubTaskCreatedAction                |
    SubTaskDeletedAction                |
    SubTaskUpdatedAction                |
    SubTasksLoadedAction                |

    AssignmentCreatedAction                |
    AssignmentDeletedAction                |
    AssignmentUpdatedAction                |
    AssignmentsLoadedAction                |

    OrgMembershipsLoadedAction         |
    OrgMembershipFetched               |
    OrgMembershipUpdatedAction         |
    OrgMembershipDeletedAction         |
    OrgMembershipCreatedAction         |

    ProjectUpdatedAction                | 

    ProjectMembershipsLoadedAction         |
    ProjectMembershipFetched               |
    ProjectMembershipUpdatedAction         |
    ProjectMembershipDeletedAction         |
    ProjectMembershipCreatedAction         |
        
    KeyboardKeyDownAction           |
    
    ShowModalAction               | 
    HideModalAction               | 

    MLModelsLoadedAction            |
    NavigationPathUpdatedAction     |
    UserLoadedAction                |
    UserLoggedInAction              |   
    UserLoggedOutAction             | 
    CurrentMembershipUpdatedAction  |
    SubTasksLoadedAction            |

    ExerciseDoneClickedAction       
    ;

export {ActionsController as Actions}
export {ActionsConsumerClass as ActionsConsumer}
