import { Entity, EntityDefinition } from '@swivl/great-grey-lib';
import React from 'react';
import { SentenceToken } from '../Tokenizer';
import DeleteEntityPopover from './DeleteEntityPopover';
import SelectEntityPopover from './SelectEntityPopover';
import "./TextToken.scss"




// import * as Model from '../../Models/Model';
interface Props {
    token:SentenceToken
    tokenClicked(token:SentenceToken,shiftKeyPressed:boolean);
    colorForEntity:any;
    possibileEntities:EntityDefinition[]
    entitySelected(entity:EntityDefinition)
    selectedEntityForDelete?:Entity 
    deleteEntity(entity:Entity)
    deselect()
 
}
interface State {

}
export default class TextToken extends React.Component<Props, State> {
    constructor(props:Props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    
  }
  
  componentDidMount() {

  }

  onClick(e:React.MouseEvent) {
    if (this.props.token.type !== "entity" && this.props.token.type !== "word") { return; }

    
    this.props.tokenClicked(this.props.token, e.shiftKey)
  }
  

  render() {
    const {token, colorForEntity, selectedEntityForDelete,deselect } = this.props; 
    let entity;
    let style:any = {};
    let className = "TextToken" 
    let text:any = token.text
    if (token.entity && token.type === 'entity') {
        style = {backgroundColor:colorForEntity[token.entity.name].light}

        if (selectedEntityForDelete && selectedEntityForDelete.startChar === token.entity.startChar && selectedEntityForDelete.endChar === token.entity.endChar) {

          entity =   <>
              <DeleteEntityPopover deselect={deselect} entity={token.entity} deleteEntity={this.props.deleteEntity} />
          <div className="entityLabel" style={{color:colorForEntity[token.entity.name].normal}}>{token.entity.name}</div></>

        } else if (token.entity.name === "SPECIAL_SELECTION_TOKEN") {
          entity = <SelectEntityPopover 
                    entitySelected={this.props.entitySelected}
                    colorForEntity={this.props.colorForEntity}
                    possibileEntities={this.props.possibileEntities}
                    deselect={deselect} 
                    />
          style.color = "#fff"
        } else {
            entity =   <div className="entityLabel" style={{color:colorForEntity[token.entity.name].normal}}>{token.entity.name}</div>

        }
    }
     className += " is" + token.type.toUpperCase()

    return <span style={style} onClick={this.onClick} className={className}>{entity}{text}</span>
  }

}
