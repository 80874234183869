import React from 'react';
import './UploadRowPicker.scss';
import { Button, Select,Option } from '@swivl/great-grey-components';
import {ShortId, DataSetRow } from '@swivl/great-grey-lib';
import {  Model } from '../../../../../Models/Model';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../../../Actions/Actions';
import { audioDurration } from '../../../../../TaskViews/Shared/AudioHelper';

const NUMBER_OF_ROWS_TO_SHOW = 5;
const REFRESH_BATCH_SIZE  =  30; // How many items should we process before moving on to the next draw
export enum RowMappingType {
  Text = "text",
  Intent = "intent",
  AudioURL = "audioURL",
  ImageURL = "imageURL",

  Include = "include",
  Exclude = "exclude",
}


interface UploadRowPickerProps {
  rawRows:Array<Array<any>>
  back():void;
  gotRowsAndColumnNames(rows:Array<DataSetRow>);
}

interface UploadRowPickerState {
  skipHeaderRow:boolean;
  currentIndex:number;
  displayRows:Array<Array<any>>;
  firstRow:Array<any>;
  mapping:Array<Option>;
  isLoading:boolean;

  processingFile:boolean; 

  options:Option[];
}
const possibleOptions:Array<Option> = [
  { value: RowMappingType.Text, label: 'Text' },
  { value: RowMappingType.Include,   label: 'Include'   },
  { value: RowMappingType.Exclude,   label: 'Exclude'   },
  { value: RowMappingType.AudioURL, label: 'Audio URL' },
  { value: RowMappingType.ImageURL, label: 'Image URL' },
  { value: RowMappingType.Intent, label: 'Intent' },

];




export  class UploadRowPicker extends ActionsConsumer<UploadRowPickerProps,UploadRowPickerState> {
  
  constructor(props:any) {
    super(props)
    let displayRows:Array<Array<any>> = [];
    for (let i = 1; i < props.rawRows.length; i++) {
      if (i > NUMBER_OF_ROWS_TO_SHOW) { break; }
        displayRows.push(props.rawRows[i]);
    }

    let foundText = false;
    let foundAudioURL = false; 
    let foundImageURL = false; 
    let foundIntent = false; 

    const firstRow = props.rawRows[0];
    let mapping = [];
    for (let i = 0; i < firstRow.length; i++) {
      if (!foundText &&
          typeof firstRow[i] === "string"  &&
          (firstRow[i].toLowerCase() === "utterance" ||
           firstRow[i].toLowerCase() === "text" )
         ) {
           mapping.push({ value: RowMappingType.Text, label: 'Text' });
           foundText = true;
         } else  if (!foundAudioURL &&
          typeof firstRow[i] === "string"  &&
          (firstRow[i].toLowerCase() === "audiourl" || 
           firstRow[i].toLowerCase() === "audio" || 
           firstRow[i].toLowerCase() === "file" || 
           firstRow[i].toLowerCase() === "url" || 
           firstRow[i].toLowerCase() === "fileurl" || 
           firstRow[i].toLowerCase() === "media"
           )) {

            mapping.push({ value: RowMappingType.AudioURL, label: 'Audio URL' });
            foundAudioURL = true;
         } else if (!foundImageURL &&
          typeof firstRow[i] === "string"  &&
          (firstRow[i].toLowerCase() === "imageurl" || 
           firstRow[i].toLowerCase() === "image"
           )) {

            mapping.push({ value: RowMappingType.ImageURL, label: 'Image URL' });
            foundImageURL = true;
         }  else if (!foundIntent &&
          typeof firstRow[i] === "string"  &&
          (firstRow[i].toLowerCase() === "intent" ||
           firstRow[i].toLowerCase() === "topScoringIntent" )
         ) {
           mapping.push({ value: RowMappingType.Intent, label: 'Intent' });
           foundIntent = true;
         } else {
           mapping.push({ value: RowMappingType.Include, label: 'Include', data:firstRow[i] });
         }

         
    }
    console.log("ORIGINAL MAPPING", mapping);

    // let options = []



    this.state = {skipHeaderRow:true,
                  currentIndex:1,
                  displayRows:displayRows,
                  firstRow:firstRow,
                  mapping:mapping,
                  isLoading:false,
                  processingFile:false,
                  options:possibleOptions 
                }
    this.hasValidData = this.hasValidData.bind(this)
    this.onDropdownChange = this.onDropdownChange.bind(this)
    this.renderRow        = this.renderRow.bind(this)
    this.nextClicked      = this.nextClicked.bind(this);

  }

  

  componentDidMount() {
    Actions.Controller.subscribeTo(this, [ActionType.KeyboardKeyDown])
  }

  hasValidData():boolean {
    let hasValidData = false;

    for (let i = 0; i < this.state.mapping.length; i++) {
      if (this.state.mapping[i].value === RowMappingType.Text || 
        this.state.mapping[i].value === RowMappingType.AudioURL || 
        this.state.mapping[i].value === RowMappingType.ImageURL || 

      (  this.state.mapping[i].value === RowMappingType.Text && 
        this.state.mapping[i].value === RowMappingType.Intent)


          ) {
          hasValidData = true;
          break;
      }
 
      
    }
    return hasValidData;
  }

  handleAction(action:Action) {

    if (action.type === ActionType.KeyboardKeyDown) {
          if (action.event.key === "Enter") {
            this.nextClicked()
        }
      }
  }

  onDropdownChange(index:number, value:Option, columnName) {
    let mapping = this.state.mapping;
    console.log("On Dropdown Chhange",index, value,columnName,mapping);

    // if (value.value === RowMappingType.Text) {
      for (let i = 0; i < mapping.length; i++) {

        // HEre we check to make sure two things don't have the same value unless that value is include or exclude
          if (mapping[i].value === value.value && value.value !== RowMappingType.Exclude && value.value !== RowMappingType.Include) {
            mapping[i] = { value: RowMappingType.Exclude, label: 'Exclude' };
          }
      }
    // }
    if (value.value === RowMappingType.Include) {
      value.data = columnName;
    } else {
      value.data = null;
    }
    mapping[index] = value;
    this.setState({mapping:mapping});

  }

  renderRow(rowNumber:number, data:Array<any>, isHeader:boolean) {

    let columns = [];
    for (let i = 0; i < this.state.firstRow.length; i++) {
        let content = (data[i]) ? data[i]  : ""
        // let className = (this.state.textRow && this.state.textRow === i) ? "textRow" ;
        if (isHeader) {
          console.log("this.state.mapping[i]",this.state.mapping[i]);

          columns.push(<th key={"headerRow_" + i}>
          {content}
          <Select
            options={this.state.options}
            value={this.state.mapping[i]}
            onChange={(selectedOption) => { this.onDropdownChange(i, selectedOption, content); }}
          />

          </th>);
        }  else {
          columns.push(<td key={"row_" + rowNumber + "_col" +i}>{content}</td>);
        }
    }


    return <tr key={"row_" + rowNumber}>
            {columns}
            </tr>
  }

  nextClicked() {
    if (!this.hasValidData()) { return; }
    this.setState({processingFile:true})
    setTimeout(()=> {
      this.processFile()
    },300);
  }

  async processFile() {
    let mapping = this.state.mapping;
    console.log("processFile",mapping)

    const rawRows = this.props.rawRows;


    /* now we itterate through the other rows and nab them too. */
    var dataPoints = []
    var refreshCounter = 0;
    let hasAudioURL = false; 
    let hasImageURL = false; 

    for (let i = 1; i < rawRows.length; i++) {
        const element = document.getElementById("uploadProgressBar");
        if (element && refreshCounter > REFRESH_BATCH_SIZE) {
          console.log("setting value",i/rawRows.length )
          element.setAttribute("value", String(i/rawRows.length * 100));
          
          const frameTick = await this.awaitNextFrame()
          console.log("frame tick", frameTick);
          refreshCounter = 0;
        }
        refreshCounter++;

        const originalArray = rawRows[i];
        console.log("MAPPING START", mapping);

        var dp:Partial<DataSetRow> = {meta:{}}
        for (let m = 0; m < mapping.length; m++) {
          const original = originalArray[m];
          const map = mapping[m];
          console.log("MAP!", map)



          if (original && map.value === RowMappingType.Text) {
            dp["text"] = original;
          }
          if (original && map.value === RowMappingType.Intent) {
            dp["intent"] = {name:original};
          }
          if (original && map.value === RowMappingType.AudioURL) {
            hasAudioURL =  true; 
            dp["audioURL"] = original;
          }
          if (original && map.value === RowMappingType.ImageURL) {
            hasImageURL =  true; 
            dp["imageURL"] = original;
          }


          // console.log("mapping[m].value",mapping[m].value)
          if (map.value === RowMappingType.Include) {

            console.log("MAPPING", originalArray, m, i, map, original);
            
            let metaValue = (original) ? original : "";
            dp.meta[map.data] = metaValue;
          }
          console.log("og", m, original, map, dp)


        }
        if (dp.text || dp.audioURL || dp.imageURL) { // Only push it if it actually has data.
          dp.id = ShortId();
          dataPoints.push(dp)
        }
    }

    if (hasAudioURL) {
      this.processAudioRows(dataPoints);
      return;
    } 


    this.props.gotRowsAndColumnNames(dataPoints)
    setTimeout(() => {
      this.setState({processingFile:false})
    }, 500);
  }

  async processAudioRows(rows:Array<DataSetRow>) {
    for (let i = 0; i < rows.length; i++) {
     let duration =  await audioDurration(rows[i].audioURL, rows[i].audioStartTime, rows[i].audioEndTime)
      rows[i].duration = duration; 
      const element = document.getElementById("uploadProgressBar");
      if (element) {
        element.setAttribute("value", String(i/rows.length * 100));
      }
      console.log("duration", duration);
      
      
    }
    this.props.gotRowsAndColumnNames(rows)

  }

  // async processAudioRow(row:DataSetRow) {
  //     return audioDurration(row.audioURL, row.audioStartTime, row.audioEndTime).then((duration) => {

  //     })
  // }
  


  async awaitNextFrame() { // This function artificially slows down processing so we can see it happen.
    return new Promise((resolve, reject) => {
      window.requestAnimationFrame(resolve)
    })
  }

  renderProcessing() {

    return <div className="ProgressBlock">
            
            <progress id="uploadProgressBar" className="progress is-primary" value="0" max="100">50%</progress>
            <h4>Processing File</h4>


    </div>;
  }
  renderTable() {
    let rows = [];
    for (let i = 0; i < this.state.displayRows.length; i++) {
        rows.push(this.renderRow(i, this.state.displayRows[i], false));
    }


    return <div className="tableWrapper">
    <table>
      <thead>
        {this.renderRow(0, this.state.firstRow, true)}
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
    </div>;

  }



  render() {

    let hasValidData = this.hasValidData();

    let content, buttons; 

    if (this.state.processingFile) {
      content = this.renderProcessing()
    } else {
      content = this.renderTable()
      buttons =  <div className="buttons">
      <Button isRound={true} isOutlined={true} onClick={this.props.back}>Back</Button>
      <Button isLoading={this.state.isLoading} onClick={this.nextClicked} isRound={true} isDisabled={!hasValidData}>Next</Button>
    </div>;
    }

    return <div className="UploadRowPicker">
              <div className="modalHeader">
                <h3>Select the columns to include</h3>
                You must select your utterance column. Other columns can be included for continuity.
              </div>
              <div className="modalContent">
                {content}


          
            </div>
            <div className="modalFooter">
              {buttons}
            </div>

    </div>;



  }

}
