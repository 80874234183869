import React from 'react';
import { Button, Size } from '@swivl/great-grey-components';
import { CreateLogger,TaskType,Assignment,WorkRowStatus,AssignmentWork,AssignmentWorkRow, DataSetRow } from '@swivl/great-grey-lib';


import "./ExerciseNav.scss";
import { Model } from '../../../../../Models/Model';
import { TaskViews } from '../../../../../TaskViews/TaskViews';
import { ActionType, Actions } from '../../../../../Actions/Actions';
import { isAssignmentDone } from '../../Helpers/AssignmentHelper';
const log = CreateLogger("Exercise:Main");

 


export interface ExerciseNavProps {
    type:TaskType
    assignment:Assignment;
    prevWork?:AssignmentWorkRow;
    work?:AssignmentWorkRow;
    nextWork?:AssignmentWorkRow;

    prevClicked()
    skipClicked()
    finishClicked()

    //For Demoing
    
    centerTitle?:any; 
    showSkipAndNext?:boolean; 

    
}

export class ExerciseNav extends React.Component<ExerciseNavProps> {
  constructor(props:ExerciseNavProps) {
    super(props) 
    this.doneClicked = this.doneClicked.bind(this )
  }
  doneClicked() {
    Actions.Controller.trigger( { type:ActionType.ExerciseDoneClicked, originalRowId:this.props.work.originalRowId })
  }
  
  renderLeft() {
      if (this.props.prevWork) {
        return <div className="ExerciseNavButton" onClick={this.props.prevClicked}>PREV</div>
      } else { return null; }
  }
  renderCenter() {

    if (this.props.centerTitle) {
      return this.props.centerTitle; 

    }
    if (this.props.work) {
      const text = this.parseStringForVariables(TaskViews.ExerciseTitle(this.props.assignment))
      return <div><h2>{text}</h2></div>
    }
  }





  parseStringForVariables(str) {
    console.log("Parse String For Variables", str);
    if(str.indexOf('#*') === -1) { return str; }
    console.log("Parse String For Variables 2", str);

    var loopIndex = 0;
    while(str.indexOf('*#') !== -1 && loopIndex < 100) {
  
      log("*!* parseStringForVariables Variable Loop Index", loopIndex);
      loopIndex += 1;
      if (loopIndex >= 99) { console.warn('out of bounds variable loop'); }
      var indexStart = str.indexOf('#*');
      var indexEnd = str.indexOf('*#');
      if (indexEnd < indexStart) {
        console.log("improper setup for variables");
        str = str.replace("*#", "");
        str = str.replace("#*", "");
      } else {
        // const variableIdWidthTags = str.substring(indexStart, indexEnd + 2);
        const variableId = str.substring(indexStart, indexEnd + 2);
        // log.info("variableId", variableId);
  
        
  
        var replacement = this.replaceVariable(variableId);
        str = str.replace(variableId, replacement);
      }
    }
    return str;
  }
  replaceVariable(variableId) {

    if (variableId === "#*categoryId*#") {
      const categoryKey = (this.props.assignment.task as any ).categoryId; 
      const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
      if (categoryKey && originalRow.categories && originalRow.categories[categoryKey]) {
        return originalRow.categories[categoryKey].name;
      }
    }
    return "UNKNOWN"
  }





  renderRight() {
    if (this.props.assignment.type === TaskType.Sentiment) {
      if (isAssignmentDone(this.props.assignment)) {
        return <div className="ExerciseNavButton" onClick={this.props.finishClicked}>FINISH</div>
      } else {
        return null;
      }
    }

    if (this.props.assignment.type === TaskType.ImageBoundingBox) {
      if (isAssignmentDone(this.props.assignment)) {
        return <div className="ExerciseNavButton" onClick={this.props.finishClicked}>FINISH</div>
      } else {
        const nextButton = (this.props.work.tempData) ?  <div className="ExerciseNavButton" onClick={this.doneClicked}>NEXT</div> : <div className="ExerciseNavButton isDisabled" >NEXT</div>
        return <div className="skipAndDone">
        <div className="ExerciseNavButton hideBg" onClick={this.props.skipClicked}>SKIP</div>
        {nextButton}
    </div>;
      }
    }

    if (this.props.showSkipAndNext) {
      return <div className="skipAndDone">
                <div className="ExerciseNavButton hideBg" onClick={this.props.skipClicked}>SKIP</div>
                <div className="ExerciseNavButton" onClick={this.doneClicked}>NEXT</div>
            </div>
    }

    if (this.props.work) {
      
      if (this.props.assignment.type === TaskType.Intent || this.props.assignment.type === TaskType.ImageCategorize) {
        return <div className="ExerciseNavButton" onClick={this.props.skipClicked}>SKIP</div>
      }

        return <div className="skipAndDone">
                  <div className="ExerciseNavButton hideBg" onClick={this.props.skipClicked}>SKIP</div>
                  <div className="ExerciseNavButton" onClick={this.doneClicked}>DONE</div>
          </div>

    
    // }
    //  else {
    //     return <div className="ExerciseNavButton" onClick={this.props.finishClicked}>FINISH</div>
     }

}
  
  render() {

    return <div className="ExerciseNav">
                    <div className="col leftCol">{this.renderLeft()}</div>
                    <div className="col centerCol">{this.renderCenter()}</div>
                    <div className="col  rightCol">{this.renderRight()}</div>
           </div>;   
  }

}
