import React from 'react';
import { CreateLogger, TimelineCategory, TimelineSegment } from '@swivl/great-grey-lib';

import "./TimelineSegments.scss"


import { MouseHandler } from '../TimelineView/MouseHandler/MouseHandler';
import { TimelineSegmentView } from './TimelineSegment/TimelineSegment';
import _ from "lodash" // Import the entire lodash library

const log = CreateLogger("AudioSplitExersiseView:TimelineSegment");



interface TimelineSegmentsProps {
  categories:TimelineCategory[];
  isPlaying:boolean;
  playingId?:string;
  playAtTime(time:number, duration?:number, playingId?:string);

  adjustStartTime(categoryIndex:number,segmentIndex:number, startTime:number)
  adjustEndTime(categoryIndex:number,segmentIndex:number, endTime:number)

  adjustPosition(categoryIndex:number,segmentIndex:number, slideOriginalSegments:TimelineSegment[],  delta:number)

  pause();
  deleteSegment(trackIndex:number, segmentIndex:number)
  mouseHandlerRef?:React.RefObject<MouseHandler>
}
interface TimelineSegmentsState {
  selectedSegment?:string 

  leftDragSegment?:PositionAndSegmentInterface 
  rightDragSegment?:PositionAndSegmentInterface 
  slidingSegment?:PositionAndSegmentInterface 
  slideOriginalSegments?:TimelineSegment[]
  slideStartingX?:number
}

export interface PositionAndSegmentInterface extends TimelineSegment {
  categoryIndex:number, 
  segmentIndex:number 
}

export class TimelineSegmentsView extends React.Component<TimelineSegmentsProps,TimelineSegmentsState> {
  // slideOriginalSegments?:TimelineSegments[]

  constructor(props:any) {
    super(props) 
    this.state =  {}
    this.segmentSelected  = this.segmentSelected.bind(this)
    // this.getSegment       = this.getSegment.bind(this)
    this.isDragging       = this.isDragging.bind(this)
    this.startDragLeft    = this.startDragLeft.bind(this)
    this.startDragRight   = this.startDragRight.bind(this)
    this.startSlide       = this.startSlide.bind(this)
    this.mouseUp          = this.mouseUp.bind(this)
    this.mouseMove        = this.mouseMove.bind(this)
}

  // getSegment(item:PositionAndSegmentInterface):PositionAndSegmentInterface | undefined {
  //   return this.props.data.categories[item.categoryIndex].segments[item.segmentIndex];

    
  // }

  isDragging() { return (this.state.leftDragSegment || this.state.rightDragSegment|| this.state.slidingSegment  ) ? true : false; }
  segmentSelected(selectedSegment?:string ) { this.setState({selectedSegment:selectedSegment}); }


  startDragLeft(item:PositionAndSegmentInterface)  { this.setState({leftDragSegment:item, rightDragSegment:null, slidingSegment:null})}
  startDragRight(item:PositionAndSegmentInterface) { this.setState({leftDragSegment:null, rightDragSegment:item, slidingSegment:null}) }


  startSlide(item:PositionAndSegmentInterface, e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!this.props.mouseHandlerRef || !this.props.mouseHandlerRef.current) { return }
    let slideStarting = this.props.mouseHandlerRef.current.posForMouseEvent(e)
    if (!slideStarting) { log.warn("CANT SLIDE"); return; }
    const slideOriginalSegments:TimelineSegment[] =  _.cloneDeep(this.props.categories[item.categoryIndex].segments) //  _.cloneDeep(this.props.data.categories[item.categoryIndex].segments);
    const slidingSegment = Object.assign(item, {})
    this.setState({leftDragSegment:null, rightDragSegment:null, slidingSegment:slidingSegment,  slideOriginalSegments:slideOriginalSegments, slideStartingX:slideStarting.x})
  }
    
  mouseUp(x:number) {
    log("mouseUp",x)
    this.setState({leftDragSegment:null, rightDragSegment:null, slidingSegment:null});
  }

  mouseMove(x:number) {
    if (!this.isDragging()) { return; }

    if (this.state.leftDragSegment) {
      this.props.adjustStartTime(this.state.leftDragSegment.categoryIndex, this.state.leftDragSegment.segmentIndex, x*0.05);
    }
    if (this.state.rightDragSegment) {
      this.props.adjustEndTime(this.state.rightDragSegment.categoryIndex, this.state.rightDragSegment.segmentIndex, x*0.05);
    }
    if (this.state.slidingSegment) {
      const delta = (x - this.state.slideStartingX) * 0.05; 
      this.props.adjustPosition(
        this.state.slidingSegment.categoryIndex,
        this.state.slidingSegment.segmentIndex,
        _.cloneDeep(this.state.slideOriginalSegments),
        delta); 
    }
    
  }




  render(){
    if (this.state.slidingSegment) {
      log("*** MY RENDER", this.state.slideOriginalSegments[this.state.slidingSegment.segmentIndex]);
    }
    // const data = this.props.data; 
    const slidingSegmentId = (this.state.slidingSegment) ? this.state.slidingSegment.id : null;
    const rows = this.props.categories.map((category, index) => {
      const row = category.segments.map((segment, segmentIndex) => { 
        return <TimelineSegmentView 
                key={segment.id }
                item={{ id:segment.id, 
                        categoryIndex:index, 
                        segmentIndex:segmentIndex, 
                        startTime:segment.startTime, 
                        endTime:segment.endTime}}
                
                slidingSegmentId={slidingSegmentId}
                
                segmentSelected={this.segmentSelected}
                selectedSegmentId={this.state.selectedSegment}
                isPlaying={this.props.isPlaying}
                playingId={this.props.playingId}
                playAtTime={this.props.playAtTime}
                pause={this.props.pause}

                startDragLeft={this.startDragLeft}
                startDragRight={this.startDragRight}
                startSlide={this.startSlide}
                deleteSegment={this.props.deleteSegment}
                />
      })
      return <div key={"row"+index} style={{top: (index * 54) + "px"}}className="timelineSegmentsRow">{row}</div>

    })
    return <div className="TimelineSegments">{rows}</div>
  }

}