import { Assignment, GGDictionary, CreateLogger, AssignmentStatus} from '@swivl/great-grey-lib';
import { ActionType, Actions, ItemsLoadedAction } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import { Model } from '../Model';
import Service from '../../Services/Service';

const log = CreateLogger("AssignmentModel")

export interface AssignmentModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<Assignment>
}> {}

export class AssignmentModel extends BaseModel<Assignment,AssignmentModelState>  {
  static get Model():AssignmentModel {   if (AssignmentModel._Model) { return AssignmentModel._Model; }  else {   AssignmentModel._Model = new AssignmentModel(); return AssignmentModel._Model ; } }

  
  private static _Model:AssignmentModel;
  // private constructor() { super();  this.state = {...this.state, dataFiles:{}} }

  state:AssignmentModelState = {
    isLoading:false,
    items:undefined
  }

  ENTITY_NAME = "assignment";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.AssignmentsLoaded,
    ItemUpdated : ActionType.AssignmentUpdated,
    ItemDeleted : ActionType.AssignmentDeleted,
    ItemCreated : ActionType.AssignmentCreated
  } 

  inProgressAssignments():Array<Assignment> {
    let output = [];
    for (const id in this.state.items) {
      if (this.state.items.hasOwnProperty(id)) {
        const element = this.state.items[id];
        if (element.status === AssignmentStatus.In_Progress) {
          output.push(element)
        }
      }
    }
    return output;
  }
 
  startTask(assignment:Assignment):Promise<Assignment> {
   return  Service.API.startItem<Assignment>(this.ENTITY_NAME, assignment).then((assignment) => {
    log("Wiched",assignment);
    return Promise.resolve(assignment)
   })
  }

  finishTask(assignment:Assignment):Promise<Assignment> {
    
    return  Service.API.finishItem<Assignment>(this.ENTITY_NAME, assignment).then((assignment) => {
     log("Finished",assignment);
     var state = this.state;
     state.items[assignment.id] =  assignment; 
     this.state = state; 
     return Promise.resolve(assignment)
    })
   }

}
