import React from 'react';
import  "./ExerciseCard.scss";
import { ActionsConsumer, Actions,ActionType,Action } from '../../../../Actions/Actions';
import { CreateLogger, Assignment, AssignmentWorkRow, TaskType } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'



const log = CreateLogger("Exercise:Card");

export interface ExerciseCardProps {
  id:string;
  work:AssignmentWorkRow,
  assignment:Assignment;
  selectedCard?:string;
  alwaysRenderChildren?:boolean;
  canGotoPrev:boolean;
  gotoPrev():void;
  update(work:AssignmentWorkRow);
  save(work:AssignmentWorkRow);
  canSkip:boolean;
  skip():void;
  unchanged(work:AssignmentWorkRow):void;
}
export interface ExerciseCardState {
  isShowing:boolean;
  data?:any; 
}


export class ExerciseCard<T,S> extends ActionsConsumer<ExerciseCardProps,ExerciseCardState> {
  constructor(props:ExerciseCardProps) {
    super(props)
    this.state = {isShowing:false}
    Actions.Controller.subscribeTo(this, [ActionType.KeyboardKeyDown, ActionType.ExerciseDoneClicked])
    this.doneClicked = this.doneClicked.bind(this);
  }

  handleAction(action:Action) {
    if (action.type === ActionType.KeyboardKeyDown && 
      this.props.id === this.props.selectedCard) {
      log("Handle Key Down", this.state.isShowing, this.props.id)
        setTimeout(()=> {
          this.handleKeyDown(action.event)
        },50);
    }
    if (action.type === ActionType.ExerciseDoneClicked) {
      if (action.originalRowId === this.props.work.originalRowId) {
        this.doneClicked()
      }
    } 

  }
  doneClicked() {
    log("doneClicked",  this.props.work.originalRowId)
  }
  handleKeyDown(event:KeyboardEvent) {
    log("Handle key down event", event,this.props.id);
  }

  
  componentDidMount()  { this.checkIfShowing(); }
  componentDidUpdate() { this.checkIfShowing(); }

  checkIfShowing() {
    if (this.props.selectedCard &&   this.props.id === this.props.selectedCard &&  !this.state.isShowing ) {
          this.setState({isShowing:true});


      } else if (this.props.id !== this.props.selectedCard &&
              this.state.isShowing
            ) {
              this.setState({isShowing:false})
            }
  }

  renderUpdownKey(title?:string) {
    return  <div className="ExerciseKeyItem">
              <div className="icon">
                <FontAwesomeIcon icon={faSort} />
              </div>
              <div className="text">{(title)?title:"Move selection"}</div>
              </div>;
  }
  
  renderEnterKey(title?:string) {
    return  <div className="ExerciseKeyItem">
              <div className="icon">
                <div className="med">⏎</div>
                <small>Enter</small>
              </div>
              <div className="text">{(title)?title:"Accept / Next"}</div>
              </div>;
  }
  renderAnyKey(key?:string ,title?:string) {
    if (!key ) { return; }
    return  <div className="ExerciseKeyItem">
              <div className="icon">
                <div className="key">{key}</div>
              </div>
              <div className="text">{(title)?title:" Next"}</div>
              </div>;
  }

  renderKey(show?:boolean ,upDownText?:string, enterText?:string, anyKey?:string, anyKeyText?:string) {
    if (!show && this.props.assignment.type !== TaskType.Intent) { return }
    return <div className="ExerciseKey">
            {this.renderUpdownKey(upDownText)}
            {this.renderEnterKey(enterText)}
            {this.renderAnyKey(anyKey,anyKeyText)}
          </div>
  }

  renderContent() {
      return <div>I am an exercise card. {this.props.id}</div>
  }

  render() {
    const content = (this.state.isShowing || this.props.alwaysRenderChildren) ? this.renderContent() : null;
    return <div className={(this.state.isShowing) ? "ExerciseCard isShowing" : "ExerciseCard"}>
            {content}
            {this.renderKey()}
          </div>;
  }

}
