import { Job,isJob, GGDictionary, JobStatus, DataSet, MLModel, MLModelData, CreateLogger, ProjectMembership, User} from '@swivl/great-grey-lib';
import { ActionType, Actions, ItemUpdatedAction } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import { Model } from '../Model';
import Service from '../../Services/Service';
import { Toast } from '../../Services/ToastService';

const log = CreateLogger("ProjectMembershipModel")

export interface ProjectMembershipModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<ProjectMembership>
}> {}

export class ProjectMembershipModel extends BaseModel<ProjectMembership,ProjectMembershipModelState>  {
  static get Model():ProjectMembershipModel {   if (ProjectMembershipModel._Model) { return ProjectMembershipModel._Model; }  else {   ProjectMembershipModel._Model = new ProjectMembershipModel(); return ProjectMembershipModel._Model ; } }
  static reset()  { delete ProjectMembershipModel._Model; }
  private static _Model:ProjectMembershipModel;
  private constructor() { super();   }
  ENTITY_NAME = "project_membership";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.ProjectMembershipsLoaded,
    ItemFetched : ActionType.ProjectMembershipFetched,
    ItemUpdated : ActionType.ProjectMembershipUpdated,
    ItemDeleted : ActionType.ProjectMembershipDeleted,
    ItemCreated : ActionType.ProjectMembershipCreated
  } 

 

  addMemberToProject(user:User, projectId:String) {

    let membership:any = {
      userId:user.id,
      projectId:projectId
    } 
    console.log(membership);
    
    this.create(membership)
  }
  

  
}
