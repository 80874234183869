import React, { ChangeEvent } from 'react';
import { DragableList, Input, InputType, Pill, PillSwitch, Select, Option } from '@swivl/great-grey-components';

import { Job, CreateLogger, Intent, TaskType, TaskData_Intent,MakeDictonaryArrayUnique, TaskData_ImageCategorize, TaskCategory, MLModel } from '@swivl/great-grey-lib';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ActionsConsumer, Actions, ActionType } from '../../Actions/Actions';
import { Model } from '../../Models/Model';
import "./SelectInstuctionsView.scss"
const log = CreateLogger("job:JobTaskIntent");
const logOfLoading = CreateLogger("LoadingLog")

interface Props { 
    taskType:TaskType 
    job:Job }
interface State {
  categories:any[]
  
}




export  class SelectInstuctionsView extends ActionsConsumer<Props,State> {
  constructor(props:Props) {
    super(props)
    this.state = { categories:[] }
    // this.toggleIntent = this.toggleIntent.bind(this)
    // this.createIntentsArray = this.createIntentsArray.bind(this)
    Actions.Controller.subscribeTo(this, [
      ActionType.InstructionsLoaded
    ])
  }
  componentDidMount() {
    Model.Instructions.load(false)
  }

  instructionsOnChange = (selectedOption:Option) => {
    const {job,taskType} = this.props; 
  
    job.tasks[taskType].instructionsId = selectedOption.value; 
    Model.Job.update(job,true);
  }

  renderContent() {
    if (!Model.Instructions.state || !Model.Instructions.state.items) {
      return <FontAwesomeIcon icon={faSpinner} spin />
    } else {
      const options = Model.Instructions.array.map((item) =>  {return { label:item.name, value:item.id  }})
      return <Select isSearchable={true} options={options} value={this.props.job.tasks[this.props.taskType].instructionsId} onChange={this.instructionsOnChange} />
    }
  }
  render() {
      return <div className="SelectInstuctionsView"><label>Task Instructions (optional)</label>{this.renderContent()}</div>
  }



}
