import React from 'react';

import { Checkbox,ButtonWithOptions, Button, Color } from '@swivl/great-grey-components';
import './DataSetDetailsView.scss';
import { DataSet, User,HoursMinutesSeconds, DataSetCreationMethod,Capitalize,NumberWithCommas, CreateLogger, DataSetRow, ColmunTypesForRows, DataSetColumnType, CategoryData } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';
import { Config } from '../../../Models/Models/Config/Config';
import Service from '../../../Services/Service';
import { ActionsConsumer, ActionType, Actions, Action } from '../../../Actions/Actions';
import CreateJobFromDataSetModal from './components/CreateJobFromDataSetModal/CreateJobFromDataSetModal';
import { DataTable } from '../../../Components/DataTable/DataTable';

const log = CreateLogger("DataSetDetailsView")

const moment = require('moment');
var pluralize = require('pluralize')

interface DataSetDetailsViewProps {
  dataSet:DataSet;
}
interface DataSetDetailsViewState {
  selectedRows:Array<string>
  isDeleting:boolean; 
  isCreatingJob:boolean;
}

export  class DataSetDetailsView extends ActionsConsumer<DataSetDetailsViewProps,DataSetDetailsViewState> {
  constructor(props:any) {
    super(props)
    this.state = {selectedRows:[], isCreatingJob:false, isDeleting:false };
    this.fetchData  = this.fetchData.bind(this);
    this.onProgress = this.onProgress.bind(this);

    this.checkboxClicked   = this.checkboxClicked.bind(this)

    this.deleteRows        = this.deleteRows.bind(this)

    this.deleteClicked     = this.deleteClicked.bind(this)
    this.createJobClicked  = this.createJobClicked.bind(this)
    this.renderHeader      = this.renderHeader.bind(this)
    this.renderTable       = this.renderTable.bind(this)
  }
  componentDidMount() { 
    Actions.Controller.subscribeTo(this, [
      ActionType.DataSetFetched
      ] )
      
    this.fetchData();
   }
   handleAction(action:Action) {
    console.log("HANDLE ACTION", action)   
    this.forceUpdate();
  
  }

  componentWillUnmount() {

  }
  fetchData() {
    
    Model.DataSet.fetch(this.props.dataSet)
  }
  onProgress(progress:number) {
    console.log("progress", progress);
    const element = document.getElementById("dataSetProgress");
    if (element) {
     element.setAttribute("value", String(progress));
    }
  }
  onError(error:Error) {
    console.warn(error);
  }

  checkboxClicked(name:string, checked:boolean) {

    if (name === "SelectAllCheckbox") {
      if (checked) {
        const rowData:Array<DataSetRow> = this.props.dataSet.rows;
        this.setState({selectedRows:rowData.map(row=>row.id)});
      } else {
        this.setState({selectedRows:[]});
      }
      return;
    }

    let selectedRows = this.state.selectedRows;

    if (checked) { 
      selectedRows.push(name)
     } else {
       for (let index = 0; index < selectedRows.length; index++) {
         if (selectedRows[index] === name) {
          selectedRows.splice(index, 1);
          break;
         }
         
       }
     }
     this.setState({selectedRows:selectedRows});
     log("selectedRows", selectedRows, name, checked)
  }
  deleteClicked() {
    if (window.confirm("Are you sure you want to delete this data set? The action cannot be undone.")) {
      Model.DataSet.delete(this.props.dataSet);
      Model.Navigation.setPath("/dataset")
    }
  }
  
  createJobClicked() {
    Actions.Controller.trigger({type:ActionType.ShowModal, modalContent:<CreateJobFromDataSetModal create={this.createNewJob} />})

  }
  createNewJob = () => {
    this.setState({isCreatingJob:true})
    Model.Job.createNewJob(this.props.dataSet).then((_)=>{ 
    })
  }
  
  deleteRows() {
    if (window.confirm(`Are you sure you want to delete ${pluralize("row",this.state.selectedRows.length, true )}?`)) {


    this.setState({isDeleting:true,selectedRows:[]}) 

    const { selectedRows} = this.state 
    let dataSet = this.props.dataSet;
    let rows = dataSet.rows; 
    let newRows = [];
    for (let index = 0; index < rows.length; index++) {
      if (!selectedRows.includes(rows[index].id)) {
        newRows.push(rows[index]);
      }
    }
    dataSet.rows =  newRows;
    dataSet.rowCount = newRows.length;
    dataSet.columnTypes = ColmunTypesForRows(newRows)
    Model.DataSet.update(dataSet,true).then((_) => {
            this.setState({isDeleting:false, selectedRows:[]}) 
    })

    // rows = newRows; 
    // log("ROWS", rows.length)
    // Model.DataSet.updateDataSetData(this.props.dataSet, data, (progress) => {
    //   log("PROGRESS", progress);
    //   this.setState({isDeleting:false, selectedRows:[]}) 

    // });

    }
  }
  renderHeader() {
    const via = (this.props.dataSet.creationMethod === DataSetCreationMethod.Upload) ? "via Upload" : ""

    let  durationView; 
    console.log(this.props.dataSet.columnTypes);
    if (this.props.dataSet.columnTypes.includes(DataSetColumnType.Duration)) {
      let duration = 0;
      if (this.props.dataSet && this.props.dataSet.rows) {
        for (let i = 0; i < this.props.dataSet.rows.length; i++) {
          if(this.props.dataSet.rows[i].duration) { duration += this.props.dataSet.rows[i].duration}        
        }
      }
      durationView = <div>Total duration: {HoursMinutesSeconds(duration)}</div>
    }
    
    let buttonOptions = [<Button color={Color.Warning} onClick={this.deleteClicked}>Delete Data Set</Button>,
      <a className="Button button" target="_blank" href={`${Config.serverURL}/api/v1/dataset/${this.props.dataSet.id}/csv?${Service.API.queryHeaders()}`}>Download CSV</a>
    
    ]

    if (this.props.dataSet.columnTypes.includes(DataSetColumnType.BoundingBoxes)) {
      buttonOptions.push(
        <a className="Button button" target="_blank" href={`${Config.serverURL}/api/v1/dataset/${this.props.dataSet.id}/vison_dataset?${Service.API.queryHeaders()}`}>Vision Dataset</a>
      )
    }
    if (this.props.dataSet.rows) {
      for (let i = 0; i < this.props.dataSet.rows.length; i++) {
        if (this.props.dataSet.rows[i].parentRowId) {
          buttonOptions.push(
            <a className="Button button" target="_blank" href={`${Config.serverURL}/api/v1/dataset/${this.props.dataSet.id}/grouped_json?${Service.API.queryHeaders()}`}>Grouped JSON</a>
          )
          break;
        }
      }
    }


    return <div className="detailsHeader">
            <div className="actionButton">
              <ButtonWithOptions 
                isLoading={this.state.isCreatingJob}
                onClick={this.createJobClicked}
                buttonOptions={buttonOptions} 

              
              >Create Job</ButtonWithOptions>
            </div>
            <h2>{this.props.dataSet.name}</h2>
            Created by {this.props.dataSet.createdBy.firstName} {this.props.dataSet.createdBy.lastName} on {moment(this.props.dataSet.createdAt).format("MMM D, YYYY")} {via}
            <br/>
            
         
            <br/>
            {NumberWithCommas(this.props.dataSet.rowCount)} Data Points
            {durationView}
            {this.renderDeleteRowsButton()}

          </div>;
  }

  renderDeleteRowsButton() {
    if (this.state.selectedRows.length > 0 || this.state.isDeleting)   {
        return <div className="deleteButton">
          <Button isLoading={this.state.isDeleting} onClick={this.deleteRows} >
            Delete {pluralize("Row",this.state.selectedRows.length, true )}
            </Button>
          </div> 
    }
       
    return null;
  }

  renderCategories(categories?:CategoryData) {
    if (!categories) { return null; }
    var output = []
    for (const key in categories) {
      if (Object.prototype.hasOwnProperty.call(categories, key)) {
        output.push(<div key={key} className="categoryPill">
          
          <span>{key}</span>
          {categories[key].name}
        </div>)        
      }
    }
    return output;
  }
  renderTable() {

    const rowData:Array<DataSetRow> = this.props.dataSet.rows;
    if (!rowData) { return null; }
    const allSelected:boolean = (this.state.selectedRows.length === rowData.length)
    var hasIntent = false; 
    var hasText = false; 
    var hasSentiment = false; 

    var hasAudioURL  = false; 
    var hasAudioCategories = false;
    var hasAudioStartTime = false; 
    var hasAudioEndTime = false; 
    var hasAudioCategory = false; 
    var hasDuration = false; 
    var hasAutoTranscribe = false; 

    var hasImageURL  = false; 

    var hasCategories  = false; 



    for (let i = 0; i < rowData.length; i++) {
      if (!hasIntent && rowData[i].intent && rowData[i].intent.name) { hasIntent = true; }
      if (!hasText && rowData[i].text ) { hasText = true; }
      if (!hasAudioURL && (rowData[i] as any).audioURL ) { hasAudioURL = true; }
      if (!hasImageURL && (rowData[i] as any).imageURL ) { hasImageURL = true; }
      if (!hasCategories && rowData[i].categories ) { hasCategories = true; }
      if (!hasSentiment && typeof (rowData[i] as any).sentiment !== 'undefined' ) { hasSentiment = true; }
      

      if (!hasAudioCategories && (rowData[i] as any).audioCategories ) { hasAudioCategories = true; }
      if (!hasAudioStartTime  && (rowData[i] as any).audioStartTime )  { hasAudioStartTime = true; }
      if (!hasAudioEndTime    && (rowData[i] as any).audioEndTime )    { hasAudioEndTime = true; }
      if (!hasAudioCategory   && (rowData[i] as any).audioCategory )   { hasAudioCategory = true; }
      if (!hasDuration        && (rowData[i] as any).duration )        { hasDuration = true; }
      if (!hasAutoTranscribe        && (rowData[i] as any).automaticTranscription )        { hasAutoTranscribe = true; }

      

      console.log("rowData[i]",rowData[i].categories, hasCategories);

      


    }

    const rows = rowData.map((item,index) =>{
      let audioCategories; 
      if (hasAudioCategories && item.audioCategories) { 
        let acText = ""
        for (let ac = 0; ac < item.audioCategories.length; ac++) {
          let catName = (item.audioCategories[ac].name) ? item.audioCategories[ac].name : "UNKNOWN"; 
          acText += item.audioCategories[ac].name  + `(${item.audioCategories[ac].segments.length}) \n`;
        }
        audioCategories = <th>{acText}</th>;
      }
    
      return  <tr key={item.id}>
                <td className="checkboxColumn">
                  <Checkbox 
                    name={item.id} 
                    checked={allSelected || this.state.selectedRows.includes(item.id)}
                    checkboxClicked={this.checkboxClicked}
                    />
                  </td>
                  {(hasText)? <th>{item.text}</th>:null }
                  {(hasSentiment)? <th>{(typeof item.sentiment != 'undefined')? item.sentiment.toFixed(2): ""}</th>:null }

                  {(hasImageURL)? <th><a href={Service.File.mediaURL(item.imageURL)} target="_blank">{item.imageURL}</a></th>:null }
                  {(hasCategories)? <th>{this.renderCategories( item.categories)}</th>:null }
                  
                  {(hasAutoTranscribe)? <th>{item.automaticTranscription}</th>:null }
                  {(hasAudioURL)? <th>{ item.audioURL}</th>:null }
                  {(hasDuration)? <th>{(item.duration) ? HoursMinutesSeconds(item.duration) : ""}</th>:null }
                  {(hasIntent)? <th>{(item.intent)?item.intent.name:""}</th>:null }
                  {audioCategories}
                  {(hasAudioCategory)? <th>{(item.audioCategory)? item.audioCategory.name : ""}</th>:null }
                  {(hasAudioStartTime)? <th>{(item.audioStartTime)? item.audioStartTime.toFixed(2)+"s": ""}</th>:null }
                  {(hasAudioEndTime)? <th>{(item.audioEndTime)? item.audioEndTime.toFixed(2)+"s": ""}</th>:null }
              </tr>;
    });
    return <table>
      <thead>
        <tr><th className="checkboxColumn">
        <Checkbox 
                  name={"SelectAllCheckbox"} 
                  checked={allSelected}
                  checkboxClicked={this.checkboxClicked}
                  />          
          </th>
          {(hasText)? <th>Text</th>:null }
          {(hasSentiment)? <th>Sentiment</th>:null }
          {(hasImageURL)? <th>Image URL</th>:null }
          {(hasCategories)? <th>Categorizations</th>:null }
          {(hasAutoTranscribe)? <th>Auto Transcribe</th>:null }
          {(hasAudioURL)? <th>Audio URL</th>:null }
          {(hasDuration)? <th>Duration</th>:null }
                {(hasIntent)? <th>Intent</th>:null }
                {(hasAudioCategories)? <th>Audio Categories</th>:null }
                {(hasAudioCategory)? <th>Audio Category</th>:null }
                {(hasAudioStartTime)? <th>Audio Start</th>:null }
                {(hasAudioEndTime)? <th>Audio End</th>:null }

          
          </tr>

      </thead>
      <tbody>
      {rows}
      </tbody>
    </table>
  }

  setSelectedRows = (selectedRows:string[]) => {
    this.setState({selectedRows})
  }
  render() {
    let content;


    
    if (this.props.dataSet && this.props.dataSet.rows) {
      content = <DataTable 
      rows={this.props.dataSet.rows} 
      selectedRows={this.state.selectedRows} 
      setSelectedRows={this.setSelectedRows}
      />
    } else {
      content  =  <div className="ProgressBlock">
                    <progress id="dataSetProgress" className="progress is-primary" value="0" max="100">50%</progress>
                  </div>;
    }
    return <div className="DataSetDetailsView">
    {this.renderHeader()}
    {content}
    </div>;
  }

}
