import React, { ChangeEvent } from 'react';
import { DragableList, Input, InputType, Pill, PillSwitch, Select, Option } from '@swivl/great-grey-components';

import { Job, CreateLogger, Intent, TaskType, TaskData_Intent,MakeDictonaryArrayUnique, TaskData_ImageCategorize, TaskCategory, MLModel } from '@swivl/great-grey-lib';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../Actions/Actions';
import { Model } from '../../../Models/Model';


import "./ImageCategorizeCreateView.scss";
import SelectModelModal from '../../../Components/SelectModelModal/SelectModelModal';
import ImageCategorizeCreateCategory from './components/ImageCategorizeCreateCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SelectInstuctionsView } from '../../../Components/SelectInstructionsView/SelectInstructionsView';
import { SelectRedundancyComponent } from '../../../Components/SelectRedundancyComponent/SelectRedundancyComponent';

const log = CreateLogger("job:JobTaskIntent");
const logOfLoading = CreateLogger("LoadingLog")

interface Props { job:Job }
interface State {
  categories:any[]
  
}




export  class ImageCategorizeCreateView extends ActionsConsumer<Props,State> {
  constructor(props:Props) {
    super(props)
    this.state = { categories:[] }
    // this.toggleIntent = this.toggleIntent.bind(this)
    // this.createIntentsArray = this.createIntentsArray.bind(this)
    this.onToggle = this.onToggle.bind(this);
    this.categoryUpdated = this.categoryUpdated.bind(this)
    Actions.Controller.subscribeTo(this, [
      ActionType.MLModelsLoaded,
      ActionType.JobUpdated,
      ActionType.InstructionsLoaded
    ])
  }
  componentDidMount() {
    Model.Instructions.load()
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    // this.setState(({categories}) => ({
    //   categories: arrayMove(categories, oldIndex, newIndex),
    // }));
  };
  
  importFromModel = () => {
      Actions.Controller.trigger({type:ActionType.ShowModal, modalContent:<SelectModelModal modelSelected={this.modelSelected} />})
    
    
  }
  modelSelected = (model:MLModel) => {
    console.log("Model selected", model);
    
    if (model.data && model.data.categories) {
      const {job} = this.props; 
      let task = (job.tasks[TaskType.ImageCategorize] as TaskData_ImageCategorize)
      task.categories = task.categories.concat(model.data.categories); 
      task.categories = task.categories.map((cat) => {
        cat.include = true; 
        if (cat.specific) { 
          cat.specific = cat.specific.map((specific) => {
            specific.include = true; 
            return specific;
          })
        }
        return cat; 
      })
      job.tasks[TaskType.ImageCategorize] = task; 
      Model.Job.update(job,true);
    }
  }


  onToggle(name:string, on:boolean) {
    logOfLoading("name", name, on);

    // const {job} = this.props; 
    // let intentTask = (job.tasks[TaskType.Intent] as TaskData_Intent)

    // if (on) {
    //   if (this.state.allIntents && this.state.allIntents[name]) {
    //     intentTask.intents.push(this.state.allIntents[name])
    //   }
    // } else {
    //   for (let index = 0; index < intentTask.intents.length; index++) {
    //     const element = intentTask.intents[index];
    //     if (element.name === name) {
    //       intentTask.intents.splice(index,1);
    //       break;
    //     }
    //   }
    // }

    // job.tasks[TaskType.Intent]  = intentTask

    // Model.Job.update(job,true);
      
    
  }
  listUpdated = (items:any[], name?:string)  => {
        const {job} = this.props; 

    let task = (job.tasks[TaskType.ImageCategorize] as TaskData_ImageCategorize)
    task.categories = items; 
    job.tasks[TaskType.ImageCategorize] = task; 
    Model.Job.update(job,true);
  }


  onChange = (e:any)  => {
    
    const {job} = this.props; 
    job.tasks[TaskType.ImageCategorize][e.target.name] =  e.target.value; 
    console.log(job.tasks[TaskType.ImageCategorize])
    Model.Job.update(job,true);
}
categoryUpdated(category:TaskCategory, index:number) {
  const {job} = this.props; 
  console.log("categoryUpdated",category, index)
  let task = (job.tasks[TaskType.ImageCategorize] as TaskData_ImageCategorize)
  task.categories[index] = category; 
  console.log("categoryUpdated",category, task.categories)

  job.tasks[TaskType.ImageCategorize] = task; 
  console.log("categoryUpdated 3",category, job.tasks[TaskType.ImageCategorize])

  Model.Job.update(job,true);
}

renderItem = (item:TaskCategory, index:number) => {
  return <ImageCategorizeCreateCategory category={item} key={item.id} index={index} categoryUpdated={this.categoryUpdated} />

}



  render() {
    console.log("Model.Instructions.state", Model.Instructions.state)
    
    const job = this.props.job;
    const task = job.tasks["Image Categorize"] as TaskData_ImageCategorize
    // const intentTask:TaskData_Intent = job.tasks[TaskType.Intent] as TaskData_Intent;
    // const intents = intentTask.intents;

    // const selectedIntentNames = intents.map((item) => item.name);
    
    // log("selectedIntentNames",selectedIntentNames)


    // const allIntents = this.state.allIntents;
    // let intentViews = []
    // for (const key in allIntents) {
    //   if (allIntents.hasOwnProperty(key)) {
    //     const element = allIntents[key];

    //     intentViews.push(<PillSwitch onToggle={this.onToggle} on={(selectedIntentNames.includes(element.name))} key={key} name={element.name}>{element.name}</PillSwitch>)
        
    //   }
    // }


    console.log("Job be: ", job);
    
    
     const items =   task.categories; //.map((item:TaskCategory) => {return {id:item.name, name:item.name, specific:item.specific} })
     console.log("items be: ", items);

    
    return <div className="ImageCategorizeCreateView">
      <h3>Task Options</h3>

      <label>
            Task Heading
          </label>
          <Input name="taskHeading"  onChange={this.onChange} value={task.taskHeading}/>


      <div className="columnNameAndRedundancy">
        <div className="columnName">

          <label>
            Output Column Name
          </label>
          <Input name="categoryName"  onChange={this.onChange} value={task.categoryName}/>
        </div>

        <SelectRedundancyComponent job={this.props.job} taskType={TaskType.ImageCategorize} />

      </div> 


      <h3>Exercise Options</h3>

      <DragableList
                 items={items} 
                 listUpdated={this.listUpdated} 
                 renderItem={this.renderItem}
                 canRemove={true}
                 />
      <a onClick={this.importFromModel}>+ import from model</a>
      <h3 style={{marginTop:"20px"}}>Instructions</h3>
      <SelectInstuctionsView job={this.props.job} taskType={TaskType.ImageCategorize} />
    </div>
  }

}
