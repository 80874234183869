export class Config {
  static serverAddress:string; // i.e. 
  static serverURL:string;
  static serverName:string;
  static fileServerURL:string;
}

/* Example:
Config.serverAddress = 'greatgreyserver.ngrok.io';
Config.serverURL = 'https://' + Config.serverAddress;
Config.serverName = 'Great-Grey-Server-Local';
*/