import React from 'react';
import { Stage, Layer } from 'react-konva';

import { ActionsConsumer,Action, Actions, ActionType } from '../../../../Actions/Actions';

import Rectangle from './Rectangle/Rectangle';
import RectTransformer from './Rectangle/RectTransformer';
import AnnotationImage from './AnnotationImage/AnnotationImage';

import {BoundingBox, ShortId} from "@swivl/great-grey-lib"
import './AnnotationTool.scss';
import Crosshairs from './Rectangle/Crosshairs';

interface Props {
  imageURL:string 
  initialBoundingBoxes?:BoundingBox[]
  rectanglesUpdate(rectangles:any[], image:HTMLImageElement)

  // imageWidth:number, 
  // imageHeight:number 
}

interface State {
  rectangles: any[],
  rectCount: number,
  selectedShapeName: string,
  mouseDown: boolean,
  mouseDraw: boolean,
  newRectX: number,
  newRectY: number,
  mousePos:{x:number, y:number},
  image?:HTMLImageElement 
}
class AnnotationTool extends ActionsConsumer<Props, State> {

  img:any; 
  stage:any; 

  constructor(props:Props) {
    super(props) 
    this.state = {
      rectangles: [], 
      rectCount: 0,
      selectedShapeName: '',
      mouseDown: false,
      mouseDraw: false,
      newRectX: 0,
      newRectY: 0,
      mousePos:{x:0, y:0}
    };
  }

  componentDidMount() {


      const image = new window.Image();
      image.src = this.props.imageURL;
      image.onload = () => {
        console.log('image loaded');
        
        const rectangles =this.rectanglesForBoundingBoxes(image)
        this.setState({
          image,
          rectangles:rectangles,
          rectCount:rectangles.length
        }, ()=> {
            this.img.moveToBottom();

        });
      };
    
  



    Actions.Controller.subscribeTo(this,
      [ 
        ActionType.KeyboardKeyDown
      ]);
  }
  handleAction(action:Action) { 
      if (action.type === ActionType.KeyboardKeyDown && this.state.selectedShapeName) {
        if (action.event.keyCode == 8) {
          this.deleteRect(this.state.selectedShapeName);
        }
      }

   }

   deleteRect = (shapeName:string) => {
      let rectangles = this.state.rectangles 
      for (let i = 0; i < rectangles.length; i++) {
        const rect = rectangles[i]; 
        if (rect.name == shapeName) {
          rectangles.splice(i, 1)
          this.setState({rectangles:rectangles})
          this.props.rectanglesUpdate(rectangles, this.state.image)
          return; 
        }
      }
   }

  handleStageMouseDown = (event) => {
    const rectangles  = this.state.rectangles;
    const stage = event.target.getStage();
    const mousePos = stage.getPointerPosition();
    // clicked on stage - clear selection or ready to generate new rectangle
    if (event.target.className === 'Image') {

      this.setState({
        mouseDown: true,
        newRectX: mousePos.x,
        newRectY: mousePos.y,
        selectedShapeName: '',
        mousePos:mousePos
      });
      return;
    }
    // clicked on transformer - do nothing
    const clickedOnTransformer = event.target.getParent().className === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    // find clicked rect by its name
    const name = event.target.name();
    
    const rect = rectangles.find(r => r.name === name);

    if (rect) {
      this.setState({
        selectedShapeName: name,
        rectangles,
        mousePos
      });
      this.props.rectanglesUpdate(rectangles, this.state.image)

    } else {
      this.setState({
        selectedShapeName: '',
        mousePos
      });
    }
  };

  handleRectChange = (index, newProps) => {
    const { rectangles } = this.state;

    

    rectangles[index] = {
      ...rectangles[index],
      ...newProps,
    };

    this.setState({ rectangles });
    this.props.rectanglesUpdate(rectangles, this.state.image)

  };

  handleMouseMove = (event) => {
    if (this.state.mouseDown) { 
      this.handleNewRectChange(event)
    } else {
      const stage = event.target.getStage();
      const mousePos = stage.getPointerPosition();
      
      return this.setState({  mousePos:mousePos });
  
    }
    
  }


  handleNewRectChange = (event) => {
    const {
      rectangles, rectCount, newRectX, newRectY,
    } = this.state;
    const stage = event.target.getStage();
    const mousePos = stage.getPointerPosition();
    if (!rectangles[rectCount]) {
      const id = ShortId()
      rectangles.push({
        x: newRectX,
        y: newRectY,
        width: mousePos.x - newRectX,
        height: mousePos.y - newRectY,
        name: `rect_${id}`,
        stroke: 'rgba(246,120,2,0.5)',
        selectedStroke: 'rgba(246,120,2,1)',
        fill: 'rgba(246,120,2,0.3)',
        key: id
      });
      this.setState({ rectangles, mouseDraw: true, mousePos:mousePos });
      this.props.rectanglesUpdate(rectangles, this.state.image)

      return;
    }
    rectangles[rectCount].width = mousePos.x - newRectX;
    rectangles[rectCount].height = mousePos.y - newRectY;
    this.setState({ rectangles,mousePos });
    // this.props.rectanglesUpdate(rectangles, this.state.image)
    return;
  };

  handleStageMouseUp = () => {
    const { rectCount, mouseDraw,rectangles } = this.state;
    if (mouseDraw) {
      this.setState({ rectCount: rectCount + 1, mouseDraw: false });
    }
    this.setState({ mouseDown: false });
        this.props.rectanglesUpdate(rectangles, this.state.image)

  };

  rectanglesForBoundingBoxes(  imageParam?:HTMLImageElement     ) {
    const boundingBoxes = this.props.initialBoundingBoxes;
    const image = (imageParam) ? imageParam : this.state.image; 

    if (!boundingBoxes || !image) { return []; }
    let rectangles = []; 
    for (let i = 0; i < boundingBoxes.length; i++) {
      const box = boundingBoxes[i];
      
      rectangles.push({
        x: box.points.x_relative_min * image.width,
        y: box.points.y_relative_min * image.height,
        width:(box.points.x_relative_max - box.points.x_relative_min) * image.width,
        height: (box.points.y_relative_max - box.points.y_relative_min) * image.height,
        name: `rect_${box.id}`,
        stroke: 'rgba(246,120,2,0.5)',
        selectedStroke: 'rgba(246,120,2,1)',
        fill: 'rgba(246,120,2,0.3)',
        key: box.id
      });
    }


    return rectangles; 
  }

  render() {
    const {
      state: { rectangles, selectedShapeName, mouseDown, image },
      handleStageMouseDown,
      handleNewRectChange,
      handleRectChange,
      handleStageMouseUp,
      handleMouseMove
    } = this;
    if (!image) { return null; }
    
    return (
      <div className="AnnotationTool" id="annotationTool">
        <Stage
          ref={(node) => {
            this.stage = node;
          }}
          container="annotationTool"
          width={image.width}
          height={image.height}
          onMouseDown={handleStageMouseDown}
          onTouchStart={handleStageMouseDown}
          onMouseMove={handleMouseMove}
          onTouchMove={handleMouseMove}
          onMouseUp={mouseDown && handleStageMouseUp}
          onTouchEnd={mouseDown && handleStageMouseUp}
        >
          <Layer>
            {rectangles.map((rect, i) => (
              <Rectangle
                sclassName="rect"
                key={rect.key}
                {...rect}
                selectedRectName={selectedShapeName}
                onTransform={(newProps) => {
                  handleRectChange(i, newProps);
                }}
              />
            ))}
            {/* <RectTransformer selectedShapeName={selectedShapeName} /> */}
            <Crosshairs mousePos={this.state.mousePos} {...this.props} 
                          width={image.width}
                          height={image.height}

 />
          </Layer>
           
           

          <Layer
            ref={(node) => {
              this.img = node;
            }}
          >
            <AnnotationImage image={image}
            />
          </Layer>
        </Stage>
      </div>
    );
  }
}

export default AnnotationTool;
