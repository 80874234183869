import { Instructions, GGDictionary, CreateLogger} from '@swivl/great-grey-lib';
import { ActionType, Actions, ItemsLoadedAction } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import { Model } from '../Model';
import Service from '../../Services/Service';
const log = CreateLogger("Instructions")


export interface InstructionsModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<Instructions>
}> {}

export class InstructionsModel extends BaseModel<Instructions,InstructionsModelState>  {
  static get Model():InstructionsModel {   if (InstructionsModel._Model) { return InstructionsModel._Model; }  else {   InstructionsModel._Model = new InstructionsModel(); return InstructionsModel._Model ; } }

  
  private static _Model:InstructionsModel;
  // private constructor() { super();  this.state = {...this.state, dataFiles:{}} }

  state:InstructionsModelState = {
    isLoading:false,
    items:undefined,
  }

  ENTITY_NAME = "Instructions";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.InstructionsLoaded,
    ItemUpdated : ActionType.InstructionsUpdated,
    ItemDeleted : ActionType.InstructionsDeleted,
    ItemCreated : ActionType.InstructionsCreated
  } 


}
