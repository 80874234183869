import React from 'react';
import './IntentReviewItem.scss';
import { CreateLogger, ReviewRow, RowChangeType, ChangeStatus, RowChange, Intent, CreateRowChange_Intent, Job, TaskData_Intent, CreateRowChange_Excluded, TaskType } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button,ButtonDropdown, Option, Color } from '@swivl/great-grey-components';
import { ReviewItemViewProps } from '../../TaskViews';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
  { value: 'dchocolate', label: 'Dark Chocolate' },
  { value: 'dstrawberry', label: 'Dark Strawberry' },
  { value: 'dvanilla', label: 'Dark Vanilla' },

  { value: 'ldchocolate', label: 'Light Chocolate' },
  { value: 'ldstrawberry', label: 'Light Strawberry' },
  { value: 'ldvanilla', label: 'Light Vanilla' },
  { value: 'ddd', label: 'Super Chocolate' },
  { value: 'ldsssstrawberry', label: 'Super Strawberry' },
  { value: 'ldvaaaanilla', label: 'Super Vanilla' },
];




const log = CreateLogger("job:IntentReviewItem")

interface IntentReviewItemChangeButtonProps {
  title:string 
  count:number 
  intent:Intent
  selectedIntent?:Intent
  intentSelected(intent:Intent)
}
export class IntentReviewItemChangeButton extends React.Component<IntentReviewItemChangeButtonProps> {
  constructor(props:any) {
    super(props)
    this.onClick = this.onClick.bind(this)
    } 
    onClick() { this.props.intentSelected(this.props.intent) }
    render() {
     return  <Button 
              color={Color.Tan} 
              isSelected={(this.props.selectedIntent && this.props.selectedIntent.name === this.props.intent.name)} 
              isRound 
              onClick={this.onClick}>
                {this.props.title} ({this.props.count})
              </Button>
    }
}




// interface IntentReviewItemProps {  
//   row:ReviewRow 
//   job:Job
//   // intentOptions:Option[]
//   updatedRow(row:ReviewRow)
// }
interface IntentReviewItemState {
  intentOptions:Option[]
}

export class IntentReviewItem extends React.Component<ReviewItemViewProps,IntentReviewItemState> {
    constructor(props:any) {
        super(props)

        const intentTaskData:TaskData_Intent = this.props.job.tasks[TaskType.Intent] as TaskData_Intent; 
        let intentOptions:Option[] = intentTaskData.intents.map((item) =>  { return { value: item.name, label: item.name, data:item } }   )
        this.state = {intentOptions:intentOptions}

        this.customOptionSelected = this.customOptionSelected.bind(this)
        this.intentSelected = this.intentSelected.bind(this)
        this.excludeSelected = this.excludeSelected.bind(this)



    }

    customOptionSelected(option:Option) {
      let item = this.props.row;
      item.changeStatus = ChangeStatus.ResolvedEscalation;
      item.change = CreateRowChange_Intent(option.data);
      this.props.updatedRow(item);
    }
    intentSelected(intent:Intent) {
      log("intent selected", intent);
      let item = this.props.row;
      item.changeStatus = ChangeStatus.ResolvedEscalation;
      item.change = CreateRowChange_Intent(intent);
      this.props.updatedRow(item);
    }
    excludeSelected() {
      let item = this.props.row;
      item.changeStatus = ChangeStatus.ResolvedEscalation;
      item.change = CreateRowChange_Excluded()
      this.props.updatedRow(item);
    }

  render() {
    const {  type,  originalRow,   work, confidence, changeStatus, change } = this.props.row;
    
    const buttons = [];


    var intents = {}

    var numberOfSkipped = 0;
    for (let i = 0; i < work.length; i++) {
      const element = work[i];
      if (element.change.type === RowChangeType.Skipped) {
        numberOfSkipped++;
      }

      if (element.change.type === RowChangeType.Intent && element.change.intent && element.change.intent.name) {
        if (intents[element.change.intent.name]) { 
          intents[element.change.intent.name].count++;
        } else {
          intents[element.change.intent.name] = {intent:element.change.intent, count:1}
        }
      }
    }

    if (numberOfSkipped > 0) {
      buttons.push(<div key={"Skipped"} className="skippedButton button is-rounded" >Skipped ({numberOfSkipped})</div>)
    }
    const selectedIntent:Intent = (change && change.type === RowChangeType.Intent) ? change.intent : null;

    for (const key in intents) {
        buttons.push(<IntentReviewItemChangeButton 
                      key={originalRow.id+"_"+key}
                      title={key}
                      count={intents[key].count}
                      intent={intents[key].intent}
                      selectedIntent={selectedIntent} 
                      intentSelected={this.intentSelected} 
                      />)
      }
    
    const selectedOption:Option = (change && change.type === RowChangeType.Intent && !intents[change.intent.name]) ? { value: change.intent.name, label: change.intent.name, data:change.intent } : null;


    buttons.push(
      <ButtonDropdown
        key="chooseDifferent"
        placeholder="Choose Different"
        options={this.state.intentOptions}
        isRound
        optionSelected={this.customOptionSelected}
        selectedOption={selectedOption}
        />
    )
    if (change && change.type === RowChangeType.Excluded) {
      buttons.push(<Button 
        key={"ignore"}
        isRound
        color={Color.Tan}
        isSelected
        >Exclude</Button>)
    } else {
      buttons.push(<Button 
        key={"ignore"}
        isRound
        isDashed
        onClick={this.excludeSelected}
        >Exclude</Button>)
    }
 


      const completeIcon = (changeStatus === ChangeStatus.ResolvedEscalation) ? <div className="completeIcon"><FontAwesomeIcon icon={faCheck} /></div> : null;
    return <div className="IntentReviewItem IntentIntentReviewItem">
        {completeIcon}
        <h3>{originalRow.text}</h3>
        <div className="options">
          {buttons}          
        </div>
    </div>;
  }

}
