import React from 'react';
import  "./ModalCard.scss";

interface ModalCardProps {
  name:string;
  selectedCard?:string;
  alwaysRenderChildren?:boolean;
}
interface ModalCardState {
  isShowing:boolean;
}

export class ModalCard extends React.Component<ModalCardProps,ModalCardState> {
  constructor(props:ModalCardProps) {
    super(props)
    this.state = {isShowing:false}
  }
  componentDidMount()  { this.checkIfShowing(); }
  componentDidUpdate() { this.checkIfShowing(); }
  checkIfShowing() {
    if (this.props.selectedCard &&
        this.props.name === this.props.selectedCard &&
        !this.state.isShowing
      ) {
        // setTimeout(() => {
          this.setState({isShowing:true});
        // }, 1000);

      } else if (this.props.name !== this.props.selectedCard &&
              this.state.isShowing
            ) {
              this.setState({isShowing:false})
            }
  }

  render() {
    const children = (this.state.isShowing || this.props.alwaysRenderChildren) ? this.props.children : null;
    return <div className={(this.state.isShowing) ? "ModalCard isShowing" : "ModalCard"}>
                {children}
          </div>;
  }

}
