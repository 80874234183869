import React from 'react';
import { SubTask, CreateLogger, TaskType, TaskData_Intent, TaskData_Entity, Assignment } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';
import './SubTaskDetailsView.scss'
import { ButtonWithOptions, Pill, Button } from '@swivl/great-grey-components';
import { NavigationModel } from '../../../Models/Models/NavigationModel';

const moment = require('moment');

const log = CreateLogger("job:SubTaskDetailsView")

export   function nameForTaskType(type):string {
  switch(type) {
    case TaskType.Intent: return "Select the Intent";
    case TaskType.Split: return "Split Sentences";
    case TaskType.Entity: return "Label Entities";
    case TaskType.Sentiment: return "Score Sentiment";
  } 
  return ""
}


interface SubTaskDetailsViewProps {
    subTask:SubTask;
}

interface SubTaskDetailsViewState {  isStartingTask:boolean;}
export  class SubTaskDetailsView extends React.Component<SubTaskDetailsViewProps,SubTaskDetailsViewState> {
  constructor(props:any) {
    super(props)
    this.state = {isStartingTask:false};
    this.startTaskClicked = this.startTaskClicked.bind(this)
  }
 

  startTaskClicked() {
    this.setState({isStartingTask:true})
    Model.SubTask.startTask(this.props.subTask).then( (assignment:Assignment) => {
      this.setState({isStartingTask:false})
      Model.Navigation.setPath("/assignment/" + assignment.id);
    }).catch((e) => { 
      this.setState({isStartingTask:false})
    })
  }


 

  estimatedTime(sec_num:number) {
    // var sec_num = parseInt(milliseconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);


    // if (hours   < 10) {hours   = "0"+hours;}
    // if (minutes < 10) {minutes = "0"+minutes;}
    // if (seconds < 10) {seconds = "0"+seconds;}

    let hoursString = String(hours)
    let minutesString = String(minutes)
    let secondsString = String(seconds)

    if (hours > 0) {
      if (minutes < 10) {minutesString = "0"+minutes;}
      if (seconds < 10) {secondsString = "0"+seconds;}
      return hoursString+':'+minutesString+':'+secondsString;
    } else if (minutes > 0) {

      if (seconds < 10) { secondsString = "0"+seconds;}
      return minutesString+':'+secondsString;
    } 

    return secondsString + " seconds"
}

  renderHeader() {
    return <div className="detailsHeader">
                  <div className="actionButton">
              <Button isLoading={this.state.isStartingTask} onClick={this.startTaskClicked}>Start Task</Button>
            </div>
      <h2>{nameForTaskType(this.props.subTask.type)}</h2>
      {this.props.subTask.data.length} exercises | est time {this.estimatedTime(Object.values(this.props.subTask.data).length * 10)}

    </div>
  }
  
  renderExamplePhrases() {
    // let rows = []
    // for (let index = 0; index < this.props.subTask.data.length; index++) {
    //   const element = this.props.subTask.data[index];
    //   rows.push(<div className="examplePhrase" key={"item"+index}>"{element.text}"</div>)
    //   if (index > 4) { break; }
      
    // }

    // return <div className="examplePhrases">
    //   <h3>EXAMPLE PHRASES</h3>
    //   {rows}
    // </div>
    


  }

  renderPosibileIntents(){
    if (this.props.subTask.type !== TaskType.Intent) { return null; }

    let pills = []
    let task:TaskData_Intent = this.props.subTask.task as TaskData_Intent;
    for (let index = 0; index < task.intents.length; index++) {
      const element = task.intents[index];


      pills.push(<Pill key={element.name} hideDelete>
        {element.name}
      </Pill>
      )
      
    }
    return <div className="modelData">
    <h3>POSSIBLE INTENTS</h3>
    {pills}
  </div>
  }

  renderPosibileEntities(){
    if (this.props.subTask.type !== TaskType.Entity) { return null; }

    let pills = []
    let task:TaskData_Entity = this.props.subTask.task as TaskData_Entity;
    for (let index = 0; index < task.entities.length; index++) {
      const element = task.entities[index];


      pills.push(<Pill key={element.name} hideDelete>
        {element.name}
      </Pill>
      )
      
    }
    return <div className="modelData">
    <h3>POSSIBLE ENTITIES</h3>
    {pills}
  </div>
  }
  

  render() {
    let content;
    return <div className="SubTaskDetailsView">
      { this.renderHeader()           }
      { this.renderExamplePhrases()   }
      { this.renderPosibileIntents()  }
      { this.renderPosibileEntities() }
    </div>;
  }


}
