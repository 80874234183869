import React from 'react';
import { ActionsConsumer, Actions, ActionType } from '../../Actions/Actions';
import "./SettingsScreen.scss";
import { Model } from '../../Models/Model';
import { NavTextButton, SelectionList,Option, Select } from '@swivl/great-grey-components';
import TwoColumnLayoutView from '../../Components/TwoColumnLayoutView/TwoColumnLayoutView';
import ProjectsView from './Projects/ProjectsView';
import OrgView from './Org/OrgView';
import MyAccountView from './MyAccount/MyAccountView';
import { MembershipRole } from '@swivl/great-grey-lib';

// import * as Model from '../../Models/Model';
interface SettingsScreenProps {
    setNavBar(left:any|undefined, center:any|undefined, right:any|undefined);
}
export default class SettingsScreen extends ActionsConsumer<SettingsScreenProps> {
  constructor(props:any) {
    super(props)
    this.logout = this.logout.bind(this)
    this.orgSelected = this.orgSelected.bind(this)

    
    Actions.Controller.subscribeTo(this, [
      ActionType.NavigationPathUpdated
     ] )
  }
  
  componentDidMount() {
    this.props.setNavBar(null,<h3>Settings</h3>,<NavTextButton onClick={this.logout} text={"Log Out"} />)
  }

  logout() {
    if (window.confirm("Are you sure you want to log out?")) {
      Model.Session.logOut()
    }
  }
  orgSelected(selectedOption:Option) {

    Model.Session.setCurrentMembership(selectedOption.data)
  }

  renderMembershipDropdown() {
    const {user, currentMembership} = Model.Session.state; 
    if (!user.membership) { return null; }
    var selectedOption; 
    const options = user.membership.map((item) => { 
      let  option  = { value: item.id, label: item.organisation.name + ` (${item.role})`, data:item }
      if (currentMembership && currentMembership.id === item.id) {  
        console.log("YO YO YO");
        selectedOption = option; }
      return option; 
    })
    console.log("currentMembership",currentMembership)
    return <div className="membershipDropdown">
      <h4>Organisation</h4>
      
    <div className="orgWrapper">


  
              <Select value={selectedOption} options={options} onChange={this.orgSelected} />


              </div>


    </div>
  }

  renderOld() {
    const user = Model.Session.state.user; 
    let content;
    if (user) {
      content = <div>
         <div>{user.firstName} {user.lastName}</div>

      
    
      </div>
    }

    return <div className="SettingsScreen">

      <h1>Settings</h1>
     {content}
     {this.renderMembershipDropdown()}
    </div>;
  }
  navItemSelected(item:Option) {
    Model.Navigation.setPath("/settings/" + item.value)
  }
  contentForPath(subPath:string) {
    switch(subPath) {
      case "organization": return <OrgView key={"prog_" + Model.Session.state.currentMembership?.organisation.id} />
      case "projects": return <ProjectsView key={"prog_" + Model.Session.state.currentMembership?.organisation.id} />
      default: return <MyAccountView />
    }
  }

  render() {
    const header = <div className="settingsHeader">
      <div className="roundAvatar huge"/>
      <h1>{Model.Session.state.currentMembership?.organisation?.name}</h1>
    </div>
    const subPath = Model.Navigation.second()

    const content = this.contentForPath(subPath)

    let options;
    if (Model.Session.state.currentMembership.role === MembershipRole.Admin) {
      options = [
        {value:"account", label:"My Account"},
        {value:"organization", label:"Organization"},
        {value:"projects", label:"Projects"},
      ]
    } else {
      options = [
        {value:"account", label:"My Account"},
      ]
    }

    return <div className="SettingsScreen">
      <TwoColumnLayoutView
        itemSelected={this.navItemSelected}
        items={options}
        selectedItemValue={subPath}
        header={header}
        >
          {content}
        </TwoColumnLayoutView>
  </div>;
    
  }

}
