import React from 'react';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../Actions/Actions';


import { NavTextButton, SelectionList,Option, Select, TabHeader } from '@swivl/great-grey-components';
import { GGDictionary, OrganisationMembership } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';

import "./OrgView.scss"
import OrgMemberView from './OrgMemberView/OrgMemberView';
// import * as Model from '../../Models/Model';
interface State {
  isPopoverOpen:boolean;
  
}
interface Props {

}
export default class OrgView extends ActionsConsumer<Props,State> {
 
  constructor(props:Props) {
    super(props)
  this.state = { isPopoverOpen:false}
  Actions.Controller.subscribeTo(this, [
    ActionType.OrgMembershipsLoaded,
    ActionType.OrgMembershipCreated,
    ActionType.OrgMembershipDeleted,
    ActionType.OrgMembershipUpdated,    
    ActionType.OrgMembershipsLoaded,
    ActionType.ProjectMembershipsLoaded
  
   ] )

//    this.itemUpdated = this.itemUpdated.bind(this)
//    this.deleteItem = this.deleteItem.bind(this)
   this.setIsPopoverOpen = this.setIsPopoverOpen.bind(this)
//    this.tabSelected = this.tabSelected.bind(this)
}
componentDidMount() {
  Model.OrgMembership.load()
}


// componentDidMount() {
//   Model.OrgMembership.loadOrgsAndMembers()
// }

// itemUpdated(item:OrganisationMembership) {
//   Model.OrgMembership.update(item, true)
// }
// deleteItem(item:OrganisationMembership) {
//   Model.OrgMembership.delete(item)
// }

setIsPopoverOpen(isPopoverOpen:boolean) { this.setState({isPopoverOpen:isPopoverOpen})}


renderBlankState() {
    return <div className="blankState">
      No Org.
    </div>
}

render() {
    const orgMemberships = (Model.OrgMembership.state.items) ? Object.values(Model.OrgMembership.state.items) : null; 

  // console.log("OrgMemberships", OrgMemberships);
    var membersView;
    if (orgMemberships) {
        membersView = orgMemberships.map((item) =>  <OrgMemberView key={item.id} org={Model.Session.state.currentMembership.organisation} membership={item} />) 
    }
  return <div className="OrgView">
      {membersView}


  </div>;
}

}
