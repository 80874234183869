import React, { ChangeEvent } from 'react';
import { DragableList, Input, InputType, Pill, PillSwitch, Select, Option } from '@swivl/great-grey-components';

import { Job, CreateLogger, Intent, TaskType, TaskData_Intent,MakeDictonaryArrayUnique, TaskCategory, MLModel, TaskData_ImageBoundingBox } from '@swivl/great-grey-lib';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../Actions/Actions';
import { Model } from '../../../Models/Model';


import "./ImageBoundingBoxCreateView.scss";
import SelectModelModal from '../../../Components/SelectModelModal/SelectModelModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ImageCategorizeCreateCategory from '../../ImageCategorize/Create/components/ImageCategorizeCreateCategory';
import { SelectInstuctionsView } from '../../../Components/SelectInstructionsView/SelectInstructionsView';

const log = CreateLogger("job:JobTaskIntent");
const logOfLoading = CreateLogger("LoadingLog")

interface Props { job:Job }
interface State {
  categories:any[]
  
}

const taskType = TaskType.ImageBoundingBox
type TaskDataType = TaskData_ImageBoundingBox;

export  class ImageBoundingBoxCreateView extends ActionsConsumer<Props,State> {
  constructor(props:Props) {
    super(props)
    this.state = { categories:[] }
    // this.toggleIntent = this.toggleIntent.bind(this)
    // this.createIntentsArray = this.createIntentsArray.bind(this)
    this.categoryUpdated = this.categoryUpdated.bind(this)
    Actions.Controller.subscribeTo(this, [
      ActionType.MLModelsLoaded,
      ActionType.JobUpdated,
      ActionType.InstructionsLoaded
    ])
  }
  componentDidMount() {
    Model.Instructions.load()
  }


  
  
//   listUpdated = (items:any[], name?:string)  => {
//         const {job} = this.props; 

//     let task = (job.tasks[taskType] as TaskDataType)
//     task.categories = items; 
//     job.tasks[taskType] = task; 
//     Model.Job.update(job,true);
//   }


  onChange = (e:any)  => {
    
    const {job} = this.props; 
    job.tasks[taskType][e.target.name] =  e.target.value; 
    console.log(job.tasks[taskType])
    Model.Job.update(job,true);
}
categoryUpdated = (selectedOption:Option) => {
  const {job} = this.props; 

  let task = (job.tasks[taskType] as TaskDataType)
  task.categoryId = selectedOption.value;
  job.tasks[taskType] = task; 
  Model.Job.update(job,true);
}




  render() {
    const job = this.props.job;
    const task = job.tasks[taskType] as TaskDataType
      const dataSet = this.props.job.dataSet;
      if (!dataSet) {
        return <div />; 
      }
      var categories = []
      for (let i = 0; i < dataSet.rows.length; i++) {
          const element = dataSet.rows[i];
          if (element.categories) {
            categories = categories.concat( Object.keys(element.categories ))
          }
          
      }
      const distinctCategories = [...new Set(categories)]

    
      const options:Option[] = distinctCategories.map((item) => { return {    label:item,value:item}})

    
    return <div className="ImageCategorizeCreateView">
      <h3>Task Options</h3>

      <label>
            Task Heading
          </label>
          <Input name="taskHeading"  onChange={this.onChange} value={task.taskHeading}/>


      {/* <div className="columnNameAndRedundancy">
 

        <div className="redundancy">
          <label>
            Redundancy
          </label>
          <Input type={InputType.Integer} name="redundancy" onChange={this.onChange} value={task.redundancy + ""} />
        </div>
      </div>  */}


      <label>
            Category to Bound
          </label>
      <Select options={options} onChange={this.categoryUpdated} value={task.categoryId} />
      <h3 style={{marginTop:"20px"}}>Instructions</h3>
      <SelectInstuctionsView job={this.props.job} taskType={TaskType.ImageBoundingBox} />
    </div>
  }

}
