import React from 'react';
import { MLModel, OrganisationMembership, Project, User } from '@swivl/great-grey-lib';
import "./CreateJobFromDataSetModal.scss"
import { Input, Option, Select, Button, Color } from '@swivl/great-grey-components';
import { Actions, ActionsConsumer, ActionType } from '../../../../../Actions/Actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// import * as Model from '../../Models/Model';
interface State {
    isCurating:boolean
}
interface Props {
    create()
}
export default class CreateJobFromDataSetModal extends ActionsConsumer<Props,State> {
 
  constructor(props:Props) {
    super(props)
    this.state = {isCurating:false}



  }
  onChange = (selectedOption:Option) =>{
    // this.setState({selectedModel:selectedOption.data})
  }
  onClick = () => {
    this.props.create()
    Actions.Controller.trigger({type:ActionType.HideModal})

  }
  curateClicked = () => {
    this.setState({isCurating:true})
    setTimeout(()=> {
      this.props.create()
      Actions.Controller.trigger({type:ActionType.HideModal})
    }, 3000);
    // this.props.create()
    // Actions.Controller.trigger({type:ActionType.HideModal})

  }
    render() {

      if (this.state.isCurating) {

        return <div className="SelectModelModal">
        <img src={'/img/nav/icon-data@2x.png'} />
        <h1>Curating Model</h1>
        <div className="buttonOrSpinner">
        One moment while we curate this job.
        </div>
        <div className="loaderWrapper"> 
        <div className="lds-ripple"><div></div><div></div></div>
 
      </div>      </div>

      }
    //  const models = Model.MLModel.array; 
    // const options = (models) ? models.map((item) =>{ return {label:item.name,  value:item.id,  data:item}}) : null; 
    //     const select = (options) ? <Select options={options} onChange={this.onChange} value ={(this.state.selectedModel)? this.state.selectedModel.id : null}/> : <div className="loadingSpinner"><FontAwesomeIcon icon={faSpinner} spin /> </div>
  return <div className="SelectModelModal">
      <img src={'/img/nav/icon-data@2x.png'} />
      <h1>Data Curation</h1>
      <div className="buttonOrSpinner">
      Would you like to curate this job?
      </div>
      <div className="buttons">
          <Button onClick={this.onClick} color={Color.Tan} >No</Button>
          <Button onClick={this.curateClicked} color={Color.Primary} >Yes</Button>
      </div>

      {/* <label>PROJECT NAME</label>
      <Input onChange={this.projectNameUpdated} />
      <label>PROJECT LEAD</label>
      <Select 
      value={(this.state.projectLead)?this.state.projectLead.id :null}
        options={options} 
        isSearchable={true}
        onChange={this.leadChanged} />
      <div className="buttons">
          <Button onClick={this.cancelClicked} color={Color.Tan} isDisabled={this.state.isCreatingProject}>Cancel</Button>
          <Button onClick={this.createClicked} color={Color.Primary} isLoading={this.state.isCreatingProject}>Create Project</Button>
      </div> */}
  </div>
}

}
