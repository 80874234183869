import { Job,isJob, GGDictionary, JobStatus, DataSet, MLModel, MLModelData, CreateLogger} from '@swivl/great-grey-lib';
import { ActionType, Actions, ItemUpdatedAction } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import { Model } from '../Model';
import Service from '../../Services/Service';
import { start } from 'repl';

const log = CreateLogger("JobModel")

export interface JobModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<Job>
}> {}

export class JobModel extends BaseModel<Job,JobModelState>  {
  static get Model():JobModel {   if (JobModel._Model) { return JobModel._Model; }  else {   JobModel._Model = new JobModel(); return JobModel._Model ; } }

  private static _Model:JobModel;
  private constructor() { super();   }
  ENTITY_NAME = "job";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.JobsLoaded,
    ItemUpdated : ActionType.JobUpdated,
    ItemDeleted : ActionType.JobDeleted,
    ItemCreated : ActionType.JobCreated,
    ItemFetched : ActionType.JobFetched
  } 

  createNewJob(optionalDataSet?:DataSet):Promise<Job> {

    let job:any = {
      id: "",
      name: "New Job",
      status: JobStatus.Unstarted,
      createdAt: new Date(),
      updatedAt: new Date(),
      tasks: {},
      models: {},
      rowCount: 0,
      createdById: Model.Session.state.user.id,
      organisationId:Model.Session.state.currentMembership.organisation.id,
      projectId:Model.Session.state.currentProjectMembership.projectId

    }
    if (optionalDataSet) {
      job.name = optionalDataSet.name + " Job";
      job.dataSet = optionalDataSet;
    }
    return this.create(job).then((createdJob) => {
      Model.Navigation.setPath("/job/"+createdJob.id)
      return Promise.resolve(createdJob)
    }).catch((e) => {
      Service.Toast.error(e, "Error creating job.");
    })
  }


  addModelToJob(job:Job, mLModel:MLModel):Promise<Job> {
    this.update(job,true)
    return Promise.resolve(job);
    // return Service.File.fetchFile<MLModelData>(mLModel.dataFileName,  () => {})
    // .then((data) => {
    //   return Promise.resolve(job)
    // }).catch((e) => {
    //   Service.Toast.error("Error loading data model.");
    //   return Promise.reject()
    // })


  }

  startJob(job:Job):Promise<Job> {
    log("start job", job)
    job.rowCount = job.dataSet.rowCount;
    return new Promise((resolve, reject) => {
      Service.API.updateItem<Job>(this.ENTITY_NAME, job)
      .then((savedJob) => { 
        return Service.API.startItem<Job>(this.ENTITY_NAME, job)
       })
      .then((startedJob:any) => {
        log("started job!", startedJob);
      // if (isJob(startedJob)) { 
      this.update(startedJob, false);
      return resolve(startedJob)
      
      // reject(Error("Invalide Return Data"))
    }).catch((e)=>{
      reject(e)

      job.status = JobStatus.Unstarted; 
      Actions.Controller.trigger({type:ActionType.JobUpdated, item:job});
      Service.Toast.error(e, "Error starting Job ");
    });

    })
  }

  async finishJob(job:Job):Promise<Job> {
    return Service.API.finishItem<Job>(this.ENTITY_NAME, job).then((finishedJob) => {
      log("Finished Job",finishedJob)
      this.state.items[job.id] = finishedJob; 
      Actions.Controller.trigger({type:ActionType.JobUpdated, item:job});
      return Promise.resolve(finishedJob);
    })
  }
  
}
