import React from 'react';


import { ModalCard } from '../../Layout/Modal/ModalCard';
import { FileUpload } from './components/FileUpload/FileUpload';
import { EnterNameCard } from './components/EnterNameCard/EnterNameCard';
import './UploadMLModel.scss'

import { ConfirmCard } from './components/ConfirmCard/ConfirmCard';

import {CreateLogger,MLModelData ,DataSetCreationMethod} from "@swivl/great-grey-lib"
import { FilePicker } from './components/FilePicker/FilePicker';

const log = CreateLogger("dataset:uploaddata")

interface UploadMLModelProps {
  closeModal():void;
}

interface UploadMLModelState {
  currentCard:string;
  name?:string;
  mlModelData?:MLModelData;
}






export  class UploadMLModel extends React.Component<UploadMLModelProps,UploadMLModelState> {
  constructor(props:any) {
    super(props)
    this.state = {name:"", currentCard:"setName"}
    
    this.nameSet                      = this.nameSet.bind(this);
    
    this.fileProcessed              = this.fileProcessed.bind(this);
    this.backFromFilePicker         = this.backFromFilePicker.bind(this);

    this.backFromConfirm                  = this.backFromConfirm.bind(this);
    this.confirm             = this.confirm.bind(this);

    

  }

  nameSet(name:string) { this.setState({currentCard:"pickFile", name:name});}

  fileProcessed(mlModelData:MLModelData) { this.setState({currentCard:"confirmCard", mlModelData:mlModelData});}
  backFromFilePicker() {this.setState({currentCard:"setName" });  }

  backFromConfirm() {this.setState({currentCard:"pickFile"});  }
  confirm() {this.setState({currentCard:"upload"});  }


  


  render() {


    return <div className="UploadMLModel">
      <ModalCard name="setName" selectedCard={this.state.currentCard}>
        <EnterNameCard 
          nameSet={this.nameSet}
          name={this.state.name}
          cancel={this.props.closeModal}/>
    </ModalCard>
    <ModalCard name="pickFile" selectedCard={this.state.currentCard}>
        <FilePicker fileProcessed={this.fileProcessed} back={this.backFromFilePicker}/>
    </ModalCard>
    <ModalCard name="confirmCard" selectedCard={this.state.currentCard}>
    <ConfirmCard
      confirm={this.confirm}
      back={this.backFromConfirm}
      mlModelData={this.state.mlModelData}

      />
    </ModalCard>
    <ModalCard name="upload" selectedCard={this.state.currentCard}>
      <FileUpload
        name={this.state.name}
        mlModelData={this.state.mlModelData}
        close={this.props.closeModal}
        />
    </ModalCard>


    </div>;
  }

}
