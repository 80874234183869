import * as React from 'react';


import './NavMenu.scss';
import { CreateLogger, MembershipRole } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';
import { ActionsConsumer, Actions, ActionType } from '../../../Actions/Actions';
import { Fade } from '@swivl/great-grey-components';
import NavTray from './Tray/NavTray';


const log = CreateLogger('NavMenu');

interface NavMenuProps {
  isNavMenuShowing:boolean;
  toggleMenu():void 
}

export default class NavMenu extends ActionsConsumer<NavMenuProps> {
    constructor(props:NavMenuProps) {
      super(props)
      this.dashboardClicked     = this.dashboardClicked.bind(this)
      this.tasksClicked         = this.tasksClicked.bind(this)
      this.jobsClicked          = this.jobsClicked.bind(this)
      this.dataSetsClicked     = this.dataSetsClicked.bind(this)
      this.modelsClicked        = this.modelsClicked.bind(this)
      this.settingsClicked      = this.settingsClicked.bind(this)

      Actions.Controller.subscribeTo(this, [
        ActionType.CurrentMembershipUpdated
       ] )
    }
    dashboardClicked() {
      this.props.toggleMenu()
    }
    tasksClicked() {
      this.props.toggleMenu()
    }
    jobsClicked() {
      this.props.toggleMenu()
    }
    dataSetsClicked() {
      this.props.toggleMenu()
    }
    modelsClicked() {
      this.props.toggleMenu()
    }
    settingsClicked() {
      this.props.toggleMenu()
    }

    renderButton() {
      return <div className="buttonLoaded">
      <div className={this.props.isNavMenuShowing ? "navButton openNav" : "navButton closeNav"} onClick={this.props.toggleMenu}>
        <div className="redStick"></div><div className="yellowStick"></div>
      </div>
    </div>
    }

    renderScreen(showing:boolean) {
      
      return <Fade show={showing}><div className="navMenuScreen" onClick={this.props.toggleMenu} >sup</div></Fade>
    }

    render() {
      const {isNavMenuShowing, toggleMenu} = this.props; 

      return <div className="NavMenuWrapper">
        {this.renderScreen(isNavMenuShowing)}
        {this.renderButton()}
        <NavTray isShowing={isNavMenuShowing} toggleMenu={toggleMenu} />

      </div>
    } 
}
