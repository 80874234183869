import React from 'react';
import { OrganisationMembership, Project, User } from '@swivl/great-grey-lib';
import "./NewProjectModal.scss"
import AddUserPopover from '../../../../../Components/AddUserPopover/AddUserPopover';
import { Model } from '../../../../../Models/Model';
import { Input, Option, Select, Button, Color } from '@swivl/great-grey-components';
import { Actions, ActionsConsumer, ActionType } from '../../../../../Actions/Actions';
import Service from '../../../../../Services/Service';

// import * as Model from '../../Models/Model';
interface State {
 projectName:string
 projectLead?:User 
 isCreatingProject:boolean
}
interface Props {

}
export default class NewProjectModal extends ActionsConsumer<Props,State> {
 
  constructor(props:Props) {
    super(props)
    this.state = {projectName:"", isCreatingProject:false}
    this.projectNameUpdated = this.projectNameUpdated.bind(this)
    this.leadChanged        = this.leadChanged.bind(this)
    this.cancelClicked      = this.cancelClicked.bind(this)
    this.createClicked      = this.createClicked.bind(this)

    Actions.Controller.subscribeTo(this, [
        ActionType.OrgMembershipsLoaded,
    ])
    Model.OrgMembership.load()

  }


  projectNameUpdated(e:React.ChangeEvent<HTMLInputElement>) {
    this.setState({projectName:e.target.value})
  }
  leadChanged(option:Option) {
    this.setState({projectLead:option.data})
  }

  cancelClicked() {
      Actions.Controller.trigger({type:ActionType.HideModal})

    
  }
  createClicked() {
      if (this.state.projectName.length < 1) {
        Service.Toast.error("Projects must have a name.")
        return; 
      }
    this.setState({isCreatingProject:true})
    Model.OrgMembership.createProject(this.state.projectName, this.state.projectLead)
    .then((proj) => {
        Actions.Controller.trigger({type:ActionType.HideModal})
    }).catch((_)=> {
        this.setState({isCreatingProject:false})
    })
  }

    render() {
     
    const members = Model.OrgMembership.array;
    console.log("MEMBERSSSS", members, Model.OrgMembership.state.items);
    
    var options:Option[]|undefined = (!members)? null : members.map((item:OrganisationMembership) => {
        if (item.user) {
            if (item.user.firstName) {
                return {label:item.user.firstName + " " + item.user.lastName, value:item.user.id, data:item.user}
             } else if (item.user.lastName) {
                    return {label:item.user.lastName, value:item.user.id, data:item.user}
            } else {
                return {label:item.user.email, value:item.user.id, data:item.user}
            }
        }
    })

  return <div className="NewProjectModal">
      <img src={'/img/nav/icon-models@2x.png'} />
      <h1>Add a Project</h1>
      <label>PROJECT NAME</label>
      <Input onChange={this.projectNameUpdated} />
      <label>PROJECT LEAD</label>
      <Select 
      value={(this.state.projectLead)?this.state.projectLead.id :null}
        options={options} 
        isSearchable={true}
        onChange={this.leadChanged} />
      <div className="buttons">
          <Button onClick={this.cancelClicked} color={Color.Tan} isDisabled={this.state.isCreatingProject}>Cancel</Button>
          <Button onClick={this.createClicked} color={Color.Primary} isLoading={this.state.isCreatingProject}>Create Project</Button>
      </div>
  </div>
}

}
