import React, { ChangeEvent } from 'react';
import { DragableList, Input, InputType, Pill, PillSwitch, Select, Option } from '@swivl/great-grey-components';

import { Job, CreateLogger, Intent, TaskType, TaskData_Intent,MakeDictonaryArrayUnique, TaskData_ImageCategorize, TaskCategory, MLModel } from '@swivl/great-grey-lib';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ActionsConsumer, Actions, ActionType } from '../../Actions/Actions';
import { Model } from '../../Models/Model';
const log = CreateLogger("job:JobTaskIntent");


interface Props { 
    taskType:TaskType 
    job:Job
    maxRedundancy?:number 
    minRedundancy?:number 
}
interface State {

    
}




export  class SelectRedundancyComponent extends ActionsConsumer<Props,State> {
  constructor(props:Props) {
    super(props)
    this.state = { categories:[] }
    // this.toggleIntent = this.toggleIntent.bind(this)
    // this.createIntentsArray = this.createIntentsArray.bind(this)    
  }

  

  onChange = (selectedOption:Option) => {
    const {job,taskType} = this.props; 
    
    job.tasks[taskType].redundancy = parseInt(selectedOption.value); 
    Model.Job.update(job,true);
  }

  renderContent() {
      let min = (this.props.minRedundancy) ? this.props.minRedundancy : 1; 
      let max = (this.props.maxRedundancy) ? this.props.maxRedundancy : 3; 
      let options:Option[] = []
      for (let i = min; i < max+1; i++) {
          options.push( { label:String(i), value:String(i) })
      }  
      return <Select isSearchable={false} options={options} value={String(this.props.job.tasks[this.props.taskType].redundancy)} onChange={this.onChange} />
  }
  render() {
      return <div className="SelectRedundancyComponent"><label>Task Redundancy</label>{this.renderContent()}</div>
  }



}
