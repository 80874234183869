import { Entity, EntityDefinition } from '@swivl/great-grey-lib';
import React from 'react';
import TextToken from './components/TextToken';
import "./TextWithEntities.scss"
import {GGTokenizer, SentenceToken} from './Tokenizer'
import _ from "lodash" 


// import * as Model from '../../Models/Model';
interface Props {
    text:string 
    entities:Entity[]
    Tokenizer?:GGTokenizer
    possibileEntities:EntityDefinition[]
    updateEntities(entities:Entity[])
}
interface State {
    selectionEntity?:Entity 
    selectedEntityForDelete?:Entity
}
export default class TextWithEntities extends React.Component<Props, State> {
    tokens:SentenceToken[]
    colorForEntity = {SPECIAL_SELECTION_TOKEN:{normal:"#5E5450", light:"#5E5450", name:"dark"}}

    constructor(props:Props) {
    super(props)
    this.state = {}
    let Tokenizer =     (props.Tokenizer) ? props.Tokenizer : new GGTokenizer()
    this.tokens = Tokenizer.tokenize(props.text)
    this.tokenClicked = this.tokenClicked.bind(this)
    this.entitySelected = this.entitySelected.bind(this)
    this.deleteEntity = this.deleteEntity.bind(this)
    this.deselect = this.deselect.bind(this)
    let colorIndex = 0; 
    const colors = [
        {normal:"#00CABE", light:"#B0EDE9",  name:"aqua"},
        {normal:"#FD583B", light:"#FFBEB2",  name:"red"},
        {normal:"#BD10E0", light:"#ECCEF2",  name:"purple"},
        {normal:"#0087FF", light:"#AEE3FC", name:"blue"},
        {normal:"#FFA900", light:"#FFDAA4", name:"yellow"},

    ]
    for (let i = 0; i < props.possibileEntities.length; i++) {
       this.colorForEntity[props.possibileEntities[i].name] = colors[colorIndex]
       colorIndex++;
       if (colorIndex >= 5) { colorIndex = 0; }
    }

    
  }
  
  componentDidMount() {

  }
  tokenClicked(token:SentenceToken, shiftKeyPressed:boolean) {
    const {selectionEntity} = this.state;

    
    if (shiftKeyPressed && selectionEntity) {

        
        this.setState({selectionEntity: {
            id:"SPECIAL_SELECTION_TOKEN",
            name:"SPECIAL_SELECTION_TOKEN",
            startChar:(token.startIndex < selectionEntity.startChar) ? token.startIndex : selectionEntity.startChar,
            endChar:(token.endIndex > selectionEntity.endChar) ? token.endIndex : selectionEntity.endChar
        }})
        return; 
    }  

    if (selectionEntity && token.type === 'entity' && token.entity && token.entity.startChar === selectionEntity.startChar) {

        this.setState({selectionEntity:null, selectedEntityForDelete:null})
        return null;
    }

    if (token.type === 'entity' && token.entity) {
        this.setState({selectionEntity:null, selectedEntityForDelete:token.entity})
        return null;
    }

    this.setState({selectionEntity: {
        id:"SPECIAL_SELECTION_TOKEN",
        name:"SPECIAL_SELECTION_TOKEN",
        startChar:token.startIndex,
        endChar:token.endIndex
    }, selectedEntityForDelete:null})



  }
  entitySelected(entity:EntityDefinition) {
    const selectionEntity = this.state.selectionEntity
    let entities = this.props.entities || []; 
    const entityObj = {
        id:entity.id,
        name: entity.name,
        startChar:selectionEntity.startChar,
        endChar:selectionEntity.endChar
    }
    const indexesToRemove = entities.map((v, i) => { if ((v.endChar >= entityObj.startChar && v.endChar <= entityObj.endChar) || (v.endChar >= entityObj.startChar && v.endChar <= entityObj.endChar) ) { return i} })
    _.pullAt(entities, indexesToRemove) 
        
    entities.push(entityObj); 
    this.props.updateEntities(entities);
    this.setState({selectionEntity:null})

  }

  deleteEntity(entity:Entity) {
    let entities = this.props.entities || []; 
    for (let i = 0; i < entities.length; i++) {
        const element = entities[i];
        if (entity.startChar === element.startChar && entity.endChar === element.endChar) {
            entities.splice(i, 1)
            break; 
        }
    }
    this.setState({selectionEntity:null, selectedEntityForDelete:null})

    this.props.updateEntities(entities);

  }
  deselect() {

    
    this.setState({selectionEntity:null, selectedEntityForDelete:null})

  }


  replaceTokensWithEntities() {
      var itemsToReplace:any = {}; 
      var indexesToRemove = []

      var tokens = JSON.parse(JSON.stringify(this.tokens)) // [...this.tokens]
        var entities = this.props.entities;
        if (this.state.selectionEntity) { entities = _.concat(entities, [this.state.selectionEntity]) }

      for (let i = 0; i < tokens.length; i++) {
          const token = tokens[i];
          for (let e = 0; e < entities.length; e++) {
              const entity = entities[e];
              if (token.startIndex <= entity.endChar &&  entity.startChar <= token.endIndex) {
                const key = entity.name + "___" + entity.startChar;
                if (!itemsToReplace[key]) {
                    itemsToReplace[key] = { id:entity.name, entity:entity, indexes:[], tokens:[], hasReplaced:false}
                } else {
                    (tokens[i] as any).shouldDelete = true; 
                    indexesToRemove.push(i)
                }
                itemsToReplace[key].indexes.push(i)

                
                itemsToReplace[key].tokens.push(token)
                tokens[i].entity = entity; 
                continue; 
            }
          }    
      }

      _.forOwn(itemsToReplace, function(value, key) {
            tokens[value.indexes[0]].text = value.tokens.map(i=>i.text).join('')     
            tokens[value.indexes[0]].type = 'entity'   
      });
      _.pullAt(tokens, indexesToRemove)
      
      
      return tokens; 
      
  }

  
  render() {
      const tokens = this.replaceTokensWithEntities()
  const tokenViews = tokens.map(i =>  <TextToken 
                                        key={i.id} 
                                        token={i} 
                                        tokenClicked={this.tokenClicked} 
                                        colorForEntity={this.colorForEntity}
                                        possibileEntities={this.props.possibileEntities}
                                        entitySelected={this.entitySelected}
                                        deleteEntity={this.deleteEntity}
                                        selectedEntityForDelete={this.state.selectedEntityForDelete}
                                        deselect={this.deselect}

                                        />)
    return <div className="TextWithEntities">
      {tokenViews}
    </div>;
  }

}
