import { JobReview } from "@swivl/great-grey-lib";
import Service from "../../Services/Service";

export class JobReviewModel {
    static get Model():JobReviewModel { if (JobReviewModel._Model) { return JobReviewModel._Model; }  else {   JobReviewModel._Model = new JobReviewModel(); return JobReviewModel._Model ; }}
    static reset()  { delete JobReviewModel._Model; }
    private static _Model:JobReviewModel;

    async loadJobReview(jobReviewId):Promise<JobReview> {
        return await Service.API.getItem<JobReview>("job_review", jobReviewId)
    }
    async saveJobReview(jobReview:JobReview):Promise<JobReview> {
        return await Service.API.updateItem<JobReview>("job_review", jobReview)
    }
    
}