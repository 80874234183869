import React, { createRef } from 'react';
import { SizeMe } from 'react-sizeme'

import { CreateLogger } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import "./WaveformWrapper.scss"
import { WaveformView } from '../../WaveformView/WaveformView';
import { PlayheadView } from '../PlayheadView/PlayheadView';
import { AudioPlayer } from '../AudioPlayer';
const log = CreateLogger("WaveformWrapper");


interface WaveformWrapperProps { 
    duration?:number; 
    dataPoints?:number[];
    delegate:AudioPlayer;

}
interface WaveformWrapperState {
}
export class WaveformWrapper extends React.Component<WaveformWrapperProps, WaveformWrapperState> {
  worker:Worker; 
  mainRef = createRef<HTMLDivElement>();

  constructor(props:any) {
    super(props) 
    this.state =  {}

}

 

  
  
  renderContent(size:any) {    
    log("size",  size);

    const width = (size.width) ? size.width : 100;

    let waveFormWidth:any = (this.props.dataPoints) ? this.props.dataPoints.length * 2 : 100;

    const left = Math.max(60, (width/2) - (waveFormWidth/2));

    const playbackAreaStyle = {left: left + "px", width:waveFormWidth  + "px"}

    let style:any = {paddingLeft: "100px"}

    return <div style={style} className="WaveformWrapper" ref={this.mainRef} >
                <div style={playbackAreaStyle}  className="playbackArea">
                <PlayheadView delegate={this.props.delegate} />
          <div className="waveform">
              <WaveformView dataPoints={this.props.dataPoints} />
          </div>
          </div>

            </div>;   
  }

  render() {
    return <SizeMe>{({ size }) => this.renderContent(size) }</SizeMe>
}
}

