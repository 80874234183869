import React from 'react';
import { Button } from '@swivl/great-grey-components';
import './CreationMethod.scss'
import { Model } from '../../../../../Models/Model';
import { DataSetCreationMethod } from '@swivl/great-grey-lib';
import { ActionsConsumer, ActionType, Action, Actions } from '../../../../../Actions/Actions';

interface CreationMethodProps {
  creationMethodSet(creationMethod:string):void;
  back():void;
}

interface CreationMethodState {
}


export  class CreationMethod extends ActionsConsumer<CreationMethodProps,CreationMethodState> {


  constructor(props:any) {
    super(props)
    this.state = {}
    this.onChange        = this.onChange.bind(this);
    this.nextClicked     = this.nextClicked.bind(this);
    Actions.Controller.subscribeTo(this,[ActionType.KeyboardKeyDown]);
  }

  onChange(event:React.ChangeEvent<HTMLInputElement>):void {
    this.setState({name:event.target.value})
  }
  handleAction(action:Action) {
    if (action.type === ActionType.KeyboardKeyDown) {
          if (action.event.key == "Enter") {
            this.nextClicked()
        }
    }
  }



  nextClicked() { this.props.creationMethodSet(DataSetCreationMethod.Upload); }

  render() {

    return <div className="CreationMethod modalCard">
      <div className="modalHeader">
        <h3>What method of ingestion?</h3>
      </div>
      <div className="modalContent">
      <div className="control">
        <label className="radio">
          <input type="radio" name="upload" checked readOnly/>
          Manual Upload
        </label>
        <label className="radio is-disabled">
          <input type="radio" name="api"  disabled/>
          API (Premium only)
        </label>
        <label className="radio is-disabled" >
          <input type="radio" name="cloud"  disabled/>
          Cloud Service (Premium only)
        </label>
      </div>

      </div>
      <div className="modalFooter">
        <div className="buttons">
          <Button isRound={true} isOutlined={true} onClick={this.props.back}>Back</Button>
          <Button  onClick={this.nextClicked} isRound={true}>Next</Button>
        </div>
      </div>
    </div>;
  }

}
