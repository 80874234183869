import { Actions, ActionType } from '../../Actions/Actions';
import { CreateLogger } from '@swivl/great-grey-lib';
const log = CreateLogger("KeyboardModel");

export class KeyboardModel {
  static get Model():KeyboardModel {
    if (KeyboardModel._Model) { return KeyboardModel._Model; }  else {   KeyboardModel._Model = new KeyboardModel(); return KeyboardModel._Model ; }
  }
  private static _Model:KeyboardModel;

  private constructor() {
    document.addEventListener('keydown', (event) => {
      console.log("key downs")
      Actions.Controller.trigger({type:ActionType.KeyboardKeyDown, event:event});
    });
  }

}
