
import React from 'react'; // we need this to make JSX compile
import { OrganisationMembership, MembershipRole, Invite } from '@swivl/great-grey-lib';
import { Select,Option } from '@swivl/great-grey-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import "./InviteRecordView.scss"
import { Model } from '../../Models/Model';
type Props = {
  invite: Invite,
}

export default class InviteRecordView extends React.Component<Props> {
 
    constructor(props:Props) {
      super(props)
      this.revoke = this.revoke.bind(this)
      
    }
    revoke() {
      Model.OrgMembership.revokeInvite(this.props.invite);
    }
      render() {
          const {invite} = this.props; 
        const name = (invite.inviteeEmail) ? invite.inviteeEmail : ""; 

        return <div className="InviteRecordView">
            <div className="squareAvatar"></div>
            <div className="name">{name}</div>
            <div className="email">{invite.createdAt}</div>
            <a className="remove" onClick={this.revoke}>Revoke</a>
        </div>;
  }
  
  }