import React, { createRef } from 'react';
import {NavBar} from './NavBar/NavBar'

import {  Model } from '../../Models/Model';
import {DataSetsScreen} from '../DataSets/DataSetsScreen';
import LoadingScreen from '../Loading/LoadingScreen';
import "./LayoutView.scss"
import { JobsScreen } from '../Jobs/JobsScreen';
import { JobReviewScreen } from '../Jobs/JobReview/JobReviewScreen';
import NavMenu from './NavMenu/NavMenu';
import DashboardScreen from '../Dashboard/DashboardScreen';
import {SubTasksScreen} from '../SubTasks/SubTasksScreen';
import {MLModelsScreen} from '../MLModels/MLModelsScreen';
import SettingsScreen from '../Settings/SettingsScreen';
import {AssignmentScreen} from '../Assignment/AssignmentScreen'
import { ActionsConsumer, Actions, ActionType, Action } from '../../Actions/Actions';
import { CreateLogger, ProjectPermissionType } from '@swivl/great-grey-lib';
import { ImageBoundingBoxScreen } from '../Demo/ImageBoundingBoxScreen';
import OrganisationScreen from '../Org/OrganisationScreen';
import { ReviewTaskScreen } from '../Jobs/ReviewTask/ReviewTaskScreen';
// import InviteScreen from '../Invite/InviteScreen';


const log = CreateLogger("LayoutView");

interface LayoutViewState {
  isNavMenuShowing:boolean;
  path:Array<string>
}
export class LayoutView extends ActionsConsumer<{}, LayoutViewState> {
  private navBar = createRef<NavBar>()

  constructor(props:any) {
    super(props);
    this.state = {isNavMenuShowing:false, path:Model.Navigation.path};
    this.toggleMenu     = this.toggleMenu.bind(this)
    this.setNavBar      = this.setNavBar.bind(this)
    this.route          = this.route.bind(this);

    Actions.Controller.subscribeTo(this,
      [ActionType.NavigationPathUpdated, ActionType.CurrentMembershipUpdated])

  }
  toggleMenu()  { this.setState({isNavMenuShowing:!this.state.isNavMenuShowing})}
  handleAction(action:Action) {
    if (action.type === ActionType.NavigationPathUpdated) { this.setState({path:action.path}) }
  }



  setNavBar(left:any|undefined, center:any|undefined, right:any|undefined) {
    console.log("Nav Bar", this.navBar)
    if (this.navBar &&  this.navBar.current) {
      this.navBar.current.setNavBar(left, center,right);
    }
  }

  route(path?:string) {
    if (path === "dataset" && Model.Session.canAccess(ProjectPermissionType.DataSet)) { return <DataSetsScreen setNavBar={this.setNavBar} />; }
    if (path === "job"      && Model.Session.canAccess(ProjectPermissionType.Job))      { return <JobsScreen setNavBar={this.setNavBar} />; }
    if (path === "task"     && Model.Session.canAccess(ProjectPermissionType.Task))     { return <SubTasksScreen setNavBar={this.setNavBar} />; }
    if (path === "model"    && Model.Session.canAccess(ProjectPermissionType.Model))    { return <MLModelsScreen setNavBar={this.setNavBar} />; }
    if (path === "dataset" && Model.Session.canAccess(ProjectPermissionType.DataSet)) { return <DataSetsScreen setNavBar={this.setNavBar} />; }
    if (path === "settings") { return <SettingsScreen setNavBar={this.setNavBar} />; }
    if (Model.Session.canAccess(ProjectPermissionType.Dashboard)) {  return <DashboardScreen setNavBar={this.setNavBar} /> }
  }


  render() {
    const path = Model.Navigation.first()
    log("Path",path )
    // if (path === "test") {
    //   return <AudioSplitExersiseView />
    // }
    if (path === "image") {
      return <ImageBoundingBoxScreen />
    }
    if (path === "assignment") {
      return <AssignmentScreen />;
    }
    if (path == "job_review") { 
      // return <ReviewTaskScreen />
      return <JobReviewScreen /> 
    }
    if (path == "job-review-task") { 
      return <ReviewTaskScreen />
      // return <JobReviewScreen /> 
    }
    const key = "layout_key" + (Model.Session.state.currentMembership?.id + "_" + Model.Session.state.currentProjectMembership?.id)

    return <div className="LayoutView">
            <NavBar ref={this.navBar} />
            <div className="layoutContent" key={key}>
            {this.route(path)}
            </div>
            <NavMenu toggleMenu={this.toggleMenu}
                          isNavMenuShowing={this.state.isNavMenuShowing}
                          />
            </div>

    // return <div className="layoutview"><NavBar /><div className="content">{this.route()}</div></div>
  }
}
