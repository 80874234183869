import React from 'react';
import { Project, ProjectMembership } from '@swivl/great-grey-lib';
import "./ProjectMemberView.scss"
import { Model } from '../../../../../Models/Model';
import ProjectMemberPermissionsPopover from './ProjectMemberPermissionsPopover/ProjectMemberPermissionsPopover';

// import * as Model from '../../Models/Model';
interface State {
 
    
}
interface Props {
    membership:ProjectMembership
    project:Project
 }
export default class ProjectMemberView extends React.Component<Props,State> {
 
  constructor(props:Props) {
    super(props)
    this.removeMember = this.removeMember.bind(this)
    
  }
  removeMember() {
    Model.OrgMembership.removeUserFromProject(this.props.membership, this.props.project)
  }
    render() {
      const user = this.props.membership.user 
        
  return <div className="ProjectMemberView">
          <div className="squareAvatar"></div>
            <span className="name">{user.firstName} {user.lastName}</span>
            <span className="email">{user.email}</span>
            <span className="location">{user.location}</span>
            <ProjectMemberPermissionsPopover membership={this.props.membership} />
            <a className="remove" onClick={this.removeMember}>Remove</a>

  </div>;
}

}
