import { Entity, EntityDefinition } from '@swivl/great-grey-lib';
import React, { ReactNode } from 'react';
import TextToken from './components/TextToken';
import "./StaticTextwithEntities.scss"
import {GGTokenizer, SentenceToken} from './Tokenizer'
import _ from "lodash" 


// import * as Model from '../../Models/Model';
interface Props {
    text:string 
    entities:Entity[]
    possibileEntities:EntityDefinition[]
}


export default class StaticTextwithEntities extends React.Component<Props> {
    tokens:SentenceToken[]
    colorForEntity = {SPECIAL_SELECTION_TOKEN:{normal:"#5E5450", light:"#5E5450", name:"dark"}}
    textComponent:any[] = []; 
    constructor(props:Props) {
    super(props)

        

    
    
    let colorIndex = 0; 
    const colors = [
        {normal:"#00CABE", light:"#B0EDE9",  name:"aqua"},
        {normal:"#FD583B", light:"#FFBEB2",  name:"red"},
        {normal:"#BD10E0", light:"#ECCEF2",  name:"purple"},
        {normal:"#0087FF", light:"#AEE3FC", name:"blue"},
        {normal:"#FFA900", light:"#FFDAA4", name:"yellow"},

    ]
    for (let i = 0; i < props.possibileEntities.length; i++) {
       this.colorForEntity[props.possibileEntities[i].name] = colors[colorIndex]
       colorIndex++;
       if (colorIndex >= 5) { colorIndex = 0; }
    }

    let {text, entities} = props; 
    entities.sort(function(a:Entity, b:Entity) {
        return a.startChar - b.startChar;
    });

  
    if (entities.length === 0){
        this.textComponent.push(<span className="text" key={"key"}>{props.text}</span>)

    } else {
        let startingIndex = 0; 
        let keyIndex = 0; 
        for (let i = 0; i < entities.length; i++) {
            const entity = entities[i];
            const colors = this.colorForEntity[entity.name]

            if (entity.startChar !== 0) {
                let beforeText = text.substring(startingIndex, (entity.startChar - 1))
                this.textComponent.push(<span className="text" key={"key"+keyIndex}>{beforeText}</span>)
                console.log("Before Text", beforeText);
            }
            let entityText = text.substring(entity.startChar, entity.endChar + 1)
            this.textComponent.push(<span style={{background:colors.light}}
                className="entity" key={"keyE"+keyIndex}>{entityText}<span className="entityName" style={{color:colors.normal}}>{entity.name}</span></span>)
            startingIndex = entity.endChar + 1;
            
            console.log("entityText", entityText);
            if (i === entities.length - 1 && entity.endChar !== entities.length) {
                this.textComponent.push(<span className="text" key={"keyEnd"+keyIndex}>{text.substring(entity.endChar + 1)}</span>)

            }
            keyIndex++; 
        }
    }

    
  }
  
  
  render() {

    return <div className="StaticTextwithEntities">
      {this.textComponent}
    </div>;
  }

}
