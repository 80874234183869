import React from 'react';
import { UploadRowPicker } from './components/UploadRowPicker/UploadRowPicker';
import { FilePicker } from './components/FilePicker/FilePicker';
import { ModalCard } from '../../Layout/Modal/ModalCard';
import { FileUpload } from './components/FileUpload/FileUpload';
import { EnterNameCard } from './components/EnterNameCard/EnterNameCard';
import './UploadDataPoints.scss'
import { CreationMethod } from './components/CreationMethod/CreationMethod';


import {CreateLogger, DataSetCreationMethod,DataSetRow} from "@swivl/great-grey-lib"

const log = CreateLogger("dataset:uploaddata")

interface UploadDataPointsProps {
  closeModal():void;
}

interface UploadDataPointsState {
  currentCard:string;
  rawRows?:Array<Array<any>>
  // These are needed to create the dataset:
  dataSetName?:string;
  creationMethod?:DataSetCreationMethod;
  rows?:Array<DataSetRow>;
}





export  class UploadDataPoints extends React.Component<UploadDataPointsProps,UploadDataPointsState> {
  constructor(props:any) {
    super(props)
    this.state = {dataSetName:null, currentCard:"setName"}
    
    this.nameSet                      = this.nameSet.bind(this);
    
    this.creationMethodSet              = this.creationMethodSet.bind(this);
    this.backFromCreationMethod         = this.backFromCreationMethod.bind(this);


    
    this.fileProcessed                = this.fileProcessed.bind(this);
    this.backFromFilePicker           =  this.backFromFilePicker.bind(this)  

    this.gotRowsAndColumnNames  = this.gotRowsAndColumnNames.bind(this);
    this.backFromRowPicker             = this.backFromRowPicker.bind(this);

  }

  nameSet(name:string) {
    log("nameSet")

    this.setState({currentCard:"creationMethod", dataSetName:name});
  }

  creationMethodSet(creationMethod:DataSetCreationMethod) {
    log("creationMethodSet")
    this.setState({currentCard:"pickFile", creationMethod:creationMethod});
  }
  backFromCreationMethod() { this.setState({currentCard:"setName"});  }

 
  
     backFromFilePicker() {  this.setState({currentCard:"creationMethod"}); }

  fileProcessed(rows:Array<Array<any>>) {
    this.setState({currentCard:"pickRow", rawRows:rows});
  }
  backFromRowPicker() {this.setState({currentCard:"pickFile", rawRows:null});  }
  gotRowsAndColumnNames(rows:Array<DataSetRow>){
    this.setState({currentCard:"upload", rows:rows});
  }


  render() {


    return <div className="UploadDataPoints">
      <ModalCard name="setName" selectedCard={this.state.currentCard}>
        <EnterNameCard 
          nameSet={this.nameSet}
          name={this.state.dataSetName}
          cancel={this.props.closeModal}/>
    </ModalCard>
    <ModalCard name="creationMethod" selectedCard={this.state.currentCard}>
      <CreationMethod
        creationMethodSet={this.creationMethodSet}
        back={this.backFromCreationMethod}
        />
    </ModalCard>


    <ModalCard name="pickFile" selectedCard={this.state.currentCard}>
        <FilePicker fileProcessed={this.fileProcessed} back={this.backFromFilePicker}/>
    </ModalCard>
    <ModalCard name="pickRow" selectedCard={this.state.currentCard}>
      <UploadRowPicker 
      rawRows={this.state.rawRows} 
      back={this.backFromRowPicker}
      gotRowsAndColumnNames={this.gotRowsAndColumnNames} />
    </ModalCard>
    <ModalCard name="upload" selectedCard={this.state.currentCard}>
      <FileUpload
        dataSetName={this.state.dataSetName}
        creationMethod={this.state.creationMethod}
        rows={this.state.rows}
        close={this.props.closeModal}
        />
    </ModalCard>


    </div>;
  }

}
