import fetchProgress from 'fetch-progress';
import { Config } from '../Models/Models/Config/Config';
import { SigningRequestResponse,DataFileType, CreateLogger } from '@swivl/great-grey-lib';
import { Model } from '../Models/Model';
import Service from './Service';
const log = CreateLogger("FileService")

export interface FileUploadResponse {
    fileName:string,
    url:string
}

export class FileService {
    static get Service():FileService {   if (FileService._Service) { return FileService._Service; }  else {   FileService._Service = new FileService(); return FileService._Service ; } }
    private static _Service:FileService;
    private constructor() { }
    
    mediaURL(url:string) {
        if (url.includes("http")) { return url} 
        return `https://${Config.serverAddress}/api/v1/image/${encodeURIComponent(url)}?${Service.API.queryHeaders()}`
    }

    fetchFile<T>(fileName:string, progressCallback:(percentage:number)=>void):Promise<T> {
        return fetch(`${Config.fileServerURL}/${fileName}`)
          .then(fetchProgress({onProgress(progress){
            //    progressCallback(progress.percentage)
            }}))
          .then((results) => {
            return results.json();
          });
    }


    async uploadFile(fileName:string, type:DataFileType, isUpdate:boolean, data:any, progressCallback:(percentage:number)=>void):Promise<FileUploadResponse> {
        return new Promise(async (resolve, reject) => { 
            var stringData; 
            try {
                stringData = JSON.stringify(data, null, 2);
                const signedRequest = await Service.API.getSignedRequest(fileName,type,isUpdate);
                var file = new File([stringData], fileName, {type:"application/json"});

                const xhr = new XMLHttpRequest();

                xhr.open('PUT', signedRequest.signedRequest);

                xhr.upload.onprogress =  (evt) => { 
                    if (evt.lengthComputable) { progressCallback((evt.loaded / evt.total) * 100)  } 
                }

                xhr.onreadystatechange = () => {
                if(xhr.readyState === 4){
                    if(xhr.status === 200){  
                        resolve({
                            fileName:signedRequest.fileName,
                            url:signedRequest.url
                        }); 
                    } else{ 
                        reject(new Error("Could Not Upload The File"));
                    }
                }
                };
                xhr.send(file);
            } catch(e) {
                log.warn("Error With Upload",  e)
                reject(new Error("Error Processing File"));

        }
    })

}

}



