import React from 'react';
import { SelectionList,Option } from '@swivl/great-grey-components';
import { CreateLogger,Assignment,WorkRowStatus,CreateRowChange_Intent, TaskData_Intent, AssignmentWorkRow, Intent, DataSetRow } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../../Screens/Assignment/Exercises/Card/ExerciseCard';
import "./IntentExerciseView.scss"
const log = CreateLogger("IntentExercise:Main");




export class IntentExerciseView extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  constructor(props:any) {
    super(props) 

    // let intents = (this.props.assignment.task as TaskData_Intent).intents;
    // let row:AssignmentWorkRow = props.work;
    this.optionSelected = this.optionSelected.bind(this)

  }
  handleKeyDown(event:KeyboardEvent)  {
    log("Key Down");
    if (!this.state.isShowing) {
      log("I'm not supposed to be showing");
    }
    if  (event.keyCode === 13) {
      let row:AssignmentWorkRow = this.props.work;
      if (this.hasUpdated && this.selectedIntent) {
        row.change = CreateRowChange_Intent(this.selectedIntent)
      } else {
        const intents = (this.props.assignment.task as TaskData_Intent).intents;
        row.change = CreateRowChange_Intent(intents[0])
      } 
      log("Change", row.change)
      this.props.save(row);
    }
  }
  
  optionSelected(selectedOption:Option) {
    log("Option Selected Selected Option", selectedOption.data)
    this.hasUpdated = true; 
    this.selectedIntent = selectedOption.data;

    // let row:AssignmentWorkRow = this.props.work;
    // row.change = CreateRowChange_Intent(selectedOption.data) ;
    // this.props.update(row);
  }


  renderContent() {
    let intents = (this.props.assignment.task as TaskData_Intent).intents;
    const options = intents.map((item) => {return { value: item.name, label: item.name, data:item }})
    log("the assignment", this.props.assignment, this.props.work.originalRowId)
    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
    
    return <div className="IntentExerciseView">
              <div className="utteranceRow">
                <div className="utterance">
                  “{originalRow.text}”
                </div>
              </div>
              <div className="selectionRow">
              <SelectionList options={options} optionSelected={this.optionSelected} />

              </div>
     
    </div>   
  }
}
