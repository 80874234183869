import React from 'react';
import {  Model } from '../../Models/Model';
import { SortAndSearch, ListItem, ListItemContentTextAndFooter, NavTextButton} from '@swivl/great-grey-components';


import {Job, CreateLogger, GGDictionary } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {JobDetailsView} from './JobDetailsView/JobDetailsView'
import { ActionsConsumer, Actions, ActionType, Action } from '../../Actions/Actions';
import { BaseScreenWithColumns, BaseScreenWithColumnsState } from '../Base/BaseScreenWithColumns';
import { A } from 'hookrouter';



const moment = require('moment');
const log = CreateLogger("job:details")
const CreateJobText = "+ Create Job";

interface JobsScreenProps {
  setNavBar(left:any|undefined, center:any|undefined, right:any|undefined);
}
interface JobsScreenState extends BaseScreenWithColumnsState {
  jobs?:Array<Job>;
  selectedJob?:Job;
  searchQuery:string;
}

export  class JobsScreen extends BaseScreenWithColumns<JobsScreenProps, Job> {
  path:string = "job"; 
  model = "Job";
  constructor(props:JobsScreenProps) {
    super(props)
    this.state = { ...this.state, searchQuery:"" }
    this.createNewJob     = this.createNewJob.bind(this)
 
    

    Actions.Controller.subscribeTo(this,
      [
        ActionType.JobUpdated, 
        ActionType.JobDeleted,
        ActionType.JobCreated,
        ActionType.JobsLoaded, 
        ActionType.JobFetched,
        ActionType.NavigationPathUpdated])

          Model.Job.load(true);


  }

  // get items():GGDictionary<Job>|undefined { return Model.Job.state.items; }

  
  componentDidMount() {
    this.props.setNavBar(null,<h3>Jobs</h3>,<NavTextButton onClick={this.createNewJob} text={CreateJobText} />)
    // Model.Job.load();
  }


  // searchOnChange(event:any):void { this.setState({searchQuery:event.target.value}) }

  createNewJob() {
    log("Create New Job Clicked");
    this.props.setNavBar(null,<h3>Jobs</h3>,<NavTextButton isLoading={true} text={CreateJobText} />)
    Model.Job.createNewJob().then((_)=>{ 
      this.props.setNavBar(null,<h3>Jobs</h3>,<NavTextButton isLoading={false} text={CreateJobText} />)
    })
  }

  renderItem(item:Job) {
    return <ListItemContentTextAndFooter
                    text={item.name}
                    footerText={item.status}
                     />
  }
  renderDetails(item:Job) {
   return <JobDetailsView  job={item} />
  }
 
  
}
