import React from 'react';
import { PermissionLevel, SetProjectMembershipTypeForMembership, ProjectMembership, ProjectMembershipType, ProjectMembershipTypeForMembership } from '@swivl/great-grey-lib';
import "./ProjectMemberPermissionsPopover.scss"
import { Popover, Switch } from '@swivl/great-grey-components';
import { faChevronDown, faChevronUp, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Model } from '../../../../../../Models/Model';
// import * as Model from '../../Models/Model';


interface Props {
    membership:ProjectMembership
 }
 interface State {
  isPopoverOpen:boolean;
  showCustom:boolean
  
}
export default class ProjectMemberPermissionsPopover extends React.Component<Props,State> {
 
  constructor(props:Props) {
    super(props)
    // const showCustom = (ProjectMembershipTypeForMembership(props.membership) === ProjectMembershipType.Custom)

    this.state = {isPopoverOpen:false,showCustom:false}
    this.setIsPopoverOpen = this.setIsPopoverOpen.bind(this)
    this.customSwitchOnChange = this.customSwitchOnChange.bind(this)
    this.showCustom = this.showCustom.bind(this)
    this.hideCustom = this.hideCustom.bind(this)
    this.setLeadPermissions = this.setLeadPermissions.bind(this)
    this.setMemberPermissions = this.setMemberPermissions.bind(this)
    this.setLabelerPermissions = this.setLabelerPermissions.bind(this)
  }

  setIsPopoverOpen(isPopoverOpen:boolean) { 
    console.log("set", isPopoverOpen);
    
    this.setState({isPopoverOpen:isPopoverOpen})
  }

  customSwitchOnChange(name:string, on:boolean) {
    console.log("customSwitchOnChange", name,on);
    
    var membership = this.props.membership
    membership[name] = (on) ? PermissionLevel.Full : PermissionLevel.None;
    Model.OrgMembership.updateProjectMembership(membership)
  }
  setLeadPermissions() {
    Model.OrgMembership.updateProjectMembership( SetProjectMembershipTypeForMembership(ProjectMembershipType.Lead,this.props.membership))
    this.setState({isPopoverOpen:false})
  }
  setMemberPermissions() {
    Model.OrgMembership.updateProjectMembership( SetProjectMembershipTypeForMembership(ProjectMembershipType.Member,this.props.membership))
    this.setState({isPopoverOpen:false})
  }
  setLabelerPermissions() {
    Model.OrgMembership.updateProjectMembership( SetProjectMembershipTypeForMembership(ProjectMembershipType.Labeler,this.props.membership))
    this.setState({isPopoverOpen:false})

  }

  showCustom(e:React.MouseEvent) {
    e.preventDefault()
    setTimeout(()=> {
      //NOTE: for some reason i need to delay this or it closed the popover
      this.setState({showCustom:true})

    },100)
  }
  hideCustom(e:React.MouseEvent) {
    e.preventDefault()
    setTimeout(()=> {
      //NOTE: for some reason i need to delay this or it closed the popover
      this.setState({showCustom:false})

    },100)
  }
  renderContent(membership:ProjectMembership) {
    if (this.state.showCustom) {
    return  <div className="customOptions popoverPanel">
     <FontAwesomeIcon icon={faArrowLeft} onClick={this.hideCustom} />
     <h3>Custom Options</h3>
      <div className="optionRow">
      Dashboard <Switch 
                  name="dashboardPermissions"
                  onChange={this.customSwitchOnChange}
                 on={(membership.dashboardPermissions === PermissionLevel.Full)} /> 

      </div>

      <div className="optionRow">
      Jobs <Switch 
                  name="jobPermissions"
                  onChange={this.customSwitchOnChange}
                 on={(membership.jobPermissions === PermissionLevel.Full)} /> 

      </div>

      <div className="optionRow">
      Data Set <Switch 
                  name="dataSetPermissions"
                  onChange={this.customSwitchOnChange}
                 on={(membership.dataSetPermissions === PermissionLevel.Full)} /> 

      </div>

      <div className="optionRow">
      Models <Switch 
                  name="modelPermissions"
                  onChange={this.customSwitchOnChange}
                 on={(membership.modelPermissions === PermissionLevel.Full)} /> 

      </div>

      <div className="optionRow">
      Tasks <Switch 
                  name="taskPermissions"
                  onChange={this.customSwitchOnChange}
                 on={(membership.taskPermissions === PermissionLevel.Full)} /> 

      </div>

      </div>
    } 
    return <div className="normalOptions popoverPanel">
           <h3>User Permissions</h3>

      <div className="permissionsButton" onClick={this.setLeadPermissions}>
          Lead <span>(Project Admin)</span>
      </div>
      <div className="permissionsButton" onClick={this.setMemberPermissions}>
          Member <span>(All Features)</span>
      </div>
      <div className="permissionsButton" onClick={this.setLabelerPermissions}>
          Labeler <span>(Just Tasks)</span>
      </div>
      <div className="permissionsButton" onClick={this.showCustom}>
          Custom <span>(You Choose)</span>
      </div>
    </div>
  }


  render() {
  const {isPopoverOpen} = this.state
  const {membership} = this.props
  const content = this.renderContent(membership)

  const chevron = (isPopoverOpen) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />
    return <div className="ProjectMemberPermissionsPopover"><Popover
        toggle={this.setIsPopoverOpen}
        isOpen={isPopoverOpen}
        align="end"
        position={['bottom', 'left']} // preferred position
        content={content}>
        <div className={(isPopoverOpen)?"isOpen popoverButton":"popoverButton"} style={{ float: "left" }} onClick={() => 
{            // this.setIsPopoverOpen(!isPopoverOpen)
}            }>

  {ProjectMembershipTypeForMembership(membership)}
  {chevron}
       
        </div>
    </Popover>
    </div>
}

}
