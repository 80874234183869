import React from 'react';
import { NavTextButton } from '@swivl/great-grey-components';
import { CreateLogger,Assignment,WorkRowStatus } from '@swivl/great-grey-lib';
import moment from 'moment'

import "./AssignmentProgress.scss";
import { ElapsedTime } from './components/ElapsedTime';
const log = CreateLogger("Assignment:Screen");

interface Props {
    numberOfItems?:number 
    completed?:number 
    createdAt?:Date 
    assignment?:Assignment;
}


export class AssignmentProgress extends React.Component<Props> {
  // constructor(props:any) {
  //   super(props) 
  // }
  
  
  
  render() {
    let { numberOfItems, completed,  createdAt,   assignment }  = this.props

    if (assignment) {

      // const assignment =  this.props.assignment; 
      const work = assignment.work; 
      completed = 0;
      for (const id in work) {  if (work.hasOwnProperty(id)) {
          if (work[id].change) {
              completed++;
          }
      }}
      createdAt = assignment.createdAt
      numberOfItems = assignment.rowCount
    }

    const elapsedTime = (createdAt) ? <ElapsedTime startDate={createdAt} /> : null

    const percentageDone = Math.max(0.1, ((completed + 1) / numberOfItems)  * 100);
    return <div className="AssignmentProgress">
        <div className="textRow">
            <div className="progressText">{completed}/{numberOfItems} Completed</div>
            {elapsedTime}
            </div>
            <div className="progressBar"><div style={{width:percentageDone+"%"}} className="progress"/></div>


    </div>   
  }

}
