import React from 'react';
import ReactPaginate from 'react-paginate';

import './ReviewTaskScreen.scss';

import "../../Assignment/Exercises/Card/ExerciseCard.scss"
import { Job,CreateLogger, JobReview, DataSetRow, AssignmentWorkRow, ChangeStatus, ReviewRow, TaskType, TaskData_Intent } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';
import { ActionsConsumer, ActionType, Actions } from '../../../Actions/Actions';
// import { JobReviewItem } from './components/JobReviewItem';
// import { reviewRows } from './TestData'
import { Button, NavTextButton,Option, Size, Switch } from '@swivl/great-grey-components';
import { NavBar } from '../../Layout/NavBar/NavBar';
import LoadingScreen from '../../Loading/LoadingScreen';
import Service from '../../../Services/Service';
import { TaskViews } from '../../../TaskViews/TaskViews';
import { AssignmentProgress } from '../../Assignment/components/AssignmentProgress/AssignmentProgress';
import InstructionsView from '../../Assignment/Exercises/components/InstructionsView/InstructionsView';
import { threadId } from 'worker_threads';
import { ReviewTaskBase } from '../../../TaskViews/Base/ReviewTaskBase';
const log = CreateLogger("job:JobReviewScreen")

const NUM_PER_PAGE = 50; 
export type ByTaskType = { [type: string]: ReviewRow[] }

interface Props {

}


interface State {
  isSaving:boolean;
  isDirty:boolean
  isFinished:boolean; 
  jobReview?:JobReview;
  // labeled?:ByTaskType;
  // escalated?:ByTaskType; 

  escalations?:ReviewRow[]
  currentEscalationIndex:number
  
  // totalUnresolved?:number; 
  // unresolvedEscalations?:number;

  // filterReviewed:boolean; 
  // currentPage:number;

  instructionsExpanded:boolean
  
}

export class ReviewTaskScreen extends ActionsConsumer<Props,State> {
    get job():Job|undefined {  return ( Model.Navigation.second() && Model.Job.state.items && Model.Job.state.items[Model.Navigation.second()]) ?  Model.Job.state.items[Model.Navigation.second()] : null }
    startTime:Date 
    currentRef?:React.RefObject<ReviewTaskBase> = React.createRef<ReviewTaskBase>()


    constructor(props:any) {
    super(props)
    this.state = { isFinished:false, isDirty:false, currentEscalationIndex:0, isSaving:false, instructionsExpanded:true};
    this.exitClicked = this.exitClicked.bind(this)
    this.saveAndExitClicked = this.saveAndExitClicked.bind(this);
    this.escalationUpdated  = this.escalationUpdated.bind(this)
    this.toggleInstuctions = this.toggleInstuctions.bind(this)
    this.prevClicked = this.prevClicked.bind(this)
    this.nextClicked = this.nextClicked.bind(this)
    this.finishClicked = this.finishClicked.bind(this)

    Actions.Controller.subscribeTo(this, [
      ActionType.NavigationPathUpdated,
      ActionType.JobsLoaded, 
      ActionType.JobFetched

    ])
    Model.Job.load();
    Model.Instructions.load()

    this.startTime = new Date()

    this.load()
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.filterReviewed !== this.state.filterReviewed) { 
  //     // this.renderNavBar();
  //   }

  // }
  toggleInstuctions() { this.setState({instructionsExpanded:!this.state.instructionsExpanded})}
  prevClicked() { 
    this.setState({currentEscalationIndex:this.state.currentEscalationIndex - 1, isFinished:false})
  }
  nextClicked() { 


    const rowToUpdate = this.currentRef?.current?.getUpdatedReviewRow()
    if (rowToUpdate) {
      this.updateRow(rowToUpdate)
    }
    this.setState({currentEscalationIndex:this.state.currentEscalationIndex + 1})
  }
  finishClicked() {
    const rowToUpdate = this.currentRef?.current?.getUpdatedReviewRow()
    if (rowToUpdate) {
      this.updateRow(rowToUpdate)
    }
    this.setState({currentEscalationIndex:this.state.currentEscalationIndex + 1, isFinished:true})
  }

  load() {
    Model.JobReview.loadJobReview(Model.Navigation.second()).then((jobReview) => {
      log("Got Job Review", jobReview);
      const rows = jobReview.rows;
      var escalations:ReviewRow[] = []
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        if (item.changeStatus === ChangeStatus.UnresolvedEscalation) {
          escalations.push(item);
        } 
      }
      Model.Job.fetchById(jobReview.jobId)
      this.setState({jobReview:jobReview,escalations:escalations})
    }).catch(Service.Toast.error);

  }

  escalationUpdated(row:ReviewRow) {
    let escalations = this.state.escalations;
    for (let i = 0; i < escalations.length; i++) {
      if (escalations[i].originalRow.id ===  row.originalRow.id) {
        escalations[i] = row;
        break;
      }
    }
    this.setState({escalations:escalations})
  }
  exitClicked() {
    var {jobReview} = this.state;
    Model.Navigation.setPath("/job/" + jobReview.jobId); 

  }
  async saveAndExitClicked(){
    this.setState({isSaving:true});
    var {jobReview} = this.state;
    const rows = jobReview.rows;
    var escalations:ReviewRow[] = []
    var unresolvedEscalations = 0; 
    for (let i = 0; i < rows.length; i++) {
      const item = rows[i];
      if (item.changeStatus === ChangeStatus.UnresolvedEscalation) {
        escalations.push(item);
        unresolvedEscalations++; 
      } 
    }
    try {
      
   
    await Model.JobReview.saveJobReview(jobReview)
    
    // .then((_:boolean) => {
      let job = Model.Job.state.items[jobReview.jobId]
      if  (job) {
        job.unresolvEdescalations = unresolvedEscalations;
        await Model.Job.update(job,  true)
      }
     
      Model.Navigation.setPath("/job/" + jobReview.jobId); 
    } catch (e) {
      
          Service.Toast.error(e);
      this.setState({isSaving:false});

    }


  }


  
  


  
  getNextReviewRow(currentEscalationIndex:number, escalations?:ReviewRow[]):ReviewRow|undefined {
    if (!escalations) { return undefined; }
    if (currentEscalationIndex < 0 || currentEscalationIndex >= escalations.length ) { return undefined}
    return escalations[currentEscalationIndex];
  }

  updateRow(reviewRow:ReviewRow) {
    const {jobReview} = this.state; 
    const rows = jobReview.rows;
    for (let i = 0; i < jobReview.rows.length; i++) {
      if (jobReview.rows[i].originalRow.id === reviewRow.originalRow.id &&
        jobReview.rows[i].type === reviewRow.type) {

          
          jobReview.rows[i] = reviewRow;
          this.setState({jobReview:jobReview, isDirty:true})
          return;
        }
      
    }

  }
  
  renderHeader(isDirty:boolean, isFinished:boolean, currentEscalationIndex:number, jobReview?:JobReview, escalations?:ReviewRow[]) {
    let center; 

    
    const left = (isDirty) ? <NavTextButton onClick={this.saveAndExitClicked} text={"Save & Exit"} isLoading={this.state.isSaving} /> : <NavTextButton onClick={this.exitClicked} text={"Exit"} isLoading={this.state.isSaving} />
    if (jobReview) {
      const completed = (isFinished) ? escalations.length : currentEscalationIndex;
      center = <AssignmentProgress createdAt={this.startTime} numberOfItems={escalations.length} completed={completed  }/>
    }
    return <NavBar 
            left={left}
            center={center}
            />
            // right={<div>Filter Reviewed <Switch on={this.state.filterReviewed} name="isFiltering" onChange={this.filterToggled} /></div>}

  }

  renderNav(currentEscalationIndex:number, escalations?:ReviewRow[]) {
    const left = (currentEscalationIndex > 0) ? <div className="ExerciseNavButton" onClick={this.prevClicked}>PREV</div> : null;
    let right; 
    if (escalations && currentEscalationIndex + 1 < escalations.length) {
      right = <div className="ExerciseNavButton" onClick={this.nextClicked}>NEXT</div>;
    } else {
      right = <div className="ExerciseNavButton" onClick={this.finishClicked}>FINISH</div>;

    }
    // const right = (escalations && currentEscalationIndex <= escalations.length) ? <div className="ExerciseNavButton" onClick={this.nextClicked}>NEXT</div> : null;
    return <div className="ExerciseNav">
    <div className="col leftCol">{left}</div>
    <div className="col centerCol"><div><h2>Escalation Review</h2></div></div>
    <div className="col  rightCol">{right}</div>
</div>;   
  }


  renderLoading() { return <div className="loadingReview"><span>🦉</span></div> }
  renderAccessDenied() { return <div className="notAllowed"><h1>You don't seem to have access to this job.</h1></div> }
  renderFinished() {
    return <div  className="FinishCard ExerciseCard isShowing">
      <div className="imageWrapper">
        <img src="/img/greatgrey.png" width="400" height="400" />
      </div>
      <h2>YOU FINISHED!</h2>

      <Button isRound size={Size.Large} onClick={this.saveAndExitClicked} isLoading={this.state.isSaving} >Save & Exit</Button>

    </div>;
  
  }
  render() {
    const { isDirty, isFinished, escalations, currentEscalationIndex, instructionsExpanded, jobReview} = this.state;

  



    let instructionsView;

    let content:any = (!jobReview || !Model.Job.state.items) ? this.renderLoading() : null;

    if (isFinished ) {

      return <div className="ReviewTaskScreen">
      {this.renderHeader(isDirty,isFinished,currentEscalationIndex, jobReview, escalations)}
      {this.renderNav(currentEscalationIndex,escalations)}
     <div className={"ExercisesView"}>
      <div className="cardsAndInstructions">
            {instructionsView}

          <div className="cardsWrapper">
          <div className="ExerciseCard isShowing">

        {this.renderFinished()}
        </div>
        </div>
        </div>      </div>
    </div>;
    } else {

    
      if (jobReview && Model.Job.state.items &&  !Model.Job.state.items[jobReview.jobId]) {
        content = this.renderAccessDenied();
      } 
      if (jobReview && Model.Job.state.items) {
      const job = Model.Job.state.items[jobReview.jobId]
      const reviewRow = this.getNextReviewRow(currentEscalationIndex, escalations) 

      let instructionsId; 
      if (reviewRow && job.tasks) {
        content = TaskViews.ResolutionTask(this.currentRef, job, reviewRow, this.nextClicked)

        instructionsId = job.tasks[reviewRow.type].instructionsId;
        
      }
        instructionsView = (instructionsId) ? <InstructionsView instructionsId={instructionsId} instructionsExpanded={this.state.instructionsExpanded} toggleInstuctions={this.toggleInstuctions} /> : null;
      }

    }
    const className = (this.state.instructionsExpanded) ? "ExercisesView instructionsExpanded" : "ExercisesView"


    return <div className="ReviewTaskScreen">
      {this.renderHeader(isDirty,isFinished,currentEscalationIndex, jobReview, escalations)}
      {this.renderNav(currentEscalationIndex,escalations)}
     <div className={className}>
      <div className="cardsAndInstructions">
            {instructionsView}

          <div className="cardsWrapper">
          <div className="ExerciseCard isShowing">

        {content}
        </div>
        </div>
        </div>      </div>
    </div>;
  }

}
