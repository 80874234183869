import React from "react";
import { Job,TaskType, UnreachableCaseError, Assignment, AssignmentWorkRow, ReviewRow } from "@swivl/great-grey-lib";

import { IntentTaskCreationView } from "./Intent/IntentTaskCreationView/IntentTaskCreationView";
import { EntityTaskCreationView } from "./Entity/EntityTaskCreationView/EntityTaskCreationView";
import { IntentTaskJobStartedView } from "./Intent/IntentTaskJobStarted/IntentTaskJobStartedView";
import { ExerciseCard, ExerciseCardProps } from "../Screens/Assignment/Exercises/Card/ExerciseCard";
import { IntentExerciseView } from "./Intent/exercises/IntentExerciseView/IntentExerciseView";
import { AudioCategorizeCreationView } from "./AudioCategorize/AudioCategorizeCreationView/AudioCategorizeCreationView";
import { AudioCategorizeJobStartedView } from "./AudioCategorize/AudioCategorizeJobStarted/AudioCategorizeJobStartedView";
import { AudioCategorizeAndSplitExercisesView } from "./AudioCategorizeAndSplitExercises/AudioCategorizeAndSplitExercisesView";
import { AudioCateforizeAndSplitExerciseCard } from "./AudioCategorizeAndSplitExercises/AudioCateforizeAndSplitExerciseCard";
import { TranscribeExerciseView } from "./Transcribe/Exercises/TranscribeExercise";
import { IntentReviewItem } from "./Intent/review/IntentReviewItem";
import { TranscribeReviewItem } from "./Transcribe/Review/TranscribeReviewItem";
import { SentimentGroupExerciseView } from "./Sentiment/Exercises/SentimentGroupExerciseView";
import { ImageCategorizeCreateView } from "./ImageCategorize/Create/ImageCategorizeCreateView";
import { ImageCategorizeExerciseView } from "./ImageCategorize/Exercise/ImageCategorizeExerciseView";
import { ImageCategorizeReviewItem } from "./ImageCategorize/Review/ImageCategorizeReviewItem";
import { ImageBoundingBoxCreateView } from "./ImageBoundingBox/Create/ImageBoundingBoxCreateView";
import { ImageBoundingBoxExerciseView } from "./ImageBoundingBox/Exercise/ImageBoundingBoxExerciseView";
import { EntityExerciseView } from "./Entity/Exercise/EntityExerciseView";
import { EntityReviewItem } from "./Entity/Review/EntityReviewItem";
import { TextCategorizeCreateView } from "./TextCategorize/Create/TextCategorizeCreateView";
import { TextCategorizeExerciseNav, TextCategorizeExerciseView } from "./TextCategorize/Exercise/TextCategorizeExerciseView";
import { ExerciseNav, ExerciseNavProps } from "../Screens/Assignment/Exercises/components/ExerciseNav/ExerciseNav";
import { TextCategorizeResolutionView } from "./TextCategorize/Resolution/TextCategorizeResolution";
import { ReviewTaskBase } from "./Base/ReviewTaskBase";


export interface ReviewItemViewProps {  
    row:ReviewRow 
    job:Job
    // intentOptions:Option[]
    updatedRow(row:ReviewRow)
  }




export class TaskViews {
    static ExerciseTitle(assignment:Assignment):string {
        if (assignment.task.taskHeading) {
            return assignment.task.taskHeading
        }

        switch (assignment.type) {
            case TaskType.Split:
                return "Split this text"
            case TaskType.Sentiment:
                return "What is the Sentiment?"
            case TaskType.Intent: 
                return "What is the intent?"
            case TaskType.Entity:
                return "Label all Entities"
            case TaskType.TextCategorize:
                    return "Categorize Text"
            case TaskType.ImageCategorize: 
                return "Categorize Image"
            case TaskType.ImageBoundingBox:
                 return "Bound Image"
            case TaskType.AudioCategorize: 
                return "Categorize Speakers"
            case TaskType.AudioSplit: 
                return "Categorize Speakers"
            case TaskType.Transcribe:
                return "Transcribe Speaker"  
            default:  
                throw new UnreachableCaseError(assignment.type);
        }
    }
    static Exercise(props:ExerciseCardProps):any|undefined {

            console.log("props.assignment.type",props.assignment.type)
        switch (props.assignment.type) {
            case TaskType.Split:
                return undefined
            case TaskType.Sentiment:
                return <SentimentGroupExerciseView key={props.id} {...props}  />
            case TaskType.Intent: 
                return <IntentExerciseView key={props.id} {...props}  />
            case TaskType.Entity:
                return <EntityExerciseView  key={props.id} {...props} />
            case TaskType.TextCategorize:
                return <TextCategorizeExerciseView  key={props.id} {...props} />;

            case TaskType.ImageCategorize:
                return <ImageCategorizeExerciseView  key={props.id} {...props} />
            case TaskType.ImageBoundingBox:
                return <ImageBoundingBoxExerciseView   key={props.id} {...props} />
            case TaskType.AudioCategorize:
                return <AudioCateforizeAndSplitExerciseCard  key={props.id} {...props} />
            case TaskType.AudioSplit:
                return <AudioCateforizeAndSplitExerciseCard  key={props.id} {...props} />
            case TaskType.Transcribe:
                return <TranscribeExerciseView  key={props.id} {...props} />
            default:  
                throw new UnreachableCaseError(props.assignment.type);
        }
    }


    
    static ExerciseNav(props:ExerciseNavProps):any|undefined {

        console.log("props.assignment.type",props.assignment.type)
    switch (props.assignment.type) {
        case TaskType.Split:
        case TaskType.Sentiment:
        case TaskType.Intent: 
        case TaskType.Entity:
        case TaskType.ImageCategorize:
        case TaskType.ImageBoundingBox:
        case TaskType.AudioCategorize:
        case TaskType.AudioSplit:
        case TaskType.Transcribe:
            return <ExerciseNav {...props} />
        
        case TaskType.TextCategorize:
            return <TextCategorizeExerciseNav  {...props} />;

            
        default:  
            throw new UnreachableCaseError(props.assignment.type);
    }
}

  
x       

    static Creation(taskType:TaskType, job:Job):React.ReactNode {
        if (!job.tasks[taskType]) { return null}
        switch (taskType) {
            case TaskType.Split:
                return <div />;
            case TaskType.Sentiment:
                return <div />;
     

            case TaskType.ImageCategorize:
                return <ImageCategorizeCreateView job={job} />
            case TaskType.ImageBoundingBox:
                return <ImageBoundingBoxCreateView job={job} />;
            case TaskType.Intent: 
                return <IntentTaskCreationView job={job} />;
            case TaskType.Entity:
                return <EntityTaskCreationView job={job} />
            case TaskType.TextCategorize:
                return <TextCategorizeCreateView job={job} />
            case TaskType.AudioCategorize:
                return <AudioCategorizeCreationView job={job} />;
            case TaskType.AudioSplit:
                return <div />;
            case TaskType.Transcribe:
                return <div />;
            default:  
                throw new UnreachableCaseError(taskType);
        }
    }

    static JobStarted(taskType:TaskType, job:Job):React.ReactNode {
        if (!job.tasks[taskType]) { return null}
        switch (taskType) {
            case TaskType.Split:
                return <div />;
            case TaskType.Sentiment:
                return <div />;
            case TaskType.Intent: 
                return <IntentTaskJobStartedView job={job} />
            case TaskType.Entity:
                return <div />;
            case TaskType.TextCategorize:
                return <div />;
            case TaskType.ImageCategorize:
                return <div />;
            case TaskType.ImageBoundingBox:
                return <div />;
            case TaskType.AudioCategorize:
                return <AudioCategorizeJobStartedView job={job} />
            case TaskType.AudioSplit:
                return <div />;
            case TaskType.Transcribe:
                return <div />;
  
            default:  
                throw new UnreachableCaseError(taskType);
        }
    }




    static ReviewItem(job:Job, row:ReviewRow ,update:(row:ReviewRow)=>void    ):React.ReactNode {
        const  key = row.type + "_" + row.originalRow.id;
        if (!job || !job.tasks)  { console.warn("Missing Data", job, row); return null}
        if (!job.tasks[row.type]) { console.warn("Missing Task Type", row); return null}
        console.log("Review item", row.type);
        
        switch (row.type) {
            case TaskType.Split:
                return <div />;
            case TaskType.Sentiment:
                return <div />;
            case TaskType.Intent: 
                return <IntentReviewItem key={key}  job={job}  row={row} updatedRow={update} />
            case TaskType.Entity:
                return <EntityReviewItem key={key}  job={job}  row={row} updatedRow={update} />
            case TaskType.TextCategorize:
                    return <div/>
            case TaskType.ImageCategorize:
                return <ImageCategorizeReviewItem key={key}  job={job}  row={row} updatedRow={update} />
            case TaskType.ImageBoundingBox:
                return <div />;
            case TaskType.AudioCategorize:
                    return <div />;
            case TaskType.AudioSplit:
                    return <div />;
            case TaskType.Transcribe:
                    return <TranscribeReviewItem key={key} job={job}  row={row} updatedRow={update} />;
            default:  
                throw new UnreachableCaseError(row.type);
        }
    }

    static ResolutionTask(ref: React.RefObject<any> , job:Job, row:ReviewRow ,next:()=>void   ):any|undefined {
        const  key = row.type + "_" + row.originalRow.id;

        switch (row.type) {
            case TaskType.Split:
        case TaskType.Sentiment:
        case TaskType.Intent: 
        case TaskType.Entity:
        case TaskType.ImageCategorize:
        case TaskType.ImageBoundingBox:
        case TaskType.AudioCategorize:
        case TaskType.AudioSplit:
        case TaskType.Transcribe:
            return undefined



            case TaskType.TextCategorize:
                return <TextCategorizeResolutionView  ref={ref} key={key}  job={job}  row={row} next={next} />;

        default:  
            throw new UnreachableCaseError(row.type);
    }
}

    
}