import React from 'react';
import  "./Modal.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface ModalProps {
  closeModal():void;

  hideCloseButton?:boolean;
}

export  class Modal extends React.Component<ModalProps> {
  constructor(props:ModalProps) {
    super(props)
    this.closeButtonClicked = this.closeButtonClicked.bind(this)
  }
  closeButtonClicked() {
    this.props.closeModal();
  }

  render() {
    const closeButton = (this.props.hideCloseButton) ? null : <div className="closeButton" onClick={this.closeButtonClicked}><FontAwesomeIcon  size='2x' icon={faTimes} /></div>
    return <div className="Modal">
              <div className="ModalContent">
                {closeButton}
                {this.props.children}
              </div>
          </div>;
  }

}
