import React from 'react';
import { ActionsConsumer,Action, Actions, ActionType } from '../../Actions/Actions';
import "./AssignmentScreen.scss";
import { Model } from '../../Models/Model';
import { NavTextButton } from '@swivl/great-grey-components';
import { Assignment, CreateLogger } from '@swivl/great-grey-lib';
import { NavBar } from '../Layout/NavBar/NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AssignmentProgress } from './components/AssignmentProgress/AssignmentProgress';
import { ExercisesView } from './Exercises/ExercisesView';
const log = CreateLogger("Assignment:Screen");
// import * as Model from '../../Models/Model';
interface AssignmentScreenProps {

}
export class AssignmentScreen extends ActionsConsumer<AssignmentScreenProps> {

  get assignment():Assignment|undefined {
     if(Model.Assignment.state.items && Model.Assignment.state.items[Model.Navigation.second()]) {
       return Model.Assignment.state.items[Model.Navigation.second()]
     } return null;
  }
  
  constructor(props:any) {
    super(props)
    Model.Assignment.load()
    Actions.Controller.subscribeTo(this,
      [ 
        ActionType.AssignmentUpdated, 
        ActionType.AssignmentDeleted,
        ActionType.AssignmentCreated,
        ActionType.AssignmentsLoaded, 
        ActionType.NavigationPathUpdated
      ]);

    this.saveAndExitClicked = this.saveAndExitClicked.bind(this)
  }
  componentDidMount() {

  }

  componentDidUpdate() {
    log("Update", Model.Assignment.state)
  }
  saveAndExitClicked() {
    log("this.assignment",this.assignment);
    if (window.confirm("Are you sure you want to exit? You haven't finished this assignment yet.")) {
      const assignment =  this.assignment; 
      if (assignment && assignment.subTask) {
        const subTaskId = (typeof assignment.subTask === 'string') ? assignment.subTask : assignment.subTask.id;
        Model.Navigation.setPath("/task/" + subTaskId);
      } else {
        Model.Navigation.setPath("/task");
      }
    }
  }


  renderNavBar(assignment:Assignment) {
    return <NavBar 
            left={<NavTextButton onClick={this.saveAndExitClicked} text={"Save & Exit"} />}
            center={<AssignmentProgress assignment={assignment}/>}
            />
  }
  renderLoading() {  return <div className="LayoutView"><NavBar center={<FontAwesomeIcon icon={faSpinner} spin/>}/></div> ; }
  
  render() {
    const assignment = this.assignment; 
    if (!assignment) { return this.renderLoading(); }

    return <div className="LayoutView">
            {this.renderNavBar(assignment)}

            <div className="AssignmentScreen">
              <ExercisesView assignment={assignment} />
            </div>
          </div>;

  }

}
