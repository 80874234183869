import React from 'react';
import { Image } from 'react-konva';
interface Props {
  imageURL:string 
  imageWidth:number, 
  imageHeight:number 
}
class AnnotationImage extends React.Component<Props> {
  state = {
    image: null,
  };

  componentDidMount() {
    const image = new window.Image();
    image.src = this.props.imageURL;
    image.onload = () => {
      this.setState({
        image,
      });
    };
  }

  render() {
    const {
      state: { image },
    } = this;
    return (
      <Image
        height={this.props.imageHeight}
        width={this.props.imageWidth}
        image={image}
      />
    );
  }
}

export default AnnotationImage;
