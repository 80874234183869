import React from 'react';
import {  Button, Pill } from '@swivl/great-grey-components';
import './ConfirmCard.scss'

import {  MLModelData } from '@swivl/great-grey-lib';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../../../Actions/Actions';

interface Props {
  mlModelData:MLModelData;

  confirm();
  back();
}
const Examples_To_Show = 7;
export class ConfirmCard extends ActionsConsumer<Props> {
  constructor(props:any) {
    super(props)
    this.onChange        = this.onChange.bind(this);
    this.nextClicked     = this.nextClicked.bind(this);
  }
  componentDidMount() {
    Actions.Controller.subscribeTo(this, [ActionType.KeyboardKeyDown])
  }
  onChange(event:React.ChangeEvent<HTMLInputElement>):void {
    this.setState({name:event.target.value})
  }
  handleAction(action:Action) {
    if (action.type === ActionType.KeyboardKeyDown) {
          if (action.event.key === "Enter") {
            this.nextClicked()
        }
      }
  }

  nextClicked() {
    console.log("nextClicked")
    this.props.confirm();
  }

  renderIntents() {
    const data = this.props.mlModelData;
    if (data.intents) {
      let output = []
      for (let index = 0; index < data.intents.length; index++) {
        if (index > Examples_To_Show - 1) {
          output.push(<Pill dark key={"intent_"+index}hideDelete>+ {data.intents.length - Examples_To_Show} More...</Pill>)
          break;
       
        }

        const element = data.intents[index];
        output.push(<Pill dark key={"intent_"+index}hideDelete>{element.name}</Pill>)
    
      }
      return <div className="datarow clearfix">
      <h3 className="clearfix">Intents</h3>
      {output}
    </div>
    }
 
  }

  renderUtterances() {
    const data = this.props.mlModelData;
    if (data.utterances) {
      let output = []
      for (let index = 0; index < data.utterances.length; index++) {
        if (index > Examples_To_Show - 1) {
          output.push(<div key={"ut"+index}>+ {data.utterances.length - Examples_To_Show} More...</div>)
          break;
       
        }

        const element = data.utterances[index];
        output.push(<div key={"ut"+index}>"{element.text}"</div>)
    
      }
      return <div className="datarow clearfix">
      <h3 className="clearfix">Utterances</h3>
      {output}
    </div>
    }
 
  }

  renderEntities() {
    const data = this.props.mlModelData;
    if (data.entities) {
      console.log("HAS ENTITIES", data.entities)
      let output = []
      for (let index = 0; index < data.entities.length; index++) {
        if (index > Examples_To_Show - 1) {
          output.push(<Pill dark key={"ent_"+index}hideDelete>+ {data.entities.length - Examples_To_Show} More...</Pill>)
         break;

        }
        output.push(<Pill dark key={"ent_"+index}hideDelete>{data.entities[index].name}</Pill>)
    
      }
      return <div className="datarow clearfix">
          <h3 className="clearfix">Entities</h3>
          {output}
        </div>
    }
 
  }




  render() {

    

    return <div className="ConfirmCard modalCard">
      <div className="modalHeader">
        <h3>Let's take a look at your model</h3>
      </div>
      <div className="modalContent">
        {this.renderIntents()}
        {this.renderEntities()}
        {this.renderUtterances()}
      </div>
      <div className="modalFooter">
        <div className="buttons">
          <Button isRound={true} isOutlined={true} onClick={this.props.back}>Back</Button>
          <Button onClick={this.nextClicked} isRound={true}>Next</Button>
        </div>
      </div>
    </div>;
  }

}
