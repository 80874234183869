import React from 'react';
import { Model } from '../../Models/Model';
import { SortAndSearch, ListItem, ListItemContentTextAndFooter, Button, NavTextButton} from '@swivl/great-grey-components';
// import { UploadDataPoints } from './Create/UploadDataPoints';
import { Modal } from '../Layout/Modal/Modal';
import { ShortId, MLModel,  NumberWithCommas, CreateLogger, GGDictionary } from '@swivl/great-grey-lib';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MLModelDetailsView } from './MLModelDetailsView/MLModelDetailsView';
import { ActionType, ActionsConsumer, Action, Actions } from '../../Actions/Actions';
import { BaseScreenWithColumns, BaseScreenWithColumnsState, SortType } from '../Base/BaseScreenWithColumns';
import { UploadMLModel } from './Create/UploadMLModel';
const log = CreateLogger("MLModelsScreen")
const moment = require('moment');

interface MLModelsScreenProps {
  setNavBar(left:any|undefined, center:any|undefined, right:any|undefined);

}
interface MLModelsScreenState extends BaseScreenWithColumnsState {
  isShowingModal:boolean;
  mLModels?:Array<MLModel>;
  selectedMLModel?:MLModel;
}

export  class MLModelsScreen extends BaseScreenWithColumns<MLModelsScreenProps, MLModel> {
  modalKey:string;
  model = "MLModel";
  path = "model";

  sortOptions = [
    {value:SortType.Newest, label:SortType.Newest},    
    {value:SortType.Oldest, label:SortType.Oldest},
    {value:SortType.Type,   label:SortType.Type},
    {value:SortType.AtoZ,   label:SortType.AtoZ},
    {value:SortType.ZtoA,   label:SortType.ZtoA}
 ];
  constructor(props:MLModelsScreenProps) {
    super(props)
    this.state = { ...this.state, isShowingModal:false }
    
    Actions.Controller.subscribeTo(this, [
      ActionType.CurrentMembershipUpdated,
      ActionType.MLModelsLoaded
      ,ActionType.MLModelUpdated,
      ,ActionType.MLModelDeleted,
      ActionType.NavigationPathUpdated ] )

    Model.MLModel.load();

  }

  componentDidMount() {
    this.props.setNavBar(null,<h3>Machine Learning Models</h3>,
      <NavTextButton onClick={this.showModal} text={"+ Add ML Model"} />
   )
  }
  renderItem(item:MLModel) {
    return <ListItemContentTextAndFooter
                  text={item.name}
                  footerText={moment(item.createdAt).format("MMM D, YYYY")}
                  footerRightText={item.type}
                  />
  }

  handleAction(action:Action) {
    if (action.type === ActionType.CurrentMembershipUpdated) {
      Model.MLModel.load(); return;
    }   
    this.forceUpdate(); 
  }
  
  renderModal() {  
    return <Modal key={this.modalKey} closeModal={this.hideModal}>
      <UploadMLModel closeModal={this.hideModal} />
    </Modal>}
  renderDetails(item:any) { return <MLModelDetailsView mLModel={item} />}
}
