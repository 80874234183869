import React from 'react';
import { SortAndSearch,Option, ListItem, ListItemContentTextAndFooter, LoadingItem} from '@swivl/great-grey-components';

import { CreateLogger,ShortId, UnreachableCaseError,GGDictionary, HasId } from '@swivl/great-grey-lib';

import { Model } from '../../Models/Model';
import { ActionsConsumer } from '../../Actions/Actions';
const log = CreateLogger("BaseScreenWithColumns:main")
var _ = require('lodash');

export enum SortType {
  Newest = "Newest",
  Oldest = "Oldest",
  Type   = "Type",
  Status = "Status",
  AtoZ   = "A-Z",
  ZtoA   = "Z-A"
}



export interface BaseScreenWithColumnsState {
  searchQuery:string;
  isShowingModal:boolean;
  sort:SortType;
}

export class BaseScreenWithColumns<P, T> extends ActionsConsumer<P, BaseScreenWithColumnsState> {
    path?:string; // Optional. If not set iit uses the model value.
    model = "undefined";
    mainClass:string = "BaseScreenWithColumns"
    modalKey:string;

    //Sort Critera
    _sortCriteraDate   = "createdAt";
    _sortCriteraType   = "type";
    _sortCriteraStatus = "status";
    _sortCriteraAlpha  = "name";
    
    


    sortOptions = [{value:SortType.Newest, label:SortType.Newest},    
                   {value:SortType.Oldest, label:SortType.Oldest},
                   {value:SortType.Type,   label:SortType.Type},
                   {value:SortType.Status, label:SortType.Status},
                   {value:SortType.AtoZ,   label:SortType.AtoZ},
                   {value:SortType.ZtoA,   label:SortType.ZtoA}
                ];

  constructor(props:P) {
    super(props);
    this.state = { searchQuery:"",isShowingModal:false,sort:SortType.Newest }
    this.modalKey           = ShortId()
    this.sortOptionSelected = this.sortOptionSelected.bind(this)
    this.onSearchChange     = this.onSearchChange.bind(this)
    this.showModal          = this.showModal.bind(this)
    this.hideModal          = this.hideModal.bind(this)
    this.onItemClick        = this.onItemClick.bind(this)
    this.sortList           = this.sortList.bind(this)
    this.renderModal        = this.renderModal.bind(this)
    this.renderDetails      = this.renderDetails.bind(this)
    this.renderItem         = this.renderItem.bind(this)
}
  
  // get items():GGDictionary<any>|undefined { return null; }
  get items():GGDictionary<T&HasId>|undefined { return Model[this.model].state.items; }

  sortOptionSelected(option:Option) {
    this.setState({sort: option.value as SortType})
  }
  
  private _renderListItem(item:any,selectedId?:string, ) {
    return <ListItem key={item.id} onItemClicked={this.onItemClick} item={item} isSelected={(selectedId && item.id === selectedId)} >
      {this.renderItem(item)}
    </ListItem>
  }

  

   _renderList() {

    if (!this.items) { return <LoadingItem /> }
    const selectedItem = Model.Navigation.second()
    const searchQuery = (this.state.searchQuery.length > 0) ? this.state.searchQuery : null;

    const sorted = this.sortList(this.items, this.state.sort);

    return sorted.map((item:any) => {
      // const item = items[id]; 

      if (searchQuery) {
        if(!this.matchesSearch(searchQuery, item)) { return null; }
      }

      return this._renderListItem(item,selectedItem)
     }) 
  }

  sortList(items:Record<string,any>, sort:SortType) {
    switch(sort) {
      case SortType.Newest:
        return _.sortBy(items, (i: any) => i[this._sortCriteraDate])
      case SortType.Oldest:
        return _.sortBy(items, (i: any) => i[this._sortCriteraDate]).reverse();
      case SortType.Type:
        return _.sortBy(items, (i: any) => i[this._sortCriteraType]);
      case SortType.Status:
        return _.sortBy(items, (i: any) => i[this._sortCriteraStatus]);
      case SortType.AtoZ:
        return _.sortBy(items, (i: any) => i[this._sortCriteraAlpha]);
      case SortType.ZtoA:
        return _.sortBy(items, (i: any) => i[this._sortCriteraAlpha]).reverse();
      default: 
        throw new UnreachableCaseError(sort);
      }
  }


  matchesSearch(searchQuery:string, item:any ):boolean {
    if (item.name && typeof item.name === 'string') {
      return item.name.includes(searchQuery);
    }
      return false;
  }


  showModal() { this.modalKey = ShortId(); this.setState({isShowingModal:true});}
  hideModal() { this.setState({isShowingModal:false});}

  onSearchChange?(event:React.ChangeEvent<HTMLInputElement>) {
    this.setState({searchQuery:event.target.value});
  }

  onItemClick(item:any) {
      const path = (this.path) ? this.path : this.model.toLowerCase();
      Model.Navigation.setPath(`/${path}/${item.id}`) 
  }
  renderItem(item:any) {
            return <ListItemContentTextAndFooter
                text={"Unset"}
                footerText={"Unset"}
                footerRightText={"Unset"} />
  }

  renderDetails(item:any) { return null; }
  renderBlank() { return null; }

  renderModal() { return null; }

  renderContent() {
    const selectedItemId = Model.Navigation.second()
    const selectedItem = (this.items && selectedItemId) ? this.items[selectedItemId] : null;
    /* NOTE: We Wrap the Details View In  It's Own Keyed Div to make sure it is consistant when changing views */
    const content =(selectedItem) ? <div key={selectedItem.id}>{this.renderDetails(selectedItem)}</div> : this.renderBlank();
    return content; 
  }

  render() {


    return <div className="DatapoolsScreen screen">
      {(this.state.isShowingModal) ? this.renderModal() : null}
    <div className="columns is-gapless full-height-columns">
      <div className="column is-one-quarter white-bg bordered-column padForMenu">
      <SortAndSearch 
        sortItems={this.sortOptions} 
        sortValue={this.state.sort}
        sortOptionSelected={this.sortOptionSelected}
        searchQuery={this.state.searchQuery}
        searchOnChange={this.onSearchChange}
        />
        {this._renderList()}
      </div>
      <div className="column">
        {this.renderContent()}
      </div>
    </div>

    </div>;
  }

}
