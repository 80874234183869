import React, { createRef } from 'react';
import { CreateLogger } from '@swivl/great-grey-lib';
import "./MouseHandler.scss"
import { Pos } from '../TimelineView';
import { CursorView } from '../CursorView/CursorView';
import { SelectionView } from '../SelectionView/SelectionView';
import {  TimelineSegmentsView } from '../../TimelineSegment/TimelineSegments';


const log = CreateLogger("AudioSplitExersiseView:MouseHandler");




interface MouseHandlerProps { 
    cursorRef?:React.RefObject<CursorView>
    selectionViewRef?:React.RefObject<SelectionView>
    timelineSegmentsRef?:React.RefObject<TimelineSegmentsView>
    setPlayhead(time:number);

}

interface MouseHandlerState {
    cursor?:Pos
    mouseDown?:Pos
    mouseUp?:Pos
    isMouseDown:boolean
    isMouseUp:boolean
    isSelecting:boolean 
    showSelection:boolean 

}

export class MouseHandler extends React.Component<MouseHandlerProps, MouseHandlerState> {
    mainRef = createRef<HTMLDivElement>();
    
    constructor(props:any) {
        super(props) 
        this.state =  {isMouseDown:false, isMouseUp:false,showSelection:false, isSelecting:true}
        // this.selectionViewProps = this.selectionViewProps.bind(this);
        this.posForMouseEvent   = this.posForMouseEvent.bind(this)
        this.onMouseDown        = this.onMouseDown.bind(this)
        this.onMouseUp          = this.onMouseUp.bind(this)
        this.onMouseMove        = this.onMouseMove.bind(this);
        this.onDoubleClick      = this.onDoubleClick.bind(this)
    }
    
    
      
    posForMouseEvent(e:React.MouseEvent<HTMLDivElement, MouseEvent>):Pos | undefined {
        if (this.mainRef &&  this.mainRef.current.getBoundingClientRect) {
        const rect = this.mainRef.current.getBoundingClientRect();
        return {x: Math.round(e.clientX - rect.left),y:Math.round(e.clientY - rect.top)}
        } return undefined; 
    }

    isWithingSelectionView(pos?:Pos):boolean {
      if (pos && 
        this.props.selectionViewRef &&  
        this.props.selectionViewRef.current) {
        const selectionView = this.props.selectionViewRef.current; 
            log("selectionView",selectionView.state, pos)

        if (selectionView.state.doneSelecting) { // Chheck to see if the click was within the selected regeon
            const {left, width} = selectionView.leftAndWidth()
            log("Clicking within selection",left, width,pos);

            if (  pos.x >= left &&   pos.x <= left+width ) {
            log("Clicking within selection");
            return true;
            }
        }
        return false; 
        // selectionView.setStartX(pos.x)

    }
    }

  onMouseDown(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const pos = this.posForMouseEvent(e);

    if (this.isWithingSelectionView(pos)) {
      log("Clicking within selection");
      return;
    } 
    if(this.props.selectionViewRef &&   this.props.selectionViewRef.current) {
      this.props.selectionViewRef.current.setStartX(pos.x)
    }
    
    if (pos) { this.setState({mouseDown:pos, isMouseDown:true, showSelection:false, mouseUp:null }); }
  }
  onMouseUp(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const pos = this.posForMouseEvent(e);
    if (this.isWithingSelectionView(pos)) {
      log("Clicking up within selection");
      return;
    } 

    // const  selectionViewProps = this.selectionViewProps(true) 
    // if (selectionViewProps && selectionViewProps.doneSelecting && this.state.showSelection) {
    //   if (pos.x >= selectionViewProps.left && pos.x <= selectionViewProps.left + selectionViewProps.width ) {
    //     log("Clicking within selection");
    //     return;
    //   }
    // }

    let isDraggingSegment = false; 
    if (pos &&  this.props.timelineSegmentsRef && this.props.timelineSegmentsRef.current) {
      isDraggingSegment = this.props.timelineSegmentsRef.current.isDragging()
      this.props.timelineSegmentsRef.current.mouseUp(pos.x)
    }

    if (pos && 
        this.props.selectionViewRef &&  
        this.props.selectionViewRef.current) {
            log("DONE SELECTING")
            this.props.selectionViewRef.current.doneSelecting(pos.x);
        }

    // const showSelection = (selectionViewProps && selectionViewProps.width > 2) 
    // if (showSelection) {
        
    // //   this.props.playAtTime(selectionViewProps.left * 0.05, selectionViewProps.width * 0.05)
    // }

 

    if (pos) { this.setState({mouseUp:pos, isMouseDown:false}); }
  }

  onMouseMove(e:React.MouseEvent<HTMLDivElement, MouseEvent>) { 
    const pos = this.posForMouseEvent(e);
    // log("this.props.cursorRef",this.props.cursorRef)
    if (this.props.cursorRef &&  this.props.cursorRef.current) {
        this.props.cursorRef.current.setX(pos.x)
    }

    if (pos && 
        this.props.selectionViewRef &&  
        this.props.selectionViewRef.current && 
        this.state.isMouseDown) {
        const selectionView = this.props.selectionViewRef.current; 
        selectionView.setEndX(pos.x)
        // log("setting end")
        this.props.selectionViewRef.current.setEndX(pos.x)
    }


    if (pos &&  this.props.timelineSegmentsRef && this.props.timelineSegmentsRef.current) {
      this.props.timelineSegmentsRef.current.mouseMove(pos.x)
    }



    // if (this.state.isMouseDown && this.props.selectionViewRef &&  this.props.selectionViewRef.current) {
    //     this.props.selectionViewRef.setSelectionView()
    // }
    if (pos) { this.setState({cursor:pos , isSelecting:this.state.isMouseDown }); }
  }

  onDoubleClick(e:React.MouseEvent<HTMLDivElement, MouseEvent>) { 
    log("ON DOUBLE CLICK")
    const pos = this.posForMouseEvent(e);
    if (pos) { this.props.setPlayhead(pos.x * 0.05); }
  }
  
  render() {          
      return <div  
                className="MouseHandler"
                ref={this.mainRef}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onDoubleClick={this.onDoubleClick}
      >{this.props.children}</div>;
  }
}