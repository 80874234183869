import React from 'react';
import { Pill, PillSwitch } from '@swivl/great-grey-components';

import { Job, CreateLogger, Intent, TaskType, TaskData_Intent,MakeDictonaryArrayUnique } from '@swivl/great-grey-lib';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../Actions/Actions';
import { Model } from '../../../Models/Model';


import "./IntentTaskCreationView.scss";
import { SelectInstuctionsView } from '../../../Components/SelectInstructionsView/SelectInstructionsView';
import { SelectRedundancyComponent } from '../../../Components/SelectRedundancyComponent/SelectRedundancyComponent';

const log = CreateLogger("job:JobTaskIntent");
const logOfLoading = CreateLogger("LoadingLog")

interface IntentTaskCreationViewProps { job:Job }
interface IntentTaskCreationViewState { selectAll:boolean,allIntents:any }






export  class IntentTaskCreationView extends ActionsConsumer<IntentTaskCreationViewProps,IntentTaskCreationViewState> {
  constructor(props:IntentTaskCreationViewProps) {
    super(props)
    this.state = { selectAll:false,allIntents:{} }
    // this.toggleIntent = this.toggleIntent.bind(this)
    // this.createIntentsArray = this.createIntentsArray.bind(this)
    this.addAllIntents = this.addAllIntents.bind(this);
    this.onToggle = this.onToggle.bind(this);
    Actions.Controller.subscribeTo(this, [ActionType.MLModelsLoaded,ActionType.JobUpdated])
  }
  componentDidMount() {
    this.addAllIntents()
  }
  addAllIntents() {
    const {job} = this.props;
    if (!Model.MLModel.state || !Model.MLModel.state.items) { return; }

    let intents = {};
    for (const id in job.models) { if (job.models.hasOwnProperty(id)) {
        const  mlModel = Model.MLModel.state.items[id];
        if (mlModel && mlModel.data && mlModel.data.intents) {
          for (let i = 0; i < mlModel.data.intents.length; i++) {
            intents[mlModel.data.intents[i].name] = mlModel.data.intents[i];
          }
        }
        
    }}
    this.setState({allIntents:intents})
    if (job.tasks[TaskType.Intent]) {
      logOfLoading("UPDATING");

      if (
        (job.tasks[TaskType.Intent] as TaskData_Intent).intents &&
        (job.tasks[TaskType.Intent] as TaskData_Intent).intents.length > 0
        
        ) { 
          logOfLoading("ALREADY HAS DATA");

           } else {
            (job.tasks[TaskType.Intent] as TaskData_Intent).intents = Object.values(intents);
            Model.Job.update(job,true);
           }

     
    }

    logOfLoading("INTENTS", intents);
    
  }

  handleAction(action:Action) {
    if (action.type === ActionType.MLModelsLoaded) {
      this.addAllIntents();
    }
    this.forceUpdate();
  }

  

  onToggle(name:string, on:boolean) {
    logOfLoading("name", name, on);

    const {job} = this.props; 
    let intentTask = (job.tasks[TaskType.Intent] as TaskData_Intent)

    if (on) {
      if (this.state.allIntents && this.state.allIntents[name]) {
        intentTask.intents.push(this.state.allIntents[name])
      }
    } else {
      for (let index = 0; index < intentTask.intents.length; index++) {
        const element = intentTask.intents[index];
        if (element.name === name) {
          intentTask.intents.splice(index,1);
          break;
        }
      }
    }

    job.tasks[TaskType.Intent]  = intentTask

    Model.Job.update(job,true);
      
    
  }

  render() {

    
    const job = this.props.job;

    const intentTask:TaskData_Intent = job.tasks[TaskType.Intent] as TaskData_Intent;
    const intents = intentTask.intents;

    const selectedIntentNames = intents.map((item) => item.name);
    
    log("selectedIntentNames",selectedIntentNames)


    const allIntents = this.state.allIntents;
    let intentViews = []
    for (const key in allIntents) {
      if (allIntents.hasOwnProperty(key)) {
        const element = allIntents[key];

        intentViews.push(<PillSwitch onToggle={this.onToggle} on={(selectedIntentNames.includes(element.name))} key={key} name={element.name}>{element.name}</PillSwitch>)
        
      }
    }


    
    
     
    
    return <div className="IntentTaskCreationView">
        <h5>Select which intents you would like to include.</h5>
        <div>{intentViews}</div>
        <SelectRedundancyComponent job={this.props.job} taskType={TaskType.Intent} />
        <SelectInstuctionsView job={this.props.job} taskType={TaskType.Intent} />

    </div>
  }

}
