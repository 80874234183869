import React from 'react';
import { Input, Button, Size } from '@swivl/great-grey-components';
import './EnterNameCard.scss'

interface EnterNameCardProps {
  nameSet(name:string);
  cancel():void;
  name?:string;
}

interface EnterNameCardState {
  name:string;
}

const MIN_NAME_LENGTH = 3;




export  class EnterNameCard extends React.Component<EnterNameCardProps,EnterNameCardState> {
  private inputRef = React.createRef<Input>()

  constructor(props:any) {
    super(props)
    this.state = {name:(props.name)? props.name : ""}
    this.onChange        = this.onChange.bind(this);
    this.onReturnPressed = this.onReturnPressed.bind(this);
    this.nextClicked     = this.nextClicked.bind(this);
  }

  onChange(event:React.ChangeEvent<HTMLInputElement>):void {
    this.setState({name:event.target.value})
  }
  componentDidMount() {
    if (this.inputRef &&  this.inputRef.current) { this.inputRef.current.focus(); }
  }
  onReturnPressed() {
    if (this.state.name.length > MIN_NAME_LENGTH) {
      this.nextClicked();
    }
  }

  nextClicked() { this.props.nameSet(this.state.name); }

  render() {

    return <div className="EnterNameCard modalCard">
      <div className="modalHeader">
        <h3>Give this machine learning model a name</h3>
      </div>
      <div className="modalContent">
      <Input
        ref={this.inputRef}
        placeholder="Enter ML Model name..."
        size={Size.Large}
        onChange={this.onChange}
        value={this.state.name}
        onReturnPressed={this.onReturnPressed}
        />
      </div>
      <div className="modalFooter">
        <div className="buttons">
          <Button isRound={true} isOutlined={true} onClick={this.props.cancel}>Cancel</Button>
          <Button  onClick={this.nextClicked} isRound={true} isDisabled={!(this.state.name.length > MIN_NAME_LENGTH)}>Next</Button>
        </div>
      </div>
    </div>;
  }

}
