import React from 'react';
import  "./ExerciseCard.scss";
import { Button, Size } from '@swivl/great-grey-components';

import { CreateLogger, Assignment } from '@swivl/great-grey-lib';
const log = CreateLogger("Exercise:Card");

interface FinishCardProps {
  id:string;
  assignment:Assignment;
  selectedCard?:string;
  finishClicked() 
}
interface FinishCardState {
  isShowing:boolean;
}

export class FinishCard extends React.Component<FinishCardProps,FinishCardState> {
  constructor(props:FinishCardProps) {
    super(props)
    this.state = {isShowing:false}
  }


  
  

  componentDidMount()  { this.checkIfShowing(); }
  componentDidUpdate() { this.checkIfShowing(); }

  checkIfShowing() {
    if (this.props.selectedCard &&   this.props.id === this.props.selectedCard &&  !this.state.isShowing ) {
          this.setState({isShowing:true});

      } else if (this.props.id !== this.props.selectedCard &&
              this.state.isShowing
            ) {
              this.setState({isShowing:false})
            }
  }


  


  

  render() {


    return <div className={(this.state.isShowing) ? "FinishCard ExerciseCard isShowing" : "FinishCard ExerciseCard"}>
      <div className="imageWrapper">
        <img src="/img/greatgrey.png" width="400" height="400" />
      </div>
      <h2>YOU FINISHED!</h2>

      <Button isRound size={Size.Large} onClick={this.props.finishClicked}>Submit Task</Button>

    </div>;
  }

}
