import React from 'react';

import { Checkbox,ButtonWithOptions, Button, Color } from '@swivl/great-grey-components';
import './DataTable.scss';
import { HoursMinutesSeconds, CreateLogger, DataSetRow, ColmunTypesForRows, DataSetColumnType, CategoryData, BoundingBox, Entity, EntityDefinition } from '@swivl/great-grey-lib';

import Service from '../../Services/Service';
import StaticTextwithEntities from '../TextWithEntities/StaticTextWithEntities';
import _ from 'lodash';

const log = CreateLogger("DataSetDetailsView")

const moment = require('moment');
var pluralize = require('pluralize')
export type ColumnTypeDict = {
    [id: string]: boolean // Id and category. The name is set durring the creation of the class and exported in the CSV as it's own column name.
  }
interface Props {
  rows:DataSetRow[]
  selectedRows:string[]
  setSelectedRows(selectedRows:string[])

}
interface State {
    columns:ColumnTypeDict
    
}

export  class DataTable extends React.Component<Props,State> {
  possibleEntities:EntityDefinition[] = []; 
  constructor(props:any) {
    super(props)
    this.state = {columns:this.columnTypesForData(props.rows)};
    this.checkboxClicked   = this.checkboxClicked.bind(this)
  }
  columnTypesForData(rowsOptional?:DataSetRow[]):ColumnTypeDict  {
    console.log("Column Type For Data");
      
    let columns:ColumnTypeDict = {}
    let entityNames:string[] = []; 
      const rows = rowsOptional || this.props.rows; 
      for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (row.entities && row.entities.length) {
            entityNames = entityNames.concat(row.entities.map(i => i.name))
          }
            for (const key in row) {
                if (!columns[key] &&
                    Object.prototype.hasOwnProperty.call(row, key)) {
                        columns[key] = true
                }
            }
          
      }

      console.log("entityNames",entityNames);
      _.uniq(entityNames)
      console.log("entityNames2",entityNames);
      this.possibleEntities = entityNames.map(i => {return {name:i}})
      return columns; 
  }
  

  componentDidUpdate(prevProps:Props) {
      if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)) {
        this.setState({columns:this.columnTypesForData(this.props.rows)})
      } else {
          console.log("No need to update");
      }
  }

  checkboxClicked(name:string, checked:boolean) {

    if (name === "SelectAllCheckbox") {
      if (checked) {
        this.props.setSelectedRows(this.props.rows.map(row=>row.id));
      } else {
        this.props.setSelectedRows([]);
      }
      return;
    }

    let selectedRows = this.props.selectedRows;

    if (checked) { 
      selectedRows.push(name)
     } else {
       for (let index = 0; index < selectedRows.length; index++) {
         if (selectedRows[index] === name) {
          selectedRows.splice(index, 1);
          break;
         }
         
       }
     }
     this.props.setSelectedRows(selectedRows);
  }

  renderCategories(categories?:CategoryData) {
    if (!categories) { return null; }
    var output = []
    for (const key in categories) {
      if (Object.prototype.hasOwnProperty.call(categories, key)) {
        output.push(<div key={key} className="categoryPill">
          
          <span>{key}</span>
          {categories[key].name}
        </div>)        
      }
    }
    return output;
  }
  renderBoundingBox(boundingBoxes?:BoundingBox[]) {
    if (!boundingBoxes) { return null; }

    var output = []

      for (let index = 0; index < boundingBoxes.length; index++) {
          const box = boundingBoxes[index];
          output.push(<div key={box.id} className="categoryPill">
          
          <span>box</span>
          {box.category.name || "uncategorized"} {(box.score) ? " - " + Math.round(box.score * 100) + "%" : ""}
        </div>)     
      }
      return output;
  }
  

  renderTextWithEntities(text:string, entitites?: Entity[]):any {
    if (entitites) {
      return <StaticTextwithEntities 
      text={text} 
      entities={entitites}
      possibileEntities={this.possibleEntities}/>
    }
    return text;
  }

  render() {

    const rowData:Array<DataSetRow> = this.props.rows;
    if (!rowData) { return null; }
    const columns = this.state.columns; 

    const allSelected:boolean = (this.props.selectedRows.length === rowData.length && rowData.length > 0)
    // var hasIntent = false; 
    // var hasText = false; 
    // var hasSentiment = false; 

    // var hasAudioURL  = false; 
    // var hasAudioCategories = false;
    // var hasAudioStartTime = false; 
    // var hasAudioEndTime = false; 
    // var hasAudioCategory = false; 
    // var hasDuration = false; 
    // var hasAutoTranscribe = false; 

    // var hasImageURL  = false; 

    // var hasCategories  = false; 



    // for (let i = 0; i < rowData.length; i++) {
    //   if (!hasIntent && rowData[i].intent && rowData[i].intent.name) { hasIntent = true; }
    //   if (!hasText && rowData[i].text ) { hasText = true; }
    //   if (!hasAudioURL && (rowData[i] as any).audioURL ) { hasAudioURL = true; }
    //   if (!hasImageURL && (rowData[i] as any).imageURL ) { hasImageURL = true; }
    //   if (!hasCategories && rowData[i].categories ) { hasCategories = true; }
    //   if (!hasSentiment && typeof (rowData[i] as any).sentiment !== 'undefined' ) { hasSentiment = true; }
      

    //   if (!hasAudioCategories && (rowData[i] as any).audioCategories ) { hasAudioCategories = true; }
    //   if (!hasAudioStartTime  && (rowData[i] as any).audioStartTime )  { hasAudioStartTime = true; }
    //   if (!hasAudioEndTime    && (rowData[i] as any).audioEndTime )    { hasAudioEndTime = true; }
    //   if (!hasAudioCategory   && (rowData[i] as any).audioCategory )   { hasAudioCategory = true; }
    //   if (!hasDuration        && (rowData[i] as any).duration )        { hasDuration = true; }
    //   if (!hasAutoTranscribe        && (rowData[i] as any).automaticTranscription )        { hasAutoTranscribe = true; }

      

    //   console.log("rowData[i]",rowData[i].categories, hasCategories);

      


    // }

    const rows = rowData.map((item,index) =>{
      let audioCategories; 
      if (columns[DataSetColumnType.AudioCategories] && item.audioCategories) { 
        let acText = ""
        for (let ac = 0; ac < item.audioCategories.length; ac++) {
          let catName = (item.audioCategories[ac].name) ? item.audioCategories[ac].name : "UNKNOWN"; 
          acText += item.audioCategories[ac].name  + `(${item.audioCategories[ac].segments.length}) \n`;
        }
        audioCategories = <th>{acText}</th>;
      }
    
      return  <tr key={item.id}>
                <td className="checkboxColumn">
                  <Checkbox 
                    name={item.id} 
                    checked={allSelected || this.props.selectedRows.includes(item.id)}
                    checkboxClicked={this.checkboxClicked}
                    />
                  </td>
                  {(columns[DataSetColumnType.Text])? <th>{this.renderTextWithEntities(item.text, item.entities)}</th>:null }
                  {(columns[DataSetColumnType.Sentiment])? <th>{(typeof item.sentiment != 'undefined')? item.sentiment.toFixed(2): ""}</th>:null }

                  {(columns[DataSetColumnType.ImageURL])? <th><a href={Service.File.mediaURL(item.imageURL)} target="_blank">{item.imageURL}</a></th>:null }
                  {(columns[DataSetColumnType.Categories])? <th>{this.renderCategories( item.categories)}</th>:null }
                  {(columns[DataSetColumnType.BoundingBoxes])? <th>{this.renderBoundingBox( item.boundingBoxes)}</th>:null }
                  


                  
                  {(columns[DataSetColumnType.AutomaticTranscription])? <th>{item.automaticTranscription}</th>:null }
                  {(columns[DataSetColumnType.AudioURL])? <th>{ item.audioURL}</th>:null }
                  {(columns[DataSetColumnType.Duration])? <th>{(item.duration) ? HoursMinutesSeconds(item.duration) : ""}</th>:null }
                  {(columns[DataSetColumnType.Intent])? <th>{(item.intent)?item.intent.name:""}</th>:null }
                  {audioCategories}
                  {(columns[DataSetColumnType.AudioCategory])? <th>{(item.audioCategory)? item.audioCategory.name : ""}</th>:null }
                  {(columns[DataSetColumnType.AudioStartTime])? <th>{(item.audioStartTime)? item.audioStartTime.toFixed(2)+"s": ""}</th>:null }
                  {(columns[DataSetColumnType.AudioEndTime])? <th>{(item.audioEndTime)? item.audioEndTime.toFixed(2)+"s": ""}</th>:null }
              </tr>;
    });
    return <table className="DataTable">
      <thead>
        <tr><th className="checkboxColumn">
        <Checkbox 
                  name={"SelectAllCheckbox"} 
                  checked={allSelected}
                  checkboxClicked={this.checkboxClicked}
                  />          
          </th>
          {(columns[DataSetColumnType.Text])? <th>Text</th>:null }
          {(columns[DataSetColumnType.Sentiment])? <th>Sentiment</th>:null }
          {(columns[DataSetColumnType.ImageURL])? <th>Image URL</th>:null }
          {(columns[DataSetColumnType.Categories])? <th>Categories</th>:null }
          {(columns[DataSetColumnType.BoundingBoxes])? <th>Bounding Box</th>:null }
          {(columns[DataSetColumnType.AutomaticTranscription])? <th>Auto Transcribe</th>:null }
          {(columns[DataSetColumnType.AudioURL])? <th>Audio URL</th>:null }
          {(columns[DataSetColumnType.Duration])? <th>Duration</th>:null }
                {(columns[DataSetColumnType.Intent])? <th>Intent</th>:null }
                {(columns[DataSetColumnType.AudioCategories])? <th>Audio Categories</th>:null }
                {(columns[DataSetColumnType.AudioCategory])? <th>Audio Category</th>:null }
                {(columns[DataSetColumnType.AudioStartTime])? <th>Audio Start</th>:null }
                {(columns[DataSetColumnType.AudioEndTime])? <th>Audio End</th>:null }

          
          </tr>

      </thead>
      <tbody>
      {rows}
      </tbody>
    </table>
  }



}
