import React from 'react';
import { SelectionList,Option, Button, Color, Size } from '@swivl/great-grey-components';
import { CreateLogger,CreateRowChange_Transcribe,Assignment,WorkRowStatus,CreateRowChange_Intent, TaskData_Intent, AssignmentWorkRow, Intent, DataSetRow, RowChangeType, AssignmentWork, CreateRowChange_Sentiment } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../Screens/Assignment/Exercises/Card/ExerciseCard';
import TextareaAutosize from 'react-textarea-autosize';

import "./SentimentGroupItem.scss"
import { AudioPlayer } from '../../Shared/AudioPlayer/AudioPlayer';
import { MiniAudioPlayer } from '../../Shared/MiniAudioPlayer/MiniAudioPlayer';
const log = CreateLogger("SentimentGroupExercise:SentimentGroupItem");


interface SentimentGroupItemProps {
    row:DataSetRow
    work: AssignmentWorkRow
    save(work:AssignmentWorkRow)

}

export class SentimentGroupItem extends React.Component<SentimentGroupItemProps> {
 
  constructor(props:any) {
    super(props) 
    this.onVeryNegative = this.onVeryNegative.bind(this);
    this.onNegative = this.onNegative.bind(this);
    this.onNeutral = this.onNeutral.bind(this);
    this.onPositive = this.onPositive.bind(this);
    this.onVeryPositive = this.onVeryPositive.bind(this);

  }  
  onVeryNegative(e:React.MouseEvent<HTMLElement>){ this.setSentiment(0.0)}
  onNegative(e:React.MouseEvent<HTMLElement>){ this.setSentiment(0.25) }
  onNeutral(e:React.MouseEvent<HTMLElement>){ this.setSentiment(0.5) }
  onPositive(e:React.MouseEvent<HTMLElement>){ this.setSentiment(0.75) }
  onVeryPositive(e:React.MouseEvent<HTMLElement>){ this.setSentiment(1) }

  setSentiment(sentiment:number) {
    if (this.props.work && this.props.work.change &&  this.props.work.change.type ===  RowChangeType.Sentiment) {

    console.log("DELTA",this.props.work.change.sentiment)
    }
    let work:AssignmentWorkRow = this.props.work;
    work.change = CreateRowChange_Sentiment(sentiment)
    this.props.save(work);
  }
  
  renderSentimentButtons(selectedType) {

    

    return <div className="sentimentButtons">
        <Button 
                size={Size.Small}
              color={Color.Tan} 
              isSelected={(selectedType ===  "VeryNegative")} 
              isRound 
              onClick={this.onVeryNegative}>
                  Very Negative
              </Button>
              <Button 
                size={Size.Small}
              color={Color.Tan} 
              isSelected={(selectedType ===  "Negative")} 
              isRound 
              onClick={this.onNegative}>
                  Negative
              </Button>
              <Button 
              color={Color.Tan} 
              isSelected={(selectedType ===  "Neutral")} 
              isRound 
              size={Size.Small}

              onClick={this.onNeutral}>
                  Neutral
              </Button>
              <Button 
              color={Color.Tan} 
              isSelected={(selectedType ===  "Positive")} 
              isRound 
              size={Size.Small}

              onClick={this.onPositive}>
                  Positive
              </Button>
              <Button 
              color={Color.Tan} 
              isSelected={(selectedType ===  "VeryPositive")} 
              isRound 
              size={Size.Small}
              onClick={this.onVeryPositive}>
                  Very Positive
              </Button>

    </div>

  }
  
  render() {
    const {row} = this.props
    
    var selectedType;
    if (this.props.work && this.props.work.change &&  this.props.work.change.type ===  RowChangeType.Sentiment) {
      if (this.props.work.change.sentiment < 0.25) { selectedType =  "VeryNegative"; }
      else if (this.props.work.change.sentiment < 0.5) { selectedType =  "Negative"; }
      else if (this.props.work.change.sentiment === 0.5) { selectedType =  "Neutral"; }
      else if (this.props.work.change.sentiment >= 1) { selectedType =  "VeryPositive"; }
      else if (this.props.work.change.sentiment >= 0.75) { selectedType =  "Positive"; }
    }

    let className = (row.audioCategory.name === "Customer") ? "SentimentGroupItem rightSide"  : "SentimentGroupItem leftSide"
    if (selectedType) {  className += " selected"; }
    return <div className={className}>
            <div className="topRow">
            <div className="playButton">
                <MiniAudioPlayer audioURL={row.audioURL} startTime={row.audioStartTime} endTime={row.audioEndTime} />
            </div>
            </div>
            <div className="text">{row.text}</div>
            {this.renderSentimentButtons(selectedType)}
           </div>;
  }
}
