import React from 'react';

import { ButtonWithOptions, Button, Color, Size, Input } from '@swivl/great-grey-components';
import { Job,CreateLogger, JobStatus, isJobStartable } from '@swivl/great-grey-lib';
import "./JobHeaderView.scss";
import { ActionsConsumer } from '../../../../../Actions/Actions';
import { Model } from '../../../../../Models/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Service from '../../../../../Services/Service';
const log = CreateLogger("job:JobHeaderView");

interface JobHeaderViewProps {
  job:Job,
}
interface JobHeaderViewState {
    isEditingTitle:boolean;
    isStartingJob:boolean;
    isFinishingJob:boolean;
}

export  class JobHeaderView extends React.Component<JobHeaderViewProps,JobHeaderViewState> {
  constructor(props:any) {
    super(props)
    this.state = {isEditingTitle:(props.job.name === "New Job"), isStartingJob:false, isFinishingJob:false};
    this.renderActionButton     = this.renderActionButton.bind(this);
    this.startJobClicked        = this.startJobClicked.bind(this);
    this.deleteClicked          = this.deleteClicked.bind(this);
    this.finishJobClicked       = this.finishJobClicked.bind(this);
    this.editNameClicked        = this.editNameClicked.bind(this);
    this.nameOnChange           = this.nameOnChange.bind(this);
    this.saveNameClicked        = this.saveNameClicked.bind(this);
    
  }

  startJobClicked() {
    this.setState({isStartingJob:true});
    Model.Job.startJob(this.props.job)
    .then((_)=> { this.setState({isStartingJob:false});    })
    .catch((e) => {  this.setState({isStartingJob:false }); })
 
  }
  deleteClicked() { 
    if (window.confirm("Are you sure you want to delete this job? The action cannot be undone.")) {
      Model.Job.delete(this.props.job)
    }
  }
  
  async finishJobClicked() {
    this.setState({isFinishingJob:true});
    try {
      await Model.Job.finishJob(this.props.job) 
      this.setState({isFinishingJob:false});

    } catch (error) {
      this.setState({isFinishingJob:false});
      Service.Toast.error(error);
    }

  }
  editNameClicked() { this.setState({isEditingTitle:true}); }

  nameOnChange(e:React.ChangeEvent<HTMLInputElement>) {
    let job = this.props.job;
    job.name = e.target.value;
    Model.Job.update(job, false);
  }
  saveNameClicked() { 
    if (this.props.job.name.length > 1) {
      Model.Job.update(this.props.job, true);
      this.setState({isEditingTitle:false});
   }
  }
  renderTitle() {
    if (this.props.job.status !== JobStatus.Unstarted) { return <h2>{this.props.job.name}</h2>;  }

    if (this.state.isEditingTitle) {
      return <div className="editJobName">
        <Input 
          name="jobName"
          size={Size.Large} 
          value={this.props.job.name} 
          onChange={this.nameOnChange}
          onReturnPressed={this.saveNameClicked}
          />
          <Button size={Size.Large} color={Color.Primary} onClick={this.saveNameClicked}>Save</Button>
        </div>
    } else {
      return  <h2 className="clickableIconHeader" onClick={this.editNameClicked}>{this.props.job.name} <span><FontAwesomeIcon icon={faEdit} size="xs"/></span> </h2>;
    }
  }

  renderActionButton() {
    
    switch (this.props.job.status) {
        case JobStatus.Unstarted:
          return <ButtonWithOptions 
                  buttonOptions={[<Button color={Color.Warning} onClick={this.deleteClicked}>Delete Job</Button>]} 
                  isLoading={this.state.isStartingJob}
                  isDisabled={!isJobStartable(this.props.job)}
                  onClick={this.startJobClicked}
                  >
                    Start Job
                  </ButtonWithOptions>;
        case JobStatus.Ready_For_Review: 
            return <Button 
            isLoading={this.state.isFinishingJob}
            isDisabled={!(this.props.job.unresolvEdescalations == 0)}
            onClick={this.finishJobClicked}
            >Finish Job</Button>
        default:
            break;
    }
  }

  render() {
    
    return <div className="JobHeaderView">
                {this.renderTitle()}
                <div className="actionButton">
                  {this.renderActionButton()}
                </div>
            </div>;
  }

}
