import React from 'react';
import { MLModel, OrganisationMembership, Project, User } from '@swivl/great-grey-lib';
import "./CreateDataSetFromDataPointsModal.scss"
import { Input, Option, Select, Button, Color } from '@swivl/great-grey-components';
import { Actions, ActionsConsumer, ActionType } from '../../../../Actions/Actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Model } from '../../../../Models/Model';

// import * as Model from '../../Models/Model';
interface State {
    isCreating:boolean
    createdDataSetId?:string
}
interface Props {
  unasigneSelected()
  dataPointIds:string[]
}
export default class CreateDataSetFromDataPointsModal extends ActionsConsumer<Props,State> {
 
  constructor(props:Props) {
    super(props)
    this.state = {isCreating:false}



  }
  // onChange = (selectedOption:Option) =>{
  //   // this.setState({selectedModel:selectedOption.data})
  // }
  onCancelClicked = () => {

    Actions.Controller.trigger({type:ActionType.HideModal})

  }
  curateClicked = () => {
    this.setState({isCreating:true})
    Model.DataPoint.createDataSetFromDataPoints(this.props.dataPointIds).then((dataSet:any) => {
      this.setState({isCreating:false,createdDataSetId:dataSet.id})
      this.props.unasigneSelected()
    })

  }
  viewDataSet = () => {
    Model.Navigation.setPath("/dataset/" + this.state.createdDataSetId)
    Actions.Controller.trigger({type:ActionType.HideModal})

  }
    render() {
      if (this.state.createdDataSetId) {
       return <div className="SelectModelModal">
      <img src={'/img/nav/icon-data@2x.png'} />
      <h1>Data Set Created</h1>
      <div className="buttonOrSpinner">
          Data Set Created
      </div>
      <div className="buttons">
          <Button onClick={this.viewDataSet} color={Color.Primary} >View</Button>
      </div>
      </div>
      }
      if (this.state.isCreating) {

        return <div className="SelectModelModal">
        <img src={'/img/nav/icon-data@2x.png'} />
        <h1>Creating Data Set</h1>
        <div className="buttonOrSpinner">
        One moment while we create this Data Set.
        </div>
        <div className="loaderWrapper"> 
        <div className="lds-ripple"><div></div><div></div></div>
 
      </div>      </div>

      }
    //  const models = Model.MLModel.array; 
    // const options = (models) ? models.map((item) =>{ return {label:item.name,  value:item.id,  data:item}}) : null; 
    //     const select = (options) ? <Select options={options} onChange={this.onChange} value ={(this.state.selectedModel)? this.state.selectedModel.id : null}/> : <div className="loadingSpinner"><FontAwesomeIcon icon={faSpinner} spin /> </div>
  return <div className="SelectModelModal">
      <img src={'/img/nav/icon-data@2x.png'} />
      <h1>Create Data Set</h1>
      <div className="buttonOrSpinner">
      Would you to create a Data Set <br/>from {(this.props.dataPointIds.length > 1) ? "these" : "this"} {this.props.dataPointIds.length} Data Point{(this.props.dataPointIds.length > 1) ? "s" : ""} ?
      </div>
      <div className="buttons">
          <Button onClick={this.onCancelClicked} color={Color.Tan} >Cancel</Button>
          <Button onClick={this.curateClicked} color={Color.Primary} >Yes</Button>
      </div>

      {/* <label>PROJECT NAME</label>
      <Input onChange={this.projectNameUpdated} />
      <label>PROJECT LEAD</label>
      <Select 
      value={(this.state.projectLead)?this.state.projectLead.id :null}
        options={options} 
        isSearchable={true}
        onChange={this.leadChanged} />
      <div className="buttons">
          <Button onClick={this.cancelClicked} color={Color.Tan} isDisabled={this.state.isCreatingProject}>Cancel</Button>
          <Button onClick={this.createClicked} color={Color.Primary} isLoading={this.state.isCreatingProject}>Create Project</Button>
      </div> */}
  </div>
}

}
