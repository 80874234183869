import { Job,isJob, GGDictionary, JobStatus, DataSet, MLModel, MLModelData, CreateLogger, OrganisationMembership, User, Project, ProjectMembership, Invite, Organisation, InviteStatus, ProjectMembershipType} from '@swivl/great-grey-lib';
import { ActionType, Actions, ItemUpdatedAction, Action } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import { Model } from '../Model';
import Service from '../../Services/Service';
import { start } from 'repl';
import { Toast } from '../../Services/ToastService';

const log = CreateLogger("OrgMembershipModel")

export interface OrgMembershipModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<OrganisationMembership>
  projects?:GGDictionary<Project>
  invites?:Invite[]
}> {}

export class OrgMembershipModel extends BaseModel<OrganisationMembership,OrgMembershipModelState>  {
  static get Model():OrgMembershipModel {   
    console.log("WHAT IS THIS", this);
    
    
    
    if (OrgMembershipModel._Model) { return OrgMembershipModel._Model; }  else {   OrgMembershipModel._Model = new OrgMembershipModel(); return OrgMembershipModel._Model ; } }
  private static _Model:OrgMembershipModel;
  private constructor() { 
    super();
    Actions.Controller.subscribeTo(this, [ ActionType.CurrentMembershipUpdated ] )
  }
  
  get projects():GGDictionary<Project> | undefined {  return (this.state as OrgMembershipModelState).projects }
  get projectsArray():Project[] | undefined {   const projects = this.projects;    return (projects) ? Object.values(projects) : undefined }


  handleAction(action:Action) {
    if (action.type === ActionType.CurrentMembershipUpdated) {
      console.log("RESETTING OrgMembership Model");
      this.state = {isLoading:false, items:undefined, projects:undefined} as OrgMembershipModelState
      
    }
  }
  ENTITY_NAME = "organisation_membership";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.OrgMembershipsLoaded,
    ItemFetched : ActionType.OrgMembershipFetched,
    ItemUpdated : ActionType.OrgMembershipUpdated,
    ItemDeleted : ActionType.OrgMembershipDeleted,
    ItemCreated : ActionType.OrgMembershipCreated
  } 

  //NOTE THIS IS UNSAFE AND ONLY TEMPORARY
  getAllUsers():Promise<any> {
    console.warn("UNSAFE ACTION");
    return Service.API.getItemsAndIds<User>("users").then((results) => {
      log("Results", results)
      
      return results; 
      
    }).catch(Service.Toast.error)
    
    return Promise.reject("Unable To Fetch Users") 
  }

  inviteUser(email:string, projectMembershipType:ProjectMembershipType, project:Project) {

      let invite:any = {
      email:email,
      projectId:project.id,
      projectMembershipType:projectMembershipType,
      organisationId:Model.Session.state.currentMembership.organisationId
    } 

    return Service.API.post<Invite>('invite', invite).then((result) => {
      console.log("Woot", result);
      var state:any = this.state; 
      if (!state.invites) { state.invites = []; }
      state.invites.push(result)
      this.state = state;  
      Service.Toast.success("User Invited")
      Actions.Controller.trigger({type:ActionType.ProjectUpdated, item:project});

    }).catch((e) => {
      // const message = (e && e.message) ? e.message : "Error Adding User To Project"
      Service.Toast.error(e)
    })

  }

  addMemberToProject(user:User, project:Project) {



    console.log("Add User To Project",user.id, project.id);
    return Service.API.post<ProjectMembership>(`organisation_membership/project/${project.id}/members/${user.id}`, {}).then((result) => {
      console.log("Woot", result);
      return this.loadProjectsAndMembers()
    }).catch((e) => {
      // const message = (e && e.message) ? e.message : "Error Adding User To Project"
      Service.Toast.error(e)
    })
  }  
  removeUserFromProject(membership:ProjectMembership, project:Project) {
    var state:OrgMembershipModelState = this.state; 
    if (state.projects[project.id]){
      for (let i = 0; i < project.members.length; i++) {
        if (project.members[i].id === membership.id) {
          project.members.splice(i,1)
          break; 
        }
        
      }      
      state.projects[project.id] = project;
      this.state = state; 
      Actions.Controller.trigger({type:ActionType.ProjectUpdated, item:project});
    }

    return Service.API.deleteItem<ProjectMembership>(`organisation_membership/project/${project.id}/membership`, membership).then((result) => {
      console.log("Woot", result);
      return this.loadProjectsAndMembers()
    }).catch((e) => {
      // const message = (e && e.message) ? e.message : "Error Adding User To Project"
      Service.Toast.error(e)
    })
  }

  removeUserFromOrg(membership:OrganisationMembership, project:Organisation) {
    if (membership.userId === Model.Session.state.user.id) {
      Service.Toast.error("You can't remove yourself from an org.");
      return; 
    }


    var state:OrgMembershipModelState = this.state; 
    if (state.items){
      for (const key in state.items) {
        if (Object.prototype.hasOwnProperty.call(state.items, key)) {
          const element = state.items[key];
          if (state.items[key].id === membership.id) {
            delete state.items[key]; 
          }
          }
        }
      }
      Actions.Controller.trigger({type:ActionType.OrgMembershipDeleted, item:membership});

      


    Service.API.deleteItem<OrganisationMembership>("organisation_membership", membership)
    .then((_) => {
    })
    .catch((e) => {
      // const message = (e && e.message) ? e.message : "Error Adding User To Project"
      Service.Toast.error(e)
    })
  }

  // async createUserAndAddToOrg(  firstName:string,
  //   lastName:string,
  //   email:string,
  //   password:string ) {
  //     try {
  //       const response = await Service.API.signUp(email,password,firstName,lastName);
  //       console.log("response", response);
        
  //       if (response && response.session && response.session.user) {
  //         this.inviteUserToOrg(response.session.user)
  //       }
  //     } catch(e) {
  //       Toast.Service.error(e)
  //       return Promise.reject(e);
  //     }
  //   }


  //   async createUserAndAddToProject(  firstName:string,
  //     lastName:string,
  //     email:string,
  //     password:string,
  //     project:Project
  //     ) {
  //       try {
  //         const response = await Service.API.signUp(email,password,firstName,lastName);
  //         console.log("response", response);
          
  //         if (response && response.session && response.session.user) {
  //           await this.inviteUserToOrg(response.session.user);
  //           this.addMemberToProject(response.session.user, project)
  //         }
  //       } catch(e) {
  //         Toast.Service.error(e)
  //         return Promise.reject(e);
  //       }
  //     }
  
  async loadInvites() {
    const invites = await Service.API.getItems<Invite>("invites");
    console.log("invites", invites)
    this.state = {...this.state, ...{invites:invites}}
    Actions.Controller.trigger({type:ActionType.ProjectMembershipsLoaded});

  }
  async revokeInvite(invite:Invite) {
    var state:OrgMembershipModelState = this.state;   
    for (let index = 0; index < state.invites.length; index++) {
      if (state.invites[index].id === invite.id) {
        state.invites[index].status = InviteStatus.Revoked;
        break; 
      }
    
    }
    this.state = state; 

    const inv = await Service.API.post("invite/" + invite.id + "/revoke", {} )
    Actions.Controller.trigger({type:ActionType.ProjectMembershipsLoaded});

  }
 
    async loadProjectsAndMembers() {
      const response = await Service.API.getItemsAndIds<Project>("organisation_membership/project/members");
      this.state = {...this.state, ...{projects:response}}
      Actions.Controller.trigger({type:ActionType.ProjectMembershipsLoaded});


      
      
    }


    async updateProject(project:Project) {
      var state:OrgMembershipModelState = this.state; 
      if (state.projects[project.id]){
        state.projects[project.id] = project;
        this.state = state; 
        Actions.Controller.trigger({type:ActionType.ProjectUpdated, item:project});
      }

      return Service.API.updateItem("organisation_membership/project", project)
    }

    async createProject(name:string, projectLead?:User) {
     
      const projData = {name:name}
      return Service.API.createItem<Project>("organisation_membership/project",projData as Project)
      .then((project:Project) => {
        if (projectLead) {
          return this.addMemberToProject(projectLead, project)
        }
      })
    }


    async updateProjectMembership(projectMembership:ProjectMembership) {
      var state:OrgMembershipModelState = this.state; 
      if (state.projects[projectMembership.projectId]){
        let project = state.projects[projectMembership.projectId]
        for (let i = 0; i < project.members.length; i++) {
          if (project.members[i].id === projectMembership.id) {
            project.members[i] = projectMembership;
            break; 
          }
          
        }      
        state.projects[project.id] = project;
        this.state = state; 
        Actions.Controller.trigger({type:ActionType.ProjectUpdated, item:project});
      }

      return Service.API.updateItem<ProjectMembership>(`organisation_membership/project/${projectMembership.projectId}/membership`, projectMembership)
      .catch((e) => {
        Service.Toast.error(e)
      })
      // DO API UPDATE
    }
}
