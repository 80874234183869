import React from 'react';
import { Rect, Line } from 'react-konva';

class Crosshairs extends React.Component<any> {
  // compare batchDraw() and draw();

  line1:any;
  line2:any;
  componentDidUpdate() {
//     // this.rect.getLayer().draw();
this.line1.getLayer().batchDraw();
this.line2.getLayer().batchDraw();
}

//   handleChange = (event) => {
//     const {
//       props: { onTransform },
//     } = this;
//     const shape = event.target;
//     // take a look into width and height properties
//     // by default Transformer will change scaleX and scaleY
//     // while transforming
//     // so we need to adjust that properties to width and height
//     onTransform({
//       x: shape.x(),
//       y: shape.y(),
//       width: shape.width() * shape.scaleX(),
//       height: shape.height() * shape.scaleY(),
//       rotation: shape.rotation(),
//     });
//   };

//   // if use rect.draw(), the new rectangle will cover its transformer
//   handleMouseEnter = (event) => {
//     const shape = event.target;
//     // shape.stroke('#3DF6FF');
//     shape.getStage().container().style.cursor = 'move';
//     // this.rect.draw();
//     this.rect.getLayer().draw();
//   };

//   handleMouseLeave = (event) => {
//     const shape = event.target;
//     // shape.stroke('#00A3AA');
//     shape.getStage().container().style.cursor = 'crosshair';
//     // this.rect.draw();
//     this.rect.getLayer().draw();
//   };

  render() {
     

    const {
      props: {
        mousePos, width, height, name, stroke, fill, selectedRectName, selectedStroke
      },
    } = this;
    
    if (!mousePos ) { return null; }
    return <>
    <Line width={1} 
    listening={false}
    points={[mousePos.x, 0, mousePos.x, height]} stroke='white' 
    
            ref={(node) => {
          this.line1 = node;
        }}
    />
        <Line width={1}     listening={false}
 points={[0, mousePos.y, width, mousePos.y]} stroke='white' 
    
    ref={(node) => {
  this.line2 = node;
}}
/>
  </>;

    // return (
    //   <Rect
    //     x={x}
    //     y={y}
    //     width={width}
    //     height={height}
    //     // force no scaling
    //     // otherwise Transformer will change it
    //     scaleX={1}
    //     scaleY={1}
    //     fill={fill}
    //     stroke={(selectedRectName && selectedRectName == name) ? selectedStroke : stroke}
    //     strokeWidth={2}
    //     name={name}
    //     // save state on dragend or transformend
    //     onDragEnd={handleChange}
    //     onTransformEnd={handleChange}
    //     onMouseEnter={handleMouseEnter}
    //     onMouseLeave={handleMouseLeave}
    //     draggable
    //     ref={(node) => {
    //       this.rect = node;
    //     }}
    //   />
    // );
  }
}

export default Crosshairs;
