import React from 'react';


import "./DeleteEntityPopover.scss";

import { Entity } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';



// import * as Model from '../../Models/Model';
interface Props {
    entity:Entity;
    deleteEntity(entity:Entity)
    deselect()
}


export default class DeleteEntityPopover extends React.Component<Props> {
  node:any
  constructor(props:Props) {
    super(props)

 
    this.deleteClicked   = this.deleteClicked.bind(this)
   

  }

  deleteClicked() { 
    this.props.deleteEntity(this.props.entity)
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)

  }
  handleClick = (e:MouseEvent) => {
    if (!e.shiftKey && !(this.node && this.node.contains(e.target))) {
      this.props.deselect()
    }
  }
  
  
  render() {

    return <div 
            ref={node => this.node = node}
            onClick={this.deleteClicked} 
            className="DeleteEntityPopover">
        <FontAwesomeIcon icon={faTrash} onClick={this.deleteClicked} />

    </div>
  }

}
