import React, { createRef } from 'react';
import { CreateLogger, ShortId } from '@swivl/great-grey-lib';
import "./TimelineControlsView.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay, faUndo } from '@fortawesome/free-solid-svg-icons'



const log = CreateLogger("AudioSplitExersiseView:TimelineControlsView");




interface TimelineControlsViewProps { 
    isPlaying:boolean; 
    playClicked()
    pauseClicked()
    backClicked()
    forwardClicked()
}




export class TimelineControlsView extends React.Component<TimelineControlsViewProps> {


    
  render() {    

    
    const playOrPause = (this.props.isPlaying) ?
    <div className="iconAndText" onClick={this.props.pauseClicked}>
        <div className="icon"><FontAwesomeIcon icon={faPause} /></div>
        <span>Pause</span>
        </div> :
    <div className="iconAndText" onClick={this.props.playClicked}>
        <div className="icon play"><FontAwesomeIcon icon={faPlay} /></div>
        <span>Play</span>
        </div>;


      return <div className="TimelineControlsView">

<div className="iconAndText" onClick={this.props.backClicked}>
        <div className="icon"><FontAwesomeIcon icon={faUndo} /></div>
        <span>-10 sec</span>
        </div>

      {playOrPause}

      <div className="iconAndText" onClick={this.props.forwardClicked}>
        <div className="icon forward"><FontAwesomeIcon icon={faUndo} /></div>
        <span>+10 sec</span>
        </div>

    </div>
    
  }
}