import React from 'react';
import { Button } from '@swivl/great-grey-components';
import { CreateLogger,Assignment,WorkRowStatus,AssignmentWork ,AssignmentWorkRow, AssignmentStatus, CreateRowChange_Skipped, CreateRowChange_Unchanged, TaskType} from '@swivl/great-grey-lib';



import { Model } from '../../Models/Model';
import { FinishCard } from '../Assignment/Exercises/Card/FinishCard';
import { ExerciseCardProps } from '../Assignment/Exercises/Card/ExerciseCard';
import { TaskViews } from '../../TaskViews/TaskViews';
import { ExerciseNav } from '../Assignment/Exercises/components/ExerciseNav/ExerciseNav';
import { ImageBoundingBoxView } from './ImageBoundingBoxView';
import AnnotationTool from './AnnotationTool/AnnotationTool'
import { InstructionBox } from './InstructionBox/InstructionBox';

const log = CreateLogger("Exercise:Main");

interface WorksInterface {
  prevWork?:AssignmentWorkRow,
  work?:AssignmentWorkRow,
  nextWork?:AssignmentWorkRow; 
}

interface Props {
    assignment:Assignment;

}
interface State {
  isInstructionBoxOpen:boolean
}
export class DemoExercisesView extends React.Component<Props,State> {
  constructor(props:any) {
    super(props) 
    this.state = {isInstructionBoxOpen:false}

    this.gotoPrev   = this.gotoPrev.bind(this)
    this.skip       = this.skip.bind(this)
    this.finish     = this.finish.bind(this)
    this.update     = this.update.bind(this)
    this.unchanged  = this.unchanged.bind(this)
    this.save       = this.save.bind(this)
    this.getWorks   = this.getWorks.bind(this);
  }
  gotoPrev() {
    const {prevWork} = this.getWorks();
    prevWork.tempData  = prevWork.change; 
    prevWork.change  = null;
    prevWork.trust = 1;
    let assignment = this.props.assignment;
    assignment.work[prevWork.originalRowId] = prevWork; 
    Model.Assignment.update(assignment, true);
  }
  skip() {
    let {work} = this.getWorks();
      work.change  = CreateRowChange_Skipped();
      work.trust = 1;
      let assignment = this.props.assignment;
      assignment.work[work.originalRowId] = work; 
      Model.Assignment.update(assignment, true);
  }
  update(work:AssignmentWorkRow) {
    let assignment = this.props.assignment;
    work.trust = 1;
    assignment.work[work.originalRowId] = work; 
    Model.Assignment.update(assignment, false);
  }
  unchanged(work:AssignmentWorkRow) {
      work.change  = CreateRowChange_Unchanged()
      work.trust = 1;
      let assignment = this.props.assignment;
      assignment.work[work.originalRowId] = work; 
      Model.Assignment.update(assignment, true);
  }


  save(work:AssignmentWorkRow) {
    work.trust = 1;
    let assignment = this.props.assignment;
    assignment.work[work.originalRowId] = work; 
    Model.Assignment.update(assignment, true);
  }

  finish() {
    // let {work} = this.getWorks();
    let assignment = this.props.assignment;
    assignment.status = AssignmentStatus.Completed;
    Model.Assignment.finishTask(assignment);
    Model.Navigation.setPath("/task")
  }
  getWorks():WorksInterface {
    const assignment =  this.props.assignment; 
    let prevWork:AssignmentWorkRow,work:AssignmentWorkRow, nextWork:AssignmentWorkRow; 
    for (let i = 0; i < assignment.workOrder.length; i++) {
      let tempWork = assignment.work[assignment.workOrder[i]];
      if (!tempWork.change) {
        work = tempWork;
        if (i > 0) { prevWork = assignment.work[assignment.workOrder[i-1]]}
        if (i < assignment.workOrder.length - 1) { nextWork = assignment.work[assignment.workOrder[i+1]]}
        break;
      }
    }
    if (!work) {
       prevWork = assignment.work[assignment.workOrder[assignment.workOrder.length-1]];
    }
    return { prevWork:prevWork, work:work, nextWork:nextWork }
  }
  
  
  finishedAccept() {
    Model.Navigation.setPath("/tasks")
  }

  renderFinishCard(assignment, selectedCard) {
    let props = {
      id:"Finish_Card",
      assignment:assignment,
      selectedCard:selectedCard,
      finishClicked:this.finish
    }
    return <FinishCard  key="Finish_Card" {...props} />     
  }

  renderSingleCard() {

    const assignment =  this.props.assignment; 

    

    const work = assignment.work[assignment.workOrder[0]];
    const selectedCard = (work) ? work.originalRowId : "Finish_Card"
    var card;
    if (work)     { 
      const props:ExerciseCardProps = {
        id:work.originalRowId,
        work:work,
        assignment:assignment,
        selectedCard:work.originalRowId,
        canGotoPrev:(work) ? true:false,
        gotoPrev:this.gotoPrev,
        update:this.update,
        save:this.save,
        canSkip:false,
        skip:this.skip,
        unchanged:this.unchanged
      }
         card   = TaskViews.Exercise(props)

      }

    return <div className="ExercisesView">
     
     
        {card}
    </div>   
  } 
  toggleInstructionBox = () => {
    console.log("Toggle");
    
    this.setState({isInstructionBoxOpen:!this.state.isInstructionBoxOpen})}
  render() {
    if (this.props.assignment.type === TaskType.Sentiment)  {
      return this.renderSingleCard();
    }

    const assignment =  this.props.assignment; 
    const {prevWork, work, nextWork} = this.getWorks();
      
    

    return <div className="ExercisesView">
  
  

          <div className="exerciseAndInstructionBox">

            <InstructionBox  
            isOpen={this.state.isInstructionBoxOpen}
            toggleInstructionBox={this.toggleInstructionBox}
            />


            <AnnotationTool
              imageURL="https://great-grey-cdn.s3-us-west-1.amazonaws.com/owl1.png"
              imageWidth={733}
              imageHeight={525}
            
            />


            <div className="demoTools">
              <img src="https://great-grey-cdn.s3-us-west-1.amazonaws.com/toolbar%402x.png" width={50} height={131} /> 

            </div>
          </div>
    

    </div>   
  }

}
