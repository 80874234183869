import React from 'react';
import { Model } from '../../Models/Model';
import { SortAndSearch, ListItem, ListItemContentTextAndFooter, Button, NavTextButton} from '@swivl/great-grey-components';
import { UploadDataPoints } from './Create/UploadDataPoints';
import { Modal } from '../Layout/Modal/Modal';
import { ShortId, DataSet,  NumberWithCommas, CreateLogger, GGDictionary } from '@swivl/great-grey-lib';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DataSetDetailsView } from './DataSetDetailsView/DataSetDetailsView';
import { ActionType, ActionsConsumer, Action, Actions } from '../../Actions/Actions';
import { BaseScreenWithColumns, BaseScreenWithColumnsState, SortType } from '../Base/BaseScreenWithColumns';
import "./DataSetsScreen.scss"
import { DataPointsDetailsView } from './DataPoints/DataPointsView';
const log = CreateLogger("DataSetsScreen")
const moment = require('moment');

interface DataSetsScreenProps {
  setNavBar(left:any|undefined, center:any|undefined, right:any|undefined);

}
interface DataSetsScreenState extends BaseScreenWithColumnsState {
  isShowingModal:boolean;
  dataSets?:Array<DataSet>;
  selectedDataSet?:DataSet;
}

export  class DataSetsScreen extends BaseScreenWithColumns<DataSetsScreenProps, DataSet> {
  modalKey:string;
  model = "DataSet";
  sortOptions = [
    {value:SortType.Newest, label:SortType.Newest},    
    {value:SortType.Oldest, label:SortType.Oldest},
    {value:SortType.Type,   label:SortType.Type},
    {value:SortType.AtoZ,   label:SortType.AtoZ},
    {value:SortType.ZtoA,   label:SortType.ZtoA}
 ];
  constructor(props:DataSetsScreenProps) {
    super(props)
    this.state = { ...this.state, isShowingModal:false }
    
    Actions.Controller.subscribeTo(this, [
      ActionType.CurrentMembershipUpdated,

      ActionType.DataSetsLoaded,
      ActionType.DataSetFetched
      ,ActionType.DataSetUpdated,
      ,ActionType.DataSetDeleted,
      ActionType.NavigationPathUpdated,
      ActionType.DataPointsLoaded,
    
    ] )


  }

  componentDidMount() {
    Model.DataSet.load();
    Model.DataPoint.load();
    this.props.setNavBar(null,<h3>Data Sets</h3>,
      <NavTextButton onClick={this.showModal} text={"+ Add Data Set"} />
   )
  }
  renderItem(item:DataSet) {
    return <ListItemContentTextAndFooter
                  text={item.name}
                  footerText={moment(item.createdAt).format("MMM D, YYYY")}
                  footerRightText={NumberWithCommas(item.rowCount) + " Data Points"} />
  }

  _renderList() {
    const dataPointsCount = Model.DataPoint.unnasigned?.length
    if (!dataPointsCount) { return super._renderList() }
    return <> 
      <a className="dataPointsCell" href="/dataset/datapoints">
        {dataPointsCount} Unassigned Data Point{(dataPointsCount > 1) ? "s" :""}
      </a>
      {super._renderList()}
    </>
    
  }
  
  renderModal() {  return <Modal key={this.modalKey} closeModal={this.hideModal}><UploadDataPoints closeModal={this.hideModal}/></Modal>}
  renderDetails(item:any) { 
    console.log("Datapoints", Model.DataPoint.state.items)
    return <DataSetDetailsView dataSet={item} />}


    renderContent() {
      console.log("RENDER MEEEEE")
       if(Model.Navigation.second() === "datapoints") {
         console.log("WOOF")
         return <DataPointsDetailsView />
       } else { return super.renderContent() }

    }
}
