import React from 'react';
import { MLModel, CreateLogger,MLModelData, DataSetCreationMethod, Capitalize, NumberWithCommas } from '@swivl/great-grey-lib';
import { Pill, ButtonWithOptions, Button, Color } from '@swivl/great-grey-components';
import { Model } from '../../../Models/Model';
import './MLModelDetailsView.scss'

const moment = require('moment');

const log = CreateLogger("job:MLModelDetailsView")

interface MLModelDetailsViewProps {
    mLModel:MLModel;
}
interface MLModelDetailsViewState {

}
const Examples_To_Show = 20;
export  class MLModelDetailsView extends React.Component<MLModelDetailsViewProps,MLModelDetailsViewState> {
  constructor(props:any) {
    super(props)
    this.state = {};
    this.deleteClicked = this.deleteClicked.bind(this)

  }

  


  deleteClicked() {

  }
  renderHeader() {
    return <div className="detailsHeader">
            <div className="actionButton">
    
    
            </div>
            <h2>{this.props.mLModel.name}</h2>

            Created by {this.props.mLModel.createdBy.firstName} {this.props.mLModel.createdBy.lastName} on {moment(this.props.mLModel.createdAt).format("MMM D, YYYY")} 
            <br/>
            Model type: {this.props.mLModel.type}
          </div>;
  }



  renderIntents() {
    const data = this.props.mLModel.data;
    if (data.intents) {
      let output = []
      for (let index = 0; index < data.intents.length; index++) {
        if (index > Examples_To_Show - 1) {
          output.push(<Pill key={"intent_"+index}hideDelete>+ {data.intents.length - Examples_To_Show} More...</Pill>)
          break;
       
        }

        const element = data.intents[index];
        output.push(<Pill key={"intent_"+index}hideDelete>{element.name}</Pill>)
    
      }
      return <div className="datarow clearfix">
      <h3 className="clearfix">Intents</h3>
      {output}
    </div>
    }
 
  }

  renderUtterances() {
    const data = this.props.mLModel.data;
    if (data.utterances) {
      let output = []
      for (let index = 0; index < data.utterances.length; index++) {
        if (index > Examples_To_Show - 1) {
          output.push(<div key={"ut"+index}>+ {data.utterances.length - Examples_To_Show} More...</div>)
          break;
       
        }

        const element = data.utterances[index];
        output.push(<div key={"ut"+index}>"{element.text}"</div>)
    
      }
      return <div className="datarow clearfix">
      <h3 className="clearfix">Utterances</h3>
      {output}
    </div>
    }
 
  }

  renderEntities() {
    const data = this.props.mLModel.data;

    if (data.entities) {
      console.log("HAS ENTITIES", data.entities)
      let output = []
      for (let index = 0; index < data.entities.length; index++) {
        if (index > Examples_To_Show - 1) {
          output.push(<Pill key={"ent_"+index}hideDelete>+ {data.entities.length - Examples_To_Show} More...</Pill>)
         break;

        }
        output.push(<Pill key={"ent_"+index}hideDelete>{data.entities[index].name}</Pill>)
    
      }
      return <div className="datarow clearfix">
          <h3 className="clearfix">Entities</h3>
          {output}
        </div>
    }
 
  }

  renderCategorizations() {
    const data = this.props.mLModel.data;

    if (data.categories) {
      console.log("HAS categories", data.categories)
      let output = []
      for (let index = 0; index < data.categories.length; index++) {
 
        if (data.categories[index].specific && data.categories[index].specific.length) {

          
          output.push( <div > 
            <div>
              <Pill key={"cat_"+index}hideDelete>{data.categories[index].name}</Pill>
              </div>
            <label>Specific Categories</label>

            <div className="subCategories">
            {data.categories[index].specific.map((item) => <Pill key={"cat_"+item.id}hideDelete>{item.name}</Pill> )}
          </div></div>)
        } else {
          output.push(<div><Pill key={"cat_"+index}hideDelete>{data.categories[index].name}</Pill></div>)

        }
      }
      return <div className="datarow clearfix">
          <h3 className="clearfix">Categories</h3>
          {output}
        </div>
    }
 
  }






  render() {
    if (!this.props.mLModel) { return null; }



    let content;
    if (this.props.mLModel.data) {
      content = <div className="dataRep">
        {this.renderIntents()}
        {this.renderEntities()}
        {this.renderUtterances()}
        {this.renderCategorizations()}
      </div>
    } else {
      content = <div className="ProgressBlock">
        <progress id="dataProgress" className="progress is-primary" value="0" max="100">50%</progress>
      </div>;
    }
    return <div className="MLModelDetailsView">
      {this.renderHeader()}
      {content}
    </div>;
  }

}
