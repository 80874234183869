import React from 'react';
import { Image } from 'react-konva';
interface Props {
  image:HTMLImageElement 

}
class AnnotationImage extends React.Component<Props> {
  state = {
    image: null,
  };
  componentDidMount() {

  }
 
  render() {
    const {
      props: { image },
    } = this;
    return (
      <Image
        height={image.height}
        width={image.width}
        image={image}
      />
    );
  }
}

export default AnnotationImage;
