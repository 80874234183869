import React from 'react';
import { SelectionList,Option } from '@swivl/great-grey-components';
import { CreateLogger,CreateRowChange_Transcribe,Assignment,WorkRowStatus,CreateRowChange_Intent, TaskData_Intent, AssignmentWorkRow, Intent, DataSetRow, RowChangeType } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../Screens/Assignment/Exercises/Card/ExerciseCard';
import TextareaAutosize from 'react-textarea-autosize';

import "./TranscribeExerciseView.scss"
import { AudioPlayer } from '../../Shared/AudioPlayer/AudioPlayer';
const log = CreateLogger("TranscribeExercise:Main");




export class TranscribeExerciseView extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  constructor(props:any) {
    super(props) 

    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
    let text = (originalRow.text) ? originalRow.text : ""

    if (this.props.work.change && this.props.work.change.type && this.props.work.change.type === RowChangeType.Transcribe &&  this.props.work.change.text) {
      text = this.props.work.change.text;
    } else  if (this.props.work.tempData && this.props.work.tempData.type && this.props.work.tempData.type ===  RowChangeType.Transcribe &&  this.props.work.tempData.text) {
      text = this.props.work.tempData.text;
    } else if (originalRow.text) {
      text = originalRow.text;
    } else if (originalRow.automaticTranscription) {
      text = originalRow.automaticTranscription;
    }


    this.state = {isShowing:false, data:text} 

    // let intents = (this.props.assignment.task as TaskData_Intent).intents;
    // let row:AssignmentWorkRow = props.work;
    this.onChange = this.onChange.bind(this)

  }
  doneClicked() {
      if (!this.state.data || this.state.data.length == 0) {
        alert("You must enter a transcription or skip.");
        return; 
      }
      let work = this.props.work;    
      work.change = CreateRowChange_Transcribe(this.state.data);
      // this.props.update(work);
      this.props.save(work);

  }


  handleKeyDown(event:KeyboardEvent)  {
    log("Key Down");
    if (!this.state.isShowing) {
      log("I'm not supposed to be showing");
    }
    if  (event.keyCode === 13) {
    //   let row:AssignmentWorkRow = this.props.work;
    //   if (this.hasUpdated && this.selectedIntent) {
    //     row.change = CreateRowChange_Intent(this.selectedIntent)
    //   } else {
    //     const intents = (this.props.assignment.task as TaskData_Intent).intents;
    //     row.change = CreateRowChange_Intent(intents[0])
    //   } 
    //   log("Change", row.change)
    //   this.props.save(row);
    }
  }
    onChange?(event:React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({data:event.target.value});
    }
    renderKey() { return null; }
  
  renderContent() {
    let intents = (this.props.assignment.task as TaskData_Intent).intents;

    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
    
    return <div className="TranscribeExerciseView">
                <AudioPlayer audioURL={originalRow.audioURL} startTime={originalRow.audioStartTime} endTime={originalRow.audioEndTime} />

                <TextareaAutosize   
                  placeholder="Type what you hear…"
                  value={this.state.data} 
                  onChange={this.onChange} 
                  autoFocus />
           </div>;
  }
}
