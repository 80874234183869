import { faQuestionCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@swivl/great-grey-components";
import { Category, Instructions, TaskCategory } from "@swivl/great-grey-lib";
import React from "react";
import ReactMarkdown from 'react-markdown'
import { Model } from "../../../../../Models/Model";

import "./InstructionsView.scss"
interface Props { 
    instructionsId:string
    instructionsExpanded:boolean
    toggleInstuctions()
}

interface State {
    instructions?:Instructions

}

export default class InstructionsView extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {}
        this.onClick = this.onClick.bind(this)
    }

    componentDidMount() {
    
            Model.Instructions.fetchById<Instructions>(this.props.instructionsId).then(( instructions) => {
                this.setState({instructions:instructions})
            })
          
    }

    onClick() {
        // this.props.categorySelected(this.props.category)
        this.props.toggleInstuctions()
    }
    renderContent() {
        if (!this.state.instructions) {
            return <div className="instructionsLoader"><FontAwesomeIcon icon={faSpinner} spin /></div>
        }
        return <ReactMarkdown 
        linkTarget="_blank"
        className="markdown"
        children={this.state.instructions.markdown} />
    }
    render() {
        return <div className="InstructionsView" onClick={this.onClick}>
            <div className="questionMarkMask"><FontAwesomeIcon icon={faQuestionCircle} /> </div>
            <div className="instructionsContent">
            {this.renderContent()}
            </div>
      </div>
    }
}