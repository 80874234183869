import { JobModel      } from './Models/JobModel'


import { SessionModel       } from './Models/SessionModel'
import { NavigationModel    } from './Models/NavigationModel'
import { KeyboardModel      } from './Models/KeyboardModal'
import { DataSetModel      } from './Models/DataSetModel'
import { MLModelModel       } from './Models/MLModelModel'
import { SubTaskModel       } from './Models/SubTaskModel'
import { AssignmentModel } from './Models/AssignmentModel'
import { JobReviewModel } from './Models/JobReviewModel'
import { OrgMembershipModel } from './Models/OrgMembershipModel'
import { ProjectMembershipModel } from './Models/ProjectMembershipModel'
import { InstructionsModel } from './Models/InstructionsModel'
import { DataPointModel } from './Models/DataPointModel'



export class Model {
  static initModels() { 
    let keyboardmodel = Model.Keyboard
    console.log(" Model.Keyboard", Model.Keyboard)
  }
  static get Navigation():NavigationModel { return NavigationModel.Model; }
  static get Session():SessionModel       { return SessionModel.Model; }
  static get Keyboard():KeyboardModel     { return KeyboardModel.Model; }


  //
  static get DataSet():DataSetModel                   { return DataSetModel.Model; }
  static get DataPoint():DataPointModel                 { return DataPointModel.Model; }
  static get Job():JobModel                             { return JobModel.Model; }
  static get JobReview():JobReviewModel                 { return JobReviewModel.Model; }
  static get MLModel():MLModelModel                     { return MLModelModel.Model; }
  static get Instructions():InstructionsModel           { return InstructionsModel.Model; }
  static get SubTask():SubTaskModel                     { return SubTaskModel.Model; }
  static get Assignment():AssignmentModel               { return AssignmentModel.Model; }
  static get OrgMembership():OrgMembershipModel         { return OrgMembershipModel.Model; }
  static get ProjectMembership():ProjectMembershipModel { return ProjectMembershipModel.Model; }


  static reset() {
    DataSetModel.reset()
    DataPointModel.reset()
    JobModel.reset()
    JobReviewModel.reset()
    MLModelModel.reset()
    InstructionsModel.reset()
    SubTaskModel.reset()
    AssignmentModel.reset()
    OrgMembershipModel.reset()
    ProjectMembershipModel.reset()
  }
}


