import { CreateLogger } from '@swivl/great-grey-lib';

const log = CreateLogger("AudioSplitExersiseView:Loader");

interface AudioHelperOutput {
    arrayBuffer:ArrayBuffer;
    audioBuffer:AudioBuffer; 
    dataPoints:number[];
}

export async function loadAudio(mediaURL:string, startTime?:number, endTime?:number):Promise<AudioHelperOutput> {
    window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    console.log("mediaURL",mediaURL)

    
    const audioContext = new AudioContext();
    
    const audioFile = await fetch(mediaURL);
    // const audioFile = await fetch('/testAudio/59458-16-59-45_01.mp3')
    const arrayBuffer = await audioFile.arrayBuffer()
    log("arrayBuffer",arrayBuffer)
    let audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
    log("audioBuffer",audioBuffer)

    if (typeof startTime !== 'undefined' && typeof endTime !== 'undefined') {
      audioBuffer = await audioBufferSlice(audioContext, audioBuffer, startTime * 1000, endTime * 1000)
      log("sliced audioBuffer",audioBuffer)

    }
    const dataPoints = filterData(audioBuffer)

    return {
      arrayBuffer:arrayBuffer,
      audioBuffer:audioBuffer,
      dataPoints:dataPoints
  }
   
  }


export async function audioDurration(mediaURL:string, startTime?:number, endTime?:number):Promise<number> {
  window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;
  console.log("mediaURL",mediaURL)

  
  const audioContext = new AudioContext();
  
  const audioFile = await fetch(mediaURL);
  // const audioFile = await fetch('/testAudio/59458-16-59-45_01.mp3')
  const arrayBuffer = await audioFile.arrayBuffer()
  log("arrayBuffer",arrayBuffer)
  let audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
  log("audioBuffer",audioBuffer)

  if (typeof startTime !== 'undefined' && typeof endTime !== 'undefined') {
    audioBuffer = await audioBufferSlice(audioContext, audioBuffer, startTime * 1000, endTime * 1000)
    log("sliced audioBuffer",audioBuffer)

  }
  return audioBuffer.duration; 
  
}




   function filterData(audioBuffer) {
    const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
    const samples =  Math.ceil(audioBuffer.duration * 10); // Number of samples we want to have in our final data set
    log("number of samples",samples )
    const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
    const filteredData = [];
    for (let i = 0; i < samples; i++) {
      let blockStart = blockSize * i; // the location of the first sample in the block
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum = sum + Math.abs(rawData[blockStart + j]) // find the sum of all the samples in the block
      }
      filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }
    // return filteredData;
    const normalizeData = filteredData => {
        const multiplier = Math.pow(Math.max(...filteredData), -1);
        return filteredData.map(n => n * multiplier);
      }
    return normalizeData(filteredData);
  }




async function audioBufferSlice(audioContext:AudioContext, buffer:AudioBuffer, begin:number, end:number):Promise<AudioBuffer> {
    return new Promise((resolve, reject) => {
    var duration = buffer.duration;
    var channels = buffer.numberOfChannels;
    var rate = buffer.sampleRate;

  
    

    // milliseconds to seconds
    begin = begin/1000;
    end = end/1000;

    if (begin < 0) {
      console.warn("Warning begin less than zero", begin);
      begin = 0; 
      // return reject(new RangeError('begin time must be greater than 0'));
    }

    if (end > duration) {
      return reject(new RangeError('end time must be less than or equal to ' + duration));
    }


    

    var startOffset = rate * begin;
    var endOffset = rate * end;
    var frameCount = endOffset - startOffset;
    var newArrayBuffer;

    try {
      newArrayBuffer = audioContext.createBuffer(channels, endOffset - startOffset, rate);
      var anotherArray = new Float32Array(frameCount);
      var offset = 0;

      for (var channel = 0; channel < channels; channel++) {
        buffer.copyFromChannel(anotherArray, channel, startOffset);
        newArrayBuffer.copyToChannel(anotherArray, channel, offset);
      }
    } catch(e) {
      return reject(e); 
    }
    return resolve(newArrayBuffer)


    })
  }


  