import React, {  } from 'react';
import { CreateLogger,CreateRowChange_AudioCategorize,CreateRowChange_AudioSplit, Intent, DataSetRow, TaskType, TimelineCategory, TimelineSegment } from '@swivl/great-grey-lib';
import "./MiniAudioPlayer.scss"
import { loadAudio } from '../AudioHelper';
import { WaveformView } from '../WaveformView/WaveformView';
import { TimelineControlsView } from '../TimelineControls/TimelineControlsView';
import { PlayheadView } from './PlayheadView/PlayheadView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop, faPlay, faSpinner } from '@fortawesome/free-solid-svg-icons'

// import { TimelineView } from './components/TimelineView/TimelineView';
// import { CategoryTitles } from './components/CategoryTitles/CategoryTitles';
// import { AddSegmentToCategory, AdjustEndTime, AdjustStartTime, DeleteSegment, AdjustPosition } from './TrackHelper';
// import "./Colors/TimelineColors.scss"
// import { TimelineControlsView } from './components/TimelineControls/TimelineControlsView';

const log = CreateLogger("AudioPlayer:Main");




interface AudioPlayerProps {
    audioURL:string 
    startTime?:number 
    endTime?:number 
}

interface AudioPlayerState {
    isLoadingAudio:boolean;
    playingId?:string
    isPlaying:boolean;
    arrayBuffer?:ArrayBuffer;
    audioBuffer?:AudioBuffer; 
    dataPoints?:number[];
    duration?:number;
}

export class MiniAudioPlayer extends React.Component<AudioPlayerProps, AudioPlayerState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  playbackNode?:AudioBufferSourceNode; // If this is active it means it is playing!
  playbackStartTime?:number; 
  playbackOffset?:number; 
  pauseTime:number = 0;

  audioContext:AudioContext; 
  
  constructor(props:any) {
    super(props) 

    log("MY PROPS", props)
    

    this.state = { isPlaying:false, isLoadingAudio:false }
    if ((window as any).ggAudioContext) { 
      log("Setting shared context");
      this.audioContext  = (window as any).ggAudioContext as AudioContext; 
    } else {
      log("setting new context");
      this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      (window as any).ggAudioContext = this.audioContext;
    }


    this.playClicked      = this.playClicked.bind(this);
    this.pauseClicked     =  this.pauseClicked.bind(this);
    this.backClicked      = this.backClicked.bind(this)
    this.forwardClicked   = this.forwardClicked.bind(this)
    this.setPlayhead      = this.setPlayhead.bind(this);
    this.playAtTime       = this.playAtTime.bind(this);

    this.loadAudio = this.loadAudio.bind(this)
    
  }

 
  
  componentWillUnmount() {
    if (this.playbackNode) {
      this.playbackNode.stop();
    }
  }
  playClicked() {
    this.pauseTime = undefined;
    this.playAtTime(0)
  }
  pauseClicked() {

    const startTime  = this.playbackStartTime;
    const contextTime = this.audioContext.currentTime; 
    this.pauseTime = (contextTime - startTime) + this.playbackOffset;
    this.playbackNode.stop();
    this.setState({isPlaying:false, playingId:null})
    log("did pause")
  }
  backClicked() {
     if (this.pauseTime > 10) {this.pauseTime -= 10; } else  { this.pauseTime = 0; }
     if (this.playbackNode) {
        const startTime  = this.playbackStartTime;
        const contextTime = this.audioContext.currentTime; 
        const split = (contextTime - startTime) + this.playbackOffset; 
        if ((split  - 10 ) > 0) { 
          this.playAtTime(split - 10); 
        } else {
          this.playAtTime(0);
        }

     }
  }

  forwardClicked() {
    // if  (!this.state.duration) { return; } 

    //   if ((this.pauseTime  + 10 ) < this.state.duration ) { this.pauseTime += 10; } else  { this.pauseTime = this.state.duration; }
    
        
    //     if(this.playbackNode) {
    //       const startTime  = this.playbackStartTime;
    //       const contextTime = this.audioContext.currentTime; 
    //       const split = (contextTime - startTime) + this.playbackOffset;  
    //       if ((split  + 10 ) < this.state.duration) { 
    //         this.playAtTime(split + 10); 
    //       } else {
    //         this.playbackNode.stop()
    //         this.pauseTime = this.state.duration;
    //       } 
    //     }

  }

  setPlayhead(time:number) {
    // log("set playhead", time);
    // if (!this.playbackNode) {
    //   this.pauseTime = time; 
    // } else {
    //   this.playAtTime(time)
    // }
  } 
  

/**
 * 
 * @param time 
 * @param duration 
 * @param playingId - Used to determin what component has triggered the playback.
 */
  playAtTime(time:number, duration?:number, playingId?:string) {
    //   time = time + this.props.startTime;
    if (!this.audioContext) {
      log.warn("NO AUDIO CONTEXT")
    }

    log("Play at time", time, duration)
    this.setState({isPlaying:true, playingId:playingId, isLoadingAudio:false})
    if (this.playbackNode) { this.playbackNode.stop(); log("Stopping existing node"); }
    if (this.state.audioBuffer) {
      try {

      this.playbackNode   =  this.audioContext.createBufferSource();
      if (this.pauseTime) {  time = this.pauseTime; }

      this.playbackOffset = time;
      this.playbackStartTime = this.audioContext.currentTime;
      // set the buffer in the AudioBufferSourceNode
      this.playbackNode.buffer = this.state.audioBuffer;
      // connect the AudioBufferSourceNode to the
      // destination so we can hear the sound
      this.playbackNode.connect(this.audioContext.destination);
      // start the source playing
   
        log("about to play")
        if (duration) {
          this.playbackNode.start(0,time,duration);
        } else {
          this.playbackNode.start(0,time);
        } 
      } catch (e) {
        log.warn("Error on playback", e);
      }
      var self = this;
      this.playbackNode.onended =  function() {
        if (this === self.playbackNode) {
          log("II'm me");
          self.playbackNode = null;
          self.setState({isPlaying:false, playingId:null})
        }
      }
    } else {
      log("No Audio Buffer")
    }
  }
  
  
  loadAudio() {
    this.setState({isLoadingAudio:true})
    loadAudio(this.props.audioURL, this.props.startTime, this.props.endTime).then((output) => {
        log("Loadded Audio",output)
      this.setState({  arrayBuffer:output.arrayBuffer, 
                       audioBuffer:output.audioBuffer,
                       dataPoints:output.dataPoints,
                       duration:output.audioBuffer.duration
                      })
    
                      setTimeout(() => {
                          this.playAtTime(0);
                      }, 300);
                      
    })

  }

  

  renderControl() {
      if (this.state.isPlaying) {
        return <div className="control" onClick={this.pauseClicked}><FontAwesomeIcon icon={faStop} /></div>
      }
      if (this.state.isLoadingAudio) {
        return <div className="control"><FontAwesomeIcon icon={faSpinner} spin /></div>
      }
      if (!this.state.arrayBuffer) {
        return <div className="control" onClick={this.loadAudio}><FontAwesomeIcon icon={faPlay}  /></div>
      } 
     
     return <div className="control" onClick={this.playClicked}><FontAwesomeIcon icon={faPlay}  /></div>
      
  }

  render() {    
    return <div className="MiniAudioPlayer">
                {this.renderControl()}
                <div className="playbackProgress"></div>


    </div>
    

    
  }
}
