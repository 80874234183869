import React, {useMemo} from 'react';
  import {useDropzone} from 'react-dropzone'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCloudUploadAlt, faTimesCircle, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import "./FilePicker.scss";
import { Button } from '@swivl/great-grey-components';

var convertXML = require('xml-js');
const convertCSV=require('csvtojson')

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#C3BCB9',
  borderStyle: 'dashed',
  backgroundColor: '#FDF6F3',
  color: '#413B38',
  outline: 'none',
  maxWidth: '600px',
  minHeight:'382px',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#F67902'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function Accept(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple:false,
    accept: 'text/csv, application/json',
    onDrop: files => { props.onDrop(files); console.log(files, props); }

  });

  const style = useMemo(() => ({
  ...baseStyle,
  ...(isDragActive ? activeStyle : {}),
  ...(isDragAccept ? acceptStyle : {}),
  ...(isDragReject ? rejectStyle : {})
}), [
  isDragActive,
  isDragReject
]);

  return (
    <div className="container">
      <div {...getRootProps({style:style})}>
        <input {...getInputProps()} />
        {isDragAccept && (
               <div className="dropArea">
               <span><FontAwesomeIcon icon={faThumbsUp} size="7x" /></span>
               <h4>Drop it like it's hot!</h4>
             </div>
        )}
          {isDragReject && (
             <div className="dropArea">
             <span><FontAwesomeIcon icon={faTimesCircle} size="7x" /></span>
             <h4>Invalid File Type</h4>
           </div>
          )}
          {!isDragActive && (
              <div className="dropArea">
              <span><FontAwesomeIcon icon={faCloudUploadAlt} size="7x" /></span>
              <h4>Drag and Drop you file here</h4>
              <div className="fauxButton">Choose File</div>
            </div>)}
      </div>
    </div>
  );
}

interface FilePickerProps {
  fileProcessed(rows:Array<Array<any>>);
  back();
}
interface FilePickerState {
  isLoading:boolean;
 }

export  class FilePicker extends React.Component<FilePickerProps,FilePickerState> {
    constructor(props:any) {
      super(props)
      this.state = {isLoading:false}
      this.filesSelected     = this.filesSelected.bind(this)
      this.readFile         = this.readFile.bind(this)
      // this.getSignedRequest = this.getSignedRequest.bind(this);
      // this.uploadFile       = this.uploadFile.bind(this);
      // this.handleXML        = this.handleXML.bind(this);
      this.handleCSV        = this.handleCSV.bind(this);
      // this.handleJSON       = this.handleJSON.bind(this);
      // this.handleArray      = this.handleArray.bind(this);

      this.renderProcessing = this.renderProcessing.bind(this);
    }

    filesSelected(files:Array<File>) {

      if (files  && files[0]) {
        let file:File = files[0];
        console.log("File Type", file.type);
        if (!(file.type === 'text/csv')) {
          alert("Files must be of type .csv");
        }
        this.setState({isLoading:true})
        // const element = document.getElementById("filePickerProgress");
        // if (element) {
        //   element.setAttribute("value", "50");
        // }


        this.readFile(file);
      }
    }
    

    readFile(file:File) {

      let fileReader = new FileReader();

      fileReader.onprogress =(evt) => {
        // evt is an ProgressEvent.
        console.log("On Progress Event", evt);
        if (evt.lengthComputable) {
          var percentLoaded = Math.round((evt.loaded / evt.total) * 100) * 0.75;
          // Increase the progress bar length.
          console.log("Seting value",percentLoaded)

          if (percentLoaded < 100) {
            const element = document.getElementById("filePickerProgress");

            if (element) {

              console.log("Seting value",percentLoaded)
              element.setAttribute("value", String(percentLoaded));
            }
            
          }
        }
      }

      fileReader.onloadend = (e) => {
        if (typeof fileReader.result === 'string') {
          console.log("fileReader.content",typeof fileReader.result)

          // if (file.type === 'text/xml') { this.handleXML(fileReader.result)}
          if (file.type === 'text/csv') { this.handleCSV(fileReader.result)}

        } else {
          this.setState({isLoading:false})

          alert("Error Reading File")
        }
      }
      fileReader.readAsText(file);
    }
    handleCSV(csvString:string) {
      const element = document.getElementById("filePickerProgress");
      element.setAttribute("value", String(90));

      console.log("Handle CSV");

      convertCSV({
          noheader:true,
          output: "csv"
      })
      .fromString(csvString)
      .then((csvRow)=>{
        console.log("DONE");
          console.log(csvRow) // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
          this.props.fileProcessed(csvRow);
      }).catch((e) => {
        console.warn("Error converting CSV", e);
        this.setState({isLoading:false})

        alert("There was an error converting your CSV.");
      })
    }
    

  renderProcessing() {

    return <div className="ProgressBlock">
            
            <progress id="filePickerProgress" className="progress is-primary" value={0} max="100">50%</progress>
            <h4>Processing File</h4>


    </div>;
  }
  render() {
      const content = (this.state.isLoading) ? this.renderProcessing() : <Accept className="picker" onDrop={this.filesSelected}/>;
    const buttons  = (this.state.isLoading) ? null :
    <div className="buttons">
    <Button isRound={true} isOutlined={true} onClick={this.props.back}>Cancel</Button>
    <Button isRound={true} isDisabled={true}>Next</Button>
  </div>;
    return <div className="FilePicker modalCard">
      <div className="modalHeader">
        <h3>Upload your dataset and we’ll get to work</h3>
        We support CSV
      </div>
      <div className="modalContent">
      {content}
      </div>
      <div className="modalFooter">
        {buttons}
      </div>
    </div>;
  }

}
