import { SubTask, GGDictionary,Assignment, CreateLogger} from '@swivl/great-grey-lib';
import { ActionType, Actions, ItemsLoadedAction } from '../../Actions/Actions';
import { BaseModel } from './BaseModel';
import { Model } from '../Model';
import Service from '../../Services/Service';

const log = CreateLogger("SubTaskModel")

export interface SubTaskModelState extends Readonly<{
  isLoading:boolean,
  items?:GGDictionary<SubTask>
}> {}

export class SubTaskModel extends BaseModel<SubTask,SubTaskModelState>  {
  static get Model():SubTaskModel {   if (SubTaskModel._Model) { return SubTaskModel._Model; }  else {   SubTaskModel._Model = new SubTaskModel(); return SubTaskModel._Model ; } }
  static reset()  { delete SubTaskModel._Model; }

  private static _Model:SubTaskModel;
  // private constructor() { super();  this.state = {...this.state, dataFiles:{}} }

  state:SubTaskModelState = {
    isLoading:false,
    items:undefined
  }

  ENTITY_NAME = "subtask";

  actions:GGDictionary<ActionType> = {
    Loaded      : ActionType.SubTasksLoaded,
    ItemUpdated : ActionType.SubTaskUpdated,
    ItemDeleted : ActionType.SubTaskDeleted,
    ItemCreated : ActionType.SubTaskCreated
  } 
 
  startTask(subTask:SubTask):Promise<Assignment> {
   return  Service.API.startItem<Assignment>(this.ENTITY_NAME, subTask).then((assignment) => {
    log("Wiched",assignment);
    return Promise.resolve(assignment)
   })


  }

}
