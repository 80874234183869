import { Input } from "@swivl/great-grey-components";
import { Category, TaskCategory } from "@swivl/great-grey-lib";
import React from "react";

interface Props { 
    category:TaskCategory;
    index:number; 
    categoryUpdated(category:TaskCategory, index:number)
}
interface State {}

export default class TextCategorizeCreateCategory extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }
    onChange(e:React.ChangeEvent<HTMLInputElement>) {
        let category = this.props.category;
        category.specificTitle = e.target.value; 
        this.props.categoryUpdated(category, this.props.index)
    }
    render() {
        const category = this.props.category;
        console.log("render cat", category);
        
        const specifcs = (category.specific) ? <span>{category.specific.length} Specific Options </span>: null;
        const specificTitle = (category.specific) ? <span><label>Specific Title</label><br/><Input placeholder="Specific Title" value={category.specificTitle} onChange={this.onChange} /></span>: null;

        return <div className="categoryContent">
        <div>{category.name}</div>
        {specifcs}
        {specificTitle}

      </div>
    }
}