import { toast } from 'react-toastify';
import { CreateLogger } from '@swivl/great-grey-lib';
const log = CreateLogger("toast")

export class Toast {
    static get Service():Toast {   if (Toast._Service) { return Toast._Service; }  else {   Toast._Service = new Toast(); return Toast._Service ; } }
    private static _Service:Toast;
    private constructor() { }
    

    success(msg:string) {
        toast.success(msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
            });
    }
  
    error(error?:Error | string, message?:string) {
        log.error(error, message);
        let msg;
        if (typeof error === 'string') { 
            msg = error 
        } else if (message) {
            msg = message;        
        } else if (error instanceof Error && error.message) {
            msg = error.message;
        } else if (error && error.message) {
            msg = error.message;
        } else {
            msg = "Error!"
        }
        toast.error(msg, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            });
            
    }

}
