import React from 'react';
import { MembershipRole, Organisation, OrganisationMembership, Project, ProjectMembership } from '@swivl/great-grey-lib';
import "./MyAccountView.scss"
import { Model } from '../../../Models/Model';
import { Option, Select } from '@swivl/great-grey-components';


export default class MyAccountView extends React.Component {
 
  constructor(props) {
    super(props)

    
  }

  
    render() {
      const user = Model.Session.state.user; 
        
  return <div className="MyAccountView">
          <div className="squareAvatar"></div>
            <span className="name">{user.firstName} {user.lastName}</span>
            <span className="email">{user.email}</span>
            <span className="location">{user.location}</span>

  </div>;
}

}
