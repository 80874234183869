import React from 'react';
import { Invite, InviteStatus, Project } from '@swivl/great-grey-lib';
import ProjectMemberView from './ProjectMembersView/ProjectMemberView';
import "./ProjectAndMembersView.scss"
import AddUserPopover from '../../../../Components/AddUserPopover/AddUserPopover';
import { Model } from '../../../../Models/Model';
import InviteRecordView from '../../../../Components/InviteRecordView/InviteRecordView';

// import * as Model from '../../Models/Model';
interface State {
 
    
}
interface Props {
    project:Project
 }
export default class ProjectAndMembersView extends React.Component<Props,State> {
 
  constructor(props:Props) {
    super(props)
    this.archiveClicked   = this.archiveClicked.bind(this)
    this.unarchiveClicked = this.unarchiveClicked.bind(this)
  }

  archiveClicked() {
    var project = this.props.project;
    project.archived = true; 
    Model.OrgMembership.updateProject(project)

  }
  unarchiveClicked() {
    var project = this.props.project;
    project.archived = false; 
    Model.OrgMembership.updateProject(project)
    
  }
    render() {
      const {project} = this.props




      project.members = project.members.sort(function(a, b) {
        if (!a.user || !b.user) { return 0; }

        var textA = a.user.firstName.toUpperCase();
        var textB = b.user.firstName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      const members = project.members?.map((membership) => <ProjectMemberView key={membership.id} membership={membership} project={this.props.project} />)
      const users = project.members?.map((membership) => membership.user)
        
      const archiveOrUnarchiveButton = (project.archived) ? <a className="archiveButton" onClick={this.unarchiveClicked}>Unarchive Project</a> : <a className="archiveButton" onClick={this.archiveClicked}>Archive Project</a>


      var invitesViews;
      const invites = (Model.OrgMembership.state as any).invites as Invite[]; 
      if (invites) {
        invitesViews = invites.map((item) => {
          if (item.projectId && item.projectId === project.id && item.status === InviteStatus.Pending) {
            return <InviteRecordView invite={item} key={item.id} />
          }
        })
      }


  return <div className="ProjectAndMembersView">
    <div className="projectTitle">
      <div className="roundAvatar"></div>
      <h2>{this.props.project.name}</h2>{archiveOrUnarchiveButton}
      </div>
{members}
{invitesViews}
<div className="addUser"> 
<AddUserPopover 
  project={project}
  button="+ Add user" 
  exclude={users}
  />
  </div>
  </div>
}

}
