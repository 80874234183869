import React from 'react';
import "./NavBar.scss"

interface NavBarProps {
  left?:any;
  center?:any;
  right?:any;
}
interface NavBarState {
  left?:any;
  center?:any;
  right?:any;
}


export class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props:NavBarProps) {
    super(props);
    this.state = {left:null, center:null, right:null};

  }
  setNavBar(left:any|undefined, center:any|undefined, right:any|undefined) {
    console.log("SET  NAV BAR",left,center,right);
    this.setState({left:left, center:center,right:right});
  }
  render() {

    return <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="columns is-gapless">
              <div className="column is-one-quarter left-column">
                {this.state.left || this.props.left}
                </div>
              <div className="column center-column">
                {this.state.center || this.props.center}
              </div>
              <div className="column is-one-quarter right-column">
                {this.state.right || this.props.right}
              </div>
            </div>
            </nav>;

}
}
