import React from 'react';
import { Input, InputType, Pill, PillSwitch } from '@swivl/great-grey-components';

import { Job, CreateLogger, Entity, TaskType, TaskData_Entity,MakeDictonaryArrayUnique, MLModel, EntityDefinition } from '@swivl/great-grey-lib';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../Actions/Actions';
import { Model } from '../../../Models/Model';


import "./EntityTaskCreationView.scss";
import SelectModelModal from '../../../Components/SelectModelModal/SelectModelModal';
import { SelectInstuctionsView } from '../../../Components/SelectInstructionsView/SelectInstructionsView';
import { SelectRedundancyComponent } from '../../../Components/SelectRedundancyComponent/SelectRedundancyComponent';

const log = CreateLogger("job:JobTaskEntity");
const logOfLoading = CreateLogger("LoadingLog")

interface JobEntities extends EntityDefinition {
  on:boolean; 
}

interface EntityTaskCreationViewProps { job:Job }
interface EntityTaskCreationViewState { }






export  class EntityTaskCreationView extends ActionsConsumer<EntityTaskCreationViewProps,EntityTaskCreationViewState> {
  constructor(props:EntityTaskCreationViewProps) {
    super(props)
    this.state = {}
    // this.toggleEntity = this.toggleEntity.bind(this)
    // this.createEntitiesArray = this.createEntitiesArray.bind(this)
    this.addAllEntities = this.addAllEntities.bind(this);
    this.onToggle = this.onToggle.bind(this);
    Actions.Controller.subscribeTo(this, [ActionType.MLModelDataFetched,ActionType.JobUpdated])
  }
  componentDidMount() {

  }
  addAllEntities() {
    // const {job} = this.props;


    // let entities = {};
    // for (const id in job.models) { if (job.models.hasOwnProperty(id)) {
    //     const  data = Model.MLModel.state.dataFiles[id];
    //     if (data && data.entities) {
    //       for (let i = 0; i < data.entities.length; i++) {
    //         entities[data.entities[i].name] = data.entities[i];
    //       }
    //     }
        
    // }}
    // this.setState({allEntities:entities})
    // if (job.tasks[TaskType.Entity]) {
    //   logOfLoading("UPDATING");

    //   if (
    //     (job.tasks[TaskType.Entity] as TaskData_Entity).entities &&
    //     (job.tasks[TaskType.Entity] as TaskData_Entity).entities.length > 0
        
    //     ) { 
    //       logOfLoading("ALREADY HAS DATA");

    //        } else {
    //         (job.tasks[TaskType.Entity] as TaskData_Entity).entities = Object.values(entities);
    //         Model.Job.update(job,true);
    //        }

     
    // }

    // logOfLoading("INTENTS", entities);
    
  }
  onChange = (e:any)  => {
    
    const {job} = this.props; 
    job.tasks[TaskType.Entity][e.target.name] =  e.target.value; 
    console.log(job.tasks[TaskType.Entity])
    Model.Job.update(job,true);
}
  handleAction(action:Action) {
    if (action.type === ActionType.MLModelDataFetched) {
      this.addAllEntities();
    }
    this.forceUpdate();
  }

  

  onToggle(name:string, on:boolean) {
    const {job} = this.props; 
    let entityTask = (job.tasks[TaskType.Entity] as TaskData_Entity)
    for (let i = 0; i < entityTask.entities.length; i++) {
      if (entityTask.entities[i].name === name) {
        entityTask.entities[i].on = on;
      }
      
    }
    job.tasks[TaskType.Entity]  = entityTask
    Model.Job.update(job,true);

    // logOfLoading("name", name, on);

    // const {job} = this.props; 
    // let entityTask = (job.tasks[TaskType.Entity] as TaskData_Entity)

    // if (on) {
    //   if (this.state.allEntities && this.state.allEntities[name]) {
    //     entityTask.entities.push(this.state.allEntities[name])
    //   }
    // } else {
    //   for (let index = 0; index < entityTask.entities.length; index++) {
    //     const element = entityTask.entities[index];
    //     if (element.name === name) {
    //       entityTask.entities.splice(index,1);
    //       break;
    //     }
    //   }
    // }

    // job.tasks[TaskType.Entity]  = entityTask

    // Model.Job.update(job,true);
      
    
  }


  importFromModel = () => {
    Actions.Controller.trigger({type:ActionType.ShowModal, modalContent:<SelectModelModal modelSelected={this.modelSelected} filterKey="entities" />}) 
  }
modelSelected = (model:MLModel) => {
  console.log("Model selected", model);
  const {job} = this.props; 
  let entityTask = (job.tasks[TaskType.Entity] as TaskData_Entity)
  const exitingEntitiyNames = (entityTask.entities) ? entityTask.entities.map(i => i.name) : [];
  const newEntities =  model.data.entities.filter(i => !exitingEntitiyNames.includes(i.name)).map((i) => { return {id:i.id, name:i.name, on:false}}) 
  entityTask.entities = newEntities;
  job.tasks[TaskType.Entity]  = entityTask;
  Model.Job.update(job,true);

  // entityTask.entities = model.data.entities.map((i) => { return {id:i.id, name:i.name}})

  // job.tasks[TaskType.Entity]  = entityTask

  // Model.Job.update(job,true);

  // if (model.data && model.data.categories) {
  //   const {job} = this.props; 
  //   let task = (job.tasks[TaskType.ImageCategorize] as TaskData_ImageCategorize)
  //   task.categories = task.categories.concat(model.data.categories); 
  //   task.categories = task.categories.map((cat) => {
  //     cat.include = true; 
  //     if (cat.specific) { 
  //       cat.specific = cat.specific.map((specific) => {
  //         specific.include = true; 
  //         return specific;
  //       })
  //     }
  //     return cat; 
  //   })
  //   job.tasks[TaskType.ImageCategorize] = task; 
  //   Model.Job.update(job,true);
  // }
}




  render() {

    
    const job = this.props.job;

    const entityTask:TaskData_Entity = job.tasks[TaskType.Entity] as TaskData_Entity;
    const entities = entityTask.entities;

    const selectedEntityNames = entities.map((item) => item.name);
    
    log("selectedEntityNames",selectedEntityNames)


    // const allEntities = this.state.allEntities;
    let entityViews = (entities) ? entities.map(i => <PillSwitch onToggle={this.onToggle} on={i.on} key={i.name+i.id} name={i.name}>{i.name}</PillSwitch>) : [];

    
    // for (const key in allEntities) {
    //   if (allEntities.hasOwnProperty(key)) {
    //     const element = allEntities[key];

    //     entityViews.push(<PillSwitch onToggle={this.onToggle} on={(selectedEntityNames.includes(element.name))} key={key} name={element.name}>{element.name}</PillSwitch>)
        
    //   }
    // }


    
    
     
    
    return <div className="EntityTaskCreationView">
        <h5>Select which entities you would like to include.</h5>
        <div>{entityViews}</div>
        <a onClick={this.importFromModel}>+ import from model</a>

        <div className="redundancy">
          <label>
            Redundancy
          </label>
          <Input type={InputType.Integer} name="redundancy" onChange={this.onChange} value={entityTask.redundancy + ""} />
        </div>
        <SelectRedundancyComponent job={this.props.job} taskType={TaskType.Entity} />

        <SelectInstuctionsView job={this.props.job} taskType={TaskType.Entity} />
    </div>
  }

}
