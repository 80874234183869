
import { Model } from '../Model';

import { Actions, ActionType } from '../../Actions/Actions';

import {User, CreateLogger,Organisation, OrganisationMembership, MembershipRole, ProjectMembership, ProjectMembershipType, PermissionLevel, ProjectPermissionType, PermissionLevelValue } from '@swivl/great-grey-lib';
import Service from '../../Services/Service';
const log = CreateLogger("SessionModel");

export interface SessionState extends Readonly <{
  isUserLoaded:boolean;
  isLoggedIn:boolean;
  isLoggingIn:boolean;
  user?:User;
  token?:string;
  currentMembership?:OrganisationMembership
  currentProjectMembership?:ProjectMembership
  
}>{}

export class SessionModel {
  static get Model():SessionModel { if (SessionModel._Model) { return SessionModel._Model; }  else {   SessionModel._Model = new SessionModel(); return SessionModel._Model ; }}
  private static _Model:SessionModel;
  static get state():SessionState { return SessionModel.Model.state; }

  state:SessionState  = {
    isUserLoaded : false,
    isLoggedIn   : false,
    isLoggingIn  : false,
    user         : undefined,
    token        : undefined,
    currentMembership:undefined
  }
  
  private constructor() {
    log("I am constructing");

    

    this.loadCurrentUserAndNotify();


  }

  
  loadCurrentUserAndNotify(){
    var session;
    try {
      const serializedSession = localStorage.getItem('session');
      
      if (serializedSession ) {
        session = JSON.parse(serializedSession);
        console.log("Got Session",session );

      }

    } catch (err) {
      throw Error("Error Loading From Local Storage");
    }



    this.state = {
      ...this.state,  
      isUserLoaded:true,



      

      isLoggingIn: (session && session.user && session.token)? true: false,
      user: (session && session.user)?session.user : undefined,
      token:(session && session.token)?session.token : undefined,
      currentMembership: (session && session.currentMembership)?session.currentMembership : undefined,
      currentProjectMembership: (session && session.currentProjectMembership)?session.currentProjectMembership : undefined
    }



    
  
    
    setTimeout(() => {this.refreshUser()}, 10);
    


}

setCurrentMembership(currentMembership?:OrganisationMembership, projectMembership?:ProjectMembership) {
  var session;
  try {
    const serializedSession = localStorage.getItem('session');
    if (serializedSession ) {
      session = JSON.parse(serializedSession);
    }
    session.currentMembership = currentMembership;
    session.currentProjectMembership = projectMembership; 

    console.log("Setting Session", session);
    
    const serializedState = JSON.stringify(session);

    
    localStorage.setItem('session', serializedState);

    let currentState = {...this.state}
    currentState.currentMembership = currentMembership; 
    currentState.currentProjectMembership = projectMembership; 
    Model.reset();
    this.state = currentState;
    // setTimeout(() => {  
        Actions.Controller.trigger({type:ActionType.CurrentMembershipUpdated});  
      //  }, 500)
  } catch (err) {
    throw Error("Error Loading From Local Storage");
  }
}

async login(email:string, password:string):Promise<any> {
    console.log("Login", email, password)
      try {
        const response = await Service.API.login(email.toLowerCase(),password);
        return this.userLoggedIn(response);
      } catch(e) {
        return Promise.reject(e);
      }
}

async signUp(email:string, password:string, firstName:string, lastName:string):Promise<any> {
    try {
      
      const response = await Service.API.signUp(email.toLowerCase(),password,firstName,lastName);
      return this.userLoggedIn(response.session);
    } catch(e) {
      return Promise.reject(e);
    }
}
async userLoggedIn(session:any):Promise<any> {

      if (!session.currentMembership && session.user && session.user.membership && session.user.membership.length) {
        session.currentMembership = session.user.membership[0];
      }
      // let currentProjectMembership; 
      if (session.currentMembership && session.currentMembership.projectMemberships && session.currentMembership.projectMemberships.length)  {
        session.currentProjectMembership = session.currentMembership.projectMemberships[0]
      }

      const serializedState = JSON.stringify(session);
      localStorage.setItem('session', serializedState);
      this.state = {
                      ...this.state,  
                      isUserLoaded:true, 
                      isLoggedIn:true,
                      user:session.user,
                      token:session.token,
                      currentMembership:session.currentMembership,
                      currentProjectMembership: session.currentProjectMembership
                    }
      Actions.Controller.trigger({type:ActionType.UserLoggedIn, user:this.state.user});
      if (session && session.currentMembership && session.currentMembership.role && Model.Session.state.currentMembership.role === MembershipRole.Admin) {
        Model.Navigation.setPath('/dashboard');
      } else {
        Model.Navigation.setPath('/task');

      }
      console.log("howdy",session )
      return Promise.resolve(true);
}

   async refreshUser() {
    // /session/user
    if (!this.state.user) { return; }
    console.log("session - REFRESHING USER");
    var user;
    try {
       user = await Service.API.refreshSessionUser()

    } catch(e) {
      user = undefined;
      console.warn("Not Logged In");
      
    }
    let state = {...this.state,user}
    console.log("session - Got user I think", state);


    //Make  sure to assign correct and current membership
    if (!state.currentMembership && user && user.membership.length) {
      console.log("session - Using First");

      state.currentMembership = user.membership[0]
    } else if (state.currentMembership && user && user.membership && user.membership.length) {
      var  foundMembership =  false; 
      let foundCurrentProjectMembership = false; 

      for (let i = 0; i < user.membership.length; i++) {
        if (user.membership[i].id === state.currentMembership.id) {
          state.currentMembership = user.membership[i]
          // Refresh the project membership.

          if (state.currentProjectMembership) {
            console.log("session - state.currentProjectMembership",state.currentProjectMembership);

            
            for (let m = 0; m < state.currentMembership.projectMemberships.length; m++) {
              let possibileMembership =  state.currentMembership.projectMemberships[m]
              if (possibileMembership.id === state.currentProjectMembership.id) {
                console.log("session - MATCH!!")
                state.currentProjectMembership = possibileMembership;
                foundCurrentProjectMembership = true; 
                break;
              }
            }
          } else {
            console.log("session - no state proj membership");
            
          }

          foundMembership = true; 
          break; 
        }
        
      }
      if (!foundMembership) {
        console.log("session - not foundMembership");

         state.currentMembership = user.membership[0]   
        //  if (state.currentMembership.projectMemberships && state.currentMembership.projectMemberships.length) {
        //   state.currentProjectMembership = state.currentMembership.projectMemberships[0] 
        //  } else {
        //   state.currentProjectMembership = undefined; 
        //  }
        }
        if (!foundCurrentProjectMembership) {
        if (state.currentMembership.projectMemberships && state.currentMembership.projectMemberships.length) {
          state.currentProjectMembership = state.currentMembership.projectMemberships[0] 
         } else {
          state.currentProjectMembership = undefined; 
         }
        }
    }

 

    var session:any = {}
    const serializedSession = localStorage.getItem('session');
    if (serializedSession ) {
      session = JSON.parse(serializedSession);
    }

    session.user = user;
    session.currentMembership = state.currentMembership;
    session.currentProjectMembership = state.currentProjectMembership;
    

    const serializedState = JSON.stringify(session);
    localStorage.setItem('session', serializedState);

    this.state = state;

    Actions.Controller.trigger({type:ActionType.UserLoaded, user:this.state.user});

  }


  async logOut() { 



    Actions.Controller.trigger({type:ActionType.UserLoggedOut});
    try {
      Service.API.logout().then((res) => {
        log("Logged Out", res);
      }).catch((e) => {
        log.warn("Error Logging Out");
        Service.Toast.error(e);
      });
      

      localStorage.removeItem('session');
      this.state = {   

        isUserLoaded : true,
        isLoggedIn   : false,
        isLoggingIn  : false,
        user         : undefined,
        token        : undefined,
        currentMembership: undefined
      }
      Model.reset();
      Actions.Controller.trigger({type:ActionType.UserLoggedOut});
      return Promise.resolve(true);
    } catch(e) {
      return Promise.reject(e.message);
    }
}


  private _canAccessCheckValue(type:ProjectPermissionType,currentProjectMembership:ProjectMembership):number {   
    switch(type) {
      case ProjectPermissionType.Dashboard: return PermissionLevelValue(currentProjectMembership.dashboardPermissions );
      case ProjectPermissionType.DataSet:  return PermissionLevelValue(currentProjectMembership.dataSetPermissions ); 
      case ProjectPermissionType.Job:       return PermissionLevelValue(currentProjectMembership.jobPermissions );
      case ProjectPermissionType.Task:      return PermissionLevelValue(currentProjectMembership.taskPermissions ); 
      case ProjectPermissionType.Model:     return PermissionLevelValue(currentProjectMembership.modelPermissions );
      case ProjectPermissionType.Project:   return PermissionLevelValue(currentProjectMembership.projectPermissions ); 
    }
  }
    

  canAccess(type:ProjectPermissionType, minimumLevel?:PermissionLevel  ):boolean {
    const { currentMembership, currentProjectMembership} = this.state; 
    if (!currentMembership || !currentProjectMembership) { return false; }
    const minimumLevelValue = (minimumLevel) ? PermissionLevelValue(minimumLevel) : 1;
    return (this._canAccessCheckValue(type, currentProjectMembership) >= minimumLevelValue)
  }

  

}

