
import addressbar from 'addressbar';
import { Model } from '../Model';
import { Actions, ActionType } from '../../Actions/Actions';

export class NavigationModel {
  static get Model():NavigationModel {   if (NavigationModel._Model) { return NavigationModel._Model; }  else {   NavigationModel._Model = new NavigationModel(); return NavigationModel._Model ; }  }
  private static _Model:NavigationModel;
  history:Array<any> = [];
  backTitles:any = {};
  path:Array<string> = [];

  private constructor() {
    console.log("I am constructing");
    addressbar.addEventListener('change',(event) =>{
      event.preventDefault();
      this.routeForAddress(event.target.value)
    });
    this.routeForAddress()

  }

  first():string|undefined {
    if (this.path && this.path.length > 0) { return this.path[0]; }
    return undefined;
  }
  second():string|undefined {
    if (this.path && this.path.length > 1) { return this.path[1]; }
    return undefined;
  }
  third():string|undefined {
    if (this.path && this.path.length > 2) { return this.path[2]; }
    return undefined;
  }

  

    setPath(path) {


      let address = addressbar.origin + path;
      this.routeForAddress(address)
    }



    routeForAddress(address?:string) {
      if (!address) {
        address = addressbar.value;
      }

      addressbar.value = {
        value:address,
        replace: false
        };



        if (this.history.length == 0 || this.history[this.history.length - 1] != addressbar.pathname) {

          if (this.history.length > 1 && this.history[this.history.length - 2] == addressbar.pathname) {
            this.history.pop()
            this.history.pop()
          }

          this.history.push(addressbar.pathname)
        }

        let path = addressbar.pathname.split('/')
        path.shift()

        if (path.length == 1 && path[0] == "logout") {
          Model.Session.logOut();
          return;
        }


        this.path = path;
        Actions.Controller.trigger({type:ActionType.NavigationPathUpdated, path:this.path});

    }
    currentPath() {
      let path = addressbar.pathname.split('/')
      path.shift()
      return path;
    }
    goBack() { }
    registerBackTitleForPath(backTitle) {
      this.backTitles[addressbar.pathname] = backTitle;
    }
    backPathAndTitle() {
      if (this.history.length < 2) {
        return null; }
        const historicBack = this.history[this.history.length - 2]
      if (this.backTitles[historicBack]) {
         return {path:historicBack, title: this.backTitles[historicBack]};
      }
      return null;
    }


}
