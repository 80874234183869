import React, {  } from 'react';
import { CreateLogger,CreateRowChange_AudioCategorize,CreateRowChange_AudioSplit, Intent, DataSetRow, TaskType, TimelineCategory, TimelineSegment } from '@swivl/great-grey-lib';
import "./AudioCategorizeAndSplitExercisesView.scss"
import { loadAudio } from '../Shared/AudioHelper';
import { TimelineView } from './components/TimelineView/TimelineView';
import { CategoryTitles } from './components/CategoryTitles/CategoryTitles';
import { AddSegmentToCategory, AdjustEndTime, AdjustStartTime, DeleteSegment, AdjustPosition } from './TrackHelper';
import "./Colors/TimelineColors.scss"
import { TimelineControlsView } from '../Shared/TimelineControls/TimelineControlsView';
import { ExerciseCardProps } from '../../Screens/Assignment/Exercises/Card/ExerciseCard';

const log = CreateLogger("AudioCategorizeAndSplitExercisesView:Main");



// export interface AudioSplitCategorySegment {
//   id:string
//   startTime:number, 
//   endTime:number
// }

// export interface AudioSplitCategory {
//   id:string, 
//   title:string,
//   segments:AudioSplitCategorySegment[]
// }
// export interface SplitData {
//   mediaURL:string,
//   categories:AudioSplitCategory[]
// }

// const mockData:SplitData = {
//   // mediaURL:"/testAudio/00001473-5E7502B00007980F-CB903700-7a878d53-72FBDEE3-5E7502B00007980A-CB903700.wav",

//   mediaURL:"https://great-grey-local.s3-us-west-1.amazonaws.com/59458-16-59-45_01.mp3",
//   categories: [
//     {id:"1234", title:"Caller", segments:[{id:"kmksdfmfs", startTime:2, endTime:20}, {id:"ddksdfmfs", startTime:22, endTime:25}]},
//     {id:"1231", title:"Agent", segments:[]},
//     {id:"1233", title:"Kyle", segments:[]},
//     {id:"123d", title:"Chuck", segments:[]},
//     {id:"1235", title:"Agent", segments:[]},
//     {id:"1236", title:"Agent", segments:[]},
//     {id:"1237", title:"Agent", segments:[]},
//   ]
// }



// interface AudioCategorizeAndSplitExercisesViewProps {}
interface AudioCategorizeAndSplitExercisesViewState {
  arrayBuffer?:ArrayBuffer;
  audioBuffer?:AudioBuffer; 
  dataPoints?:number[];
  duration?:number;
  isPlaying:boolean;
  playingId?:string;
  // data:SplitData; 
  categories:TimelineCategory[]
  hasMadeAChange:boolean; 
}



export class AudioCategorizeAndSplitExercisesView extends React.Component<ExerciseCardProps, AudioCategorizeAndSplitExercisesViewState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  playbackNode?:AudioBufferSourceNode; // If this is active it means it is playing!
  playbackStartTime?:number; 
  playbackOffset?:number; 
  pauseTime:number = 0;

  audioContext:AudioContext; 

  constructor(props:any) {
    super(props) 

    log("MY PROPS", props)
    
    let categories:TimelineCategory[] = [];

    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
    let task = this.props.assignment.task;
    if (task.type == TaskType.AudioCategorize) {
      for (let i = 0; i < task.categories.length; i++) {
        categories.push({...task.categories[i], segments:[]})
      }
    }
    if (this.props.work.tempData && this.props.work.tempData.type && this.props.work.tempData.type === "AudioCategorize" &&  this.props.work.tempData.categories) {
      categories = this.props.work.tempData.categories;
    } else if (originalRow.audioCategories) {
      categories = originalRow.audioCategories
    }
    // work:AssignmentWorkRow,
    // assignment:Assignment;
    // selectedCard?:string;
    log("Original Row", originalRow);


    this.state = {isPlaying:false, categories:categories, hasMadeAChange:false}
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

    this.playClicked      = this.playClicked.bind(this);
    this.pauseClicked     =  this.pauseClicked.bind(this);
    this.backClicked      = this.backClicked.bind(this)
    this.forwardClicked   = this.forwardClicked.bind(this)
    this.setPlayhead      = this.setPlayhead.bind(this);
    this.playAtTime       = this.playAtTime.bind(this);

    this.addSegmentToCategory = this.addSegmentToCategory.bind(this);
    this.adjustStartTime      = this.adjustStartTime.bind(this);
    this.adjustEndTime        = this.adjustEndTime.bind(this);
    this.adjustPosition       = this.adjustPosition.bind(this);
    this.deleteSegment        = this.deleteSegment.bind(this);

    this.doneClicked          = this.doneClicked.bind(this);
  }

  doneClicked() {
    // if (this.state.hasMadeAChange) {
      let work = this.props.work;    
      if (this.props.assignment.task.type === TaskType.AudioCategorize) { 
        work.change = CreateRowChange_AudioCategorize(this.state.categories);
        console.log("CreateRowChange_AudioCategorize")

      } else  {
        console.log("CreateRowChange_AudioSplit")
        work.change = CreateRowChange_AudioSplit(this.state.categories);

      }
      this.props.save(work);
    // } 
    // else {
    //   this.props.unchanged(this.props.work);
    // }
  }
  componentWillUnmount() {
    if (this.playbackNode) {
      this.playbackNode.stop();
    }
  }
  playClicked() {
    this.playAtTime(0)
  }
  pauseClicked() {
    const startTime  = this.playbackStartTime;
    const contextTime = this.audioContext.currentTime; 
    this.pauseTime = (contextTime - startTime) + this.playbackOffset;
    this.playbackNode.stop()
  }
  backClicked() {
     if (this.pauseTime > 10) {this.pauseTime -= 10; } else  { this.pauseTime = 0; }
     if (this.playbackNode) {
        const startTime  = this.playbackStartTime;
        const contextTime = this.audioContext.currentTime; 
        const split = (contextTime - startTime) + this.playbackOffset; 
        if ((split  - 10 ) > 0) { 
          this.playAtTime(split - 10); 
        } else {
          this.playAtTime(0);
        }

     }
  }
  forwardClicked() {
    if  (!this.state.duration) { return; } 

      if ((this.pauseTime  + 10 ) < this.state.duration ) { this.pauseTime += 10; } else  { this.pauseTime = this.state.duration; }
    
        
        if(this.playbackNode) {
          const startTime  = this.playbackStartTime;
          const contextTime = this.audioContext.currentTime; 
          const split = (contextTime - startTime) + this.playbackOffset;  
          if ((split  + 10 ) < this.state.duration) { 
            this.playAtTime(split + 10); 
          } else {
            this.playbackNode.stop()
            this.pauseTime = this.state.duration;
          } 
        }

  }

  setPlayhead(time:number) {
    log("set playhead", time);
    if (!this.playbackNode) {
      this.pauseTime = time; 
    } else {
      this.playAtTime(time)
    }
  } 
  

/**
 * 
 * @param time 
 * @param duration 
 * @param playingId - Used to determin what component has triggered the playback.
 */
  playAtTime(time:number, duration?:number, playingId?:string) {
    log("Play at time", time, duration)
    this.setState({isPlaying:true, playingId:playingId})
    if (this.playbackNode) { this.playbackNode.stop();  }
    if (this.state.audioBuffer) {

      this.playbackNode   =  this.audioContext.createBufferSource();
      if (!time && this.pauseTime) {  time = this.pauseTime; }

      this.playbackOffset = time;
      this.playbackStartTime = this.audioContext.currentTime;
      // set the buffer in the AudioBufferSourceNode
      this.playbackNode.buffer = this.state.audioBuffer;
      // connect the AudioBufferSourceNode to the
      // destination so we can hear the sound
      this.playbackNode.connect(this.audioContext.destination);
      // start the source playing
      if (duration) {
        this.playbackNode.start(0,time,duration);
      } else {
        this.playbackNode.start(0,time);
      }
      var self = this;
      this.playbackNode.onended =  function() {
        if (this ===  self.playbackNode) {
          log("II'm me");
          self.playbackNode = null;
          self.setState({isPlaying:false, playingId:null})

        }
      }
    }
  }
  
  componentDidMount() {
    // var worker = new Worker('/workers/WaveFormWorker.js');
    // worker.addEventListener('message', function(e) {
    //   log('Worker said: ', e.data);
    // }, false);

    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 


    loadAudio(originalRow.audioURL).then((output) => {
      this.setState({  arrayBuffer:output.arrayBuffer, 
                       audioBuffer:output.audioBuffer,
                       dataPoints:output.dataPoints,
                       duration:output.audioBuffer.duration
                      })
      // this.canvas =  new AudioSplitCanvas(this.canvasRef, output.dataPoints)
      // worker.postMessage(output.dataPoints); // Send data to our worker.

    })

  }
  

  addSegmentToCategory(trackIndex:number, segment:TimelineSegment) {
    log("addSegmentToCategory",trackIndex, segment)
    this.setState({hasMadeAChange:true, categories:AddSegmentToCategory(this.state.categories, trackIndex, segment, this.state.duration)})
  }

  adjustStartTime(categoryIndex:number,segmentIndex:number, startTime:number) {
    log("Adjust Start Time!!!")

    this.setState({hasMadeAChange:true, categories:AdjustStartTime(this.state.categories, categoryIndex,segmentIndex, startTime)})
  }
  adjustEndTime(categoryIndex:number,segmentIndex:number, endTime:number) {
    log("Adjust End Time!!!")
    this.setState({hasMadeAChange:true, categories:AdjustEndTime(this.state.categories, categoryIndex,segmentIndex, endTime, this.state.duration)})
  }
  adjustPosition(categoryIndex:number,segmentIndex:number, originalSegments:TimelineSegment[],  delta:number){
    this.setState({hasMadeAChange:true, categories:AdjustPosition(this.state.categories, categoryIndex,segmentIndex, originalSegments,  delta, this.state.duration)});
  }
  deleteSegment(categoryIndex:number, segmentIndex:number) {
    log("Delete Segment")
    this.setState({hasMadeAChange:true, categories:DeleteSegment(this.state.categories, categoryIndex,segmentIndex)})

  }

  

  
  

  render() {    

    

    const duration = (this.state.duration) ? this.state.duration :( (window.screen.width -  320) * 0.05 );
    return <div className="AudioCategorizeAndSplitExercisesView">
            <div className="leftColumn">
              <TimelineControlsView  
                  isPlaying={this.state.isPlaying}
                  playClicked={this.playClicked}
                  pauseClicked = {this.pauseClicked}
                  backClicked = {this.backClicked}
                  forwardClicked = {this.forwardClicked}
              />
             
             
              <CategoryTitles categories={this.state.categories} />

            </div>
            <div className="rightColumn">
              <TimelineView 
                categories={this.state.categories}
                playAtTime={this.playAtTime}
                pause={this.pauseClicked}
                delegate={this} 
                isPlaying={this.state.isPlaying}
                playingId={this.state.playingId}
                duration={duration} 
                dataPoints={this.state.dataPoints} />
            </div>
    </div>;
  }
}
