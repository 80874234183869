import React from 'react';
import './JobDetailsView.scss';
import { JobHeaderView } from './components/JobHeaderView/JobHeaderView';
import { DataSetSelectionView } from './components/DataSetSelectionView/DataSetSelectionView';
import { Job,CreateLogger, GGDictionary, MLModelData, MLModel, JobStatus, DataSetColumnType, TaskType } from '@swivl/great-grey-lib';
import { MLModelSelectionView } from './components/MLModelSelectionView/MLModelSelectionView';
import { Model } from '../../../Models/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ActionsConsumer, ActionType, Actions, Action } from '../../../Actions/Actions';
import { JobTask } from './JobTask/JobTask';
import LoadingScreen from '../../Loading/LoadingScreen';
const log = CreateLogger("job:JobDetailsView")
const logOfLoading = CreateLogger("LoadingLog")


interface JobDetailsViewProps {
  job:Job;
}


interface JobDetailsViewState {
  isLoadingMLData:boolean;
  fetched:boolean 
}

export  class JobDetailsView extends ActionsConsumer<JobDetailsViewProps,JobDetailsViewState> {
  constructor(props:any) {
    super(props)
    this.state = {isLoadingMLData:false,fetched:false};
    this.viewEscalations    = this.viewEscalations.bind(this)
    this.viewLabeledRows    = this.viewLabeledRows.bind(this)
    this.setMLLoadingStatus = this.setMLLoadingStatus.bind(this)
    this.renderMLModels     = this.renderMLModels.bind(this)
    this.renderTasks        = this.renderTasks.bind(this)



    Actions.Controller.subscribeTo(this, [
      ActionType.MLModelDataFetched,
      ActionType.DataSetsLoaded,
      ActionType.JobUpdated,
      ActionType.JobFetched
    ])

  }
  handleAction(action:Action) { 
    if (action.type == ActionType.JobFetched && action.item.id === this.props.job.id) {
      setTimeout(() => {
        this.setState({fetched:true})
        console.log("FETCHED", action.item);
        this.forceUpdate();
      }, 5000)
 

      return;
    }

    // switch(action.type) {
    //   case ActionType.JobUpdated:
    //     if (action.item.id === this.props.job.id) { this.forceUpdate(); }
    //   case ActionType.DataSetsLoaded:
    //   case ActionType.JobUpdated: 
    //   this.forceUpdate();
    // }

    this.forceUpdate();

   }

  // We have to do some trickary to check to see if all the model data is loaded and injected. 

  componentDidMount() {
     this.checkForData() 
     Model.Job.fetch(this.props.job)
    
    }
  componentDidUpdate() { this.checkForData(); }

  hasLoadedData:Array<string> = []; 
  checkForData() {

    
    // const {job} = this.props
    // if (job.status !== JobStatus.Unstarted) { return } 
    // logOfLoading("LOGGING!")
    // if (job.status === JobStatus.Unstarted) {
    //   for (const id in job.models) { if (job.models.hasOwnProperty(id)) {
    //     if (!this.hasLoadedData.includes[id]) {
    //         if (Model.MLModel.state.dataFiles[id]) {
    //           this.hasLoadedData.push(id)
    //         } else {
    //           if (Model.MLModel.state.items &&  Model.MLModel.state.items[id]) {
    //             logOfLoading("Fetching!")
    //             this.hasLoadedData.push(id);

    //           Model.MLModel.fetchMLData(
    //             Model.MLModel.state.items[id]
    //             ,true, 
    //             ()=>{}).then((mLModel) => {
    //               logOfLoading("Fetched")
    //               this.checkForData()
    //               this.forceUpdate()
    //             })
              
    //           }
    //         }
    //       }
    //     }


    //   }}
  }
  setMLLoadingStatus(isLoadingMLData:boolean) { this.setState({isLoadingMLData:isLoadingMLData}); }
    
  renderMLModels() {
    if (!(this.props.job.dataSet && this.props.job.dataSet.id && Model.DataSet.state.items && Model.DataSet.state.items[this.props.job.dataSet.id])) { 
      return null; 
    }
    const dataSet = Model.DataSet.state.items[this.props.job.dataSet.id]; 

    if (dataSet.columnTypes.includes(DataSetColumnType.Text)) {
        return <MLModelSelectionView job={this.props.job} setMLLoadingStatus={this.setMLLoadingStatus} />
    }
    return null;
  }
  renderTasks() {
    if (!(this.props.job.dataSet && this.props.job.dataSet.id && Model.DataSet.state.items && Model.DataSet.state.items[this.props.job.dataSet.id])) { 
      return null; 
    }
    const dataSet = Model.DataSet.state.items[this.props.job.dataSet.id]; 

    console.log("Column Types",  dataSet.columnTypes); 
    let tasks  = []; 

    if (dataSet.columnTypes.includes(DataSetColumnType.ImageURL)) {
      tasks.push(<JobTask key={"ImageCategorize"} job={this.props.job} taskType={TaskType.ImageCategorize}></JobTask>)
    }

    if (dataSet.columnTypes.includes(DataSetColumnType.ImageURL) &&
        dataSet.columnTypes.includes(DataSetColumnType.Categories)
    ) {
      tasks.push(<JobTask key={TaskType.ImageBoundingBox} job={this.props.job} taskType={TaskType.ImageBoundingBox}></JobTask>)
    }
    if (dataSet.columnTypes.includes(DataSetColumnType.AudioURL)) {
      tasks.push(<JobTask key={"AudioCategorize"} job={this.props.job} taskType={TaskType.AudioCategorize}></JobTask>)
    }



    if (dataSet.columnTypes.includes(DataSetColumnType.AudioCategories)) {
      tasks.push(<JobTask key={"AudioSplit"} job={this.props.job} taskType={TaskType.AudioSplit}></JobTask>)
    }

    if (dataSet.columnTypes.includes(DataSetColumnType.AudioURL)) {
      tasks.push(<JobTask key={"Transcribe"} job={this.props.job} taskType={TaskType.Transcribe}></JobTask>)
    }
    


    if (dataSet.columnTypes.includes(DataSetColumnType.Text)) {
      console.log("BOBA", TaskType.TextCategorize);
      
      tasks = tasks.concat([ <JobTask key={"split"}     job={this.props.job} taskType={TaskType.Split}></JobTask>,
                     <JobTask key={"sentiment"}         job={this.props.job} taskType={TaskType.Sentiment}></JobTask>,
                     <JobTask key={"entity"}            job={this.props.job} taskType={TaskType.Entity}></JobTask>,
                     <JobTask key={"intent"}            job={this.props.job} taskType={TaskType.Intent}></JobTask>,
                     <JobTask key={"textCategorize"}    job={this.props.job} taskType={TaskType.TextCategorize}></JobTask>])
    }
   
    log("DataSetColumnType.Text",DataSetColumnType.Text)
    return tasks;  

    // ColmunTypesForRows(rows:DataSetRow[]):DataSetColumnType[]
    // const {job} = this.props


    // switch (this.props.job.dataSet?.rowType) {
    //     case RowType.Text:
    //       if (!Model.MLModel.state.items) {
    //         return <div className="loadingMLData" ><FontAwesomeIcon icon={faSpinner}  spin={true} /> Loading Task Data</div>
    //       }
    //       if (job.status === JobStatus.Unstarted) {
    //         for (const id in job.models) { if (job.models.hasOwnProperty(id)) {
    //             const element = job.models[id];
    //             if (!Model.MLModel.state.dataFiles[id]) {
    //               logOfLoading("Doesnt have model", id)
    //               return <div className="loadingMLData" ><FontAwesomeIcon icon={faSpinner}  spin={true} />  Loading Task Data</div>
    //             }
    //           }}
    //       }


    //       return <TextJobDetailsView job={this.props.job} />


          
    //     default:
    //       return null;
    //         break;
    // }
  }

  viewEscalations() { 
    const {job} = this.props

    if (job.tasks[TaskType.TextCategorize]) {
      Model.Navigation.setPath(`/job-review-task/${this.props.job.jobReviewId}/escalations`);
    } else {
      Model.Navigation.setPath(`/job_review/${this.props.job.jobReviewId}/escalations`);
    }
  
  }
  viewLabeledRows() { Model.Navigation.setPath(`/job_review/${this.props.job.jobReviewId}`); }

  renderReviewStats() {
    const {job} = this.props
    if (job.status !== JobStatus.Ready_For_Review) { return null; }
    if (!job.rowCount) { return null }
    const unresolvedEscalations = (job.unresolvEdescalations) ? job.unresolvEdescalations : 0;


    return <div className="reviewStats">
      <div className="labelButtonWrapper" onClick={this.viewEscalations}>
        <h2>Escalations</h2>
        <div className="labelButton">
        <h3 className="escalations">{unresolvedEscalations}</h3>
        Resolve Escalations
      </div>  
      </div>  

      <div className="labelButtonWrapper" onClick={this.viewLabeledRows}>
        <h2>Labeled Rows</h2>
        <div className="labelButton">
        <h3>{job.rowCount - unresolvedEscalations}</h3>
        View Labeled
      </div>  
      </div>  
    </div>
  }

  render() {
    if  (!this.props.job) { return null; }
    console.log("this.props.job",this.props.job)
  
    if (this.props.job.status === JobStatus.In_Progress) {

    return <div className="JobDetailsView">
    <JobHeaderView job={this.props.job} />
      <img 
        src="https://great-grey-cdn.s3-us-west-1.amazonaws.com/fake-jobs.png" 
        width={2178/2} height={1192/2}
      />

  </div>;
    }


    return <div className="JobDetailsView">
      <JobHeaderView job={this.props.job} />
      <DataSetSelectionView job={this.props.job} />
      {this.renderMLModels()}
      {this.renderReviewStats()}

      {this.renderTasks()}
    </div>;
  }

}
