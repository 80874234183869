import React from 'react';
import moment from 'moment'
export  class ElapsedTime extends React.Component<{startDate:Date}> {
    estimatedTime(sec_num:number) {
        // var sec_num = parseInt(milliseconds, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
    
        // if (hours   < 10) {hours   = "0"+hours;}
        // if (minutes < 10) {minutes = "0"+minutes;}
        // if (seconds < 10) {seconds = "0"+seconds;}
    
        let hoursString = String(hours)
        let minutesString = String(minutes)
        let secondsString = String(seconds)
    
        if (hours > 0) {
          if (minutes < 10) {minutesString = "0"+minutes;}
          if (seconds < 10) {secondsString = "0"+seconds;}
          return hoursString+':'+minutesString+':'+secondsString;
        } else if (minutes > 0) {
    
          if (seconds < 10) { secondsString = "0"+seconds;}
          return minutesString+':'+secondsString;
        } 
    
        return secondsString + "s"
    }

    mounted:boolean = false; 
    interval:any; 
 constructor(props:any) {
    super(props) 
    this.mounted = true;
    this.interval = setInterval(() => {this.tick()}, 100)
    this.tick = this.tick.bind(this);

  }
  componentWillUnmount() {clearInterval(this.interval) }
  tick() {
    this.forceUpdate()
  }

    render() {
        let timestamp = moment(this.props.startDate);
        let diff = moment().diff(timestamp, 'seconds'); 
        return <div className="elapsedTime">{this.estimatedTime(diff)} Time Elapsed</div>
    }
}