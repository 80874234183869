import React, { createRef } from 'react';
import { CreateLogger, TimelineCategory } from '@swivl/great-grey-lib';
import "./PlayheadView.scss"
import {  AudioPlayer } from '../AudioPlayer';


const log = CreateLogger("AudioSplitExersiseView:PlayheadView");




interface PlayheadViewProps { 
  delegate:AudioPlayer;
}

interface PlayheadViewState {
  x:number;
}
export class PlayheadView extends React.Component<PlayheadViewProps, PlayheadViewState> {
    
  tickInterval:NodeJS.Timeout | undefined;
  constructor(props:any) {
    super(props) 
    this.state =  {x:0}
    this.tick               = this.tick.bind(this)
    this.tickInterval = setInterval(()=>{this.tick()}, 50);
}
tick() {
  if(this.props.delegate.playbackNode) {
    const startTime  = this.props.delegate.playbackStartTime;
    const contextTime = this.props.delegate.audioContext.currentTime; 
    const split = (contextTime - startTime) + this.props.delegate.playbackOffset;
    this.setState({x:Math.round(split * 20)})
  } else if ((this.props.delegate.pauseTime * 20) !== this.state.x ) {
    log("TOCK")
    this.setState({x:this.props.delegate.pauseTime * 20})
  }
}

  
  
  render() {    

    const x =  this.state.x - 1;


      return <div style={{transform:"translate(" + x + "px)"}} className="PlayheadView">
        <div className="mainPlayhead"/>
  

          </div>

    
  }
}