import React from 'react';
import { ActionsConsumer, Actions, ActionType } from '../../../Actions/Actions';


import { NavTextButton, SelectionList,Option, Select, TabHeader } from '@swivl/great-grey-components';
import { GGDictionary, OrganisationMembership, Project } from '@swivl/great-grey-lib';
import { Model } from '../../../Models/Model';

import ProjectAndMembersView from './components/ProjectAndMembersView';
import "./ProjectsView.scss"
import NewProjectModal from './components/NewProjectModal/NewProjectModal';
// import * as Model from '../../Models/Model';
interface State {
  isPopoverOpen:boolean;
  tab:string 
  
}
interface Props {

}
export default class ProjectsView extends ActionsConsumer<Props,State> {
 
  constructor(props:Props) {
    super(props)
  this.state = {tab:"active", isPopoverOpen:false}
  Actions.Controller.subscribeTo(this, [
    ActionType.OrgMembershipsLoaded,
    ActionType.OrgMembershipCreated,
    ActionType.OrgMembershipDeleted,
    ActionType.OrgMembershipUpdated,    
    ActionType.ProjectMembershipsLoaded,
    ActionType.ProjectUpdated    
  
   ] )

   this.itemUpdated = this.itemUpdated.bind(this)
   this.deleteItem = this.deleteItem.bind(this)
   this.setIsPopoverOpen = this.setIsPopoverOpen.bind(this)
   this.tabSelected = this.tabSelected.bind(this)
}

componentDidMount() {
  Model.OrgMembership.loadProjectsAndMembers()

  Model.OrgMembership.loadInvites()
}

itemUpdated(item:OrganisationMembership) {
  Model.OrgMembership.update(item, true)
}
deleteItem(item:OrganisationMembership) {
  Model.OrgMembership.delete(item)
}

setIsPopoverOpen(isPopoverOpen:boolean) { this.setState({isPopoverOpen:isPopoverOpen})}

tabSelected(tab:Option) { this.setState({tab:tab.value})}

newProjectClicked() {
  Actions.Controller.trigger({type:ActionType.ShowModal, modalContent:<NewProjectModal />})

}

renderBlankState() {
  if (this.state.tab === 'active') {
    return <div className="blankState">
      Get started by creating your first project ↗️
    </div>
  } else {
    return <div className="blankState">
Archive a project when you’re finished<br/>working on it and no one needs access to it  </div>
  }
}

render() {
  // const projectMemberships = (Model.ProjectMembership.state.items) ? Object.values(Model.ProjectMembership.state.items) : null; 

  // console.log("projectMemberships", projectMemberships);
 
  const {isPopoverOpen, tab} = this.state
  
  var items = Model.OrgMembership.projectsArray; 
 

  console.log("ITEMS", items,  Model.OrgMembership.state);
  var views, numberOfItems = 0;
  
  if (items) {
    items.sort(function(a, b) {
      if (!a.name || !b.name) { return 0; }
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    views = items.map((item) => { 
      
      if (this.state.tab === 'active' && !item.archived) {
        numberOfItems++;
      return <ProjectAndMembersView key={item.id} project={item} />
      } 
      if (this.state.tab === 'archived' && item.archived) {
        numberOfItems++;

        return <ProjectAndMembersView key={item.id} project={item} />
        }
      }
    )
  }  
  
  if (items && !numberOfItems) {
    views = this.renderBlankState()
  }
  const options = [
    { value: 'active', label: 'Active' },
    { value: 'archived', label: 'Archived' },
  ];

  return <div className="ProjectsView">
    <a className="addProjectButton" onClick={this.newProjectClicked}>+ New Project</a>
    <TabHeader tabs={options} selectedTab={this.state.tab} tabSelected={this.tabSelected} />
    {views}


  </div>;
}

}
