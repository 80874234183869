import React from 'react';

import { Pill, Button ,OnOffItem} from '@swivl/great-grey-components';

import {Job, CreateLogger, Intent, TaskType, TaskData_Intent,MakeDictonaryArrayUnique, TaskData_AudioCategorize } from '@swivl/great-grey-lib';
import { ActionsConsumer, Actions, ActionType, Action } from '../../../Actions/Actions';
import { Model } from '../../../Models/Model';


import "./AudioCategorizeJobStartedView.scss";

const log = CreateLogger("job:JobTaskIntent");

interface AudioCategorizeJobStartedViewProps { job:Job }
interface AudioCategorizeJobStartedViewState {  }






export  class AudioCategorizeJobStartedView extends React.Component<AudioCategorizeJobStartedViewProps,AudioCategorizeJobStartedViewState> {
  constructor(props:AudioCategorizeJobStartedViewProps) {
    super(props)
  }

  render() {
    const job = this.props.job;
    const task:TaskData_AudioCategorize = job.tasks[TaskType.AudioCategorize] as TaskData_AudioCategorize;
    log("TASK", task);
    const categories =  task.categories.map((item,index) => <Pill key={item.id} hideDelete dark>{item.name}</Pill>);

    
    return <div className="AudioCategorizeJobStartedView">
        {categories}
    </div>
  }

}
