import { CreateLogger, ShortId, TimelineCategory, TimelineSegment } from '@swivl/great-grey-lib';
import _ from "lodash" // Import the entire lodash library

const log = CreateLogger("AudioSplitExersiseView:Helper");

const MIN_SEGMENT_LENGTH = 0.5; 

export function AddSegmentToCategory(categories:TimelineCategory[], trackIndex:number, segment:TimelineSegment, duration:number):TimelineCategory[] {
    if (segment.endTime > duration) {  segment.endTime = duration; }
    if (segment.startTime < 0) {  segment.startTime = 0; }
    categories[trackIndex].segments.push(segment); 
    categories[trackIndex].segments = resolveOverlaps(categories[trackIndex].segments, segment);
    return categories; 
}
function timeInRange(time:number,  dominant:TimelineSegment ):boolean {
    return (time > dominant.startTime && time < dominant.endTime) ? true : false; 
}
function isInside(child:TimelineSegment,  parent:TimelineSegment ):boolean {
    return (timeInRange(child.startTime, parent) && timeInRange(child.endTime, parent)) ? true : false; 
}

export function DeleteSegment(categories:TimelineCategory[],categoryIndex:number,segmentIndex:number):TimelineCategory[] {
    if (categories[categoryIndex] && categories[categoryIndex].segments[segmentIndex] ) {
        categories[categoryIndex].segments.splice(segmentIndex, 1);
    }
    return categories; 
}
export function AdjustStartTime(categories:TimelineCategory[],categoryIndex:number,segmentIndex:number, startTime:number):TimelineCategory[] {
    if (startTime <  0) { startTime = 0; }
    if (categories[categoryIndex] && categories[categoryIndex].segments[segmentIndex] ) {
        let segment = categories[categoryIndex].segments[segmentIndex];
        segment.startTime = startTime;
        if (segment.endTime - segment.startTime < MIN_SEGMENT_LENGTH) {
            segment.startTime = segment.endTime - MIN_SEGMENT_LENGTH
        }
        categories[categoryIndex].segments[segmentIndex] = segment;
        categories[categoryIndex].segments = resolveOverlaps(categories[categoryIndex].segments, segment);
    }
    return categories;
}
export function AdjustEndTime(categories:TimelineCategory[],categoryIndex:number,segmentIndex:number, endTime:number, duration:number):TimelineCategory[] {
    if (endTime > duration) { endTime = duration }

    if (categories[categoryIndex] && categories[categoryIndex].segments[segmentIndex] ) {
        const segment = categories[categoryIndex].segments[segmentIndex];
        segment.endTime = endTime
        if (endTime - segment.startTime < MIN_SEGMENT_LENGTH) {
            segment.endTime = segment.startTime + MIN_SEGMENT_LENGTH;
        }
        categories[categoryIndex].segments[segmentIndex] = segment;
        categories[categoryIndex].segments = resolveOverlaps(categories[categoryIndex].segments, segment);
        }
    return categories;
}

export function AdjustPosition(categories:TimelineCategory[], categoryIndex:number,segmentIndex:number, originalSegments:TimelineSegment[],  delta:number,  duration:number,):TimelineCategory[] {
    const segment =  originalSegments[segmentIndex];
    segment.startTime =  Math.max(0 , segment.startTime + delta);
    segment.endTime =  Math.min(duration, delta + segment.endTime) ;
    originalSegments[segmentIndex] = segment;
    categories[categoryIndex].segments = originalSegments;
    categories[categoryIndex].segments = resolveOverlaps(categories[categoryIndex].segments, segment);
    return categories;
}



function resolveOverlaps(segments:TimelineSegment[], dominant:TimelineSegment):TimelineSegment[] {
    // const {startTime, endTime} = dominant;
    let filteredSegments:TimelineSegment[] = []; 
    for (let i = 0; i < segments.length; i++) {
        let segment = segments[i];
        if (segment.id === dominant.id) { filteredSegments.push(segment); continue; }

        //  First Check to see if the dominant is wholy within one of these. This can happen when selecting and adding.
        if  (isInside( dominant,segment)) {
            // Now we add two new segments, one on each side. 
            let leftNewSegment:TimelineSegment  = { id:ShortId(), startTime:segment.startTime, endTime:dominant.startTime} 
            let rightNewSegment:TimelineSegment  = { id:ShortId(), startTime:dominant.endTime, endTime:segment.endTime} 
            if (!(leftNewSegment.endTime - leftNewSegment.startTime < MIN_SEGMENT_LENGTH)) {   filteredSegments.push(leftNewSegment) }
            if (!(rightNewSegment.endTime - rightNewSegment.startTime < MIN_SEGMENT_LENGTH)) {   filteredSegments.push(rightNewSegment) }
            continue;
        }

        // Next we check to see if there is overlapping and we need to move anything. 

        const startInRange = timeInRange(segment.startTime, dominant );
        const endInRange   = timeInRange(segment.endTime, dominant );
        
        if (startInRange && endInRange) { // Check to see if it is enveloped. 
            continue; 
        }
        if (startInRange) {
            segment.startTime = dominant.endTime; 
        }
        if (endInRange) {
            segment.endTime = dominant.startTime;
        }
        if (!(segment.endTime - segment.startTime < MIN_SEGMENT_LENGTH)) {
            filteredSegments.push(segment) 
        }

        
    }

    return filteredSegments; 

}


