import React from 'react';
import { ActionsConsumer,Action, Actions, ActionType } from '../../Actions/Actions';
import "./ImageBoundingBoxScreen.scss";
import { Model } from '../../Models/Model';
import { NavTextButton } from '@swivl/great-grey-components';
import { Assignment, CreateLogger } from '@swivl/great-grey-lib';
import { NavBar } from '../Layout/NavBar/NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AssignmentProgress } from '../Assignment/components/AssignmentProgress/AssignmentProgress';
import { DemoExercisesView } from './DemoExersizeView';

const DemoAssignment = {"id":"702d81fa-0e24-4a70-a567-dca5bd2279d4","createdAt":"2020-08-19T22:05:24.991Z","updatedAt":"2020-08-19T22:05:24.991Z","type":"Intent","status":"In_Progress","task":{"name":"Intent Task","type":"Intent","status":"In Progress","intents":[],"redundancy":3,"subTaskSize":100},"rowCount":2,"work":{"W9362e65c":{"trust":1,"change":null,"userId":"8ab216b7-f90f-49a6-8d6f-81333590715c","originalRowId":"W9362e65c"},"X8ac35d61":{"trust":1,"change":null,"userId":"8ab216b7-f90f-49a6-8d6f-81333590715c","originalRowId":"X8ac35d61"}},"workOrder":["X8ac35d61","W9362e65c"],"subTaskId":"462928a9-9032-483d-9b6c-a3f1a0a8e59e","userId":"8ab216b7-f90f-49a6-8d6f-81333590715c","jobId":"cb10f672-d09a-4f87-b479-ead15da124c0","organisationId":"ffdd96c4-247d-4304-8ec0-aec949a040f7","subTask":{"id":"462928a9-9032-483d-9b6c-a3f1a0a8e59e","createdAt":"2020-08-19T22:05:19.265Z","updatedAt":"2020-08-19T22:05:24.986Z","type":"Intent","status":"Unstarted","task":{"name":"Intent Task","type":"Intent","status":"In Progress","intents":[],"redundancy":3,"subTaskSize":100},"redundancy":3,"minimumConsensus":"0.8","availableAssignments":2,"rowCount":2,"data":{"W9362e65c":{"id":"W9362e65c","meta":[{"value":"uOViNdWMZJ","column":"brainResultId"},{"value":"0.999922752","column":"score"},{"value":"handled","column":"handled"}],"text":"Login heko","intent":{"name":"Help - Login"}},"X8ac35d61":{"id":"X8ac35d61","meta":[{"value":"xacEPVLMDJ","column":"brainResultId"},{"value":"0.7300666","column":"score"},{"value":"handled","column":"handled"}],"text":"How can i learn","intent":{"name":"Getting Started"}}},"dataOrder":["X8ac35d61","W9362e65c"],"jobId":"cb10f672-d09a-4f87-b479-ead15da124c0","organisationId":"ffdd96c4-247d-4304-8ec0-aec949a040f7"}}
const log = CreateLogger("Assignment:Screen");
// import * as Model from '../../Models/Model';
interface ImageBoundingBoxScreenProps {

}
export class ImageBoundingBoxScreen extends ActionsConsumer<ImageBoundingBoxScreenProps> {

  get assignment():Assignment|undefined {
        return DemoAssignment as unknown as Assignment; 
    if(Model.Assignment.state.items && Model.Assignment.state.items[Model.Navigation.second()]) {
       return Model.Assignment.state.items[Model.Navigation.second()]
     } return null;
  }
  
  constructor(props:any) {
    super(props)
    Model.Assignment.load()
    Actions.Controller.subscribeTo(this,
      [ 
        ActionType.AssignmentUpdated, 
        ActionType.AssignmentDeleted,
        ActionType.AssignmentCreated,
        ActionType.AssignmentsLoaded, 
        ActionType.NavigationPathUpdated
      ]);

    this.saveAndExitClicked = this.saveAndExitClicked.bind(this)
  }
  componentDidMount() {

  }

  componentDidUpdate() {
    log("Update", Model.Assignment.state)
  }
  saveAndExitClicked() {
    Model.Navigation.setPath("/task");
    return; 
    
    log("this.assignment",this.assignment);
    if (window.confirm("Are you sure you want to exit? You haven't finished this assignment yet.")) {
      const assignment =  this.assignment; 
      if (assignment && assignment.subTask) {
        const subTaskId = (typeof assignment.subTask === 'string') ? assignment.subTask : assignment.subTask.id;
        Model.Navigation.setPath("/task/" + subTaskId);
      } else {
        Model.Navigation.setPath("/task");
      }
    }
  }


  renderNavBar(assignment:Assignment) {
    return <NavBar 
            left={<NavTextButton onClick={this.saveAndExitClicked} text={"Save & Exit"} />}
            center={<AssignmentProgress assignment={assignment}/>}
            />
  }
  renderLoading() {  return <div className="LayoutView"><NavBar center={<FontAwesomeIcon icon={faSpinner} spin/>}/></div> ; }
  
  render() {
    const assignment = this.assignment; 
    if (!assignment) { return this.renderLoading(); }

    return <div className="LayoutView">
            {this.renderNavBar(assignment)}

            <div className="ImageBoundingBoxScreen">
              <DemoExercisesView assignment={assignment} />
            </div>
          </div>;

  }

}
