

import {Job,  ItemById, CreateLogger, JobStatus,MLModel, GGDictionary, HasId, Capitalize, ShortId } from '@swivl/great-grey-lib';
import { Actions, ActionType, ItemsLoadedAction, ItemUpdatedAction, ItemCreatedAction, Action, ItemFetchedAction} from '../../Actions/Actions';
import { Model } from '../Model';
import Service from '../../Services/Service';
import _ from "lodash" // Import the entire lodash library

import { ActionConsumerInterface } from '../../Actions/Controller/ActionsController';
const log = CreateLogger("BaseModel")




export interface BaseModelState<T> extends Readonly<{
  isLoading:boolean; 
  items?:GGDictionary<T>,
}> {} 

interface ExtendedBaseModel extends Readonly<{
  isLoading:boolean; 
  items?:any
}> {} 

// const log = CreateLogger("JobModel");

export class BaseModel<T,S extends BaseModelState<T>> implements ActionConsumerInterface{
    ENTITY_NAME = "job";
    subscriptionId = ShortId();

    
    state:BaseModelState<T> | S = {
        isLoading:false, 
        items:undefined,
    }

    actions:GGDictionary<ActionType> = {
        Loaded: ActionType.ItemsLoaded,
        ItemUpdated : ActionType.ItemUpdated,
        ItemDeleted: ActionType.ItemDeleted,
        ItemCreated: ActionType.ItemCreated,
        ItemFetched: ActionType.ItemFetched
    }
  handleAction(action:Action) {  }

    static reset()  { 
      if ((this as any)._Model) {         
        Actions.Controller.unsubscribe((this as any)._Model)
        delete (this as any)._Model; 
      }
  }

/** Helper method to get an array of the items. Usefull for itterating. */
get array():Array<T> {  return (this.state.items) ? Object.values(this.state.items) : null; }

  load(merge?:boolean) {
    Service.API.getItemsAndIds<T>(this.ENTITY_NAME).then((results) => {
      log("Results", results)
      if (merge && this.state.items) {
        _.merge(results, this.state.items)
      }
      this.state = {...this.state,   isLoading:false, items:results}
      Actions.Controller.trigger({type:this.actions.Loaded} as ItemsLoadedAction);

    }).catch(Service.Toast.error)
  }
  

  async fetch(item:T&HasId) {
    // let item = Object.assign({}, itemParam);
    Service.API.getItem<T>(this.ENTITY_NAME, item.id).then((result) => {
      console.log("Fetch Results", result)
      var initialState:any =  this.state;
      if (!initialState.items) { initialState.items = {}}
      initialState.items[item.id] = result; 
      this.state = {...this.state,   isLoading:false, items:initialState.items}
      console.log("FETCHED!",result);
      Actions.Controller.trigger({type:this.actions.ItemFetched, item:result} as ItemFetchedAction);

    }).catch(Service.Toast.error)
 
    
  }
  async fetchById<T>(itemId:string):Promise<T> {
    // let item = Object.assign({}, itemParam);
    return Service.API.getItem<T>(this.ENTITY_NAME, itemId).then((result) => {
      log("Results", result)
      var initialState:any =  this.state;
      if (!initialState.items) { initialState.items = {}}
      initialState.items[itemId] = result; 
      this.state = {...this.state,   isLoading:false, items:initialState.items}
      console.log("FETCHED!",result);
      Actions.Controller.trigger({type:this.actions.ItemFetched} as ItemsLoadedAction);
      return initialState.items[itemId]; 
    }).catch(Service.Toast.error)
 
    
  }
  
  async update(item:T&HasId, shouldSave?:boolean) {
    // let item = Object.assign({}, itemParam);
    const initialState = Object.assign({}, this.state);

    // const unsavedItem = {...initialState.items[item.id]};
    
 

    this.state = {...this.state,   isLoading:false, items:{...this.state.items, [item.id]: item}}
    Actions.Controller.trigger({type:this.actions.ItemUpdated, item:item} as ItemUpdatedAction);
    if (shouldSave) {
      log("Saving Item", item);

      return Service.API.updateItem<T>(this.ENTITY_NAME, item).catch((e)=>{
        this.state = initialState;
        Actions.Controller.trigger({type:this.actions.ItemUpdated, item:item} as ItemUpdatedAction);
        Service.Toast.error(e, "Error updating " + this.ENTITY_NAME);
      })
    }  else {
      return Promise.resolve(item)
    }
  }
  create(item:T):Promise<any|void> {
    return new Promise((resolve, reject) => {
        Service.API.createItem<T>(this.ENTITY_NAME, item)
        .then((item:T&HasId) => {
            this.state = {...this.state,   isLoading:false, items:{...this.state.items, [item.id]: item}}
            Actions.Controller.trigger({type:this.actions.ItemCreated, item:item} as ItemCreatedAction);
            resolve(item)
        })
        .catch((e)=>{
            Actions.Controller.trigger({type:this.actions.ItemUpdated, item:item} as ItemUpdatedAction);
            Service.Toast.error(e, "Error updating " + this.ENTITY_NAME);
            reject(e);
          })
    })

 
  }
  delete(item:T&HasId):Promise<T> { 
    const originalState  = {...this.state}
    const state = {...this.state}
    delete state.items[item.id]
    this.state = state; 
    Actions.Controller.trigger({type:this.actions.ItemDeleted, item:item} as ItemUpdatedAction);
    return new Promise((resolve, reject) => {
      Service.API.deleteItem<T>(this.ENTITY_NAME, item)
      .then((_) => {
        Service.Toast.success(Capitalize(this.ENTITY_NAME) + " deleted.");
        resolve(item)
      }).catch((e)=>{
          this.state = originalState;
          Actions.Controller.trigger({type:this.actions.ItemDeleted, item:item} as ItemUpdatedAction);
          Service.Toast.error(e, "Error deleting " + this.ENTITY_NAME);
          reject(e);
      })
  })
    
  }


  
}
