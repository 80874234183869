import { Action, ActionType } from "../Actions";
import { CreateLogger } from "@swivl/great-grey-lib";
const log = CreateLogger("actions:controller");

export interface ActionConsumerInterface {
    subscriptionId:string; 
    handleAction(action:Action):void; 
}
type SubscriberDictionary = {
    [subscriptionId:string]: ActionConsumerInterface;
}
type SubscriptionType = {
    [actionType:string]: SubscriberDictionary;
}
export class ActionsController {
    static get Controller():ActionsController {   if (ActionsController._Controller) { return ActionsController._Controller; }  else {   ActionsController._Controller = new ActionsController(); return ActionsController._Controller ; }  }
    private static _Controller:ActionsController;

    subscriptions:SubscriptionType = {}

    subscribeTo(actionConsumer:ActionConsumerInterface, actions:Array<ActionType>) {

        for (let index = 0; index < actions.length; index++) {
            const action = actions[index];
            if (Object.values(ActionType).includes(action)) {
                if (!this.subscriptions[action]) { this.subscriptions[action] = {}; }
                this.subscriptions[action][actionConsumer.subscriptionId] = actionConsumer;
            } else {
                log.warn("Trying To Subscribe to an unknown action.",action)
            }
        }
    }
    unsubscribe(actionConsumer:ActionConsumerInterface) {
        for (const actionType in this.subscriptions) {
            if (this.subscriptions.hasOwnProperty(actionType)) {            
                if (this.subscriptions[actionType][actionConsumer.subscriptionId]) {
                    log("Unsubscribing", actionType, actionConsumer.subscriptionId)
                    delete this.subscriptions[actionType][actionConsumer.subscriptionId]
                }
            }
        }
    }
    trigger(action:Action) {
        if (!action.type) { log.warn("No Action Type", action); return; }
        let numberOfSubscribers = 0;
        if (Object.values(ActionType).includes(action.type)) {
            const subscribers = this.subscriptions[action.type];
            for (const subscriptionId in subscribers) {
                if (subscribers.hasOwnProperty(subscriptionId)) {
                    numberOfSubscribers++;
                    subscribers[subscriptionId].handleAction(action);
                }
            }
        } else {
            log.warn("Trying To Trigger an unknown action.", action)

        }
        log(`Triggered ${action.type} to ${numberOfSubscribers} subscribers ${this.subscriptions[action.type]}`);
    }
}
