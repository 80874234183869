import React from 'react';
import { CreateLogger } from '@swivl/great-grey-lib';

import "./TimelineSegment.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { PositionAndSegmentInterface } from '../TimelineSegments';

const log = CreateLogger("AudioSplitExersiseView:TimelineSegment");


interface TimelineSegmentProps { 
  
  

    item:PositionAndSegmentInterface

    slidingSegmentId:string 
    
    selectedSegmentId?:string 
    segmentSelected(selectedSegmentId?:string )

    isPlaying:boolean;
    playingId?:string;
    playAtTime(time:number, duration?:number, playingId?:string);
    pause();
    deleteSegment(trackIndex:number, segmentIndex:number)

    startDragLeft(item:PositionAndSegmentInterface)
    startDragRight(item:PositionAndSegmentInterface)
    startSlide(item:PositionAndSegmentInterface, e:React.MouseEvent<HTMLDivElement, MouseEvent>);    
}




export class TimelineSegmentView extends React.Component<TimelineSegmentProps> {
    divRef = React.createRef<HTMLDivElement>()  

    
  constructor(props:any) {
        super(props) 
        this.onClick = this.onClick.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.playClicked = this.playClicked.bind(this);
        this.pauseClicked = this.pauseClicked.bind(this);
        this.deleteClicked = this.deleteClicked.bind(this); 

        this.leftOnMouseDown    = this.leftOnMouseDown.bind(this);
        this.rightOnMouseDown   = this.rightOnMouseDown.bind(this);
        this.onMouseDown        = this.onMouseDown.bind(this)

    }
    onClick() {
      this.props.segmentSelected(this.props.item.id);
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClick, false)
    }
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClick, false)

    }
    // Used for handling clicks outside the component when the menu is open. 
    handleClick(e) {
      if (!this.props.selectedSegmentId || this.props.item.id !== this.props.selectedSegmentId) { return; }
      if (!this.divRef.current.contains(e.target)) {
        this.props.segmentSelected(null);
      }
    }

    playClicked(e:React.MouseEvent) {
      e.preventDefault();
      console.log("Play Clicked")
      this.props.playAtTime(this.props.item.startTime, this.props.item.endTime - this.props.item.startTime, this.props.item.id )
    }

    pauseClicked(e:React.MouseEvent) {
      e.preventDefault();
      this.props.pause();
    }

    deleteClicked() {
      this.props.deleteSegment(this.props.item.categoryIndex, this.props.item.segmentIndex)
    }



    leftOnMouseDown(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
      e.preventDefault();
      e.stopPropagation()

      this.props.startDragLeft(this.props.item);
    }
  
    rightOnMouseDown(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
      e.preventDefault();
      e.stopPropagation()
      this.props.startDragRight(this.props.item);
    }
    onMouseDown(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
      e.preventDefault();
      e.stopPropagation()
 
      log("ON MOUSE DOWN!!!")
      
      this.props.startSlide(this.props.item, e);

    }

 

    




    renderMenu() {
      if (!this.props.selectedSegmentId || this.props.item.id !== this.props.selectedSegmentId) { return null; }
      const playPause = (this.props.isPlaying && this.props.playingId === this.props.item.id) ?
      <a onClick={this.pauseClicked}><FontAwesomeIcon icon={faPause} /></a> :
      <a onClick={this.playClicked}><FontAwesomeIcon icon={faPlay} /></a>;
      return <div className="segmentMenu">
                <a onClick={this.deleteClicked}><FontAwesomeIcon icon={faTrash} /></a>
                {playPause}
              <div className="segmentMenuArrow" />
            </div>
    }








  render() {
    const { id, startTime, endTime } = this.props.item;

    

    const style = {
        left:(startTime * 20) +"px", 
        width:((endTime - startTime) * 20) + "px"
    }   

    // const className = (this.props.slidingSegmentId && this.props.slidingSegmentId ===  id) ? "TimelineSegment isSliding" : "TimelineSegment"

    return <div 
            ref={this.divRef} 
            style={style} 
            className="TimelineSegment"
            onClick={this.onClick}
            onMouseDown={this.onMouseDown}
            >
      <div className="leftHandle" onMouseDown={this.leftOnMouseDown}>  <div className="marker"/></div>
      <div className="rightHandle" onMouseDown={this.rightOnMouseDown}> <div className="marker"/></div>
      {this.renderMenu()}
    </div>  
  } 

}