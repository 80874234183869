import React, { createRef } from 'react';
import './TranscribeReviewItem.scss';
import { CreateLogger, ReviewRow,TranscribeComparisonString, RowChangeType, ChangeStatus, RowChange, Intent, CreateRowChange_Intent, Job, TaskData_Intent, CreateRowChange_Excluded, TaskType, AssignmentWorkRow, ShortId, CreateRowChange_Transcribe } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button,ButtonDropdown, Option, Color, Input, Pill, SaveInput } from '@swivl/great-grey-components';
import { ReviewItemViewProps } from '../../TaskViews';
import { MiniAudioPlayer } from '../../Shared/MiniAudioPlayer/MiniAudioPlayer';



const log = CreateLogger("job:TranscribeReviewItem")

interface TranscribeReviewItemOptionProps {
    selectedOptionText?:string, 
    option?:TranscribeChangeOptions 
    isCustom:boolean
    optionSelected(change:RowChange)

}
export class TranscribeReviewItemOption extends React.Component<TranscribeReviewItemOptionProps> {

    
    constructor(props:TranscribeReviewItemOptionProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() { 
        this.props.optionSelected(this.props.option.change);

    }

    render() {
        let text
        if (this.props.isCustom && this.props.option.change) {
            text = "Custom: " + (this.props.option.change as any).text;
        } else if (this.props.isCustom) {
            text = "Custom"; 
        } else {
            text = (this.props.option.change as any).text;
        }

        const className = (this.props.selectedOptionText && this.props.option.cleanedText === this.props.selectedOptionText ) ? "TranscribeReviewItemOption selected" : "TranscribeReviewItemOption"

        return <div className={className} onClick={this.onClick}>
            <div className="radioButton"><span/></div>
            <div className="text">{text}</div>
        </div>
    }
}



interface TranscribeChangeOptions {
    id:string,
    work:AssignmentWorkRow[]
    change:RowChange
    cleanedText:string
    count:number
}
interface TranscribeReviewItemState {
    isEditingCustom:boolean 
}
export class TranscribeReviewItem extends React.Component<ReviewItemViewProps,TranscribeReviewItemState> {
    private audioRef = createRef<HTMLAudioElement>()

    options:TranscribeChangeOptions[] = [];
    numberOfSkipped:number = 0;
    constructor(props:ReviewItemViewProps) {
        super(props)
        this.state = {isEditingCustom:false} 

    const {  type,  originalRow,   work, confidence, changeStatus, change } = props.row;

    for (let i = 0; i < work.length; i++) {
        const assignmentWorkRow = work[i];
        if (assignmentWorkRow.change && assignmentWorkRow.change.type === RowChangeType.Transcribe) {
            let rowCleanedText = TranscribeComparisonString(assignmentWorkRow.change.text);
            let hasFound = false; 
            for (let o = 0; o < this.options.length; o++) {
                if (this.options[o].cleanedText === rowCleanedText) {
                    hasFound = true; 
                    this.options[o].count++;
                    this.options[o].work.push(assignmentWorkRow);
                }
            }
            if  (!hasFound) {
                this.options.push({
                    id:ShortId(),
                    work:[assignmentWorkRow],
                    change:assignmentWorkRow.change,
                    cleanedText:rowCleanedText,
                    count:1
                })
            }


        }

        if (assignmentWorkRow.change && assignmentWorkRow.change.type === RowChangeType.Skipped) {
            this.numberOfSkipped++; 
        }

        
    }

        this.customOptionSelected   = this.customOptionSelected.bind(this);
        this.optionSelected         = this.optionSelected.bind(this);
        this.setIsEditingCustom     = this.setIsEditingCustom.bind(this)
        this.saveCustom             = this.saveCustom.bind(this);
        this.excludeClicked         = this.excludeClicked.bind(this)
        this.play = this.play.bind(this)
    }
    play() {
        if (this.audioRef && this.audioRef.current) {
            (this.audioRef.current as HTMLAudioElement ).play();
        }
    }
    customOptionSelected(text:string) {
        let item = this.props.row;
        item.changeStatus = ChangeStatus.ResolvedEscalation;
        item.change = CreateRowChange_Transcribe(text);
        this.props.updatedRow(item);
    }

    optionSelected(change:RowChange) {
        let item = this.props.row;
        item.changeStatus = ChangeStatus.ResolvedEscalation;
        item.change = change;
        this.props.updatedRow(item);
        this.setState({isEditingCustom:false}); 

    }
    setIsEditingCustom() { 
        let item = this.props.row;
        item.changeStatus = ChangeStatus.UnresolvedEscalation;
        item.change = undefined;
        this.props.updatedRow(item);

        this.setState({isEditingCustom:true}); 
    
    }
    // customOnChange(event:React.ChangeEvent<HTMLInputElement>):void {
    //     if  (!event.target.value || event.target.value.length < 1) { return; }
    //     let item = this.props.row;
    //     item.changeStatus = ChangeStatus.ResolvedEscalation;
    //     item.change = CreateRowChange_Transcribe(event.target.value);
    //     this.props.updatedRow(item);
    // }
    excludeClicked() {
        this.setState({isEditingCustom:false}); 

        let item = this.props.row;
        item.changeStatus = ChangeStatus.ResolvedEscalation;
        item.change = CreateRowChange_Excluded()
        this.props.updatedRow(item);
  
    }
    saveCustom?(name:string, value:string, saveComplete:()=>void) {
        let item = this.props.row;
        item.changeStatus = ChangeStatus.ResolvedEscalation;
        item.change = CreateRowChange_Transcribe(value);
        this.props.updatedRow(item);
        saveComplete();
        setTimeout(() => {
            console.log("My item", this.props.row)
        },1000)
    }

    renderCustom() {
        const {  type,  originalRow,   work, confidence, changeStatus, change } = this.props.row;

        var isCustom = true; 
        var isExclude = false 
        var text;        
        if (change && change.type ===  RowChangeType.Transcribe) {
            text = TranscribeComparisonString(change.text)
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].cleanedText === text) {
                    isCustom = false; 
                    break;
                }
            }
        }   
        if (change && change.type ===  RowChangeType.Excluded ) { isCustom = false; isExclude = true; }
        if (changeStatus && changeStatus ===  ChangeStatus.UnresolvedEscalation ) { isCustom = false }



        const className = (!isExclude && (isCustom || this.state.isEditingCustom )) ? "TranscribeReviewItemOption selected customOption" : "TranscribeReviewItemOption  customOption"

        if (isCustom || this.state.isEditingCustom ) {
            const editText =  (change && change.type ===  RowChangeType.Transcribe) ? change.text : ""

            return <div  key={"custom1"} className={className} >
                        <div className="radioButton"><span/></div>
                        <SaveInput 
                        name="customInput"
                        placeholder="Enter text..."
                        save={this.saveCustom} value={editText} />
                    </div>;
        }
        

        return <div key={"custom"} className={className} onClick={this.setIsEditingCustom}>
                <div className="radioButton"><span/></div>
                <div className="text">Custom</div>
            </div>
    }

    renderExcludeButton() {
   
        if (this.props.row.changeStatus === ChangeStatus.ResolvedEscalation &&
                          this.props.row.change && 
                          this.props.row.change.type === RowChangeType.Excluded) {
                            return <div key="Exclude" className={"TranscribeReviewItemOption selected"} >
                            <div className="radioButton"><span/></div>
                            <div className="text">Exclude</div>
                        </div> 
                          } else {
                            return <div key="Exclude" className={"TranscribeReviewItemOption"} onClick={this.excludeClicked}>
                            <div className="radioButton"><span/></div>
                            <div className="text">Exclude</div>
                        </div> 
                          }
                          
                          
    }
  render() {
    const {  type,  originalRow,   work, confidence, changeStatus, change } = this.props.row;
    
    const completeIcon = (changeStatus === ChangeStatus.ResolvedEscalation) ? <div className="completeIcon"><FontAwesomeIcon icon={faCheck} /></div> : null;

    const skipped = (this.numberOfSkipped > 0) ? <Pill dark hideDelete>{this.numberOfSkipped} Skipped</Pill> : null;

    const changeCleanText = (!this.state.isEditingCustom && change && change.type === RowChangeType.Transcribe) ? TranscribeComparisonString(change.text) : null;


    let options = this.options.map((item, index) => {
        return <TranscribeReviewItemOption 
                key={item.id}
                selectedOptionText={changeCleanText}
                optionSelected={this.optionSelected} 
                option={item}  
                isCustom={false}  
                />
    })
    options.push(this.renderCustom())
    options.push(this.renderExcludeButton())
    return <div className="TranscribeReviewItem">
        <div className="player">
        <MiniAudioPlayer audioURL={originalRow.audioURL} startTime={originalRow.audioStartTime} endTime={originalRow.audioEndTime} />
        </div>
        {completeIcon}
        {options}
        {skipped}

    </div>;
  }

}
