import React from 'react';
import { CreateLogger } from '@swivl/great-grey-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import "./WaveformView.scss"
const log = CreateLogger("WaveformView");


interface WaveformViewProps { 
    duration?:number; 
    dataPoints?:number[];
}
interface WaveformViewState {
    imgData?:any 
}
export class WaveformView extends React.Component<WaveformViewProps, WaveformViewState> {
  worker:Worker; 
  constructor(props:any) {
    super(props) 
    this.state =  {}
    this.worker = new Worker('/workers/WaveFormWorker.js');
  }

  componentDidMount() {
    log("componentDidMount")
    this.worker.addEventListener('message', (e) => {
      log("Got MEssage")
        this.setState({imgData:e.data})
      }, false);
  }
  componentDidUpdate(prevProps) {
      if (!prevProps.dataPoints && this.props.dataPoints) {
          log("Loaded Data Points")
          this.worker.postMessage(this.props.dataPoints); 
      }
  }


  renderImage() { 
      return (this.state.imgData) ? <img src={this.state.imgData} /> : null; 
  }
  render() {    

    let style:any = (this.props.dataPoints) ? {width:this.props.dataPoints.length * 2 + "px"} : {width:" 100px"}
    let spinner; 
    if (this.state.imgData) {
      style.background = "url(" + this.state.imgData + ")";
    }  else {
      spinner = <div className="waveformSpinner">
        <FontAwesomeIcon icon={faSpinner} spin/>
      </div>
    }
    return <div style={style} className="WaveformView" >
            {spinner}
            </div>;   
  }
}

