import React from 'react';
import { SelectionList,Option } from '@swivl/great-grey-components';
import { CreateLogger,CreateRowChange_Transcribe,Assignment,WorkRowStatus,CreateRowChange_Intent, TaskData_Intent, AssignmentWorkRow, Intent, DataSetRow, RowChangeType } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../Screens/Assignment/Exercises/Card/ExerciseCard';
import TextareaAutosize from 'react-textarea-autosize';

import "./SentimentGroupExerciseView.scss"
import { AudioPlayer } from '../../Shared/AudioPlayer/AudioPlayer';
import { SentimentGroupItem } from './SentimentGroupItem';
const log = CreateLogger("SentimentGroupExercise:Main");




export class SentimentGroupExerciseView extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  constructor(props:any) {
    super(props) 

    var data:DataSetRow[] = []
    for (const key in props.assignment.subTask.data) {
      if (props.assignment.subTask.data.hasOwnProperty(key)) {
        data.push( props.assignment.subTask.data[key])
      }
    }
    data.sort((a,b) => a.audioStartTime - b.audioStartTime) 
    


    this.state = {isShowing:false,data:data} 

    // let intents = (this.props.assignment.task as TaskData_Intent).intents;
    // let row:AssignmentWorkRow = props.work;
    this.onChange = this.onChange.bind(this)

  }
  doneClicked() {
      if (!this.state.data || this.state.data.length == 0) {
        alert("You must enter a transcription or skip.");
        return; 
      }
      let work = this.props.work;    
      work.change = CreateRowChange_Transcribe(this.state.data);
      // this.props.update(work);
      this.props.save(work);

  }


  handleKeyDown(event:KeyboardEvent)  {
    log("Key Down");
    if (!this.state.isShowing) {
      log("I'm not supposed to be showing");
    }
    if  (event.keyCode === 13) {
    //   let row:AssignmentWorkRow = this.props.work;
    //   if (this.hasUpdated && this.selectedIntent) {
    //     row.change = CreateRowChange_Intent(this.selectedIntent)
    //   } else {
    //     const intents = (this.props.assignment.task as TaskData_Intent).intents;
    //     row.change = CreateRowChange_Intent(intents[0])
    //   } 
    //   log("Change", row.change)
    //   this.props.save(row);
    }
  }
    onChange?(event:React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({data:event.target.value});
    }
    renderKey() { return null; }
  
  renderContent() {

      log("Sentiment  Exercise Props", this.props )
    // let intents = (this.props.assignment.task as TaskData_Intent).intents;

    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
    
    let data:any[] = this.state.data;
    let test = []
    for (let i = 0; i < data.length; i++) {
      test.push(<SentimentGroupItem 
                  key={i} 
                  row={data[i]} 
                  work={this.props.assignment.work[data[i].id]}
                  save={this.props.save}
                  />)
      
    }

    return <div className="SentimentGroupExerciseView">
              <div className="sentimentColumn">
              <div className="sentimentHeader">
                <span className="leftHeader">Agent</span>
                <span className="rightHeader">Customer</span>
              </div>
               {test}
               </div>

           </div>;
  }
}
