import React from 'react';
import { SelectionList,Option, Button } from '@swivl/great-grey-components';
import { CreateLogger,Assignment,WorkRowStatus,CreateRowChange_Intent, TaskData_Intent, AssignmentWorkRow, Intent, DataSetRow, TaskData_ImageCategorize, TaskCategory, CreateRowChange_ImageCategorize } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../Screens/Assignment/Exercises/Card/ExerciseCard';
import "./ImageCategorizeExerciseView.scss"
import ImageCategorizeExerciseViewButton from './Button/ImageCategorizeExerciseViewButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Service from '../../../Services/Service';
const log = CreateLogger("IntentExercise:Main");




export class ImageCategorizeExerciseView extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  constructor(props:any) {
    super(props) 
    this.state = {isShowing:false, data:{}}
    // let intents = (this.props.assignment.task as TaskData_Intent).intents;
    // let row:AssignmentWorkRow = props.work;
    this.categorySelected = this.categorySelected.bind(this)
    this.clearSelected    = this.clearSelected.bind(this)

  }
  handleKeyDown(event:KeyboardEvent)  {
    // log("Key Down");
    // if (!this.state.isShowing) {
    //   log("I'm not supposed to be showing");
    // }
    // if  (event.keyCode === 13) {
    //   let row:AssignmentWorkRow = this.props.work;
    //   if (this.hasUpdated && this.selectedIntent) {
    //     row.change = CreateRowChange_Intent(this.selectedIntent)
    //   } else {
    //     const intents = (this.props.assignment.task as TaskData_Intent).intents;
    //     row.change = CreateRowChange_Intent(intents[0])
    //   } 
    //   log("Change", row.change)
    //   this.props.save(row);
    // }
  }
  

  categorySelected(category:TaskCategory) {
    if (category.specific && category.specific.length) {
      this.setState({data:{showingSpecific:category}});
      return;
    }

    let row:AssignmentWorkRow = this.props.work;
    // if (this.hasUpdated && this.selectedIntent) {
    //   row.change = CreateRowChange_Intent(this.selectedIntent)
    // } else {
    //   const intents = (this.props.assignment.task as TaskData_Intent).intents;
    //   row.change = CreateRowChange_Intent(intents[0])
    // } 

    row.change = CreateRowChange_ImageCategorize({id:category.id, name:category.name})
    log("Change", row.change)
    this.props.save(row);


  }
  clearSelected() {       this.setState({data:{showingSpecific:null}}); }

  renderContent() {
    let task = (this.props.assignment.task as TaskData_ImageCategorize);
    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 

    console.log("MY TASK", task)
    let {data} = this.state; 
    let specificHeading;
    let buttons = []

    for (let i = 0; i < task.categories.length; i++) {
      const cat = task.categories[i];
      
      if (data.showingSpecific) {
        if (data.showingSpecific.id === cat.id) {
        specificHeading = <h3><FontAwesomeIcon  icon={faArrowLeft} onClick={this.clearSelected} />{cat.specificTitle}</h3>
        buttons = cat.specific.map((item) => <ImageCategorizeExerciseViewButton key={item.id} category={item} categorySelected={this.categorySelected} /> )
      }
      } else {
        buttons.push( <ImageCategorizeExerciseViewButton key={cat.id} category={cat} categorySelected={this.categorySelected} /> )
      }
    }

    const imageURL = Service.File.mediaURL(originalRow.imageURL) ;
    
    return <div className="ImageCategorizeExerciseView">
        <div className="imageAndButtons">
          <div className="imageWrapper">
            <img src={imageURL} />

          </div>
          <div className="buttonsArea">
            {specificHeading}
            <div className="buttonsWrapper">

            {buttons}
            </div>

          </div>
        </div>
     
    </div>   
  }
}
