import React from 'react';
import { MLModel, OrganisationMembership, Project, User } from '@swivl/great-grey-lib';
import "./SelectModelModal.scss"
import { Model } from '../../Models/Model';
import { Input, Option, Select, Button, Color } from '@swivl/great-grey-components';
import { Actions, ActionsConsumer, ActionType } from '../../Actions/Actions';
import Service from '../../Services/Service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// import * as Model from '../../Models/Model';
interface State {
    selectedModel?:MLModel
}
interface Props {
    filterKey?:string 
    modelSelected(model:MLModel)
}
export default class SelectModelModal extends ActionsConsumer<Props,State> {
 
  constructor(props:Props) {
    super(props)
    this.state = {}

    Actions.Controller.subscribeTo(this, [
        ActionType.MLModelsLoaded,
    ])
    Model.MLModel.load()

  }
  onChange = (selectedOption:Option) =>{
    this.setState({selectedModel:selectedOption.data})
  }
  doneClicked = () => {
    this.props.modelSelected(this.state.selectedModel)
    Actions.Controller.trigger({type:ActionType.HideModal})

  }

    render() {
     const models = Model.MLModel.array; 
     const filterKey = this.props.filterKey

      const options = (models) ? models.filter((item) => {
        if (filterKey && (!item.data || !item.data[filterKey])) { return false; }
        return true; 
      }).map((item) =>{ 
      return {label:item.name,  value:item.id,  data:item}}) : null; 
        const select = (options) ? <Select options={options} onChange={this.onChange} value ={(this.state.selectedModel)? this.state.selectedModel.id : null}/> : <div className="loadingSpinner"><FontAwesomeIcon icon={faSpinner} spin /> </div>
  return <div className="SelectModelModal">
      <img src={'/img/nav/icon-models@2x.png'} />
      <h1>Select Model</h1>
      <div className="buttonOrSpinner">
      {select}
      </div>
      <Button onClick={this.doneClicked} color={Color.Primary} isDisabled={(options)?false:true} isLoading={(options)?false:true}>Select</Button>

      {/* <label>PROJECT NAME</label>
      <Input onChange={this.projectNameUpdated} />
      <label>PROJECT LEAD</label>
      <Select 
      value={(this.state.projectLead)?this.state.projectLead.id :null}
        options={options} 
        isSearchable={true}
        onChange={this.leadChanged} />
      <div className="buttons">
          <Button onClick={this.cancelClicked} color={Color.Tan} isDisabled={this.state.isCreatingProject}>Cancel</Button>
          <Button onClick={this.createClicked} color={Color.Primary} isLoading={this.state.isCreatingProject}>Create Project</Button>
      </div> */}
  </div>
}

}
