import React from "react"
import Service from "../../../../Services/Service"
import "./ImageReviewImage.scss"
interface Props {
    imageURL:string 

  }
  interface State {
      isExpanded:boolean 
  }
  export default class ImageReviewImage extends React.Component<Props,State> {
    constructor(props:any) {
      super(props)
      this.state = {isExpanded:false}
      this.onClick = this.onClick.bind(this)
      } 
      onClick() { this.setState({isExpanded:!this.state.isExpanded}) }
      render() {
       return <div onClick={this.onClick} className={(this.state.isExpanded) ? "ImageReviewImage isExpanded" : "ImageReviewImage"}>
           <img src={Service.File.mediaURL(this.props.imageURL)} /> 
       </div>
      }
  }
  