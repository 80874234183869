import React, { createRef } from 'react';
import { CreateLogger,TimelineCategory } from '@swivl/great-grey-lib';
import "./CursorView.scss"
import { Pos } from '../TimelineView';
import { MouseHandler } from '../MouseHandler/MouseHandler';



const log = CreateLogger("AudioSplitExersiseView:CursorView");




interface CursorViewProps { 
    mouseHandler?:React.RefObject<MouseHandler>;
    categories:TimelineCategory[]
    duration:number; 

}

interface CursorViewState {
  x:number; 
    
}
function value_limit(val, min, max) {
  return val < min ? min : (val > max ? max : val);
}

export class CursorView extends React.Component<CursorViewProps, CursorViewState> {

  constructor(props:any) {
    super(props) 
    this.state =  {x:0}

}
  setX(x:number) { 
    


    
    this.setState({x:value_limit(x,0, this.props.duration * 20)});
   }

  
  
  render() {    

      const x = this.state.x - 2;

      const className =  (this.props.mouseHandler && this.props.mouseHandler.current && this.props.mouseHandler.current.props.timelineSegmentsRef && this.props.mouseHandler.current.props.timelineSegmentsRef.current && this.props.mouseHandler.current.props.timelineSegmentsRef.current.isDragging()) ? 
      "CursorView isDraggingSegment" : "CursorView" 


  

      const tracks = this.props.categories.map((item,index) => <div key={"cursor_" + index} className="rowCursor"/> )   


      return <div style={{transform:"translate(" + x + "px)"}} className={className}>
        <div className="mainCursor"/>
        <div className="trackCursors">
        {tracks}
        </div>
       
          </div>

    
  }
}