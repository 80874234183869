import React from 'react';
import "./InstructionBox.scss"
interface Props {
    toggleInstructionBox():void; 
    isOpen:boolean; 
}
export class InstructionBox extends React.Component <Props> {

  constructor(props:Props) {
    super(props)

  }
  componentDidMount() {

  }


  
  render() {
    
    const className = (this.props.isOpen) ? "InstructionBox open" : "InstructionBox"
    return <div className={className} >
              <h3>Example of Owls</h3>
            <img src="https://great-grey-cdn.s3-us-west-1.amazonaws.com/owl3.png" width={318} height={318} /> 
            <img src="https://great-grey-cdn.s3-us-west-1.amazonaws.com/owl2.png" width={318} height={163} /> 


          </div>;

  }

}
