import React, { createRef } from 'react';
import { CreateLogger, TimelineCategory } from '@swivl/great-grey-lib';
import "./TimelineView.scss"
import { WaveformView } from '../../../Shared/WaveformView/WaveformView';
import { TimelineTracks } from '../TimelineTracks/TimelineTracks';
import { CursorView } from './CursorView/CursorView';
import { AudioCategorizeAndSplitExercisesView } from '../../AudioCategorizeAndSplitExercisesView';
import { SelectionView } from './SelectionView/SelectionView';
import {  TimelineSegmentsView } from '../TimelineSegment/TimelineSegments';
import { MouseHandler } from './MouseHandler/MouseHandler';
import { PlayheadView } from './PlayheadView/PlayheadView';

const log = CreateLogger("AudioCategorizeAndSplitExercisesView:TimelineView");

export interface Pos {
    x:number
    y:number 
}

interface TimelineViewProps { 
    duration?:number;
    dataPoints?:number[];
    delegate:AudioCategorizeAndSplitExercisesView
    isPlaying:boolean;
    playingId?:string;
    playAtTime(time:number, duration?:number, playingId?:string);
    pause();
    categories:TimelineCategory[]
 }

interface TimelineViewState {
    // cursor?:Pos
    // isMouseDown:boolean
    // isMouseUp:boolean
    // isSelecting:boolean 
    // showSelection:boolean
    // mouseDown?:Pos
    // mouseUp?:Pos
    // playbackCursor?:Pos 
}
export class TimelineView extends React.Component<TimelineViewProps, TimelineViewState> {
  mainRef = createRef<HTMLDivElement>();
  mouseHandlerRef = createRef<MouseHandler>();
  cursorRef = createRef<CursorView>();
  selectionViewRef = createRef<SelectionView>();
  timelineSegmentsRef = createRef<TimelineSegmentsView>();

  tickInterval:NodeJS.Timeout | undefined;;
    constructor(props:any) {
    super(props) 
    this.state =  {isMouseDown:false, isMouseUp:false,showSelection:false, isSelecting:true}

 
    
  }


  
  
  
  
  render() {    
    log("render")
    return <MouseHandler 
            ref={this.mouseHandlerRef}  
            cursorRef={this.cursorRef}  
            selectionViewRef={this.selectionViewRef}
            timelineSegmentsRef={this.timelineSegmentsRef}
            setPlayhead={this.props.delegate.setPlayhead}
            >    
              <div  
              className="TimelineView" 
              ref={this.mainRef}
              // onMouseDown={this.onMouseDown}
              // onMouseUp={this.onMouseUp}
              // onMouseMove={this.onMouseMove}
              >
              <PlayheadView categories={this.props.categories} delegate={this.props.delegate} />
                  <TimelineSegmentsView
                    ref={this.timelineSegmentsRef}
                    categories={this.props.categories}
                    isPlaying={this.props.isPlaying} 
                    playingId={this.props.playingId}
                    playAtTime={this.props.playAtTime} 
                    pause={this.props.pause}
                    mouseHandlerRef={this.mouseHandlerRef}

                    adjustStartTime={this.props.delegate.adjustStartTime}
                    adjustEndTime={this.props.delegate.adjustEndTime}
                    adjustPosition={this.props.delegate.adjustPosition}

                    deleteSegment={this.props.delegate.deleteSegment}
                    

                    />
  <SelectionView 
                ref={this.selectionViewRef}

                categories={this.props.categories}
                addSegmentToCategory={this.props.delegate.addSegmentToCategory}
            isPlaying={this.props.isPlaying} 
            playingId={this.props.playingId}
            playAtTime={this.props.playAtTime} 
            pause={this.props.pause}
            
            />

        <CursorView 
          ref={this.cursorRef} 
          duration={this.props.duration} 
          mouseHandler={this.mouseHandlerRef} 
          categories={this.props.categories}
           />

        
            <div className="waveform">
              <WaveformView dataPoints={this.props.dataPoints} />
            </div>
        <TimelineTracks 
          duration={this.props.duration}      
          categories={this.props.categories}
        />
    </div>   </MouseHandler>
  }
}