
import React from 'react';
import { SelectionList,Option, Button } from '@swivl/great-grey-components';
import { CreateLogger,Assignment,WorkRowStatus,CreateRowChange_Intent, TaskData_Intent, AssignmentWorkRow, Intent, DataSetRow, TaskData_ImageCategorize, TaskCategory, CreateRowChange_ImageCategorize, TaskData_ImageBoundingBox, BoundingBoxType, BoundingBox, CreateRowChange_ImageBoundingBox, RowChangeType } from '@swivl/great-grey-lib';
import { ExerciseCard,ExerciseCardProps, ExerciseCardState } from '../../../Screens/Assignment/Exercises/Card/ExerciseCard';
import "./ImageBoundingBoxExerciseView.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Service from '../../../Services/Service';
import AnnotationTool from './AnnotationTool/AnnotationTool';
const log = CreateLogger("IntentExercise:Main");




export class ImageBoundingBoxExerciseView extends ExerciseCard<ExerciseCardProps,ExerciseCardState> {
  hasUpdated = false; 
  selectedIntent?:Intent;
  constructor(props:any) {
    super(props) 
    this.state = {isShowing:false, data:{}}
    this.rectanglesUpdate = this.rectanglesUpdate.bind(this)

  }
  handleKeyDown(event:KeyboardEvent)  {
    // log("Key Down");

    
    if  (event.key === "Enter") {
        this.doneClicked()
        
    }
  }
  

  categorySelected(category:TaskCategory) {
    if (category.specific && category.specific.length) {
      this.setState({data:{showingSpecific:category}});
      return;
    }

    let row:AssignmentWorkRow = this.props.work;


    // row.change = CreateRowChange_ImageCategorize({id:category.id, name:category.name})
    log("Change", row.change)
    this.props.update(row)

  }
  doneClicked() { 
    console.log("this.props.work",this.props.work) 
    let row:AssignmentWorkRow = this.props.work;
    if (row.tempData) {
        row.change = row.tempData;
        this.props.save(row); 

    }


 }




  rectanglesUpdate(rectangles:any[], image:HTMLImageElement) {
    var boundingBoxes:BoundingBox[] = [];
    let task = (this.props.assignment.task as TaskData_ImageBoundingBox);
    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 

    for (let i = 0; i < rectangles.length; i++) {
        const rect = rectangles[i];
        const cat = (originalRow.categories && task.categoryId) ?  originalRow.categories[task.categoryId] : null
        boundingBoxes.push({ 
                            id: rect.key,
                            type: BoundingBoxType.Rectangle,
                            points: {
                                x_relative_min: rect.x/image.width, 
                                y_relative_min: rect.y/image.height, 
                                x_relative_max: (rect.x + rect.width) / image.width,
                                y_relative_max: (rect.y + rect.height) / image.height,
                            },
                            category: cat
        })
    }
    console.log("rectanglesUpdate", rectangles, boundingBoxes);

    let row:AssignmentWorkRow = this.props.work;


    row.tempData = CreateRowChange_ImageBoundingBox(boundingBoxes)
    this.props.update(row);

  }


  renderContent() {
    let task = (this.props.assignment.task as TaskData_ImageBoundingBox);
    const originalRow:DataSetRow = this.props.assignment.subTask.data[this.props.work.originalRowId] 
    let row:AssignmentWorkRow = this.props.work;

    // console.log("MY TASK", task)
    // let {data} = this.state; 
    // let specificHeading;
    // let buttons = []

    // for (let i = 0; i < task.categories.length; i++) {
    //   const cat = task.categories[i];
      
    //   if (data.showingSpecific) {
    //     if (data.showingSpecific.id === cat.id) {
    //     specificHeading = <h3><FontAwesomeIcon  icon={faArrowLeft} onClick={this.clearSelected} />{cat.specificTitle}</h3>
    //     buttons = cat.specific.map((item) => <ImageBoundingBoxExerciseViewButton key={item.id} category={item} categorySelected={this.categorySelected} /> )
    //   }
    //   } else {
    //     buttons.push( <ImageBoundingBoxExerciseViewButton key={cat.id} category={cat} categorySelected={this.categorySelected} /> )
    //   }
    // }

    const imageURL = Service.File.mediaURL(originalRow.imageURL) ;
    // const boundingBoxes = [ {
    //     id:"a1234",
    //     type: BoundingBoxType.Rectangle,
    //     points: {  x_relative_min: 0.5,
    //         y_relative_min: 0.5,
    //         x_relative_max: 0.9,
    //         y_relative_max: 0.9
    //     }
    // }]

    console.log("Row", row)
    const initialBoundingBoxes = (row.tempData && row.tempData.type === RowChangeType.ImageBoundingBox) ? row.tempData.boundingBoxes : null; 
    return <div className="ImageBoundingBoxExerciseView">

        <AnnotationTool
            initialBoundingBoxes={initialBoundingBoxes}
            imageURL={imageURL}
            rectanglesUpdate={this.rectanglesUpdate}
            
            />
    </div>   
  }
}
