import React from 'react';


import "./AddUserPopover.scss";
import { Model } from '../../Models/Model';
import { Popover, NavTextButton, SelectionList,Option, Select, Input, Size, InputType, Button, Color } from '@swivl/great-grey-components';
import { Organisation, OrganisationMembership, Project, ProjectMembershipType, ProjectPermissionType, User } from '@swivl/great-grey-lib';
import { faSearch, faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Toast } from '../../Services/ToastService';
import { Action, Actions, ActionsConsumer, ActionType } from '../../Actions/Actions';

// import * as Model from '../../Models/Model';
interface Props {
    button?:string | React.ReactNode
    project?:Project
    exclude?:User[]

}
interface State {
  isPopoverOpen:boolean;
  isInvitingUser:boolean;
  searchQuery:string;
  users?:User[]

  // firstName:string,
  // lastName:string,
  email:string,
  newUserRole: ProjectMembershipType

  // password:string 
}
const options = [
  { value: ProjectMembershipType.Lead, label: 'LEAD' },
  { value: ProjectMembershipType.Member, label: 'MEMBER' },
  { value: ProjectMembershipType.Labeler, label: 'LABELER' },
];

type MemberProps = {  user: User, userSelected(user:User) }
export const Member = ({ user, userSelected }: MemberProps) => { 
  const onClick = () => { userSelected(user); }
  return <div className="member" onClick={onClick}>{user.firstName} {user.lastName}</div>
}

export default class AddUserPopover extends ActionsConsumer<Props,State> {
  constructor(props:Props) {
    super(props)

    this.state = {
      searchQuery:"", 
      isPopoverOpen:false, 
      isInvitingUser:(!props.project),
      // firstName:"",
      // lastName:"",
      email:"",
      // password:"" 
      newUserRole: ProjectMembershipType.Labeler
    }
 
    this.setIsPopoverOpen   = this.setIsPopoverOpen.bind(this)
    this.cancelClicked      = this.cancelClicked.bind(this)
    this.userSelected       = this.userSelected.bind(this)
    this.fieldUpdated       = this.fieldUpdated.bind(this)
    this.userRoleChanged    = this.userRoleChanged.bind(this)
    this.toggleNewUser      = this.toggleNewUser.bind(this)
    this.setisInvitingUser  = this.setisInvitingUser.bind(this)
    this.inviteNewUser      = this.inviteNewUser.bind(this)

    Actions.Controller.subscribeTo(this, [ ActionType.OrgMembershipsLoaded ] )

  }
  userRoleChanged(selectedOption:Option) { this.setState({newUserRole:selectedOption.value as ProjectMembershipType})}

  
  componentDidMount() {
    Model.OrgMembership.load()
    
    // .then((users) => {
    //     console.log("Got Users", users);
    //     if (users) {
    //       // const excludeIds = (this.props.exclude) ? this.props.exclude.map((item)=>item.id) : null; 
    //       // var theUsers = (Object.values(users) as User[]).filter((u) => {
    //       //   if (excludeIds && excludeIds.includes(u.id)) {
    //       //     return false; 
    //       //   }
    //       //   return true; 
    //       // })

    //       this.setState({users:(Object.values(users) as User[])})

    //     }
    // })
    
  }
    handleAction(action:Action) {
    if (action.type === ActionType.OrgMembershipsLoaded) {
      console.log("RESETTING OrgMembership Model");
      let memberships = Model.OrgMembership.array; 
      const excludeIds = (this.props.exclude) ? this.props.exclude.map((item)=>item.id) : null; 

      let users = []
      for (let i = 0; i < memberships.length; i++) {
        const item = memberships[i];
                if (item.user && !(excludeIds && excludeIds.includes(item.user.id))) {
             users.push(item.user)
            }
      }
      // let users = memberships.map((item:OrganisationMembership)=> { 
      //     if (!(excludeIds && excludeIds.includes(item.user.id))) {
      //         return item.user
      //       }
      // })
      users.sort(function(a, b) {
        var textA = a.firstName.toUpperCase();
        var textB = b.firstName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.setState({users:users})
      
    }
  }
  setIsPopoverOpen(isPopoverOpen:boolean) { 
    console.log("set", isPopoverOpen);
    
    this.setState({isPopoverOpen:isPopoverOpen})
  }
  cancelClicked() { 
    console.log("Cancel clicked");
    
    this.setState({isPopoverOpen:false})}

  userSelected(user:User) {
    console.log("User selected", user);
    if (this.props.project) {
      Model.OrgMembership.addMemberToProject(user,this.props.project)
    } else {
      // Model.OrgMembership.inviteUser(user,)
    }
    this.setState({isPopoverOpen:false})
  }
  toggleNewUser(event: React.MouseEvent<HTMLDivElement, MouseEvent>) { 
    console.log("Toggle new user");

    setTimeout(()=> {
      //NOTE: for some reason i need to delay this or it closed the popover
      this.setisInvitingUser(!this.state.isInvitingUser)

    },100)
  }

  fieldUpdated(event:React.ChangeEvent<HTMLInputElement>) {
    let state = this.state; 
    state[event.target.name] = event.target.value;
     this.setState(state)
  }

  setisInvitingUser(isInvitingUser) {
    this.setState({ isInvitingUser:isInvitingUser})
  }
  inviteNewUser(e:React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const {email, newUserRole} = this.state; 
    // if (!firstName.length) { Toast.Service.error("You must enter a first name"); return; }
    // if (!lastName.length)  { Toast.Service.error("You must enter a last name"); return; }
    if (!email.length)     { Toast.Service.error("You must enter a email"); return; }
    // if (!password.length)  { Toast.Service.error("You must enter a password"); return; }
    // if (this.props.project) {
    //   // Model.OrgMembership.createUserAndAddToProject(firstName, lastName, email, password,this.props.project)
    // } else {
    //   // Model.OrgMembership.createUserAndAddToOrg(firstName, lastName, email, password)
    // }

    Model.OrgMembership.inviteUser(email, newUserRole, (this.props.project) ? this.props.project : null)

    this.setState({      searchQuery:"", 
    isPopoverOpen:false, 
    isInvitingUser:false,
    // firstName:"",
    // lastName:"",
    email:"",
    // password:"" 
  })

  }
  renderInvite() {
    return <div className="AddUserPopover createNew">
      <header>
        <div className="backButton" onClick={this.toggleNewUser}>
        <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        Add New User
      </header>
      <form onSubmit={this.inviteNewUser}>


<label>EMAIL</label>

<Input 
name="email"
value={this.state.email} 
onChange={this.fieldUpdated}
size={Size.Small}
/>
<label>ROLE</label>
<Select options={options} value={this.state.newUserRole} onChange={this.userRoleChanged} />
{/* <label>Password</label> */}

{/* <Input 
name="password"
type={InputType.Password}
value={this.state.password} 
onChange={this.fieldUpdated}
size={Size.Small}
/> */}
<div className="buttons">
  <Button color={Color.Tan} size={Size.Small} onClick={this.toggleNewUser}>Cancel</Button>
  <Button color={Color.Primary} size={Size.Small} type={"submit"}>Add User</Button>

</div>
          </form>
      </div>

  }
   renderSelect() {
          const excludeIds = (this.props.exclude) ? this.props.exclude.map((item)=>item.id) : null; 
          // var theUsers = (Object.values(users) as User[]).filter((u) => {
          //   if (excludeIds && excludeIds.includes(u.id)) {
          //     return false; 
          //   }
          //   return true; 
          // })


    var userList;
    if (this.state.users) {
      console.log("this.state.users", this.state.users);
      
     userList = this.state.users.map((item)=> 
     {
      if (excludeIds && excludeIds.includes(item.id)) {
        return null; 
      }
       if (this.state.searchQuery.length) {
         if(!(item.firstName + " " + item.lastName).toLowerCase().includes(this.state.searchQuery)) {return null}
       }
     return <Member key={item.id} user={item} userSelected={this.userSelected} />
    }) 
     
   } else {
      userList = <div className="spinnerRow"><FontAwesomeIcon icon={faSpinner} spin/></div>;
   }
    return <div className="AddUserPopover">
      <header> 
        <Input 
          value={this.state.searchQuery} 
          name="searchQuery"
          onChange={this.fieldUpdated}
          size={Size.Small}
          rightIcon={faSearch}
          />
          <div className="cancelButton" onClick={this.cancelClicked}>Cancel</div>
      </header>
      <div className="members">
        <div 
          className="newAccountButton" 
          onClick={this.toggleNewUser}>+ Invite By Email</div>
      {userList}
      </div>
      </div>

  }
  
  render() {
    const {isPopoverOpen, isInvitingUser} = this.state
    const content = (isInvitingUser) ? this.renderInvite() : this.renderSelect() 
      return <Popover
          toggle={this.setIsPopoverOpen}
          isOpen={isPopoverOpen}
          align="start"
          position={['bottom', 'right']} // preferred position
          content={content}>
          <div className={(isPopoverOpen)?"addPopoverOpen popoverButton":"popoverButton"} style={{ float: "left" }} onClick={() => 
{            // this.setIsPopoverOpen(!isPopoverOpen)
}            }>
            {this.props.button}
          </div>
      </Popover>
  }

}
