import React from 'react';
import {DataSet,DataFileType, DataSetCreationMethod,DataSetRow,  CreateLogger, ColmunTypesForRows, DataSetType } from '@swivl/great-grey-lib';
import { Model } from '../../../../../Models/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faSadCry } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@swivl/great-grey-components';

import "./FileUpload.scss"
import Service from '../../../../../Services/Service';
import { FileUploadResponse } from '../../../../../Services/FileService';
import { render } from 'react-dom';
const log = CreateLogger("FileUpload")
interface FileUploadProps {

  dataSetName:string;
  creationMethod:DataSetCreationMethod;
  rows:Array<DataSetRow>;
  close():void;

}
interface FileUploadState{
  URL?:string,
  fileUploaded:boolean;
  dataSet?:DataSet;
  error?:Error
}

export  class FileUpload extends React.Component<FileUploadProps,FileUploadState> {
  constructor(props:any) {
    super(props)
    this.state = { fileUploaded:false, dataSet:null}
    this.uploadFile       = this.uploadFile.bind(this);
    this.updateProgress   = this.updateProgress.bind(this)
  }
  componentDidMount() {
    setTimeout(() => {this.uploadFile() }, 1000 );
  }
  uploadFile() {
    Model.DataSet.createAndAddNewDataSet(
      {
        name: this.props.dataSetName,
        type: DataSetType.Normal,
        rowCount: this.props.rows.length,
        columnTypes: ColmunTypesForRows(this.props.rows), 
        creationMethod: this.props.creationMethod,
        jobHistory: [],
        rows:this.props.rows,
        createdBy:Model.Session.state.user,
        organisationId:Model.Session.state.currentMembership.organisation.id,
        projectId:Model.Session.state.currentProjectMembership.project.id
        }).then((dataSet:DataSet) => { 
    Model.Navigation.setPath("/dataset/" + dataSet.id)
    this.setState({fileUploaded:true});
  }).catch((e:Error) => {
    this.setState({error:e})
    log.warn(e);
})




  }

  
  updateProgress(percentComplete:number) {
  
    const element = document.getElementById("uploadingProgressBar");
    if (element) {
     element.setAttribute("value", String(percentComplete));
    }
  }




  render() {
    if (this.state.error) {

      return <div className="FileUpload modalCard">
      <div className="modalHeader">
      </div>
      <div className="modalContent">
          <div className="ProgressBlock">

          <span><FontAwesomeIcon icon={faSadCry} size="7x" /></span>
          <h4>Error Creating Data Set</h4>
          {(this.state.error.message) ? this.state.error.message : "Unknown Error"}
          </div>
          </div>


      <div className="modalFooter">
      </div>
    </div>;

    }

    if (this.state.fileUploaded) {


        return <div className="FileUpload modalCard">
          <div className="modalHeader">
          </div>
          <div className="modalContent">
              <div className="ProgressBlock">

              <span><FontAwesomeIcon icon={faThumbsUp} size="7x" /></span>
              <h4>Data Set Created</h4>
              <Button isRound={true} onClick={this.props.close} >View</Button>
              </div>
              </div>


          <div className="modalFooter">
          </div>
        </div>;

      }

      return <div className="FileUpload modalCard">
        <div className="modalHeader">
        <h3>Standby while we upload your data.</h3>
        </div>
        <div className="modalContent">
        <div className="ProgressBlock">
            
            <progress id="uploadingProgressBar" className="progress is-primary" value="0" max="100">50%</progress>
            <h4>Uploading File</h4>


    </div>;


        </div>
        <div className="modalFooter">
        </div>
      </div>;

  }

}
