import React from 'react';


import "./SelectEntityPopover.scss";

import { Entity, EntityDefinition } from '@swivl/great-grey-lib';



// import * as Model from '../../Models/Model';
interface Props {
    colorForEntity:any;
    possibileEntities:EntityDefinition[]
    entitySelected(entity:EntityDefinition)
    deselect()

}
interface State {
  isPopoverOpen:boolean;
}

interface ItemProps {
    colorClass:string 
    entity:EntityDefinition;
    entitySelected(entity:EntityDefinition)
}

class SelectEntityPopoverItem extends React.Component<ItemProps> {
    constructor(props:ItemProps) { super(props); this.onClick = this.onClick.bind(this) }  
    onClick() {this.props.entitySelected(this.props.entity)}
    render() { return <div onClick={this.onClick} className={"SelectEntityPopoverItem color_"+this.props.colorClass}>{this.props.entity.name}</div>}
}

export default class SelectEntityPopover extends React.Component<Props,State> {
  node:any; 
  constructor(props:Props) {
    super(props)

    this.state = {
        isPopoverOpen:false
    }
 
    this.setIsPopoverOpen   = this.setIsPopoverOpen.bind(this)
   

  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)

  }
  handleClick = (e) => {
    if (!e.shiftKey && !(this.node && this.node.contains(e.target))) {
      this.props.deselect()
    }
  }
  setIsPopoverOpen(isPopoverOpen:boolean) { 
    
    this.setState({isPopoverOpen:isPopoverOpen})
  }
  
  
  
  render() {
    const {isPopoverOpen} = this.state
    const content = this.props.possibileEntities.map(entity => 
        <SelectEntityPopoverItem     
        key={entity.name}
        colorClass={this.props.colorForEntity[entity.name].name} 
        entity={entity}
        entitySelected={this.props.entitySelected} />
        )
    return <div             ref={node => this.node = node}
    className="SelectEntityPopover">
        {content}

    </div>
  }

}
