import { Input } from "@swivl/great-grey-components";
import { Category, TaskCategory } from "@swivl/great-grey-lib";
import React from "react";
import "./ImageCategorizeExerciseViewButton.scss"
interface Props { 
    category:TaskCategory;
    selectedCategory?:TaskCategory;
    categorySelected(category:TaskCategory)
}

interface State {}

export default class ImageCategorizeExerciseViewButton extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick() {
        this.props.categorySelected(this.props.category)
        
    }
    render() {
        const category = this.props.category;
        return <div className="ImageCategorizeExerciseViewButton" onClick={this.onClick}>
        {category.name}
      </div>
    }
}